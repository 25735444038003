import { NamedString } from './namedString.model';
import { Store, select } from '@ngrx/store';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map, filter} from 'rxjs/operators';
import * as fromReducers from './namedString.reducer';

@Pipe({name: 'injectedText'})
export class InjectedTextPipe implements PipeTransform {

    private debugModeIsActive = false;

    constructor(private store: Store<fromReducers.AppState>) {
        this.store.pipe(select(fromReducers.selectIsDebugModeActivated)).subscribe((activ) => {
          this.debugModeIsActive = activ;
        });
    }

    transform(key: string): Observable<string> {
        return this.store.pipe(
            select(fromReducers.selectEntitiesByID, { id: key }),
            map((localization: NamedString) => {
                if (this.debugModeIsActive) {
                  return key;
                }
                if (localization) {
                    return localization.text;
                } else {
                    // TODO this must return the undefined
                    // key in case we are in development mode.
                    // TODO In production it should always
                    // return "" if the key is not defined.
                    // or return a default value if possible
                    // return 'UNDEFINED KEY: ' + key;
                    return undefined;
                }
            }),
            filter((text: string) => text !== undefined));
    }
}
