import { Directive, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AnalyticsProvider } from "@modeso/twint-lib-analytics-fe";
import { BasePageComponent } from "../base.page";


@Directive({
    // tslint:disable-next-line: directive-selector
    selector: 'app-cancelation-policy-page-controller'
  })
export class CancellationPolicyController extends BasePageComponent  {
    constructor(
        injector: Injector,
        protected router: Router,
        protected route: ActivatedRoute,
        private analyticsProvider: AnalyticsProvider
      ) {
        super(injector);
      }

      /** NgInit */
      ngOnInit() {
        super.ngOnInit();
        this.savePageView();
      }

      savePageView() {
        this.analyticsProvider.savePageView$({
          productLine: null, pageName: 'First Cancelation Policy Page',
          terminalId: sessionStorage.getItem('terminalId'),
          pagePath: this.router.url,
        });
    }

}
