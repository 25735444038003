<div style="position: relative;">
  <div class="product-buy-container ihub-styles" style="background-color: white;">
    <dgoods-view-container>
      <h1>{{ "ihub_shop_summary" | injectedText | async}}</h1>
      <p style="margin-top: 16x;margin-bottom: 24px;"> {{"ihub_shop_please_check_your_selection" | injectedText |async }} </p>
    </dgoods-view-container>
    <div *ngIf="policy">
      <ihub-virtualpolicy [policy]="draft" [person]="person" [productIcon]="productIcon"></ihub-virtualpolicy>
    </div>
    
    <div *ngIf="hasShowSummary" class="insurance-information">
      <div *ngFor="let attribute of enrichedAdditionalAttributes" >
        <div *ngIf="attribute.showOnSummary" class="row">
          <span class="info_label">{{ attribute.shopTitle ? attribute.shopTitle : attribute.title}}</span>
          <span class="info">{{ attribute.value }}</span>
        </div>
      </div>
    </div>


    <div style="padding-bottom: 55px;">
      <hr class="hr-margin">
      <span class="pricetag">
        <div *ngIf="delayPaymentByMonth === 1"  class="discount-label">
          {{ 'ihub_first_month_free' | injectedText | async}}
        </div>
        <div *ngIf="delayPaymentByMonth > 1"  class="discount-label">
          {{ delayPaymentByMonth }} {{ 'ihub_shop_delay_payment_for' | injectedText | async}}
        </div>      
        <strong>{{ "ihub_shop_currency" | injectedText | async }} {{ draft?.price | number:'1.2-2' }}</strong> <span *ngIf="!product.isSinglePayment"> / {{ "ihub_shop_per_month" | injectedText | async }}</span> 
      </span>
    </div>

    <div style="margin-bottom: 50px;">


    <div class="div-1">
      <input type="checkbox" id="hasAccount" name="hasAccount" [checked]="hasAccount"
      (change)="hasAccount = !hasAccount; emitAcceptedTerms()" >
      <label for="hasAccount"  class="label-1"></label>        
      <span id="avbLink" class="span-text"></span>
    </div>

    <div class="div-1">
      <input type="checkbox" id="acceptTerms1" name="acceptTerms1" [checked]="acceptTerm1"
      (change)="acceptTerm1 = !acceptTerm1; emitAcceptedTerms()" >
      <label for="acceptTerms1"  class="label-1"></label>
      <span  class="span-text" [innerHTML]="'ihub_shop_accept_insurance' | injectedText | async"></span>
    </div>
       
    
    
    <div class="div-1">
      <input type="checkbox" id="receiveEmails" name="receiveEmails" [checked]="receiveEmails"
      (change)="receiveEmails = !receiveEmails;">
      <label for="receiveEmails"  class="label-1"></label>
      <span class="span-text" [innerHTML]="'ihub_shop_accept_to_recieve_mails' | injectedText | async"></span>
    </div>
        
          
    </div>

    <div class="wruth-logo">
    <ihub-wurth-logo></ihub-wurth-logo>
    </div>


    <div>
      <dgoods-button [delegate]="this" class="button-style" identifier="next" [disabled]="disabled">
        {{"ihub_shop_proceed_to_checkout" | injectedText | async}}
      </dgoods-button>
    </div>
    <div>
      <dgoods-button [delegate]="this" class="button-style" identifier="back" state="notAction">
        {{ "dgoods_back_button" | injectedText | async }}
      </dgoods-button>
    </div>
  </div>
  <div *ngIf="waitingForAppResponse" style=" display: flex; position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: white;">
    <mat-spinner style="margin:auto;"></mat-spinner>
  </div>
</div>