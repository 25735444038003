import { Directive, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InsuranceProvider } from '@modeso/ihub-lib-insurance-fe';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
import { DGoodsButtonComponent, IDGoodsButtonDelegate } from '@modeso/twint-lib-core-fe';
import { BasePageComponent } from '../base.page';
@Directive({
    // tslint:disable-next-line: directive-selector
    selector: 'app-my-insurance-details-page-controller'
  })
export class MyInsuranceDetailsPageControllerDirective extends BasePageComponent
implements OnInit, IDGoodsButtonDelegate {

    protected orderUuid: string;
    private terminalId: string;
    constructor(
        protected route: ActivatedRoute,
        protected insuranceProvider: InsuranceProvider,
        protected analyticsProvider: AnalyticsProvider,
        injector: Injector,
        protected router: Router,
    ) {
        super(injector);
        this.orderUuid = this.route.snapshot.paramMap.get('orderUuid');
        this.terminalId = sessionStorage.getItem('terminalId');
    }
    onButtonClicked(sender: DGoodsButtonComponent, identifier?: string): void {
        const currentLanguage = localStorage.getItem('currentLanguage');
        this.router.navigate([`../../${currentLanguage}/history`]);
    }

    ngOnInit() {
        super.ngOnInit();
        this.savePageView();
    }

    savePageView() {
        this.analyticsProvider.savePageView$({
          productLine: null, pageName: 'My Insurance Details',
          terminalId: this.terminalId,
          pagePath: this.router.url,
        });
    }
}
