import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions/dgoods.user.actions';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { DgoodsUserService } from '../services/dgoods.user.service';
const debug = Debug('modeso:ihub-lib-user-fe:UserEffects');
import { IUserStartUoFRegistrationRequest, IUserStartUoFRegistrationResponse } from '@modeso/types__ihub-lib-user-be';


@Injectable()
export class UserEffects {

  constructor(private actions$: Actions, private service: DgoodsUserService) { }

  onUpdateUser$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onUpdateUser.type),
      mergeMap(
        (payload: any) => {
          return this.service.updateUserEmail(payload.payload)
            .pipe(
              retry(1),
              tap(response => debug(response.email)
              ),
              map(
                response => (fromActions.onUpdateUserSuccess({ payload: response })),
              ),
              catchError((error) => of(fromActions.onUpdateUserFailed({ payload: error })))
            );
        }
      )
    )
  );

  /**
  * Effect of startUofRegistration.
  */
  startUofRegistration$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onStartUofRegistration.type),
      mergeMap(
        (action: fromActions.IActionWithPayload<IUserStartUoFRegistrationRequest>) => {
          debug(action);
          return this.service.startUofRegistration(action.payload)
            .pipe(
              // retry(1),
              map(
                response => (fromActions.onStartUofRegistrationSuccess({ payload: response })),
              ),
              catchError((error) => of(fromActions.onStartUofRegistrationFailed({ payload: error })))
            );
        }
      )
    )
  );

  /**
  * Effect of onUofDeRegistration.
  */
  onUofDeRegistration$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onUofDeRegistration.type),
      mergeMap(
        (action: any) => {
          debug(action);
          return this.service.uofDeRegistration()
            .pipe(
              // retry(1),
              map(
                response => (fromActions.onUofDeRegistrationSuccess({ payload: response })),
              ),
              catchError((error) => of(fromActions.onUofDeRegistrationFailed({ payload: error })))
            );
        }
      )
    )
  );

  errorOnUpdateUser$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onUpdateUserFailed.type),
      tap(
        (action: fromActions.IActionWithPayload<any>) => this.handleOnLoadAllCartErrors(action.payload)
      )
    )
    , { dispatch: false });


  errorOnUoFOperation$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onStartUofRegistrationFailed.type),
      tap(
        (action: fromActions.IActionWithPayload<any>) => this.handleOnLoadAllCartErrors(action.payload)
      )
    )
    , { dispatch: false });

  onCreatePerson$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onCreatePerson.type),
      mergeMap(
        (payload: any) => {
          return this.service.createPerson(payload.payload)
            .pipe(
              retry(1),
              tap(response => debug(response.id)
              ),
              map(
                response => (fromActions.onCreatePersonSuccess({ payload: response.id })),
              ),
              catchError((error) => of(fromActions.onCreatePersonFailed({ payload: error })))
            );
        }
      )
    )
  );

  getPerson$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.getPersonById.type),
      mergeMap(
        (payload: any) => {
          return this.service.getPerson(payload.payload)
            .pipe(
              retry(1),
              tap(
                response => debug(response)
              ),
              map(
                response => (fromActions.onGetPersonLoadedSuccessfully({ payload: response.person }))
              ),
              catchError((error) => of(fromActions.onGetPersonLoadingFailed({ payload: error })))
            );
        }
      )
    )
  );

  getPersonOfUser$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.getPersonOfUser.type),
      mergeMap(
        (payload: any) => {
          return this.service.getPersonOfUser(payload.payload)
            .pipe(
              retry(1),
              tap(
                response => debug(response)
              ),
              map(
                response => (fromActions.onGetPersonLoadedSuccessfully({ payload: response.person }))
              ),
              catchError((error) => of(fromActions.onGetPersonLoadingFailed({ payload: error })))
            );
        }
      )
    )
  );

  onUpdatePerson$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onUpdatePerson.type),
      mergeMap(
        (payload: any) => {
          return this.service.updatePerson(payload.payload)
            .pipe(
              retry(1),
              tap(response => debug(response.id)
              ),
              map(
                response => (fromActions.onUpdatePersonSuccess({ payload: response.id })),
              ),
              catchError((error) => of(fromActions.onUpdatePersonFailed({ payload: error })))
            );
        }
      )
    )
  );

  onGetPersonLoadingFailed$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onGetPersonLoadingFailed.type),
      tap(
        (action: fromActions.IActionWithPayload<any>) => {
          localStorage.removeItem('personId');
        },
      ),
    )
    , { dispatch: false },
  );

  getUser = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.getUserDetails.type),
      mergeMap(
        () => {
          return this.service.getUser()
            .pipe(
              retry(1),
              tap(
                response => debug(response)
              ),
              map(
                response => (fromActions.getUserDetailsSuccess({ payload: response }))
              ),
              catchError((error) => of(fromActions.getUserDetailsFailed({ payload: error })))
            );
        }
      )
    )
  );

  handleOnLoadAllCartErrors(error) {
    debug(error);
    return error;
  }

}
