export class DelayPaymemtUtil {

    delayPayment = parseInt(localStorage.getItem('delayPayment'));
    productLine = localStorage.getItem('productLineId');

    public  getDelayPaymentForProducts(products) {
        if (this.delayPayment) {
            if (this.productLine) {
              products.map((product) => {
                if (product.product === this.productLine) {
                  return product.delayPaymentByMonth = this.delayPayment ;
              }});
            } else {
              products.map((product) => {
                product.delayPaymentByMonth = this.delayPayment;
                return product; });
            }
        }
        return products;
    }


    public getDelayPaymentForProduct(product) {
        if (this.delayPayment) {
            if (this.productLine) {
              if (product.product === this.productLine) {
                product.delayPaymentByMonth = this.delayPayment;
              }
            } else {
                product.delayPaymentByMonth = this.delayPayment;
            }
          }
        return product;
    }
}
