import { Component, Input } from '@angular/core';

export interface IIHubTile {
  title: string;
  description: string;
  icon: string;
  blocked: boolean;
  reference: string;
  price?: string;
  priceStaticConfig?: string;
  needsTranslation?: boolean;
  delayPaymentByMonth: number;
  paymentPer?: string;
  isCategory?: boolean;
  isPromoProduct?: boolean;
}
export interface IIHubTileDelegate {
  onTileClick(item: IIHubTile): void;
}

@Component({
  selector: 'ihub-tile',
  templateUrl: './ihub-tile.component.html',
  styleUrls: ['./ihub-tile.component.scss']
})
export class IHubTileComponent {

  @Input() item: IIHubTile;
  @Input() delegate?: IIHubTileDelegate;
  @Input() isTransparent?= false;

  constructor() { }

  /**§
   * emit the card product to the parent "product-list" to navigate with it
   */
  onClick() {
    if (this.delegate) {
      this.delegate.onTileClick(this.item);
    }
  }

}
