import { Component, Input, OnInit , Output , EventEmitter, ViewChild} from '@angular/core';
import { IHubPopUpComponent, IIHubPopupContent } from '@modeso/twint-lib-core-fe';
import Debug from 'debug';
const debug = Debug('ihub:insurance:IncuranceItemComponent');
import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'ihub-insurance-details',
  templateUrl: './insurance-details.component.html',
  styleUrls: ['./insurance-details.component.scss']

})
export class InsuranceDetailsComponent implements OnInit  {

  @Input() subCategoryProducts: any;
  @Output() isRadioButtonChecked: EventEmitter<any> = new EventEmitter(null);
  @ViewChild('popup', { static: true }) popup: IHubPopUpComponent;
  // tslint:disable-next-line: variable-name
  _popupContent: IIHubPopupContent;
  services = {};

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit() {
    for (const product of this.subCategoryProducts) {
      this.services[product.product] = [];
      product.services.forEach(
        (service) => {
          let sub = [];
          if (service.sub) {
            sub = service.sub.map((sub1) => {
              return {
                text: sub1.title,
                description: sub1.text,
                cover: sub1.cover,
              };
            });
          }
          const s = {
            text: service.title,
            description: service.text,
            cover: service.cover,
            sub
          };
          this.services[product.product].push(s);
        });

      }
  }

  onClick(insurance) {
    if (!insurance.blocked) {
      this.isRadioButtonChecked.emit({checked: true , product: insurance.product });
    }
  }

  public openPopup(event: any, item) {
    event.stopPropagation();
    this._popupContent = {
      title: item.text,
      text: item.description,
    };
    this.popup.visible = true;
    debug('openPopup', item, event);
  }
}
