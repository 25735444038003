import { EntityState, EntityAdapter, createEntityAdapter, Dictionary } from '@ngrx/entity';
import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as fromActions from './../actions/localization.actions';
import { LocalizationModel } from '../models/localization.model';

export const featureKey = 'modesoLocalizationMicroservice';

// State Declarations - START
export const adapter: EntityAdapter<LocalizationModel> = createEntityAdapter<LocalizationModel>({
    selectId: selectContentId,
    sortComparer: sortById,
});

export function selectContentId(a: LocalizationModel): string {
    return a.key;
}

export function sortById(a: LocalizationModel, b: LocalizationModel): number {
    return a.key.localeCompare(b.key);
}

export interface FeatureState extends EntityState<LocalizationModel> {
  debugMode: boolean;
}

export interface AppState {
    modesoLocalizationMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.modesoLocalizationMicroservice;
export const selectFeatureContent = createSelector(
    selectFeature,
    (state: FeatureState) => state
);


export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();

export const selectLocalizationEntities = createSelector(
    selectFeatureContent,
    selectEntities,
);

export const selectContentAllEntities = createSelector(
    selectFeatureContent,
    selectAll
);

export const selectEntitiesByID = createSelector(
    selectLocalizationEntities,
    (entities, props) => {
        return entities[props.id];
    }
);

export const selectIsDebugModeActivated = createSelector(
  selectFeatureContent,
  (state: FeatureState) => {
    return state.debugMode;
  }
);

// Selectors Declarations - END

// Reducer Declarations - START
export const initialState: FeatureState = adapter.getInitialState({
  debugMode: false
});

const contentReducer = createReducer(
    initialState,
    on(fromActions.getLocalizations, state => ({ ...state})),
    on(fromActions.onLocalizationsLoadedSuccessfully, (state, action) => {
        return adapter.addMany(action.payload, {...state});
    }
    ),
    on(fromActions.onLocalizationsLoadingFailed, state => ({ ...state})),
    on(fromActions.getLocalizationByKey, state => ({ ...state})),
    on(fromActions.onLocalizationByKeyLoadedSuccessfully, (state, action) => {
            return adapter.addOne(action.payload, {...state});
        }
    ),
    on(fromActions.onLocalizationByKeyLoadingFailed, state => ({ ...state})),
    on(fromActions.onActivateDebugMode, (state, action) => ({ ...state, debugMode: action.payload})),
);

export function reducer(state: FeatureState | undefined, action: Action) {
    return contentReducer(state, action);
}
// Reducer Declarations - END


