import { Action, createReducer, on, createSelector } from '@ngrx/store';
import { ICreatePersonRequest } from '@modeso/types__ihub-lib-user-be';
import * as fromActions from './../actions/dgoods.user.actions';
import { IUser } from '../models/user.model';


export const userFeatureKey = 'modesoUserMicroservice';

// State Declarations - START

export interface FeatureState {
  email: string;
  status?: string;
  uofRegistartionToken: string;
  id: string;
  person: ICreatePersonRequest;
  uofDeregistrationResponse: boolean;
  user:IUser;
}

export interface AppState {
  modesoUserMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.modesoUserMicroservice;
export const featureUser = createSelector(
  selectFeature,
  (state: FeatureState) => state
);


export const featureUofRegistrationToken = createSelector(
  selectFeature,
  (state: FeatureState) => state.uofRegistartionToken
);


export const featurePersonId = createSelector(
  selectFeature,
  (state: FeatureState) => state.id
);

export const featurePerson = createSelector(
  selectFeature,
  (state: FeatureState) => state.person
);

export const featureUofStatus = createSelector(
  selectFeature,
  (state: FeatureState) => state.user
);
// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {
  email: null,
  uofRegistartionToken: null,
  status: undefined,
  uofDeregistrationResponse: false,
  id: undefined,
  person: undefined,
  user:undefined
};

const userReducer = createReducer(
  initialState,
  on(fromActions.onUpdateUser, (state) => ({ ...state })),
  on(fromActions.onUpdateUserSuccess, (state, action) => ({ ...state, email: action.payload.email, status: undefined })),
  on(fromActions.onUpdateUserFailed, (state, action) => ({ ...state, status: action.payload.status })),

  on(fromActions.onStartUofRegistration, (state) => ({ ...state, uofRegistartionToken: null })),
  on(fromActions.onStartUofRegistrationSuccess, (state, action) => ({ ...state, uofRegistartionToken: action.payload.registrationToken })),
  on(fromActions.onStartUofRegistrationFailed, (state, action) => ({ ...state, status: action.payload.status })),
  on(fromActions.onCreatePerson, (state) => ({ ...state })),
  on(fromActions.onCreatePersonSuccess, (state, action) => ({ ...state, id: action.payload, status: undefined })),
  on(fromActions.onCreatePersonFailed, (state, action) => ({ ...state, status: action.payload.status })),
  on(fromActions.getPersonById, state => ({ ...state })),
  on(fromActions.onGetPersonLoadedSuccessfully, (state, action) => ({ ...state, person: action.payload })),
  on(fromActions.onGetPersonLoadingFailed, (state) => ({ ...state })),
  on(fromActions.onUpdatePerson, (state) => ({ ...state })),
  on(fromActions.onUpdatePersonSuccess, (state, action) => ({ ...state, id: action.payload, status: undefined })),
  on(fromActions.onUpdatePersonFailed, (state, action) => ({ ...state, status: action.payload.status })),
  on(fromActions.onUofDeRegistration, (state) => ({ ...state, uofRegistartionToken: null })),
  on(fromActions.onUofDeRegistrationSuccess, (state, action) => ({ ...state, uofDeregistrationResponse: action.payload.success })),
  on(fromActions.onUofDeRegistrationFailed, (state, action) => ({ ...state, status: action.payload.status })),

  on(fromActions.getUserDetails, (state) => ({ ...state })),
  on(fromActions.getUserDetailsSuccess, (state, action) => ({ ...state, user: action.payload })),
  on(fromActions.getUserDetailsFailed, (state) => ({ ...state }))

);

export function reducer(state: FeatureState | undefined, action: Action) {
  return userReducer(state, action);
}
