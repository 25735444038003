import { Component, Renderer2, OnInit, OnChanges, OnDestroy, AfterViewInit } from '@angular/core';
import { DGoodsPriceOptionComponent } from './dgoods-price-option.component';
import Debug from 'debug';
import { first } from 'rxjs/operators';
import { InjectedTextPipe } from '@modeso/modeso-lib-core-fe';
import { Subscription } from 'rxjs';
const debug = Debug('dgoods:shared:DGoodsPriceOptionDenominationComponent');

export enum DenominationComponentState {
  UNSELECTED,
  SELECTED,
  SELECTED_DATAINPUT,
}

@Component({
  selector: 'dgoods-price-option-denomination',
  templateUrl: './dgoods-price-option-denomination.component.html',
  styleUrls: ['./dgoods-price-option.component.scss', './dgoods-price-option-denomination.component.scss']
})
export class DGoodsPriceOptionDenominationComponent extends DGoodsPriceOptionComponent implements
  OnInit, OnChanges, OnDestroy, AfterViewInit {

  private label: HTMLElement;
  private inputField: HTMLInputElement;
  private inputGroup: HTMLDivElement;
  private componentState: DenominationComponentState;
  private defaultValue = 0;
  private listenerBlur: () => void;
  private listenerFocus: () => void;
  private listenerKeyPress: () => void;
  private listenerKeyDown: () => void;
  private subs: Subscription;
  private inputValue: number;
  private lastValue = 0;
  private viewIsReady = false;

  constructor(protected renderer: Renderer2, private localize: InjectedTextPipe) {
    super(renderer);
  }

  ngOnInit(): void {
    this.constructView();
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.viewIsReady = true;
    if (this.selected) {
      this.selectState();
    } else {
      this.unselectState();
    }
  }

  private constructView() {
    this.label = this.renderer.createElement('label');
    this.inputField = this.renderer.createElement('input');
    this.inputGroup = this.renderer.createElement('div');
    this.renderer.appendChild(this.inputGroup, this.inputField);

    this.renderer.addClass(this.label, 'dgoods-option-label');
    this.renderer.addClass(this.inputGroup, 'dgoods-option-inputGroup');
    this.renderer.setAttribute(this.inputField, 'type', 'text');
    // this.renderer.setAttribute(this.inputField, 'inputmode', 'numeric');
    this.renderer.setAttribute(this.inputField, 'pattern', '[0-9]*');
    this.renderer.setAttribute(this.inputField, 'oninput', 'this.value = this.value.replace(/[^0-9.]/g, \'\') && (parseInt(this.value));');
    // this.renderer.setAttribute(this.inputField, 'oninput', 'this.value=(parseInt(this.value)||0)');

    // inputmode="numeric" pattern="[0-9]*"

    this.renderer.appendChild(this.rootelement.nativeElement, this.label);
    this.renderer.appendChild(this.rootelement.nativeElement, this.inputGroup);
    this.listenerBlur = this.renderer.listen(this.inputField, 'blur', () => {
      debug('Blur');
      this.onBlurInput();
    });
    this.listenerFocus = this.renderer.listen(this.inputField, 'focus', () => {
      debug('Focus');
      // this.onBlurInput();
    });
    // this.listenerKeyPress = this.renderer.listen(this.inputField, 'keypress', (event) => {
    //   debug('keypress');
    //   const charCode = (event.which) ? event.which : event.keyCode;
    //   debug(charCode);
    //   if ((charCode < 48 || charCode > 57) && charCode !== 8) {
    //     return false;
    //   }
    //   return true;
    //   // this.onBlurInput();
    // });
    // this.listenerKeyDown = this.renderer.listen(this.inputField, 'keydown', (event) => {
    //   debug('keydown');
    //   const charCode = (event.which) ? event.which : event.keyCode;
    //   debug(charCode);
    //   if ((charCode < 48 || charCode > 57) && charCode !== 8) {
    //     return false;
    //   }
    //   return true;
    //   // this.onBlurInput();
    // });

    this.subs = this.localize.transform('dgoods_shared_priceoptiondenomination_ownAmount').subscribe((element) => {
      debug('TEXT');
      const text = this.renderer.createText(element);
      if (this.label.hasChildNodes()) {
        this.renderer.removeChild(this.label, this.label.firstChild);
      }
      this.renderer.appendChild(this.label, text);
    });
  }

  public onClickOnAmount(event?: Event) {
    if (event) {
      // event.stopPropagation();
    }
  }

  protected updateView() {
    debug('Update view');
    debug(this.componentState);
    super.updateView();
    if (!this.selected) {
      this.componentState = DenominationComponentState.UNSELECTED;
      // this.renderer.removeClass(this.rootelement.nativeElement, 'selected');
    }
    if (!this.viewIsReady) {
      return;
    }
    switch (this.componentState) {
      case DenominationComponentState.UNSELECTED:

        this.unselectStyles();
        if (!this.amountIsValid()) {
          this.setErrorStyle();
        }
        break;

      case DenominationComponentState.SELECTED_DATAINPUT:
        this.dataInputState();
        break;

      case DenominationComponentState.SELECTED:
        this.selectStyles();
        break;

    }


  }

  onToggle() {
    debug('onToggle');
    debug(this.componentState);
    switch (this.componentState) {
      case DenominationComponentState.UNSELECTED:
        super.onToggle();
        this.dataInputState();
        break;

      case DenominationComponentState.SELECTED_DATAINPUT:
        if (this.value > 0) {
          this.selectState();
          if (this.delegate) {
            let ref = this.reference;
            if (this.price && this.price.reference) {
              ref = this.price.reference;
            }
            if (this.selected) {
              this.delegate.onPriceSelected(this.value, this.reference);
            } else {
              this.delegate.onPriceUnselected(this.value, this.reference);
            }
          } else {
            if (this.selected) {
              debug('onPriceSelected event not fired. There is no delegate attached to the component.');
            } else {
              debug('onPriceUnselected event not fired. There is no delegate attached to the component.');
            }
          }
        } else {
          super.onToggle();
          this.unselectState();
        }
        break;

      case DenominationComponentState.SELECTED:
        super.onToggle();
        this.unselectState();
        break;

    }

  }


  private selectState() {
    this.selectStyles();
    this.componentState = DenominationComponentState.SELECTED;
  }

  private selectStyles() {
    debug('set selectStyles');
    this.renderer.setStyle(this.amount.nativeElement, 'display', '');
    this.renderer.setStyle(this.label, 'display', 'none');
    this.renderer.addClass(this.rootelement.nativeElement, 'selected');
    this.renderer.removeClass(this.rootelement.nativeElement, 'placeholder');
    this.renderer.removeClass(this.inputGroup, 'visible');
    this.renderer.removeClass(this.rootelement.nativeElement, 'error');
    this.renderer.removeClass(this.description.nativeElement, 'error');
  }

  private dataInputState() {
    debug('set dataInputState');
    this.renderer.setStyle(this.label, 'display', 'none');
    this.renderer.setStyle(this.amount.nativeElement, 'display', 'none');
    this.renderer.addClass(this.inputGroup, 'visible');
    this.renderer.removeClass(this.rootelement.nativeElement, 'placeholder');
    this.renderer.addClass(this.rootelement.nativeElement, 'selected');
    if (this.value === 0 && !this.hasValidationError) {
      this.renderer.setProperty(this.inputField, 'value', '');
    }
    this.renderer.removeClass(this.rootelement.nativeElement, 'error');
    this.renderer.removeClass(this.description.nativeElement, 'error');
    this.inputField.focus();
    this.componentState = DenominationComponentState.SELECTED_DATAINPUT;
  }


  private unselectState() {
    this.unselectStyles();
    this.componentState = DenominationComponentState.UNSELECTED;
  }

  private unselectStyles() {
    debug('set unselectStyles');
    this.renderer.setStyle(this.label, 'display', '');
    this.renderer.setStyle(this.amount.nativeElement, 'display', '');
    debug('value');
    debug(this.value);
    debug('Value: ' + this.value);

    this.renderer.addClass(this.rootelement.nativeElement, 'placeholder');
    this.renderer.removeClass(this.rootelement.nativeElement, 'selected');

    if (!this.hasValidationError) {
      this.renderer.setProperty(this.amount.nativeElement, 'innerText', 'CHF 00');
      this.renderer.setProperty(this.inputField, 'value', '');
    }
    this.renderer.removeClass(this.rootelement.nativeElement, 'error');
    this.renderer.removeClass(this.description.nativeElement, 'error');
    this.renderer.removeClass(this.inputGroup, 'visible');
  }

  private setErrorStyle() {
    debug('set errorStyle');
    this.renderer.setStyle(this.label, 'display', '');
    this.renderer.setStyle(this.amount.nativeElement, 'display', '');
    debug('value');
    debug(this.value);
    this.renderer.addClass(this.rootelement.nativeElement, 'error');
    this.renderer.addClass(this.description.nativeElement, 'error');

    if (this.value === 0) {
      this.renderer.addClass(this.rootelement.nativeElement, 'placeholder');
      this.renderer.removeClass(this.rootelement.nativeElement, 'selected');
    } else {
      this.renderer.removeClass(this.rootelement.nativeElement, 'placeholder');
      this.renderer.removeClass(this.rootelement.nativeElement, 'selected');
    }
    this.renderer.removeClass(this.inputGroup, 'visible');
  }

  private onBlurInput() {
    this.inputValue = parseFloat(parseFloat(this.inputField.value).toFixed(0));
    this.inputValue = Math.round(this.inputValue);
    this.lastValue = this.inputValue;
    debug('Input Value: ' + this.inputValue);
    debug('Value: ' + this.value);
    if (this.inputValue >= this.price.minValue && this.inputValue <= this.price.maxValue) {
      this.value = this.inputValue;
      debug('Value: ' + this.value);
      const renderedValue = this.inputValue.toFixed(0);
      this.renderer.setProperty(this.amount.nativeElement, 'innerText', 'CHF ' + renderedValue);
      this.hasValidationError = false;
      this.onToggle();
    } else {
      if (isNaN(this.inputValue)) {
        this.inputValue = 0;
      }
      let renderedValue = this.inputValue.toFixed(0);
      if (renderedValue === '0') {
        renderedValue = '00';
      }
      const errordValue = this.inputValue;
      this.value = this.defaultValue;
      this.inputValue = this.defaultValue;

      debug('Value: ' + this.value);
      this.renderer.setProperty(this.amount.nativeElement, 'innerText', 'CHF ' + renderedValue);
      this.renderer.setProperty(this.inputField, 'value', errordValue);
      this.hasValidationError = true;
      this.value = 0;
      this.price.value = 0;
      this.onCancel();
    }
  }

  public amountIsValid() {
    const amount = this.lastValue;
    debug('lastValue');
    debug(amount);
    if (isNaN(amount) || amount === 0) { return true; }
    return this.inputValue >= this.price.minValue && this.inputValue <= this.price.maxValue;
  }

  private onCancel() {

    this.onToggle();

  }



  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
    if (this.listenerBlur) {
      this.listenerBlur();
    }
    if (this.listenerFocus) {
      this.listenerFocus();
    }
    if (this.listenerKeyPress) {
      this.listenerKeyPress();
    }
    if (this.listenerKeyDown) {
      this.listenerKeyDown();
    }
  }
}
