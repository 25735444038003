
import { ChangeDetectorRef, Directive, Injector, NgModule, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DGoodsButtonComponent,
  IHubSwitchComponent, IIHubSwitchDelegate, IIHubSwitchSelection,
  IHubPopUpComponent, IIHubPopupContent
} from '@modeso/twint-lib-core-fe';
import Debug from 'debug';
import { ICreateInsuranceRequest, PriceCalculatorFactory } from '@modeso/types__ihub-lib-insurance-be';
import { Prices, ProductDTO, VariationConfiguration } from '@modeso/types__ihub-lib-products-be';
import { InsuranceProvider } from '@modeso/ihub-lib-insurance-fe';
import { ProductStoreProvider } from '@modeso/ihub-lib-products-fe';
import { Observable } from 'rxjs';
import { InsuranceCheckoutBaseComponent } from '../InsuranceCheckout/InsuranceCheckoutBase.component';
import { DelayPaymemtUtil } from '../../utils/delayPaymentUtil';
const debug = Debug('dgoods:project:InsuranceBuyPageControllerDirective');
import { Subscription } from 'rxjs';
import moment from 'moment';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'app-buy-page-controller'
})
export class InsuranceBuyPageControllerDirective extends InsuranceCheckoutBaseComponent
  implements IIHubSwitchDelegate {

  protected productcode;
  public insuranceProduct: ProductDTO;
  public disabled = false;
  protected observables = new Map<string, Observable<ProductDTO>>();
  public singlePrices = new Map<string, number>();
  public familyPrices = new Map<string, number>();
  public familyOrSingleSelection = [];
  public variationSelections = [];
  public activePrice;
  private priceCalculatorFactory: PriceCalculatorFactory;
  @ViewChild('popup', { static: true }) popup: IHubPopUpComponent;
  // tslint:disable-next-line: variable-name
  _popupContent: IIHubPopupContent;
  additionalAttributes: any;
  currentUserPrice: any;
  dateObject: any = {};
  parentCategory = [];
  personObject: any;
  waitingForPrice = false;
  waitingForResponse = false;
  createInsurance$: Subscription;
  priceSubscription$: Subscription;
  variations = [];
  isSelectionToggled = false;
  selectedVariation: string;
  priceCalculationResponse = [];
  priceValidation = {} as { min: any, max: any };
  externalPriceError = false;
  changeValue = false;
  isDateChanged = false;
  constructor(
    public router: Router,
    route: ActivatedRoute,
    insuranceProvider: InsuranceProvider,
    productProvider: ProductStoreProvider,
    injector: Injector,
  ) {
    super(router, route, insuranceProvider, productProvider, injector);
    this.productcode = this.route.snapshot.paramMap.get('product');
    this.getProductByProductCodeFromStore();
    this.priceCalculatorFactory = PriceCalculatorFactory.getInstanceOfPriceFactory();

  }

  /** NgInit */
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() {
    super.ngOnInit();
    let draftAddititionalAttributes;
    let formArrayAttributes;

    if (this.draft && this.checkOnAdditionalAttributes()) {
      draftAddititionalAttributes = [];
      formArrayAttributes = [];

      this.insuranceProduct.additionalAttributes.forEach((attribute) => {
        const value = this.draft.additionalAttributes[0] ? this.draft.additionalAttributes[0][attribute.id] :
          this.draft.additionalAttributes[attribute.id];

        const formArrayFields = [];

        if (attribute.hasFormArray) {
          // incase of of draft & hasFormArray => update the formArrayFields with the additionalAttr. values
          formArrayAttributes = this.draft.additionalAttributes[0] ? this.draft.additionalAttributes[0][attribute.formArrayId] :
            this.draft.additionalAttributes[attribute.formArrayId];
          if (+value === formArrayAttributes.length + 1) {
            for (let i = 0; i < value - 1; i++) {
              formArrayFields.push(attribute.formArrayFields.map(att => {
                return { ...att, value: formArrayAttributes[i][att.id] };
              }));
            }
          }
          if (+value === +formArrayAttributes.length) {
            for (let i = 0; i < value; i++) {
              formArrayFields.push(attribute.formArrayFields.map(att => {
                return { ...att, value: formArrayAttributes[i][att.id] };
              }));
            }
          }
        }

        if (attribute.embeddedAdditionalAttributes && attribute.embeddedAdditionalAttributes.length > 0) {
          // tslint:disable-next-line: no-shadowed-variable
          attribute.embeddedAdditionalAttributes.forEach((attribute) => {
            // tslint:disable-next-line: no-shadowed-variable
            const value = this.draft.additionalAttributes[0] ? this.draft.additionalAttributes[0][attribute.id] :
              this.draft.additionalAttributes[attribute.id];
            attribute.value = value;
          });
        }

        formArrayAttributes.length ? draftAddititionalAttributes.push({ ...attribute, formArrayFields, formArrayAttributes, value }) :
          draftAddititionalAttributes.push({ ...attribute, value });
      });
      this.currentUserPrice = this.draft.additionalAttributes[0] ? this.draft.additionalAttributes[0] : this.draft.additionalAttributes;

    }
    this.additionalAttributes = draftAddititionalAttributes || this.insuranceProduct.additionalAttributes;
    this.changeValue = !this.draft ? true : false;
  }

  /** IDGoodsButtonDelegate --- START */

  onButtonClicked(sender: DGoodsButtonComponent, id: string): void {
    debug('Button clicked', sender);
    if (id === 'next') {
      // Navigate to next page
      this.navigateNext();
    } else if (id === 'back') {
      // Navigate back
      this.navigateBack();
    }
  }

  private navigateBack() {

    this.productcode = this.parentCategory.length > 0 ? this.parentCategory[0].product : this.productcode;
    this.router.navigate([`${this.languageFromURL}/insurance-detail/` + this.productcode]);
  }
  private navigateNext() {
    // Set insurance product in store
    this.createInsurance();
  }

  /** IDGoodsButtonDelegate --- END */


  /** IIHubSwitchSelection --- START */

  onSelect(selection: IIHubSwitchSelection, sender: IHubSwitchComponent, id: string): void {
    debug(selection);
    this.isSelectionToggled = true;
    this.selectedVariation = selection.reference;
    this.calculatePrice();
  }

  /** IIHubSwitchSelection --- END */

  protected getProductByProductCodeFromStore() {
    debug('register get product observable');
    this.observables.set('getProductByProductCodeFromStore', this.productProvider.getProduct$(this.productcode));

    this.productProvider.getAllProducts$().subscribe((products) => {
      if (products) {
        this.parentCategory = products.filter((prod) => {
          return prod.subCategory.length > 0 && prod.subCategory.includes(this.productcode);
        });
      }
    });

    this.subscriptions.push(this.productProvider.getProduct$(this.productcode).subscribe((product) => {
      this.insuranceProduct = { ...product };
      this.insuranceProduct = new DelayPaymemtUtil().getDelayPaymentForProduct(this.insuranceProduct);

      if (this.insuranceProduct.additionalAttributes) {
        const findAttribute = this.insuranceProduct.additionalAttributes.find((addAttr) => addAttr.id === 'price');
        if (findAttribute) {
          const priceValidationObj = findAttribute.validation as any;
          this.priceValidation = {
            max: priceValidationObj.max.value,
            min: priceValidationObj.min.value
          };
        }
      }


      debug(this.insuranceProduct);
      this.singlePrices = new Map<string, number>();
      this.familyPrices = new Map<string, number>();
      if (product) {
        (product.variations).forEach(element => {
          if (this.isNumber(product.singlePrices, element.name)) {
            this.singlePrices.set(element.name, product.singlePrices[element.name] as number);
          }
          if (product.hasFamily && this.isNumber(product.familyPrices, element.name)) {
            this.familyPrices.set(element.name, product.familyPrices[element.name] as number);
          }
        });
        if (product.additionalAttributes && !this.draft) {
          this.disabled = true;
        }
      }
    }));
  }

  protected calculatePrice() {
    let variation: string;
    if (this.variationSelections.length > 0) {
      variation = this.variationSelections.filter((v: IIHubSwitchSelection) => v.selected === true)[0].reference;
    }
    let family = false;
    if (this.familyOrSingleSelection.length > 0 && this.familyOrSingleSelection[1].selected) {
      family = true;
    }
    let price: number;
    if (family && typeof this.familyPrices.get(variation) !== 'number') {
      price = this.calculatePriceByType(this.insuranceProduct.familyPrices, variation, this.currentUserPrice);
    } else if (!family && typeof this.singlePrices.get(variation) !== 'number') {
      price = this.calculatePriceByType(this.insuranceProduct.singlePrices, variation, this.currentUserPrice);
    } else if (family) {
      price = this.familyPrices.get(variation);
    } else {
      price = this.singlePrices.get(variation);
    }
    this.activePrice = typeof price === 'number' ? price.toFixed(2) : '';
    return price;
  }


  private createInsurance() {
    let family = false;
    if (this.familyOrSingleSelection.length > 0 && this.familyOrSingleSelection[1].selected) {
      family = true;
    }

    let variation: string;
    let variationTitle: string;
    if (this.variations.length > 0) {
      const variationData = this.variations.filter((v: IIHubSwitchSelection) => v.selected === true)[0];
      variation = variationData.reference;
      variationTitle = variationData.text;
    } else if (this.variationSelections.length > 0) {
      const variationData = this.variationSelections.filter((v: IIHubSwitchSelection) => v.selected === true)[0];
      variation = variationData.reference;
      variationTitle = variationData.text;
    }

    const price = +this.activePrice;

    let orderUuid;
    if (this.draft) {
      orderUuid = this.draft.orderUuid;
    }

    const request: ICreateInsuranceRequest = {
      orderUuid,
      product: this.insuranceProduct.product,
      productTitle: this.insuranceProduct.title,
      family,
      variation,
      price,
      terminalId: sessionStorage.getItem('terminalId'),
      token: '',
      language: '',
      additionalAttributes: this.additionalAttributes,
      variationTitle,
      merchant: this.insuranceProduct.merchant
    };

    this.createInsurance$ = this.insuranceProvider.createInsurance(request).subscribe((res) => {
      if (res.spinnerState) {
        this.waitingForResponse = true;
        this.disabled = true;
      } else {
        this.waitingForResponse = false;
        this.disabled = false;

        if (res.error) {
          this.router.navigateByUrl('/de-ch/error');
        } else {
          debug('navigate to user detail');
          this.router.navigate([`${this.languageFromURL}/user-details`]);
        }
      }
    });
  }
  private isNumber(prices: Prices, key: string) {
    return typeof prices[key] === 'number';
  }
  private calculatePriceByType(prices: Prices, key: string, additionalAttributes: any): number {
    const config = prices[key] as VariationConfiguration;

    if (config.type === 'lookupTable_To') {
      const priceCalculator = this.priceCalculatorFactory.getPriceCalculator(config.type);
      const price = priceCalculator.calculatePrice(config.params, additionalAttributes);
      return price;
    }

    if (config.type === 'multiplyPriceByFactor') {
      let extraPriceInfo = {};
      if (this.parentCategory.length > 0) {
        const holidayCost = this.insuranceProduct.additionalAttributes.filter(attr => attr.id === config.params.key)[0];

        extraPriceInfo = {
          // tslint:disable-next-line: no-string-literal
          max: holidayCost.validation['max'].value
        };
      }
      const priceCalculator = this.priceCalculatorFactory.getPriceCalculator(config.type);
      const price = priceCalculator.calculatePrice(config.params, additionalAttributes, extraPriceInfo);
      return price;
    }

    if (config.type === 'multiply') {
      if (additionalAttributes && additionalAttributes.startDate) {
        if (typeof additionalAttributes.startDate !== 'string') {
          additionalAttributes.startDate = additionalAttributes.startDate.format('DD.MM.YYYY');
        }
        this.dateObject.startDate = additionalAttributes.startDate;
      }
      if (additionalAttributes && additionalAttributes.endDate) {
        if (typeof additionalAttributes.endDate !== 'string') {
          additionalAttributes.endDate = additionalAttributes.endDate.format('DD.MM.YYYY');
        }
        this.dateObject.endDate = additionalAttributes.endDate;
      }

      // to get the price onload
      if (!this.dateObject.startDate || !this.dateObject.endDate) {
        const priceCalculator = this.priceCalculatorFactory.getPriceCalculator(config.type);
        const price = priceCalculator.calculatePrice(config.params, additionalAttributes);
        return price;
      }
      if (this.dateObject && this.dateObject.endDate && this.dateObject.startDate) {
        this.currentUserPrice = null;
        const priceCalculator = this.priceCalculatorFactory.getPriceCalculator(config.type);
        const price = priceCalculator.calculatePrice(config.params, this.dateObject);
        return price;
      }
    }

    if (config.type === 'externalPriceCalculation') {
      if (additionalAttributes && additionalAttributes.startDate) {
        if (typeof additionalAttributes.startDate !== 'string') {
          additionalAttributes.startDate = additionalAttributes.startDate.format('DD.MM.YYYY');
        }
        this.dateObject.startDate = additionalAttributes.startDate;
      }
      if (additionalAttributes && additionalAttributes.endDate) {
        if (typeof additionalAttributes.endDate !== 'string') {
          additionalAttributes.endDate = additionalAttributes.endDate.format('DD.MM.YYYY');
        }
        this.dateObject.endDate = additionalAttributes.endDate;
      }
      if ((additionalAttributes && additionalAttributes.price) ||
          (this.dateObject && this.dateObject.endDate && this.dateObject.startDate)
          || this.shouldCheckAdditionalAttributePrice(config, additionalAttributes)) {
        // call tarification incase price or category only changed
        // tslint:disable-next-line: max-line-length
        this.isDateChanged = true;
        const isNotDraft = ((additionalAttributes && additionalAttributes.price) ?
          (!this.isSelectionToggled && additionalAttributes.price <= this.priceValidation.max && additionalAttributes.price >= this.priceValidation.min) :
          (!this.isSelectionToggled && this.isDateChanged));
        if (this.changeValue ? isNotDraft : this.draft) {
          this.waitingForPrice = true;
          this.disabled = true;
          this.externalPriceError = false;
          this.isSelectionToggled = false;

          this.priceSubscription$ = this.insuranceProvider.calculateExternalPrice$(additionalAttributes ? additionalAttributes : this.dateObject, this.insuranceProduct.product,
            this.insuranceProduct.merchant).subscribe(response => {

              this.isDateChanged = false;
              if (response.status.toString() === '200') {
                this.variations = [];
                this.priceCalculationResponse = [];
                this.waitingForPrice = false;
                this.disabled = false;
                this.priceCalculationResponse = response.response;
                if (!this.priceCalculationResponse[0].variation) {
                  this.activePrice = this.priceCalculationResponse[0].price.toFixed(2);
                } else {
                  const externalVars = this.insuranceProduct.variationOptionsInfo.extenalVariations;
                  this.priceCalculationResponse.forEach(element => {
                    const selection: IIHubSwitchSelection = {
                      text: externalVars[element.variation].title,
                      selected: false,
                      reference: element.variation,
                      description: externalVars[element.variation].text
                    };
                    this.variations.push(selection);
                  });

                  if (this.draft) {
                    this.changeValue = true;
                    const selectionData = this.variations.filter((v) => v.reference === this.draft.variation);
                    const selectedVariation = this.priceCalculationResponse.filter((v) => v.variation === this.draft.variation)[0];

                    if (selectionData.length > 0) {
                      selectionData[0].selected = true;
                      this.activePrice = selectedVariation.price.toFixed(2);
                    } else {
                      this.variations[0].selected = true;
                      this.activePrice = this.priceCalculationResponse[0].price.toFixed(2);
                    }

                  } else {
                    this.variations[0].selected = true;
                    this.activePrice = this.priceCalculationResponse[0].price.toFixed(2);
                    this.selectedVariation = this.variations[0].reference;
                  }
                }
              }
            }, err => {
              if (err.status.toString() === '500') {
                this.waitingForPrice = false;
                this.externalPriceError = true;
                this.disabled = true;
              }
            });

          return this.draft && this.draft.price || Number(this.insuranceProduct.priceStaticConfig);

        } else {
          this.isSelectionToggled = false;

          if (this.selectedVariation) {
            // tslint:disable-next-line: max-line-length
            this.activePrice = Number(this.priceCalculationResponse.find((price) => price.variation === this.selectedVariation).price.toFixed(2));
            return this.activePrice;
          } else {
            return this.draft && this.draft.price || Number(this.insuranceProduct.priceStaticConfig);
          }
        }

      } else {
        this.isSelectionToggled = false;
        return this.draft && this.draft.price || Number(this.insuranceProduct.priceStaticConfig);
      }

    }

  }

  shouldShowPrice() {
    return !this.waitingForPrice && !this.externalPriceError
  }

  public openPopup(event: any) {
    event.stopPropagation();
    this._popupContent = {
      title: this.insuranceProduct.variationOptionsInfo.popUpTitle,
      text: this.insuranceProduct.variationOptionsInfo.popUpText,
      needsTranslation: false,
    };
    this.popup.visible = true;
    debug('openPopup', this._popupContent, event);
  }

  public toggleToPersonDetailsButtonState(state: boolean) {
    this.disabled = state;
  }
  public saveAdditionAttributes(additionalAttributes: any) {

    if (additionalAttributes.title === '') {
      delete additionalAttributes.title;
    }

    if (additionalAttributes.petDOB && typeof additionalAttributes.petDOB !== 'string') {
      additionalAttributes.petDOB = additionalAttributes.petDOB.format('DD.MM.YYYY');
    }

    if (additionalAttributes.purchaseDate && typeof additionalAttributes.purchaseDate !== 'string') {
      additionalAttributes.purchaseDate = additionalAttributes.purchaseDate.format('DD.MM.YYYY');
    }

    if (additionalAttributes.startDate && typeof additionalAttributes.startDate !== 'string') {
      additionalAttributes.startDate = additionalAttributes.startDate.format('DD.MM.YYYY');
    }

    if (additionalAttributes.endDate && typeof additionalAttributes.endDate !== 'string') {
      additionalAttributes.endDate = additionalAttributes.endDate.format('DD.MM.YYYY');
    }

    if (additionalAttributes.bookingDate && typeof additionalAttributes.bookingDate !== 'string') {
      additionalAttributes.bookingDate = additionalAttributes.bookingDate.format('DD.MM.YYYY');
    }

    if (additionalAttributes.personDetails) {
      additionalAttributes.personDetails.forEach((detail) => {
        if (detail.dateOfBirth && typeof detail.dateOfBirth !== 'string') {
          detail.dateOfBirth = detail.dateOfBirth.format('DD.MM.YYYY');
        }
      });

      if (additionalAttributes.includeUserOnTrip !== undefined) {

        if (additionalAttributes.includeUserOnTrip === '' && !additionalAttributes.includeUserOnTrip.value) {
          additionalAttributes.includeUserOnTrip = false;
        }
      }
    }

    this.additionalAttributes = additionalAttributes;
    this.currentUserPrice = additionalAttributes;

    this.calculatePrice()
  }

  public eventHandler($event) {
    if (this[$event.handlerName]) {
      this[$event.handlerName]($event.data);
    }
  }

  private priceOfBikeHandler = (data: any) => {
    this.currentUserPrice = data;
    let variation;
    if (this.variationSelections.length > 0) {
      variation = this.variationSelections.filter((v: IIHubSwitchSelection) => v.selected === true)[0].reference;
    }
    // tslint:disable-next-line: no-string-literal
    const variationParam = this.insuranceProduct.singlePrices[variation]['params'].key.split(',');
    for (const param of variationParam) {
      if (param in this.currentUserPrice) {
        this.calculatePrice();
      }
    }
  }

  private startDateHandler = (data: any) => {
    this.currentUserPrice = data;
    let variation;
    if (this.variationSelections.length > 0) {
      variation = this.variationSelections.filter((v: IIHubSwitchSelection) => v.selected === true)[0].reference;
    }
    // tslint:disable-next-line: no-string-literal
    const variationParam = this.insuranceProduct.singlePrices[variation]['params'].key.split(',');
    for (const param of variationParam) {
      if (param in this.currentUserPrice) {
        this.calculatePrice();
      }
    }
  }

  private endDateHandler = (data: any) => {
    this.currentUserPrice = data;
    let variation;
    if (this.variationSelections.length > 0) {
      variation = this.variationSelections.filter((v: IIHubSwitchSelection) => v.selected === true)[0].reference;
    }
    // tslint:disable-next-line: no-string-literal
    const variationParam = this.insuranceProduct.singlePrices[variation]['params'].key.split(',');

    for (const param of variationParam) {
      if (param in this.currentUserPrice) {
        this.calculatePrice();
      }
    }
  }

  private holidayCostHandler = (data: any) => {
    this.currentUserPrice = data;
    let variation;
    if (this.variationSelections.length > 0) {
      variation = this.variationSelections.filter((v: IIHubSwitchSelection) => v.selected === true)[0].reference;
    }
    // tslint:disable-next-line: no-string-literal
    const variationParam = this.insuranceProduct.singlePrices[variation]['params'].key.split(',');
    for (const param of variationParam) {
      if (param in this.currentUserPrice) {
        this.calculatePrice();
      }
    }
  }

  private priceHandler = (data: any) => {
    this.currentUserPrice = data;
    let variation;
    if (this.variationSelections.length > 0) {
      variation = this.variationSelections.filter((v: IIHubSwitchSelection) => v.selected === true)[0].reference;
    }
    // tslint:disable-next-line: no-string-literal
    const variationParam = this.insuranceProduct.singlePrices[variation]['params'].key.split(',');
    for (const param of variationParam) {
      if (param in this.currentUserPrice) {
        this.isSelectionToggled = false;
        this.calculatePrice();
      }
    }
  }

  private checkOnAdditionalAttributes() {
    const { additionalAttributes } = this.draft;
    if (Array.isArray(additionalAttributes)) {
      return additionalAttributes.length;
    } else if (typeof additionalAttributes === 'object' && additionalAttributes !== null) {
      return Object.keys(additionalAttributes).length;
    }
    return null;
  }

  private shouldCheckAdditionalAttributePrice(config: VariationConfiguration, additionalAttributes: any): any {
    if (!additionalAttributes) return false;
  
    const keysToCheck = config.params.key ? config.params.key.split(",") : []
    return keysToCheck.every(key => additionalAttributes[key] != null && additionalAttributes[key] != undefined)
  }

  retryExternalPrice() {
    this.calculatePrice();
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    if (this.createInsurance$) {
      this.createInsurance$.unsubscribe();
    }

    if (this.priceSubscription$) {
      this.priceSubscription$.unsubscribe();
    }
  }
}


