import { Component, OnInit, Injector } from '@angular/core';
import Debug from 'debug';
import { EmailPageControllerDirective } from './email.page.controller';
import { UserProvider } from '@modeso/ihub-lib-user-fe';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
const debug = Debug('dgoods:shared:EmailPageUiTest');

@Component({
  selector: 'app-email-page',
  templateUrl: './email.page.view.html',
  styleUrls: ['./email.page.view.scss']
})
export class EmailPage extends EmailPageControllerDirective implements OnInit {
  constructor(location: Location, injector: Injector, userProvider: UserProvider, route: ActivatedRoute, router: Router) {
    super(location, injector, userProvider, route, router);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
