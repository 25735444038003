import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as fromActions from './../actions/cart.actions';
import { CheckoutModel } from '../models/order.model';
import { PurchasedProducts } from '../models/purchasedProducts.model';
import { OrderConfirmationModel } from '../models/orderConfirmation.model';


export const cartFeatureKey = 'modesoCartMicroservice';

// State Declarations - START

export interface FeatureState {
  cart: CheckoutModel;
  order: OrderConfirmationModel;
  purchasedProducts: Array<PurchasedProducts>;
  error: any;
  // checkoutStatus: 'initial' | 'success' | 'failed';
}

export interface AppState {
  modesoCartMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.modesoCartMicroservice;
export const featureCart = createSelector(
  selectFeature,
  (state: FeatureState) => state.cart
);

export const featureOrder = createSelector(
  selectFeature,
  (state: FeatureState) => state.order
);

export const featureError = createSelector(
  selectFeature,
  (state: FeatureState) => state.error
);

export const featurePurchasedProducts = createSelector(
  selectFeature,
  (state: FeatureState) => state.purchasedProducts
);
// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {
  cart: new CheckoutModel(),
  order: new OrderConfirmationModel(),
  purchasedProducts: new Array<PurchasedProducts>(),
  error: undefined
};

const cartReducer = createReducer(
  initialState,
  on(fromActions.postCart, (state) => ({ ...state, error: undefined })),
  on(fromActions.onCartPostedSuccessfully, (state, action) => ({ ...state, cart: action.payload, error: undefined })),
  on(fromActions.onCartPostingFailed, (state, action) => ({ ...state, cart: {}, error: action.payload })),

  on(fromActions.getOrdersByUserId, (state) => ({})),
  on(fromActions.onGetOrdersByUserIdSuccessfully, (state, action) => ({ ...state, purchasedProducts: action.payload })),
  on(fromActions.onGetOrdersByUserIdFailed, (state) => ({ ...state })),

  on(fromActions.getOrderByReceiptUrl, (state) => ({ ...state })),
  on(fromActions.onGetOrderByReceiptUrlSuccessfully, (state, action) => ({ ...state, order: action.payload, error: undefined })),
  on(fromActions.onGetOrderByReceiptUrlFailed, (state, action) => ({ ...state, order: {}, error: action.payload }))

);

export function reducer(state: FeatureState | undefined, action: Action) {
  return cartReducer(state, action);
}
