import { createAction, props, Action } from '@ngrx/store';
import { IAppStateProtocolModel } from '../shared/models/appStateProtocol.model';


export const updateStoreProtocol = createAction(
  '[Protocol update] Update Store with Protocol',
  props<{payload: IAppStateProtocolModel }>()
);

export const updateStoreProtocolSuccess = createAction(
  '[Protocol update] Update Store with Protocol succss',
  props<{payload: IAppStateProtocolModel}>()
);

export const updateStoreProtocolFail = createAction(
  '[Protocol update] Update Store with Protocol succss',
);


export const getProtocol = createAction(
  '[Protocol get] Get saved Protocol'
);

export const getProtocolSuccess = createAction(
  '[Protocol get] Get saved Protocol success',
  props<{ payload: IAppStateProtocolModel }>()
);

export const getProtocolFail = createAction(
  '[Protocol get] Get saved Protocol fail'
);

export interface ActionWithPayload<T> extends Action {
  payload: T;
}