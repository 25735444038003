import { Validators } from '@angular/forms';
import { IConfiguredAttributeValidation } from '@modeso/types__ihub-lib-products-be';
import { ValidatorType } from '../enums/validatorType.enum';
import { CustomValidation } from '../../utils/Controls/customValidators.validator';

export class DynamicFormUtils  {

    customValidators: CustomValidation;

    constructor() {
        this.customValidators = new CustomValidation();
    }

    public getValidators(validators: Map<string, IConfiguredAttributeValidation>) {
        const allControlMessages = {};
        const validationArray = [];
        let validatorKey: string;
        Object.keys(validators).forEach((key) => {
           if (validators[key].type === ValidatorType.Error) {
             if (key === 'required') {
               validationArray.push(Validators.required);
               return;
             }
             if (key === 'requiredTrue') {
              validationArray.push(Validators.requiredTrue);
              return;
            }
             if (key === ValidatorType.CustomForm) {
               // tslint:disable-next-line: no-shadowed-variable
               const validatorValue = validators[key].value;
               validationArray.push(this.customValidators[validatorValue]);
               allControlMessages[key] = {
                 message: validators[key].message,
                 type: validators[key].type,
                 textColor: validators[key].textColor,
               };
               return;
             }
             const validatorValue = validators[key].value;
             if (Validators[key]) {
               validationArray.push(Validators[key](validatorValue));
               validatorKey = key.toLowerCase();
             } else {
               validationArray.push(this.customValidators[key]);
               validatorKey = key;
             }
           } else {
             if (this.customValidators[key]) {
              const validatorValue = validators[key].value;
              if (validatorValue) {
                this.customValidators.value = validatorValue;
              }
               validationArray.push(this.customValidators[key]);
               validatorKey = key;
             } else {
               // as to not add properties to validation array forex: hideTime in mat-datepicker
               return ;
             }
           }
           allControlMessages[validatorKey] = {
             message: validators[key].message,
             type: validators[key].type,
             textColor: validators[key].textColor,
           };
         });
        return {validationArray, allControlMessages};
       }

}
