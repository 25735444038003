import { Component, Injector, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationComponentComponent, InsuranceProvider, IUpdateInsuranceDelegate } from '@modeso/ihub-lib-insurance-fe';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
// tslint:disable-next-line: max-line-length
import { IActivateInsuranceRequest, IInsuranceOperationResponse, IInsuranceProduct, InsuranceStatusEnum, IUpdateInsuranceStatusRequest } from '@modeso/types__ihub-lib-insurance-be';
import { MyInsuranceDetailsPageControllerDirective } from './my-insurance-details.page.controller';
import { IHubPopUpComponent, IIHubPopupContent } from '@modeso/twint-lib-core-fe';
import { take } from 'rxjs/operators';
import { DamageReportProvider } from '@modeso/ihub-lib-damage-fe';
import { InjectedTextPipe } from '@modeso/modeso-lib-core-fe';
import { ICreateDamageRequest } from '@modeso/types__ihub-lib-damage-be';
import { DomSanitizer } from '@angular/platform-browser';
import { UserProvider } from '@modeso/ihub-lib-user-fe';
import Debug from 'debug';
import { ShopUtil } from '../../utils/shopUtil';
import moment from 'moment';


const debug = Debug('ihub:project:MyInsuranceDetailsPageComponent');

export enum InsuranceProducts {
  TRAVEL_INSURANCE = 'travel-care-hansemerkur',
  SKI_INSURANCE = 'ski-insurance',
  RENTAL_CAR = 'rentalcar-hansemerkur'
}
@Component({
  selector: 'app-my-insurance-details-page',
  templateUrl: './my-insurance-details.page.component.html',
  styleUrls: ['./my-insurance-details.page.component.scss'],
})
export class MyInsuranceDetailsPageComponent extends MyInsuranceDetailsPageControllerDirective implements IUpdateInsuranceDelegate {
  public insuranceStatus;
  public insuranceDetails: IInsuranceOperationResponse;
  @ViewChild('popup', { static: true }) popup: IHubPopUpComponent;
  // tslint:disable-next-line: variable-name
  _popupContent: IIHubPopupContent;
  popupTitle = '';
  damageReports: ICreateDamageRequest;
  isDamageReport = false;
  text;
  private personId: string;
  person;
  productIcon;
  allProducts;
  product: any;
  hasShowSummary = false;
  additionalAttributes = [];
  displayAttributes = [];
  waitingForAppResponse = false;
  alreadySwitchedToApp = false;
  waitingForAppTimer: any;
  timeoutWait = 180;
  highlights: any[];
  constructor(route: ActivatedRoute,
              insuranceProvider: InsuranceProvider,
              analyticsProvider: AnalyticsProvider,
              injector: Injector, router: Router,
              private damageProvider: DamageReportProvider,
              private injectTextPipe: InjectedTextPipe,
              private sanitizer: DomSanitizer,
              private dialog: MatDialog,
              private userProvider: UserProvider

  ) {
    super(route, insuranceProvider, analyticsProvider, injector, router);
    this.personId = localStorage.getItem('personId') ? localStorage.getItem('personId') : undefined;
    this.allProducts = JSON.parse(localStorage.getItem('products')) as IInsuranceProduct[];
    if (this.personId) {
      this.getPerson();
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() {
    super.ngOnInit();
    this.getInsuranceByOrderUuid();
    this.subscriptions.push(
      this.insuranceProvider.getPaymentToken$().subscribe(this.onRequestPayment.bind(this))
    );
  }


  private getInsuranceByOrderUuid() {
    this.subscriptions.push(this.insuranceProvider.getMyInsuranceWithOrderUuid$(this.orderUuid).subscribe((data) => {
      if (data) {
        this.insuranceDetails = data;
        this.setInsuranceData();
        const hasOneVariation = this.product.variations.length > 1 || !this.product.displayVariationsAccordingToNumber ? false : true;
        const hasFamily = this.product.hasFamily;
        const shopTotal = this.product.shopTotal;
        this.insuranceDetails = {...this.insuranceDetails, shopTotal , hasFamily , hasOneVariation };
      } else {

        this.subscriptions.push(this.insuranceProvider.getInsuranceByOrderUuid$(this.orderUuid).subscribe({
          // tslint:disable-next-line: no-shadowed-variable
          next: (data) => {

            if (data) {
              this.insuranceDetails = data;
              this.setInsuranceData();
              const hasOneVariation = this.product.variations.length > 1 || !this.product.displayVariationsAccordingToNumber ? false : true;
              const hasFamily = this.product.hasFamily;
              const shopTotal = this.product.shopTotal;
              this.insuranceDetails = {...this.insuranceDetails, shopTotal , hasFamily , hasOneVariation};
            }
          }
        }));
      }
    }));

  }

  getInsuranceStatus(status: number, endDate: Date): number {
    if (endDate && new Date() > new Date(endDate)) {
      return InsuranceStatusEnum.EXPIRED;
    } else if (status === InsuranceStatusEnum.ACTIVE || status === InsuranceStatusEnum.WAITFORUOF) {
      return InsuranceStatusEnum.ACTIVE;
    }
    return status;
  }

  redirectToDamage() {
    this.router.navigateByUrl(`/de-ch/damage-report/${this.orderUuid}`);
  }


  public openPopup(event: any, title) {
    event.stopPropagation();
    this.renderElement(title);
    this._popupContent = {
      title: this.popupTitle,
      text: this.damageReportHistory(this.damageReports)
    };
    this.popup.visible = true;
  }

  getDamageReportHistory() {
    // tslint:disable-next-line: no-string-literal
    const insuranceId = this.insuranceDetails['_id'];
    let index = 0;

    this.damageProvider.getDamageReportByInsuranceId$(insuranceId).pipe(take(2))
      .subscribe(damage => {
        index++;
        if (index === 2) {
          this.damageReports = damage;
          Object.keys(this.damageReports).length !== 0 ? this.isDamageReport = true : this.isDamageReport = false;
        }
      });
  }

  private renderElement(localizedString: string) {
    this.injectTextPipe.transform(localizedString).pipe(take(1)).subscribe((value) => {
      this.popupTitle = value;
    });
  }

  private damageReportHistory(damages) {
    this.text = '';
    for (const damage of damages) {
      // tslint:disable-next-line: max-line-length
      this.text += `<div><div style='color:grey;margin-bottom: 7px;'>${damage.dateOfIncident}</div><div>${damage.description}</div><br></div>`;
    }
    return this.text;
  }

  safeHTML(unsafe: string) {
    return this.sanitizer.bypassSecurityTrustHtml(unsafe);
  }
  openConfirmationModal(insuranceStatus: number): void {
    const insurance: IUpdateInsuranceStatusRequest = {
      orderUuid: this.orderUuid,
      insuranceStatus,
      endDate: this.insuranceDetails.endDate,
    };
    const data = {
      insurance,
      mainTitle: 'ihub_shop_cancel_termination',
      description: 'ihub_shop_really_withdraw',
      buttonTextConfirmation: 'ihub_shop_cancel_termination',
      buttonTextClose: 'dgoods_back_button'
    };
    if (insuranceStatus === 2) {
      this.dialog.open(ConfirmationComponentComponent, {
        data,
      });
    } else {
      // tslint:disable-next-line: max-line-length
      this.router.navigate([`../../${this.languageFromURL}/first-cancelation-step/${this.orderUuid}`], { state: { insurance: this.insuranceDetails } });
    }
  }
  getPerson() {
    let index = 0;
    this.userProvider.getPersonOfUser$().pipe(take(2))
      .subscribe((person) => {
        index++;
        if (index === 2) {
          if (person) {
            this.person = person;
          }
        }
      });
  }

  gotoMyAccount() {
    this.router.navigate([`${this.languageFromURL}/account/edit`]);
  }
  showClaimButton(): boolean {
    return ((!this.insuranceDetails.endDate || new Date(this.insuranceDetails.endDate) > new Date()) &&
    this.insuranceStatus !== InsuranceStatusEnum.PENDING &&
    this.insuranceStatus !== InsuranceStatusEnum.EXPIRED) ||
    (Object.values(InsuranceProducts).includes(this.insuranceDetails.product) &&
    (moment(this.insuranceDetails.endDate).add(2, 'years').isAfter(moment())));
  }

  showCancellationButton(): boolean {
    return !this.product.isSinglePayment &&
    this.insuranceStatus !== InsuranceStatusEnum.PENDING &&
    this.insuranceStatus !== InsuranceStatusEnum.EXPIRED;
  }

  isEndDateReached() {
    if (this.insuranceStatus === InsuranceStatusEnum.CANCELLED ) {
      return true;
    } else {
      return (this.insuranceDetails.endDate && new Date(this.insuranceDetails.endDate) < new Date()) ? true  : false;
    }
  }

  private getProductIcon() {
    this.productIcon = this.product.icon;
  }

  private setInsuranceData() {
    this.setProduct();
    this.getDamageReportHistory();
    this.getProductIcon();
    this.insuranceStatus =
    this.getInsuranceStatus(this.insuranceDetails.insuranceStatus, this.insuranceDetails.endDate);
  }
  private setProduct() {
    this.additionalAttributes = [];
    this.displayAttributes = [];
    this.product = this.allProducts.filter((product) => {
      return product.product === this.insuranceDetails.product ;
    })[0];
    this.getHighLights();
    const subCategoryProduct = this.allProducts.find(prod => prod.subCategory.includes(this.product.product));
    this.product.icon =  subCategoryProduct ? subCategoryProduct.icon : this.product.icon;
    if (this.product.additionalAttributes) {
      for (const attribute of this.product.additionalAttributes) {
        if (attribute.showOnSummary) {
          this.additionalAttributes.push(attribute);
          this.hasShowSummary = attribute.showOnSummary;
        }
      }
    }
    if (this.product.displayAttributes && this.insuranceDetails.displayAttributes.length > 0) {
      for (const attribute of this.product.displayAttributes) {
          this.displayAttributes.push(attribute);
      }
    }  }

  get InsuranceStatusEnum(): typeof InsuranceStatusEnum  {
    return InsuranceStatusEnum;
  }

  public async confirmPayment(event) {
    if (event) {
      const request: IActivateInsuranceRequest = {
        orderUuid: this.orderUuid,
      };
      debug('single payment request:', request);
      this.insuranceProvider.dispatchSinglePaymentInsurance(request);
    }

  }

  switchToApp(registrationToken: any) {
    this.alreadySwitchedToApp = true;
    this.waitingForAppTimer = setInterval(() => {
      debug('checking for update on current insurance');
      if (--this.timeoutWait >= 0) {
        this.insuranceProvider.updateCurrentProductTheUserWantsToBuy$(this.orderUuid);
      } else {
        debug('checking for update on current insurance - timeout');
        this.router.navigate(['../../de-ch/paymentcanceld/' + this.orderUuid]);
      }
    }, 1000);

    new ShopUtil().switchToApp(registrationToken, this.product.isSinglePayment);
  }

  onRequestPayment(token: string) {
    debug('onRequestPayment', token);

    if (token && this.product.isSinglePayment) {
      this.waitingForAppResponse = false;
      this.switchToApp(token);
    }
    return true;
  }

  getHighLights(): void {
    if (this.product.subCategory.length === 0 && this.product.highlights.length === 0) {
      this.allProducts.forEach(product => {
        if (product.subCategory.includes(this.product.product)
        ) {
          this.highlights = product.highlights;
        }
      });
    } else {
      this.highlights = this.product.highlights;

    }
  }
}
