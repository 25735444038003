import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CartService } from '../services/cart.service';
import * as fromActions from '../actions/cart.actions';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
const debug = Debug('modeso:twint-lib-cart-fe:CartEffects');


@Injectable()
export class CartEffects {
  constructor(private actions$: Actions, private service: CartService) { }

  /**
   * Effect of post cart.
   */
  postCart$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.postCart.type),
      mergeMap(
        payload => {
          return this.service.postCart(payload)
            .pipe(
              // retry(1),
              map(
                response => (fromActions.onCartPostedSuccessfully({ payload: response })),
              ),
              catchError((error) => of(fromActions.onCartPostingFailed({ payload: error })))
            );
        }
      )
    )
  );

  /**
   * Effect of post cart error.
   */
  errorOnPostCart$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onCartPostingFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllCartErrors(action.payload)
      )
    )
    , { dispatch: false });


  getOrdersByUserId$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.getOrdersByUserId.type),
      mergeMap(
        () => {
          return this.service.getOrderByUserId()
            .pipe(
              retry(1),
              map(
                response => {

                  return fromActions.onGetOrdersByUserIdSuccessfully({ payload: response.purchasedOrders });
                }
              ),
              catchError((error) => {
                return of(fromActions.onGetOrdersByUserIdFailed({ payload: error }));
              })
            );
        }
      )
    )
  );

  errorOnGetORdersByUserId$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onGetOrdersByUserIdFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllCartErrors(action.payload)
      )
    ), { dispatch: false }
  );

  getOrderByReceiptUrl$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.getOrderByReceiptUrl.type),
      mergeMap(
        (payload: any) => {
          return this.service.getOrderByReceiptUrl(payload.receiptURL)
            .pipe(
              map(
                response => {
                  debug("response from cart service");
                  const orderModel = response.orderModel;
                  if (orderModel.productConfiguration) {
                    orderModel.productConfiguration = {
                      config: objToMap(orderModel.productConfiguration.config),
                      text: objToMap(orderModel.productConfiguration.text),
                    };
                  }
                  return fromActions.onGetOrderByReceiptUrlSuccessfully({ payload: response.orderModel });
                }
              ),
              catchError((error) => {
                debug("response from cart service");
                return of(fromActions.onGetOrderByReceiptUrlFailed({ payload: error }));
              }),
            );
        }
      )
    )
  );

  errorOnGetOrderByReceiptUrl$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onGetOrderByReceiptUrlFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllCartErrors(action.payload)
      )
    )
    , { dispatch: false });

  handleOnLoadAllCartErrors(error) {
    debug(error);
    return error;
  }
}


function objToMap(inputObject) {
  const result = new Map<string, string>();

  Array.from(Object.getOwnPropertyNames(inputObject)).forEach(element => {
    result.set(element, inputObject[element]);
  });

  return result;
}
