/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./maintenance.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./maintenance.page";
import * as i3 from "@angular/router";
var styles_MaintenancePage = [i0.styles];
var RenderType_MaintenancePage = i1.ɵcrt({ encapsulation: 0, styles: styles_MaintenancePage, data: {} });
export { RenderType_MaintenancePage as RenderType_MaintenancePage };
export function View_MaintenancePage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "login-page-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "twint-logo"], ["src", "assets/twint_logo_q_pos_bg.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "login-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "login-msg-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "login-logo"], ["src", "assets/clock.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "login-msg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Shop is under maintenance"]))], null, null); }
export function View_MaintenancePage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-maintenance", [], null, null, null, View_MaintenancePage_0, RenderType_MaintenancePage)), i1.ɵdid(1, 114688, null, 0, i2.MaintenancePage, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MaintenancePageNgFactory = i1.ɵccf("app-maintenance", i2.MaintenancePage, View_MaintenancePage_Host_0, {}, {}, []);
export { MaintenancePageNgFactory as MaintenancePageNgFactory };
