<div #overlay [hidden]="hiddenTermsOfProvider" class="overlay">
  <div class="terms-of-service-container">
    <div class="title-content-container">
      <dgoods-title-with-content title="dgoods_shared_termsofservicecomponent_title">
        <div #termsContent [ngClass]="enforceTC? 'content enforce' : 'content'">
          <span [innerHTML]="termAndCondition"></span>
        </div>
      </dgoods-title-with-content>
    </div>

    <div class="button-container">
      <div class="sticky" *ngIf="!enforceTC">
        <dgoods-button *ngIf="!enforceTC" [delegate]="this">
          {{ "dgoods_shared_termsofservicecompontent_conclude" | localize | async }}
        </dgoods-button>
      </div>
      <div *ngIf="enforceTC">
          <div *ngIf="isTermsScrollable && !termsScrollStart" class="icon-overlay">
            <img class="icon-chevron-down" src="assets/icon-chevron-down.svg" />
          </div>

          <div class="button-padding-style">
            <dgoods-button [delegate]="this" [disabled]="false">
              {{ "dgoods_shared_termsofservicecompontent_agree" | localize | async }}
            </dgoods-button>
          </div>

          <dgoods-button [delegate]="this" [state]="'notAction'">
            {{ "dgoods_shared_termsofservicecompontent_disagree" | localize | async }}
          </dgoods-button>
      </div>
    </div>
  </div>
</div>
