import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { Store } from '@ngrx/store';
import * as fromApp from './../../state/app.state.reducer';
import { IDGoodsButtonDelegate, DGoodsOverlayComponent } from '@modeso/twint-lib-core-fe';
import { ShopUtil } from '../../utils/shopUtil';

const debug = Debug('dgoods:shop:LoginPage');
@Component({
  selector: 'app-page-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage extends BasePageComponent implements OnInit, IDGoodsButtonDelegate {

  @ViewChild('incognitoOverlay', { static: false }) incognitoOverlay: DGoodsOverlayComponent;

  constructor(injector: Injector, private store: Store<fromApp.AppState>) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.detectIncognitoModeOnChrome();
    this.detectPrivateModeOnSafari();
  }

  onButtonClicked() {
    debug('Open Twint app');
    new ShopUtil().switchToAppAndCloseWindow();
  }


  async detectIncognitoModeOnChrome() {
    if ('storage' in navigator && 'estimate' in navigator.storage) {
      const { usage, quota } = await navigator.storage.estimate();
      console.log(`Using ${usage} out of ${quota} bytes.`);

      if (quota < 120000000) {
        this.incognitoOverlay.hidden = false;
      } else {
        debug('Not Incognito');
      }
    } else {
      debug('Can not detect');
    }
  }

  detectPrivateModeOnSafari() {
    try {
      window.localStorage.setItem('test', '1');
    } catch (e) {
      this.incognitoOverlay.hidden = false;
    }
  }

}

