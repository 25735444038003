/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./skeleton/content-area/content-area.component.ngfactory";
import * as i3 from "./skeleton/content-area/content-area.component";
import * as i4 from "./pages/PortraitMode/portrait-mode.page.view.ngfactory";
import * as i5 from "./pages/PortraitMode/portrait-mode.page.view";
import * as i6 from "./app.component";
import * as i7 from "@angular/common";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container-fluid"], ["id", "shop-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-content-area", [], null, null, null, i2.View_ContentAreaComponent_0, i2.RenderType_ContentAreaComponent)), i1.ɵdid(3, 49152, null, 0, i3.ContentAreaComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-page-portrait-mode", [], null, null, null, i4.View_PortraitModePage_0, i4.RenderType_PortraitModePage)), i1.ɵdid(5, 245760, null, 0, i5.PortraitModePage, [i1.Injector, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i6.AppComponent, [i7.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
