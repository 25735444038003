import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as productActions from './../actions/products.actions';
import * as spotlightActions from './../actions/spotlights.actions';
import { ISortingOrder, ISpotlight, ProductDTO } from '@modeso/types__ihub-lib-products-be';

export const productsFeatureKey = 'modesoProductsMicroservice';

// State Declarations - START

export interface FeatureState {
  products: Array<ProductDTO>;
  spotlights: Array<ISpotlight>;
  featureProduct: ISortingOrder;
}

export interface AppState {
  modesoProductsMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.modesoProductsMicroservice;
export const selectFeatureProducts = createSelector(
  selectFeature,
  (state: FeatureState) => state.products
);
export const selectFeatureSpotlights = createSelector(
  selectFeature,
  (state: FeatureState) => state.spotlights
);

export const getFeatureProducts = createSelector(
  selectFeature,
  (state: FeatureState) => state.featureProduct
);

/**
 * selector to get product by id from products array of the store
 */
export const selectProductById = createSelector(
  selectFeature,
  (entities, props) => {
    return entities.products.find(product => product.product === props.id);
  }
);

// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {
  products: new Array<ProductDTO>(),
  spotlights: new Array<ISpotlight>(),
  featureProduct:undefined
};

const productsReducer = createReducer(
  initialState,
  on(productActions.getAllProducts, state => ({ ...state })),
  on(productActions.onAllProductsLoadedSuccessfully, (state, action) => ({ ...state, products: action.payload })),
  on(productActions.onAllProductsLoadingFailed, (state) => ({ ...state })),
  on(spotlightActions.onGetSpotlights, state => ({ ...state })),
  on(spotlightActions.onGetSpotlightsSuccessfully, (state, action) => ({ ...state, spotlights: action.payload })),
  on(spotlightActions.onGetSpotlightsFailed, (state) => ({ ...state })),
  on(productActions.onGetFeatureProductByIssuer, state => ({ ...state })),
  on(productActions.onGetFeatureProductByIssuerSuccessfully, (state, action) => ({ ...state, featureProduct: action.payload })),
  on(productActions.onGetFeatureProductByIssuerFailed, (state) => ({ ...state })),
);

export function reducer(state: FeatureState | undefined, action: Action) {
  return productsReducer(state, action);
}
// Reducer Declarations - END
