import { BaseControlHandlerClass } from './BaseControlHandlerClass';
import { PriceBasedOnInsuranceAttributeHandler } from './input/priceBasedOnAttributeHandler';

// @dynamic
export class ControlsHandlerFactory {
    public static handlerDictionary: Map<string, BaseControlHandlerClass> = new Map<string, BaseControlHandlerClass>();
    private static instance: ControlsHandlerFactory;
    public static getInstance() {
        if (!this.instance) {
            this.initDict();
            this.instance = new ControlsHandlerFactory();
        }
        return this.instance;
    }
    private constructor() {}
    static initDict() {
        //this.handlerDictionary.set(ControlHandlerName.ANIVO_GADGET_PRICE_HANDLER, new AnivoGadgetPriceHandler());
    }

    getObject(handlerName: string) {
        if (ControlsHandlerFactory.handlerDictionary.get(handlerName))
            return ControlsHandlerFactory.handlerDictionary.get(handlerName);
        return new PriceBasedOnInsuranceAttributeHandler()
    }
}
