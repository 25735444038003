import { Component, Input, TemplateRef } from '@angular/core';
import { IIHubTileDelegate, IIHubTile } from '../ihub-tile/ihub-tile.component';

export interface IIHubListDelegate {
  onListItemClicked(item: IIHubTile): void;
}

@Component({
  selector: 'ihub-list',
  templateUrl: './ihub-list.component.html',
  styleUrls: ['./ihub-list.component.scss']
})
export class IHubListComponent implements IIHubTileDelegate {

  @Input() templateRef: TemplateRef<IIHubTile>;
  @Input() items: IIHubTile[] = [];
  @Input() delegate?: IIHubListDelegate;

  constructor() { }

  onTileClick(tile: IIHubTile): void {
    if (this.delegate) {
      this.delegate.onListItemClicked(tile);
    }
  }

  hasEvenProducts() {
    return this.items.length % 2 === 0;
  }
}
