import { Component, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
import Debug from 'debug';
import { IHubPopUpComponent, IIHubPopupContent } from '../ihub-popup/ihub-popup.component';
const debug = Debug('dgoods:shared:IHubSwitchComponent');

export interface IIHubExpandableContent {
  delayPaymentByMonth: number;
  price: string;
  timeframe: string;
  toggletext: string;
  subtitletext: string;
  currencytext: string;
  avbtext: string;
  avblink: string;
  vitext: string;
  vilink: string;
  isPromoProduct?:boolean;

}


export interface IIHubInsuranceServiceItem {
  text: string;
  description?: string;
  cover?: string;
  sub?: IIHubInsuranceServiceItem[];
  isCategory?:boolean;
}

@Component({
  selector: 'ihub-detail-expandable',
  templateUrl: './ihub-detail-expandable.component.html',
  styleUrls: ['./ihub-detail-expandable.component.scss']
})
export class IHubInsuranceDetailsExpandable {
  panelOpenState = false;

  @Input() content: IIHubExpandableContent;
  @Input() services: IIHubInsuranceServiceItem[];
  @Input() serviceHtml?: string;
  @Input() translated?: boolean = false;
  @Input() isCategory?: boolean = false;
  @ViewChild("popup", { static: true }) popup: IHubPopUpComponent;
  _popupContent: IIHubPopupContent;

  constructor(public sanitizer: DomSanitizer) {
  }
  /**§
   * emit the card product to the parent "product-list" to navigate with it
   */
  onClick() {
    /* if (this.delegate) {
       this.delegate.onTileClick(this.item);
     }*/
  }

  /**
   * onClick
   */
  public openPopup(event: any, item: IIHubInsuranceServiceItem) {
    event.stopPropagation();
    this._popupContent = {
      title: item.text,
      text: item.description,
    };
    this.popup.visible = true;
    debug("openPopup", item, event);
  }
}
