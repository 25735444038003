import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InsuranceProvider } from '@modeso/ihub-lib-insurance-fe';
import { ProductStoreProvider } from '@modeso/ihub-lib-products-fe';
import Debug from 'debug';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { DGoodsButtonComponent, IHubPopUpComponent, IIHubPopupContent, IDGoodsButtonDelegate } from '@modeso/twint-lib-core-fe';
import { DamageReportProvider } from '@modeso/ihub-lib-damage-fe';
import { InjectedTextPipe } from '@modeso/modeso-lib-core-fe';
import { take } from 'rxjs/operators';
import { BasePageComponent } from '../base.page';
import * as env from '../../../environments/environment';
import { ProductDTO } from '@modeso/types__ihub-lib-products-be/dist/interfaces/response/product-dtos';
import { NgModel } from '@angular/forms';
import moment from 'moment';
const debug = Debug('ihub:project:DamageReportComponent');


@Component({
  selector: 'damage-report',
  templateUrl: './damage-report.page.view.html',
  styleUrls: ['./damage-report.page.view.scss']
})

export class DamageReportPageController extends BasePageComponent implements IDGoodsButtonDelegate, OnInit {
  damageReportModel: any = {};
  descriptionTextArea = true;
  ibanNoBlurred = false;
  bankNameBlurred = false;
  accountNameBlurred = false;
  descriptionBlurred = false;
  dateBlurred = false;
  amountBlurred = false;
  acceptTerm1 = false;
  acceptTerm2 = false;
  @ViewChild('popup', { static: true }) popup: IHubPopUpComponent;
  @ViewChild('date', { static: true }) date: NgModel;
  // tslint:disable-next-line: variable-name
  _popupContent: IIHubPopupContent;
  popupText = '';
  imagesDropped = false;
  uploadedFiles = [];
  insurance;
  personId;
  // tslint:disable-next-line: max-line-length
  datePattern = '(?:((?:0[1-9]|1[0-9]|2[0-9])[.](?:0[1-9]|1[0-2])|(?:30)[.](?!02)(?:0[1-9]|1[0-2])|31[.](?:0[13578]|1[02]))[.](?:19|20)[0-9]{2})';
  waitingForDamageReponse = false;
  errorFile = false;
  totalFilesSize = 0;
  uploadedFileNames = [];
  insuranceProduct: ProductDTO;
  insurancePhoneNumber;
  emergencyPhoneNumber;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private insuranceProvider: InsuranceProvider,
    private productProvider: ProductStoreProvider,
    private injector: Injector,
    private injectTextPipe: InjectedTextPipe,
    private damageProvider: DamageReportProvider

  ) {
    super(injector);

    this.personId = localStorage.getItem('personId');
    this.subscriptions.push(this.route.params.subscribe((param) => {
      const orderUuid = param.orderUuid;
      this.subscriptions.push(this.insuranceProvider.getInsuranceByOrderUuid$(orderUuid).subscribe((value) => {
        if (!value) {
          return;
        }
        this.insurance = value;
        const products: any[] =  JSON.parse(localStorage.getItem('products'));
        this.insuranceProduct = products.find((x) => x.product === this.insurance.product);
        this.insurancePhoneNumber = this.insuranceProduct.claimsPhoneNumber;
        this.emergencyPhoneNumber = this.insuranceProduct.emergencyPhoneNumber;
      }));
    }));
  }
  ngOnInit() {
    super.ngOnInit();
    this.date.control.setValidators((control) => {
      if (!control.value) {
        return;
      }
      const {day, month, year} = control.value;
      const date1 = moment();
      const date2 = moment(`${day}.${month}.${year}`, 'DD.MM.YYYY');
      return date2 > date1 ? { notValid: true } : null;
    });
  }
  public dropped(files: NgxFileDropEntry[]): void {
    this.imagesDropped = true;
    for (const droppedFile of files) {

      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {

        this.totalFilesSize += file.size;
        this.uploadedFileNames.push(droppedFile.fileEntry.name);
        this.uploadedFiles.push(droppedFile);
      });
    }
    if (!this.isFileSizeAllowed(this.totalFilesSize) || !this.isFileAllowed(this.uploadedFileNames)) {
      this.errorFile = true;
    }

  }

  removeFile(removedFile) {
    this.errorFile = false;
    for (const [i, file] of this.uploadedFiles.entries()) {
      if (file.fileEntry.name === removedFile.fileEntry.name) {
        const fileEntry = file.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.uploadedFiles.splice(i, 1);
          this.totalFilesSize -= file.size;
          this.uploadedFileNames.splice(i, 1);
        });

      }
    }
    if (!this.isFileSizeAllowed(this.totalFilesSize) || !this.isFileAllowed(this.uploadedFileNames)) {
      this.errorFile = true;
    }
  }

  onButtonClicked(sender: DGoodsButtonComponent, id: string): void {
    debug('Button clicked', sender);
    if (id === 'next') {
      // Navigate to next page
      this.navigateNext();
    } else if (id === 'back') {
      // Navigate back
      this.navigateBack();
    }
  }

  private navigateBack() {
    this.router.navigate([`${this.languageFromURL}/my-insurance-detail/${this.insurance.orderUuid}`]);
  }
  private navigateNext() {
    let index = 0;

    let accountName = this.damageReportModel.accountName;
    if (accountName === undefined || accountName == null) {
      accountName = ' ';
    }
    const {day, month, year} = this.damageReportModel.date;
    const dateOfIncident = `${day}.${month}.${year}`;
    const body = {
      iban: this.damageReportModel.ibanNo,
      description: this.damageReportModel.description,
      personId: this.personId,
      insuranceId: this.insurance._id,
      amount: this.damageReportModel.amount,
      accountName,
      bankName: this.damageReportModel.bankName,
      dateOfIncident,
    };

    if (this.uploadedFiles.length > 0) {
      // tslint:disable-next-line: no-string-literal
      body['files'] = this.uploadedFiles;
    }
    if (this.insurance.policyNumber) {
      // tslint:disable-next-line: no-string-literal
      body['policyNo'] = this.insurance.policyNumber;
    }
    this.waitingForDamageReponse = true;
    this.damageProvider.createDamageReport$(body).pipe(take(2))
      .subscribe(id => {

        index++;
        if (id && index === 2) {
          this.waitingForDamageReponse = false;
          this.navigateBack();
        }
      });

  }

  onblur(value: boolean, inputName: string) {
    switch (inputName) {
      case 'ibanNo':
        this.ibanNoBlurred = value;
        break;
      case 'bankName':
        this.bankNameBlurred = value;
        break;
      case 'accountName':
        this.accountNameBlurred = value;
        break;
      case 'description':
        this.descriptionBlurred = value;
        this.descriptionTextArea = value;
        break;
      case 'date':
        this.dateBlurred = value;
        break;
      case 'amount':
        this.amountBlurred = value;
        break;

      default:
    }
  }


  public openPopup(event: any, text) {
    event.stopPropagation();
    this._popupContent = {
      title: '',
      text,
      needsTranslation: false
    };
    this.popup.visible = true;
    debug('openPopup', event);
  }

  isFileAllowed(filesName) {
    let isFileAllowed = true;
    const allowedFiles = ['.pdf', '.jpg', '.jpeg', '.png', '.heic', '.heif', '.PDF', '.JPG', '.JPEG', '.PNG', '.HEIC', '.HEIF'];
    const regex = /(?:\.([^.]+))?$/;
    for (const fileName of filesName) {
      const extension = regex.exec(fileName);
      if (undefined !== extension && null !== extension) {
        if (!allowedFiles.includes(extension[0])) {
          return isFileAllowed = false;
        }
      }
    }
    return isFileAllowed;
  }

  isFileSizeAllowed(size) {
    let isFileSizeAllowed = false;
    if (size < 50000000) {
      isFileSizeAllowed = true;
    }
    return isFileSizeAllowed;

  }
}
