
import { Directive, Injector, ViewChild, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DGoodsButtonComponent,
  DGoodsNavigationComponent, IDGoodsButtonDelegate,
} from '@modeso/twint-lib-core-fe';
import Debug from 'debug';
import { Observable } from 'rxjs';
import { InsuranceCheckoutBaseComponent } from '../InsuranceCheckout/InsuranceCheckoutBase.component';
import { IActivateInsuranceRequest, IInsurancePartial, IInsuranceProduct, InsuranceStatusEnum } from '@modeso/types__ihub-lib-insurance-be';
import { InsuranceProvider } from '@modeso/ihub-lib-insurance-fe';
import { ProductStoreProvider } from '@modeso/ihub-lib-products-fe';
import { UserProvider } from '@modeso/ihub-lib-user-fe';
import { take } from 'rxjs/operators';
import { ICreatePersonRequest } from '@modeso/types__ihub-lib-user-be';
import { ShopUtil } from '../../utils/shopUtil';
import moment from 'moment';


const debug = Debug('dgoods:project:InsuranceSummaryPageControllerDirective');

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'app-insurance-summary-detail-page-controller'
})
export class InsuranceSummaryPageControllerDirective extends InsuranceCheckoutBaseComponent implements IDGoodsButtonDelegate, OnInit {
  protected productcode;
  protected observables = new Map<string, Observable<IInsuranceProduct>>();

  private status: InsuranceStatusEnum;
  private userProvider: UserProvider;

  acceptTerm1 = false;
  disabled = true;
  personId: string;
  person: ICreatePersonRequest;
  receiveEmails = false;
  hasAccount = false;
  alreadySwitchedToApp = false;
  waitingForAppResponse = false;
  waitingForAppTimer: any;
  timeoutWait = 180;
  product: any;
  uofStatus: string;
  enrichedAdditionalAttributes: {id: string, key: string, value: string}[] = [];

  constructor(
    public router: Router,
    route: ActivatedRoute,
    insuranceProvider: InsuranceProvider,
    productProvider: ProductStoreProvider,
    injector: Injector,
  ) {
    super(router, route, insuranceProvider, productProvider, injector);
    this.userProvider = injector.get(UserProvider);
    this.personId = localStorage.getItem('personId') ? localStorage.getItem('personId') : undefined;
    if (this.personId) {
      this.getPerson();
    }
  }

  /** NgInit */
  ngOnInit() {
    super.ngOnInit();
    this.subscriptions.push(
      this.insuranceProvider.getPaymentToken$().subscribe(this.onRequestPayment.bind(this))
    );
    this.subscriptions.push(
      this.userProvider.getUofRegistartionToken$().subscribe(this.onUoFResponse.bind(this))
    );
    this.userProvider.getUser$().subscribe((user: any) => {
      if (user) {
        this.uofStatus = user.user.uofStatus;
      }
    });
    this.subscriptions.push(
      this.draft$.subscribe(draft => {
        if (!draft || this.alreadySwitchedToApp || !this.uofStatus) { return; }
        this.draft = draft;
        this.product = JSON.parse(localStorage.getItem('products')).filter(product => product.product === this.draft.product)[0];
        // tslint:disable-next-line: no-string-literal
        this.draft['hasFamily'] = this.product.hasFamily;
        // tslint:disable-next-line: no-string-literal
        // tslint:disable-next-line: max-line-length
        this.draft.hasOneVariation = this.product.variations.length > 1  || !this.product.displayVariationsAccordingToNumber  ? false : true;

        if (this.draft.additionalAttributes) {
          const attributes = this.checkOnAdditionalAttributes(this.draft.additionalAttributes);
          this.getAttributes(attributes)

        }

        if (this.draft.insuranceStatus === InsuranceStatusEnum.PENDING || this.draft.insuranceStatus === InsuranceStatusEnum.ACTIVE) {

          if (this.waitingForAppTimer) {
            clearInterval(this.waitingForAppTimer);
          }

          if (!this.product.isSinglePayment) {
            if (this.uofStatus !== 'ACTIVE') {
              this.userProvider.startUofRegistration({
                product: draft.product,
                orderUuid: draft.orderUuid,
                terminalId: draft.terminalId
              });
            } else {
              this.router.navigate([`${this.languageFromURL}/insurance-confirmation/` + this.draft.orderUuid]);
            }

          }
        }
      })
    );
  }

  getAttributes(additionalAttributes) {
    this.enrichedAdditionalAttributes = [
      ...(this.product.additionalAttributes || [])
    ]
    .filter(attr => attr.showOnSummary)
    .map(attr => {
      const attrValue = additionalAttributes[attr.id]
      let enrichedValue = attrValue;
      if (attr.enum) {
        const enumValue = Object.values(attr.enum).find((e: any) => e.value === attrValue) as any
        enrichedValue = enumValue.useValue ? enumValue.value : enumValue.language;
      }
      if (attr.validation['dateFormat']) {
        enrichedValue = moment(attrValue, "DD.MM.YYYY").format(attr.validation['dateFormat'].value)
      }
      return { ...attr, value: enrichedValue }
    });
  }


  @HostListener('unloaded')
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.waitingForAppTimer) {
      clearInterval(this.waitingForAppTimer);
    }
  }

  onRequestPayment(token: string) {
    debug('onRequestPayment', token);
    if (token && this.product.isSinglePayment) {
      this.switchToApp(token);
      this.router.navigate([`${this.languageFromURL}/insurance-confirmation/` + this.draft.orderUuid]);
    }
    return true;
  }

  /** UOF --- START */

  onUoFResponse(token: string) {
    debug('UofResponse', token);
    if (token) {
      this.switchToApp(token);
      this.router.navigate([`${this.languageFromURL}/insurance-confirmation/` + this.draft.orderUuid]);
    }
    return true;
  }

  switchToApp(registrationToken: any) {
    this.alreadySwitchedToApp = true;
    new ShopUtil().switchToApp(registrationToken, this.product.isSinglePayment);
  }

  /** UOF --- END */

  /** IDGoodsButtonDelegate --- START */

  onButtonClicked(sender: DGoodsButtonComponent, id: string): void {
    debug('Button clicked', sender);
    if (id === 'next') {
      this.navigateNext();
    } else if (id === 'back') {
      // Navigate back
      this.navigateBack();
    }
  }

  private navigateBack() {
    this.router.navigate(['../../de-ch/user-details']);
  }

  private async navigateNext() {
    this.waitingForAppResponse = true;
    this.waitingForAppTimer = setInterval(() => {
      debug('checking for update on current insurance');
      if (--this.timeoutWait >= 0) {
        this.insuranceProvider.updateCurrentProductTheUserWantsToBuy$(this.draft.orderUuid);
      } else {
        debug('checking for update on current insurance - timeout');
        this.router.navigate(['../../de-ch/paymentcanceld/' + this.draft.orderUuid]);
      }
    }, 1000);


    if (this.product.isSinglePayment) {
      await this.confirmPayment();
    } else {
      // Set insurance product in store
      await this.activateInsurance();
    }

  }

  /** IDGoodsButtonDelegate --- END */


  private async activateInsurance() {
    if (this.draft === undefined) {
      throw new Error('there is no draft to update...');
    }

    const request: IActivateInsuranceRequest = {
      orderUuid: this.draft.orderUuid,
    };

    debug('activateInsurance request:', request);
    this.updatePerson();
    this.insuranceProvider.activateInsurance(request);
  }

  emitAcceptedTerms() {
    if (this.acceptTerm1 && this.hasAccount) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  private getPerson() {
    let index = 0;
    this.userProvider.getPersonOfUser$().pipe(take(2))
      .subscribe((person) => {
        index++;
        this.personId = undefined;
        if (index === 2) {
          if (person) {
            this.personId = person._id;
            this.person = person;
            this.receiveEmails = person.receiveEmails;
          }
        }
      });
  }

  private updatePerson() {
    this.person.receiveEmails = this.receiveEmails;
    this.person.id = this.personId;
    this.userProvider.updatePerson$(this.person);
  }


  private async confirmPayment() {
    if (this.draft === undefined) {
      throw new Error('there is no draft to update...');
    }

    const request: IActivateInsuranceRequest = {
      orderUuid: this.draft.orderUuid,
    };

    debug('single payment request:', request);
    this.updatePerson();
    this.insuranceProvider.dispatchSinglePaymentInsurance(request);
  }

  private checkOnAdditionalAttributes(additionalAttributes) {
    if (Array.isArray(additionalAttributes)) {
      return additionalAttributes[0];
    } else  {
      return additionalAttributes;
    }
  }
}
