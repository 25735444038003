import { Component, OnInit } from '@angular/core';
import Debug from 'debug';
import { Router } from '@angular/router';

const debug = Debug('dgoods:project:FAQPage');

@Component({
  selector: 'app-paymentalert',
  templateUrl: './paymentalert.page.html',
  styleUrls: ['./paymentalert.page.scss']
})
export class PaymentAlertPage implements OnInit {


  constructor(private router: Router) { }

  ngOnInit() {

  }

  onButtonClicked() {
    debug('Button clicked');
    this.router.navigate(['../../de-ch/landing-buy']);
  }
}
