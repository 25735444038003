import { Component, Input, OnInit } from '@angular/core';
export interface IIHubHightlight {
  icon: string;
  text: string;
}
@Component({
  selector: 'ihub-view-insurance-highlights',
  templateUrl: './view-insurance-highlights.component.html',
  styleUrls: ['./view-insurance-highlights.component.scss']
})

export class ViewInsuranceHighlightsComponent implements OnInit {
  @Input() highlights: IIHubHightlight[];
  constructor() { }

  ngOnInit() {
  }

}
