import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import { CustomInputDate } from '../../models/customDateInput';
import moment from 'moment';


@Component({
  selector: 'app-date-custom-control',
  templateUrl: './date-custom-control.component.html',
  styleUrls: ['./date-custom-control.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: DateCustomControlComponent }],
  host: {
    '[class.custom-date-input-floating]': 'shouldLabelFloat'
  },
})
export class DateCustomControlComponent implements ControlValueAccessor,
            MatFormFieldControl<CustomInputDate>, OnDestroy {
  
  static nextId = 0;
  @ViewChild('day', {static: false}) dayInput: HTMLInputElement;
  @ViewChild('month', {static: false}) monthInput: HTMLInputElement;
  @ViewChild('year', {static: false}) yearInput: HTMLInputElement;

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  parts: FormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'custom-date-input';
  id = `custom-date-input-${DateCustomControlComponent.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};
  get empty() {
    const { value: { day, month, year } } = this.parts;
    return !day && !month && !year;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): CustomInputDate | null {
    if (this.parts.valid) {
      const {
        value: { day, month, year },
      } = this.parts;
      return new CustomInputDate(day, month, year);
    }
    return null;
  }
  set value(date: CustomInputDate | null) {
    const { day, month, year } = date || new CustomInputDate('', '', '');
    this.parts.setValue({ day, month, year });
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.parts.invalid && this.touched;
  }

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.parts = formBuilder.group({
      day: [
        null,
        [
          Validators.required,
          Validators.max(31),
          Validators.min(1),
          Validators.minLength(1),
          Validators.maxLength(2),
        ],
      ],
      month: [
        null,
        [
          Validators.required,
          Validators.max(12),
          Validators.min(1),
          Validators.minLength(1),
          Validators.maxLength(2),
        ],
      ],
      year: [
        null,
        [Validators.required, 
          Validators.minLength(4), Validators.maxLength(4)],
      ],
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }
  autofilled?: boolean;

  ngOnInit() {
  }
  
  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.custom-date-input-container'
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    if (this.parts.controls.year.valid) {
      //
    } else if (this.parts.controls.month.valid) {
      this._focusMonitor.focusVia(this.yearInput, 'program');
    } else if (this.parts.controls.day.valid) {
      this._focusMonitor.focusVia(this.monthInput, 'program');
    } else {
      this._focusMonitor.focusVia(this.dayInput, 'program');
    }
  }

  writeValue(date: CustomInputDate | null): void {
    this.value = date;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }
  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement
  ): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }
  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }
  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }
}


