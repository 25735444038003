import { Component, OnInit, Input } from '@angular/core';


export interface IIHubHightlight {
  icon: string;
  text: string;
}

@Component({
  selector: 'ihub-highlights',
  templateUrl: './ihub-highlights.component.html',
  styleUrls: ['./ihub-highlights.component.scss']
})
export class IHubHighlightsComponent {

  @Input() highlights: IIHubHightlight[];

}
