import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as fromActions from '../actions/spotlights.actions';
import { SpotlightService } from '../services/spotlights.service';
import Debug from 'debug';
const debug = Debug('ihub:spotlight:SpotlightsEffects ');

@Injectable()
export class SpotlightsEffects {

  constructor(private actions$: Actions, private service: SpotlightService) { }

  getSpotllights$ = createEffect(
    () => this.actions$.pipe(
        ofType(fromActions.onGetSpotlights.type),
        mergeMap(
            (action: fromActions.ActionWithPayload<string>) => {
                debug('Effect getSpotllights is Dispatched', action.payload);
                return this.service.getSpotlights(action.payload)
                    .pipe(
                        map(
                            (response) => {
                                debug(response);
                                return fromActions.onGetSpotlightsSuccessfully({ payload: response });
                            },
                        ),
                        catchError((error) => {
                            return of(fromActions.onGetSpotlightsFailed({ payload: error }));
                        }),
                    );
            },
        ),
    ),
  );

  errorOnGetSpotlights$ = createEffect(
    () => this.actions$.pipe(
        ofType(fromActions.onGetSpotlightsFailed.type),
        tap(
            (action: fromActions.ActionWithPayload<any>) => this.handleAllErrors(action.payload)
        )
    ), { dispatch: false }
  );

  handleAllErrors(error) {
    debug(error);
    return error;
  }
}
