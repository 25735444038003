import { Injectable } from '@angular/core';
import * as fromActions from './../actions/insurance.actions';
import * as fromFeature from './../reducers/insurance.reducer';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import {
  IInsuranceOperationResponse, ICreateInsuranceRequest,
  IInsurancePartial, IActivateInsuranceRequest
} from '@modeso/types__ihub-lib-insurance-be';

import Debug from 'debug';
import { InsuranceService } from '../services/insurance.service';
const debug = Debug('modeso:ihub-lib-insurance-fe:InsuranceProvider');

@Injectable({
  providedIn: 'root'
})
export class InsuranceProvider {

  constructor(private store: Store<fromFeature.AppState>, private service: InsuranceService) { }

  public createInsurance(insurance: ICreateInsuranceRequest) {
    this.store.dispatch(fromActions.createInsurance({ payload: insurance }));
    return this.store.pipe(select(fromFeature.featureCreateInsuranceResponse));

  }

  public activateInsurance(insurance: IActivateInsuranceRequest) {
    this.store.dispatch(fromActions.activateInsurance({ payload: insurance.orderUuid }));
  }

  public flush() {
    this.store.dispatch(fromActions.flush());
  }

  public getCurrentProductTheUserWantsToBuy$(): Observable<IInsurancePartial> {
    return this.store.pipe(select(fromFeature.featureInsurance));
  }

  public updateCurrentProductTheUserWantsToBuy$(orderUuid: string) {
    this.store.dispatch(fromActions.updateInsuranceStatus({ payload: orderUuid }));
  }

  public fetchAllMyPurchasedInsurances$() {
    this.store.dispatch(fromActions.getInsurancesByUserId());
  }

  public getPurchasedInsurances$(): Observable<IInsuranceOperationResponse[]> {
    return this.store.pipe(select(fromFeature.featureActiveInsurances));
  }

  public getInsuranceByOrderUuid$(orderUuid: string): Observable<IInsuranceOperationResponse> {
    this.store.dispatch(fromActions.getInsuranceDetailsByOrderUuid({ payload: orderUuid }));
    return this.store.pipe(select(fromFeature.featureInsuranceDetails));
  }
  public getPolicyPdf$(orderUuid: string): Observable<any> {
    this.store.dispatch(fromActions.onGetPolicyPdf({ payload: orderUuid }));
    return this.store.pipe(select(fromFeature.featurePolicyPdf));
  }
  public getMyInsuranceWithOrderUuid$(orderUuid: string): Observable<IInsuranceOperationResponse> {
    return this.store.pipe(select(fromFeature.featureActiveInsurances), map(
      (allinsurances) => {
        if (allinsurances != null && allinsurances.length > 0) {
          const filtered = allinsurances.filter((insurance) => insurance.orderUuid === orderUuid);
          if (filtered.length > 0) {
            return filtered[0];
          } else {
            return undefined;
          }
        }
      }
    ));
  }

  public getAVBPdf$(orderUuid: string): Observable<any> {
    this.store.dispatch(fromActions.onGetAVBPdf({ payload: orderUuid }));
    return this.store.pipe(select(fromFeature.featureAVBPdf));
  }

  public dispatchUpateInsuranceStatus(insurance: any): void {
    this.store.dispatch(fromActions.onUpdateInsuranceState({ payload: insurance }));
  }

  public getDraftedInsurancesByUserId$(): Observable<any> {
    this.store.dispatch(fromActions.onGetDraftedInsurances());
    return this.store.pipe(select(fromFeature.featureDraftedInsurances));
  }


  public updateInsuranceState(insurance: any) {
    this.store.dispatch(fromActions.updateInsuranceState({ payload: insurance }));
  }

  public dispatchSinglePaymentInsurance(insurance: IActivateInsuranceRequest) {
    this.store.dispatch(fromActions.onSinglePaymentInsurance({ payload: insurance.orderUuid }));
  }

  public getPaymentToken$(): Observable<string> {
    return this.store.pipe(select(fromFeature.featurePaymentToken));
  }

  public calculateExternalPrice$(additionalAttributes: any, productName: string, merchant: string) {
    return this.service.calculateExternalPrice(additionalAttributes, productName, merchant);
  }

  public getError$(): Observable<any> {
    return this.store.pipe(select(fromFeature.featureError),
      map((error: any) => {
        return error;
      })
    );
  }

}
