<div class="order-confirmation-container">
  <dgoods-navigation [delegate]="this" [account]="false" [hidden]="showNavigationBar"></dgoods-navigation>
  <div *ngIf="!dataSource" class="bordered">
    <dgoods-dots-loading-indicator></dgoods-dots-loading-indicator>
  </div>
  <dgoods-order-confirmation *ngIf="dataSource" [productImage]="image"
    [productConfiguration]="dataSource.productConfiguration" [currency]="dataSource.currency" [price]="dataSource.price"
    [productName]="dataSource.productName" [date]="dataSource.date" [email]="dataSource.email"
    [status]="dataSource.status" [isInProductionMode]="isInProductionMode">

  </dgoods-order-confirmation>
  <div class="button-container" [hidden]="!showNavigationBar">
    <div class="sticky">
      <dgoods-button [delegate]="this">
        {{"conclude" | localize | async}}
      </dgoods-button>
    </div>
  </div>
</div>

