<div class="product-buy-container ihub-styles"  style="margin-bottom: 32px;">
  <dgoods-view-container>
    <div *ngIf="insuranceProduct" class="fixed-background">
      <h1>{{ insuranceProduct.title }}</h1>
      <p style="padding-bottom: 20px;" id="insurance-description"></p>
      <ihub-highlights [highlights]='insuranceProduct.highlights'></ihub-highlights>

      <div *ngIf="insuranceProduct.conditionTerm">
        <div class="title">{{"ihub_shop_contract_term_title" | injectedText | async }}</div>
        <div [innerHTML]="insuranceProduct.conditionTerm" class="par"></div>
      </div>

      <div *ngIf="insuranceProduct.subCategory.length > 0">
        <ihub-detail-expandable [content]="content" [services]="services" [serviceHtml]="serviceHtml" [isCategory]="isCategory"></ihub-detail-expandable>
        <ihub-insurance-details   [subCategoryProducts]="subCategoryProducts" (isRadioButtonChecked)="onProductCheck($event)"></ihub-insurance-details>
      </div>


    </div>
</dgoods-view-container>
<ihub-detail-expandable *ngIf="!isCategory" [content]="content" [services]="services" [serviceHtml]="serviceHtml" [isCategory]="isCategory"></ihub-detail-expandable>
<p class="lastinfo centered">{{ 'ihub_shop_checkout_steps' | injectedText | async }} <strong>{{ 'ihub_shop_checkout_3_steps' | injectedText | async }}</strong></p>
  <div>
    <dgoods-button [delegate]="this" class="button-style" state="normal" identifier="next" [disabled]="disabled">
      {{ "ihub_shop_calculate_the_price" | injectedText | async }}
    </dgoods-button>
  </div>
  <div>
    <dgoods-button [delegate]="this" class="button-style" state="notAction" identifier="back">
      {{ "dgoods_back_button" | injectedText | async }}
    </dgoods-button>
  </div>
</div>


<ihub-popup #popup [content]="_popupContent"></ihub-popup>
