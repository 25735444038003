import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IHubPopUpComponent, IIHubPopupContent } from '@modeso/twint-lib-core-fe';
import { IConfiguredAttribute } from '@modeso/types__ihub-lib-products-be';
import { IInsuranceAttributeControl } from '../../ComponentFactory/IInputControl.interface';
import { BaseControl } from '../Base';
import { CheckboxService } from '../services/checkbox-data-handler';


@Component({
  selector: 'ihub-radio-check-button-control',
  templateUrl: './radio-button-check-control.component.html',
  styleUrls: ['./radio-button-check-control.component.scss']
})
export class RadioButtonCheckControlComponent extends BaseControl implements OnInit, IInsuranceAttributeControl {
  @ViewChild('popup', { static: true }) popup: IHubPopUpComponent;

  popupContent: IIHubPopupContent;
  insuranceAttribute: IConfiguredAttribute;
  form: FormGroup;
  defaultValue;
  selectionArray = [];
  constructor(private data: CheckboxService) { super(); }
  validationErrors: any;

  ngOnInit() {

    for (const [key, value] of Object.entries(this.insuranceAttribute.enum)) {
      if (value.useValue) {
        this.selectionArray.push({
          id: value.value,
          value: value.value,
          isChecked: value.isChecked,
          text: value.text
        });
      } else {
        this.selectionArray.push({
          id: value.language,
          value: value.value,
          isChecked: value.isChecked,
          text: value.text

        });
      }

      if (value.isChecked === true) {
        this.defaultValue = value.value;
      }
    }

    if (this.insuranceAttribute.value !== undefined) {
      this.form.get(this.insuranceAttribute.id)
      .setValue(this.insuranceAttribute.value);
      this.selectionArray.map((selection) => {
       return selection.isChecked = selection.value === this.insuranceAttribute.value ? true : false;
      });
    } else {
      this.form.get(this.insuranceAttribute.id)
      .setValue(this.defaultValue);
    }
    this.data.changeData({data: this.form.getRawValue()});
  }

  public openPopup(event: any,option:any) {
    event.stopPropagation();
    this.popupContent = {
      title: ((option.title || option.id) as string),
      text: (option.text as string),
      needsTranslation: false,
      isUnsafe: true,
    };
    this.popup.visible = true;
  }

  onChangeRadioButton(value) {
    this.form.get(this.insuranceAttribute.id).setValue(value);
    this.data.changeData({data: this.form.getRawValue()});
  }


}
