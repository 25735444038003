import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as fromActions from './../actions/ihub.damage.actions';


export const damageFeatureKey = 'modesoDamageReportMicroservice';

// State Declarations - START

export interface FeatureState {
  status?: string;
  id:string;
  damage:any;
}

export interface AppState {
  modesoDamageReportMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.modesoDamageReportMicroservice;
export const featureDamage = createSelector(
  selectFeature,
  (state: FeatureState) => state
);



export const featureDamageReportId = createSelector(
  selectFeature,
  (state: FeatureState) => state.id
);

export const featureDamageReport = createSelector(
  selectFeature,
  (state: FeatureState) => state.damage
);
// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {

  status: undefined,
  id:undefined,
  damage:undefined,
};

const damageReducer = createReducer(
  initialState,
    on(fromActions.onCreateDamageReport, (state) => ({ ...state })),
    on(fromActions.onCreateDamageReportSuccess, (state, action) => ({ ...state, id: action.payload, status: undefined })),
    on(fromActions.onCreateDamageReportFailed, (state, action) => ({ ...state, status: action.payload.status })),
    on(fromActions.getDamageByInsuranceId, state => ({ ...state })),
    on(fromActions.onGetDamageByInsuranceIdSuccessfully, (state, action) => ({ ...state, damage: action.payload })),
    on(fromActions.onGetDamageByInsuranceIdFailed, (state) => ({ ...state })),
  
);

export function reducer(state: FeatureState | undefined, action: Action) {
  return damageReducer(state, action);
}
