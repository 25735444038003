/**
 * order Model.
 */
export class OrderModel {
  public token: string;
  public receiptURL: string;
  public orderId: string;

  constructor() { }
}

export class CheckoutModel {
  public order: OrderModel;
  public messageId: string;
}
