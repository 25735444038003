export class ProductConfiguration {
  constructor(public config: Map<string, string>, public text: Map<string, string>) {}
}
export class OrderConfirmationModel {
  public productImages: ProductLineImages[];
  public currency: string;
  public price: number;
  public productName: string;
  public date: string;
  public email: string;
  public status: string;
  public productConfiguration: ProductConfiguration;
  constructor() { }

}

export class ProductLineImages {
  id: string;
  frontImage: string;
  imageSize: string;
  imageType: string;
}
