import { IDGoodsButtonDelegate, DGoodsButtonComponent } from '@modeso/twint-lib-core-fe';
import { UserProvider } from '@modeso/ihub-lib-user-fe';
import { Injector, OnInit, ViewChild, Directive, HostListener } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { ActivatedRoute, Router } from '@angular/router';
import { DgoodsSessionStoreProvider } from '@modeso/twint-lib-session-fe';
import { Location } from '@angular/common';
import { UserDetailPageComponent } from '../UserDetails/user-detail.page.component';
import { ShopUtil } from '../../utils/shopUtil';

const debug = Debug('dgoods:project:AccountPageController');

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'app-account-page-controller'
})
export class AccountPageControllerDirective extends BasePageComponent implements
  OnInit,
  IDGoodsButtonDelegate {

  disabled: boolean = true;
  @ViewChild('userDetails', { static: false }) userDetails: UserDetailPageComponent;

  private reregister: boolean;
  waitingForUofResponse = false;

  constructor(private injector: Injector, private userProvider: UserProvider, private route: ActivatedRoute,
    private router: Router, private sessionProvider: DgoodsSessionStoreProvider, private location: Location) {
    super(injector);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.userProvider.getUofRegistartionToken$().subscribe(token => {
        this.reregister = !token;
      }));
    super.ngOnInit();
  }

  @HostListener('unloaded')
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    super.ngOnDestroy();
  }


  onButtonClicked(sender: DGoodsButtonComponent, id: string): void {
    debug('Button clicked', sender);
    if (id === 'next') {
      // Navigate to next page
      this.navigateNext();
    }
    if (id === 'back') {
      // Navigate back
      this.navigateBack();

    }
  }
  private navigateBack() {
    this.location.back();
  }

  private async navigateNext() {
    if (this.userDetails.personId) {

      this.userDetails.updatePerson();

    } else {
      this.userDetails.createNewPerson();
    }

    this.router.navigate([`${this.languageFromURL}/landing-buy`]);

  }

  onUoFResponse(token: string) {
    this.waitingForUofResponse = true;
    debug('UofResponse', token);
    if (token) {
      new ShopUtil().switchToApp(token);
      this.waitingForUofResponse = false;
    }
    return true;
  }


  linkToTwint() {
    if (!this.reregister) {
      this.userProvider.uofDeRegistration();
    } else {
      this.userProvider.startUofRegistration({
        product: '',
        orderUuid: '',
        terminalId: sessionStorage.getItem('terminalId')
      });

      this.subscriptions.push(
        this.userProvider.getUofRegistartionToken$().subscribe(this.onUoFResponse.bind(this))
      );
    }
  }

  gotoFAQ() {
    this.router.navigate([`${this.languageFromURL}/account/faq`]);

  }

}
