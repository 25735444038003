import { Component, Input, OnInit } from '@angular/core';
import {InsuranceStatusEnum} from "@modeso/types__ihub-lib-insurance-be";
import debug from 'debug';


export interface IUpdateInsuranceDelegate {
  openConfirmationModal(insuranceStatus: number): void;
}

@Component({
  selector: 'ihub-insurance-cancelation-actions',
  templateUrl: './insurance-cancelation-actions.component.html',
  styleUrls: ['./insurance-cancelation-actions.component.scss']
})
export class InsuranceCancelationActionsComponent implements OnInit {

  @Input() insuranceStatus: number;
  @Input() delegate: IUpdateInsuranceDelegate;
  constructor() { }

  ngOnInit() {
  }

  openDialog(insuranceStatus: number): void {
    if (this.delegate) {
      this.delegate.openConfirmationModal(insuranceStatus);
    } else {
      debug('openConfirmationModal is not fired. There is no delegate attached to the component.');
    }
  }

  get InsuranceStatusEnum(): typeof InsuranceStatusEnum {
    return InsuranceStatusEnum;
  }

}
