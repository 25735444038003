import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IHubPopUpComponent, IIHubPopupContent } from '@modeso/twint-lib-core-fe';
import { IConfiguredAttribute } from '@modeso/types__ihub-lib-products-be';
import { IInsuranceAttributeControl } from '../../ComponentFactory/IInputControl.interface';
import { BaseControl } from '../Base';
import { CheckboxService } from '../services/checkbox-data-handler';


@Component({
  selector: 'ihub-checkbox-control',
  templateUrl: './checkbox-control.component.html',
  styleUrls: ['./checkbox-control.component.scss']
})
export class CheckboxControlComponent extends BaseControl implements OnInit, IInsuranceAttributeControl {
  @ViewChild('popup', { static: true }) popup: IHubPopUpComponent;

  popupContent: IIHubPopupContent;
  insuranceAttribute: IConfiguredAttribute;
  form: FormGroup;
  constructor(private data: CheckboxService) { super(); }
  validationErrors: any;
  ngOnInit() {

    if (this.insuranceAttribute.value !== undefined) {
      this.form.get(this.insuranceAttribute.id)
      .setValue(this.insuranceAttribute.value);
    } else {
      this.insuranceAttribute.preDefinedValue = this.insuranceAttribute.preDefinedValue === "false" ? false : true;
      this.form.get(this.insuranceAttribute.id)
      .setValue(this.insuranceAttribute.preDefinedValue);
    }
    this.data.changeData({data: this.form.getRawValue(), isChanged: false});
  }

  public openPopup(event: any) {
    event.stopPropagation();
    this.popupContent = {
      title: (this.insuranceAttribute.title as string),
      text: (this.insuranceAttribute.text as string),
      needsTranslation: false,
    };
    this.popup.visible = true;
  }

  onChangeCheckBox() {   
    this.data.changeData({data: this.form.getRawValue(), isChanged: true});
  }

}
