import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import * as fromApp from './../../state/app.state.reducer';
import { ShopUtil } from '../../utils/shopUtil';
import { ActivatedRoute } from '@angular/router';


const debug = Debug('dgoods:shop:ErrorPage');
@Component({
  selector: 'app-page-error',
  templateUrl: './error.page.view.html',
  styleUrls: ['./error.page.view.scss']
})
export class ErrorPage extends BasePageComponent implements OnInit {

  errorMessageRoute;
  errorMessageKey;
  constructor(
    private route: ActivatedRoute,
    injector: Injector,
  ) {
    super(injector);
    debug('Error Page');
    this.errorMessageRoute = this.route.snapshot.queryParamMap.get('errorStatus');
    if (this.errorMessageRoute === 'forbidden') {
      this.errorMessageKey = 'ihub_shop_forbidden_error_msg';
    } else {
      this.errorMessageKey = 'dgoods_shop_default_error_msg';
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.errorMessageRoute !== 'forbidden') {
      setTimeout(() => {
        new ShopUtil().switchToAppAndCloseWindow();
      }, 1000);
    }
  }
}

