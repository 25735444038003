import { HammerGestureConfig } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { environment } from '../environments/environment';
import { DgoodsSessionConfig } from '@modeso/twint-lib-session-fe';
import { ProductsConfig } from '@modeso/ihub-lib-products-fe';
import { ModesoLocalizationConfig } from '@modeso/modeso-lib-localization-fe';
import { InsuranceConfig } from '@modeso/ihub-lib-insurance-fe';
import { CartConfig } from '@modeso/twint-lib-cart-fe';
import { DgoodsUserConfig } from '@modeso/ihub-lib-user-fe';
import { DgoodsAnalyticsConfig } from '@modeso/twint-lib-analytics-fe';
import { IHubDamageConfig } from '@modeso/ihub-lib-damage-fe';
import { IConfig } from 'ngx-mask';
export class MyHammerConfig extends HammerGestureConfig {
    constructor() {
        super(...arguments);
        this.overrides = {
            swipe: { direction: Hammer.DIRECTION_ALL },
        };
    }
}
const config = {
    apiGateway: environment.modeso.apiGateway,
};
const productsConfig = {
    apiGateway: environment.modeso.apiGateway,
    serviceId: 'products'
};
const insuranceConfig = {
    apiGateway: environment.modeso.apiGateway,
    serviceId: 'insurance'
};
const cartConfig = {
    apiGateway: environment.modeso.apiGateway,
    serviceId: 'cart'
};
const localizationServiceConfig = {
    apiGateway: environment.modeso.apiGateway,
    serviceId: 'localization'
};
const userConfig = {
    apiGateway: environment.modeso.apiGateway,
    serviceId: 'users'
};
const analyticsConfig = {
    apiGateway: environment.modeso.apiGateway,
    serviceId: 'analytics'
};
const damageReportConfig = {
    apiGateway: environment.modeso.apiGateway,
    serviceId: 'damageReport'
};
export const ngMaskConfig = null;
const ɵ0 = navigator.language;
export class AppModule {
}
export { ɵ0 };
