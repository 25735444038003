import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';

import { Store, select } from '@ngrx/store';
import * as fromApp from './../../state/app.state.reducer';
import { tap } from 'rxjs/operators';
import { ShopUtil } from '../../utils/shopUtil';


const debug = Debug('dgoods:shop:ErrorPage');
@Component({
  selector: 'app-major-error-page',
  templateUrl: './error.page.view.html',
  styleUrls: ['./error.page.view.scss']
})
export class MajorErrorPage extends BasePageComponent implements OnInit {

  constructor(
    private store: Store<fromApp.AppState>,
    injector: Injector
  ) {
    super(injector);
    debug('Error Page');
  }

  ngOnInit(): void {
    super.ngOnInit();
    setTimeout(() => {
      new ShopUtil().switchToAppAndCloseWindow();
    }, 1000);
  }

}

