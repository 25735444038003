import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DgoodsSessionService } from './services/dgoods.session.service';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CommonModule } from '@angular/common';
import * as fromSession from './reducers/dgoods.session.reducer';
import { DgoodsSessionEffects } from './effects/dgoods.session.effects';
import { DgoodsSessionConfig, DgoodsSessionConfigService } from './dgoods.session.config';
import { TestComponent } from './components/test-component/test.component';
import { AuthHandler } from './interceptors/authentication.session.interceptor';

@NgModule({
  declarations: [TestComponent],
  providers: [DgoodsSessionService],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromSession.featureKey, fromSession.reducer),
    EffectsModule.forFeature([DgoodsSessionEffects])
  ],
  exports: [TestComponent]
})
export class DgoodsSessionModule {
  static forRoot(config: DgoodsSessionConfig): ModuleWithProviders {
    return {
      ngModule: DgoodsSessionModule,
      providers: [
        AuthHandler,
        DgoodsSessionService,
        {
          provide: DgoodsSessionConfigService,
          useValue: config
        }
      ]
    };
  }
}
