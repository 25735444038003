export enum AttributeType {
    MAT_EXPANDABLE_FORM_ARRAY = 'matexpandableFormArray',
    DATE = 'date',
    TEXT = 'text',
    CURRENCY = 'currency',
    NUMBER = 'number',
    DATE_PICKER = 'date-picker',
    SELECT = 'select',
    SWITCH = 'switch',
    CHECK_BOX = 'check-box',
    TITLE = 'title',
    RADIO_BUTTON = 'radio-button',
    RADIO_BUTTON_CHECK = 'radio-button-check'
}
