/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faq.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@modeso/modeso-lib-localization-fe";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../../node_modules/@modeso/twint-lib-core-fe/modeso-twint-lib-core-fe.ngfactory";
import * as i7 from "@modeso/twint-lib-core-fe";
import * as i8 from "./faq.page";
var styles_FAQPage = [i0.styles];
var RenderType_FAQPage = i1.ɵcrt({ encapsulation: 0, styles: styles_FAQPage, data: {} });
export { RenderType_FAQPage as RenderType_FAQPage };
function View_FAQPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["class", "toggle"], ["type", "checkbox"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "label", [["class", "question"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵppd(4, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "answer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵppd(9, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 0, "hr", [], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", ("question" + _v.parent.context.$implicit.index), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", ("question" + _v.parent.context.$implicit.index), ""); _ck(_v, 2, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.titleKey)))); _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 10).transform(i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.answerKey)))); _ck(_v, 8, 0, currVal_3); }); }
function View_FAQPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 5, null, View_FAQPage_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵppd(3, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵppd(5, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = (i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 4).transform(i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.titleKey)))) && i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 6).transform(i1.ɵunv(_v, 2, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.answerKey))))); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FAQPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.ɵa, [i4.Store, i5.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "faq-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "page-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "header-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "p", [["class", "header-style"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FAQPage_1)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "sticky"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "dgoods-button", [], null, null, null, i6.View_DGoodsButtonComponent_0, i6.RenderType_DGoodsButtonComponent)), i1.ɵdid(12, 49152, null, 0, i7.DGoodsButtonComponent, [], { delegate: [0, "delegate"] }, null), (_l()(), i1.ɵted(13, 0, [" ", " "])), i1.ɵppd(14, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.questionsLocalizationKeys; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 7).transform(i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), "dgoods_faq_title")))); _ck(_v, 5, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 15).transform(i1.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i1.ɵnov(_v, 0), "dgoods_shared_navigationcompontent_back")))); _ck(_v, 13, 0, currVal_3); }); }
export function View_FAQPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-faq", [], null, null, null, View_FAQPage_0, RenderType_FAQPage)), i1.ɵdid(1, 114688, null, 0, i8.FAQPage, [i2.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FAQPageNgFactory = i1.ɵccf("app-faq", i8.FAQPage, View_FAQPage_Host_0, {}, {}, []);
export { FAQPageNgFactory as FAQPageNgFactory };
