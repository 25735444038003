import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CommonModule } from '@angular/common';
import * as fromAnalytics from './reducers/dgoods.analytics.reducer';
import { DgoodsAnalyticsConfigService, DgoodsAnalyticsConfig } from './dgoods.analytics.config';
import { DgoodsAnalyticsService } from './services/dgoods.analytics.service';
import { AnalyticEffects } from './effects/dgoods.analytics.effects';

@NgModule({
  // declarations: [TestComponent],
  providers: [DgoodsAnalyticsService],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAnalytics.analyticsFeatureKey, fromAnalytics.reducer),
    EffectsModule.forFeature([AnalyticEffects])
  ],
  // exports: [TestComponent]
})
export class DgoodsAnalyticsModule {
  static forRoot(config: DgoodsAnalyticsConfig): ModuleWithProviders {
    return {
      ngModule: DgoodsAnalyticsModule,
      providers: [
        DgoodsAnalyticsService,
        {
          provide: DgoodsAnalyticsConfigService,
          useValue: config
        }
      ]
    };
  }
}
