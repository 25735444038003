
import { Component, OnInit, Injector } from '@angular/core';
import Debug from 'debug';
import { AccountPageControllerDirective } from './account.page.controller';
import { UserProvider } from '@modeso/ihub-lib-user-fe';
import { Router, ActivatedRoute } from '@angular/router';
import { DgoodsSessionStoreProvider } from '@modeso/twint-lib-session-fe';
import { Location } from '@angular/common';
const debug = Debug('dgoods:shared:AccountPageUiTest');

@Component({
  selector: 'app-account-page',
  templateUrl: './account.page.view.html',
  styleUrls: ['./account.page.view.scss']
})
export class AccountPage extends AccountPageControllerDirective implements OnInit {
  constructor(injector: Injector, userProvider: UserProvider, route: ActivatedRoute,
    router: Router, sessionProvider: DgoodsSessionStoreProvider, location: Location) {
    super(injector, userProvider, route, router, sessionProvider, location);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * getAgbLink
   */
  public getAgbLink() {
    const browserLanguage = navigator.language;
    let agbLanguage = '';
    if (browserLanguage.includes('de')) {
      agbLanguage = 'de';
    } else if (browserLanguage.includes('fr')) {
      agbLanguage = 'fr';
    } else if (browserLanguage.includes('it')) {
      agbLanguage = 'it';
    } else {
      agbLanguage = 'en';
    }
    return 'assets/pdf/' + agbLanguage + '/shopterms.pdf';
  }
}
