import { CustomValidationFactory } from '@modeso/types__ihub-lib-insurance-be';
import { AbstractControlWithHints } from './ControlWithHints.interface';
import { AbstractControl, ValidatorFn } from '@angular/forms';

export class CustomValidation {

    private customValidationFactory: CustomValidationFactory;
    public value: any;
    constructor() {
        this.customValidationFactory = CustomValidationFactory.getInstanceOfCustomValidationFactory();
    }
    public supportedPurchaseDate = (control: AbstractControlWithHints) => {
        const validator = this.customValidationFactory.customValidation('supportedPurchaseDate');
        const validation = validator.validate(control.value);
        control.hints = validation;
        return null;
    }
    public compatitorProductPrice = (control: AbstractControlWithHints) => {
        const validator = this.customValidationFactory.customValidation('compatitorProductPrice');
        const validation = validator.validate({ value: control.value, cheaperValue: this.value });
        control.hints = validation;
        return null;
    }
    public invalidPurchaseDate = (control: AbstractControlWithHints) => {

        const validator = this.customValidationFactory.customValidation('invalidPurchaseDate');
        return  validator.validate(control.value);
    }

    public notInThePast = (control: AbstractControlWithHints) => {
        const validator = this.customValidationFactory.customValidation('notInThePast');
        return  validator.validate(control.value);
    }


    public invalidDateFormat = (control: AbstractControlWithHints) => {
        const validator = this.customValidationFactory.customValidation('invalidDateFormat');
        return  validator.validate(control.value);
    }

    public CheckDateDifference = (customFields): ValidatorFn => {

        const startDateField: string = customFields[0];
        const endDateField: string = customFields[1];

        return (formGroup: AbstractControl): { [key: string]: boolean } | null => {

            const rentalDate = formGroup.get(startDateField).value;
            const returnDate = formGroup.get(endDateField).value;

            const validator = this.customValidationFactory.customValidation('CheckDateDifference');
            const isInvalid =  validator.validate({rentalDate, returnDate});

            if (isInvalid) {
                if (formGroup.get(startDateField).status === 'INVALID' ) {

                    formGroup.get(startDateField).setErrors({customForm: true});
                } else if (formGroup.get(endDateField).status === 'INVALID') {

                    formGroup.get(endDateField).setErrors({customForm: true});
                } else if (formGroup.get(endDateField).status === 'VALID' && formGroup.get(startDateField).status === 'VALID')  {

                    formGroup.get(endDateField).setErrors({customForm: true});

                } else {

                    formGroup.get(endDateField).setErrors({customForm: true});

                }

            } else if (!isInvalid && rentalDate && returnDate ) {
                formGroup.get(endDateField).setErrors(null);
                formGroup.get(startDateField).setErrors(null);
            } else {
                return null;
            }

        };
    }
}

