import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { IInsuranceOperationResponse, InsuranceStatusEnum } from '@modeso/types__ihub-lib-insurance-be';

import moment from 'moment';

@Component({
  selector: 'ihub-insurance-information',
  templateUrl: './insurance-information.component.html',
  styleUrls: ['./insurance-information.component.scss']
})
export class InsuranceInformationComponent implements OnInit, OnChanges {
  @Input() insuranceDetails: IInsuranceOperationResponse;
  @Input() insuranceStatus: number;
  @Input() additionalAttributes: any;
  @Input() displayAttributes: any;
  @Input() hasShowSummary: boolean;
  @Input() isSinglePayment: boolean;
  @Output() isRedirectToPayment: EventEmitter<any> = new EventEmitter(null);
  @Input() isEndDateReached;
  enrichedAditionalAttributes: { id: string, key: string, value: string }[] = [];

  paymentFlowStatus;
  isButtonClicked = false;

  ngOnInit(): void {
    this.paymentFlowStatus = this.insuranceDetails.payments.filter((log) => log.response === 'NOT_SENT');
  }

  ngOnChanges() {
    this.getAttributes();
  }

  activateInsurance() {
    this.isButtonClicked = true;
    this.isRedirectToPayment.emit(true);
  }

  public get insuranceStatusEnum(): typeof InsuranceStatusEnum {
    return InsuranceStatusEnum;
  }

  getAttributes() {
    this.enrichedAditionalAttributes = [
      ...(this.additionalAttributes || []), 
      ...(this.displayAttributes || []).map(attr => { return {...attr, showOnSummary: true}})
    ]
    .filter(attr => attr.showOnSummary)
    .map(attr => {
      const attrValue = this.checkOnAdditionalAttributes(this.insuranceDetails.additionalAttributes)[attr.id] || this.checkOnAdditionalAttributes(this.insuranceDetails.displayAttributes)[attr.id]
      let enrichedValue = attrValue;
      if (attr.enum) {
        const enumValue = Object.values(attr.enum).find((e: any) => e.value === attrValue) as any
        enrichedValue = enumValue.useValue ? enumValue.value : enumValue.language;
      }
      if (attr.validation['dateFormat']) {
        enrichedValue = moment(attrValue, "DD.MM.YYYY").format(attr.validation['dateFormat'].value)
      }
      return { ...attr, value: enrichedValue }
    });
  }

  private checkOnAdditionalAttributes(additionalAttributes) {
    if (Array.isArray(additionalAttributes)) {
      return additionalAttributes[0];
    } else  {
      return additionalAttributes;
    }
  }
}

