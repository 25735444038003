// tslint:disable-next-line: max-line-length
import { Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { IConfiguredAttribute } from '@modeso/types__ihub-lib-products-be';
import {Subscription} from 'rxjs';
import { FormControlWithHints } from '../../utils/Controls/FormControlWithHints';
import { ValidatorType } from '../../utils/enums/validatorType.enum';
import { ControlsHandlerFactory } from '../../utils/FormHandlers/ControlsHandlerFactory';
import { DynamicComponentFactory } from './ComponentFactory/ComponentFactory';
import { IInsuranceAttributeControl } from './ComponentFactory/IInputControl.interface';
import { DynamicControlDirective } from './dynamic-control.directive';
import { CustomValidation } from '../../utils/Controls/customValidators.validator';
import { DynamicFormUtils } from '../../utils/Controls/dynamicFormUtils';
@Component({
  selector: 'ihub-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  @Input()  additionalAttributes: IConfiguredAttribute[];
  @Output() emitToPersonDetailsButtonState: EventEmitter<boolean> = new EventEmitter(true);
  @Output() emitAdditionalAttributes: EventEmitter<any> = new EventEmitter(null);
  @Output() emitControlSubject: EventEmitter<{handlerName: string, data: any}> = new EventEmitter(null);
  @Output() emitFormValues: EventEmitter<any> = new EventEmitter();
  component: any;
  componentFactory: DynamicComponentFactory;
  componentsList: ComponentRef<IInsuranceAttributeControl>[] = [];
  subscriptions: Subscription[] = [];
  customValidators: CustomValidation;
  isInit = true;
  @ViewChild(DynamicControlDirective, {static: true}) appDynamicControl!: DynamicControlDirective;
  form!: FormGroup;
  constructor(private viewContainerRef: ViewContainerRef,
              private componentFactoryResolver: ComponentFactoryResolver) {
                this.componentFactory = new DynamicComponentFactory();
                this.form = new FormGroup({});
                this.customValidators = new CustomValidation();
              }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit() {
    this.form = this.initForm();
    this.bindComponentInternalFormToForm();
    this.subscriptions.push(this.form.statusChanges.subscribe((status) => {
      this.emitFormValues.emit(this.form.getRawValue());
      if (status === 'VALID') {
         this.emitToPersonDetailsButtonState.emit(false);
         this.emitAdditionalAttributes.emit(this.form.getRawValue());
      } else {
        this.emitToPersonDetailsButtonState.emit(true);
      }
    }));
  }
  bindComponentInternalFormToForm() {
    for (const component of this.componentsList) {
      component.instance.form = this.form;
    }
  }
  initForm() {

    let group = {};
    let isCustomValidator = false;
    let customFields = [];
    let customValidationFnName: string;

    const data  = this.loopOnAttributes(group, isCustomValidator, customValidationFnName , customFields , this.additionalAttributes);

    group = data.group;
    isCustomValidator = data.isCustomValidator;
    customValidationFnName = data.customValidationFnName;
    customFields = data.customFields;

    if (isCustomValidator) {
      return new FormGroup(group, {validators: this.customValidators[customValidationFnName](customFields)});
    } else {
      return new FormGroup(group);
    }

  }

  loopOnAttributes(group, isCustomValidator, customValidationFnName , customFields, additionalAttributes) {

    for (const attribute of additionalAttributes) {
      if (attribute.hasFormArray) {
          group[attribute.formArrayId] =  new FormArray([]);
      }

      if (attribute.validation) {
        const validators =  new DynamicFormUtils().getValidators(attribute.validation);
        group[attribute.id] =  new FormControlWithHints(attribute.value || '' , validators.validationArray);
        if (attribute.validation[ValidatorType.CustomForm]) {
          isCustomValidator = true;
          customFields.push(attribute.id);
          customValidationFnName = attribute.validation[ValidatorType.CustomForm].value;
       }
        this.loadComponent(attribute, validators.allControlMessages);
      } else {
        group[attribute.id] =  new FormControlWithHints(attribute.value || '');
        this.loadComponent(attribute);
      }
    }
    return {group, isCustomValidator , customValidationFnName , customFields};

  }

  loadComponent(insuranceAttribute: IConfiguredAttribute, allValidationErrorMessages?: any) {
    if (insuranceAttribute.type) {
      this.component = this.componentFactory.getComponent(insuranceAttribute.type);
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory<IInsuranceAttributeControl>(this.component);
      this.viewContainerRef = this.appDynamicControl.viewContainerRef;
      const componentRef = this.viewContainerRef.createComponent<IInsuranceAttributeControl>(componentFactory);
      componentRef.instance.insuranceAttribute = insuranceAttribute;
      componentRef.instance.validationErrors = allValidationErrorMessages;
      this.initHandler(insuranceAttribute.id);
      this.componentsList.push(componentRef);
    }
  }

  public initHandler(id: string) {
    let eventHandler
    const handlerName = `${id}Handler`;
   if(this.isInit) {
    eventHandler =  ControlsHandlerFactory.initDict();
    this.isInit = false;
   }
    eventHandler = ControlsHandlerFactory.getInstance().getObject(handlerName);
    
    if (!eventHandler) {
      return;
    }
    this.subscriptions.push(eventHandler.subject.subscribe((value) => {
      if (!value) {
        return;
      }
      this.emitControlSubject.emit({ handlerName, data: value});
    }));
  }
}
