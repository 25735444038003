
import { Component, OnInit, Injector } from '@angular/core';
import Debug from 'debug';
import { InsuranceSummaryPageControllerDirective } from './insurance-summary.page.controller';
import { ActivatedRoute, Router } from '@angular/router';
import { InsuranceProvider } from '@modeso/ihub-lib-insurance-fe';
import { ProductStoreProvider } from '@modeso/ihub-lib-products-fe';
import { IInsurancePartial, IInsuranceProduct } from '@modeso/types__ihub-lib-insurance-be';
import { InjectedTextPipe } from '@modeso/modeso-lib-core-fe';
const debug = Debug('dgoods:shared:InsuranceUserDetailPage');

@Component({
  selector: 'app-insurance-summary-page',
  templateUrl: './insurance-summary.page.view.html',
  styleUrls: ['./insurance-summary.page.view.scss']
})
export class InsuranceSummaryPage extends InsuranceSummaryPageControllerDirective implements OnInit {

  public subscriptions = [];
  policy;
  productIcon;
  delayPaymentByMonth;
  allProducts;
  product: any;
  hasShowSummary: boolean = false;
  // public appSwithOverlayDelegate = new AppSwithOverlayDelegate();

  constructor(
    router: Router,
    route: ActivatedRoute,
    insuranceProvider: InsuranceProvider,
    productProvider: ProductStoreProvider,
    injector: Injector,
    private injectedTextPipe: InjectedTextPipe
  ) {
    super(router, route, insuranceProvider, productProvider, injector);
    this.allProducts = JSON.parse(localStorage.getItem('products')) as IInsuranceProduct[];
  }

  /** OnInit --- START */

  ngOnInit() {
    super.ngOnInit();
    this.subscriptions.push(this.draft$.subscribe((draft: IInsurancePartial) => {
      if(!draft) {
        return;
      }
      this.policy = { ...draft };
      this.setProduct();
      this.draft = {...draft, shopTotal: this.product.shopTotal}
      this.subscriptions.push(this.injectedTextPipe.transform('ihub_shop_understood_avb').subscribe((value) => {
        if(value) {
          const element = document.getElementById("avbLink");
          element.innerHTML = value;
          const anchor =element.getElementsByTagName("a")[0];
          anchor.target = "_blank"
          anchor.href = this.getAvbLink(this.policy);
        }
      }));
    }));

    this.getDelayPaymentByMonth();
    this.setProductIcon();

  }
  /** OnInit --- END */
  private getAvbLink(insurance) {
    const products = this.allProducts;
    const product = products.find((insuranceProduct) => insuranceProduct.product === insurance.product);
    return product.disclaimerLink;
  }

  private setProduct() {
    this.product = this.allProducts.filter((product) => {
      return product.product === this.policy.product ;
    })[0];

    if (this.product.additionalAttributes) {
      for (const attribute of this.product.additionalAttributes) {
        if (attribute.showOnSummary) {
          this.hasShowSummary = attribute.showOnSummary;
        }
      }
    }
  }
  private setProductIcon(){
    if(!this.product) {
      return;
    }
    this.productIcon = this.product.icon;
  }

  private getDelayPaymentByMonth() {

    let delayPayment = parseInt(localStorage.getItem('delayPayment'));
    let productLine = localStorage.getItem('productLineId');
    let actualDelayPayment = this.allProducts.filter((product) => {
      return product.product === this.policy.product ;
    })[0].delayPaymentByMonth;

    if (delayPayment) {
      if (productLine) {
        this.delayPaymentByMonth =  this.policy.product === productLine ? delayPayment : actualDelayPayment;
      } else {
        this.delayPaymentByMonth = delayPayment;
      }
    } else {
      this.delayPaymentByMonth = actualDelayPayment;
    }
  }
}
