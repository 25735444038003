import { Component, Input, OnInit } from '@angular/core';
import { ICreatePersonRequest } from '@modeso/types__ihub-lib-user-be';

@Component({
  selector: 'ihub-person-information',
  templateUrl: './person-information.component.html',
  styleUrls: ['./person-information.component.scss']
})
export class PersonInformationComponent implements OnInit {
  @Input() person: ICreatePersonRequest;

  constructor() { }

  ngOnInit() {
  }

}
