<div class="product-buy-container">
  <form name="form" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
    <div class="Rectangle-Copy-2">
      <div class="personal-info">
        {{ "ihub_shop_person" | injectedText | async }}
      </div>
      <div class="input_inactive">
        <mat-form-field class="mat-form-field-display" appearance="outline">
          <mat-label>{{ "ihub_shop_first_name" | injectedText | async }}</mat-label>
          <input matInput placeholder="{{ 'ihub_shop_first_name' | injectedText | async }}" #firstName="ngModel" name="firstName"
          [(ngModel)]="userModel.firstName"  minlength="1" maxlength="100" required
          (focus)="onblur(false,'firstName')" (blur)="onblur(true,'firstName')">
          <a *ngIf="userModel.firstName && !firstNameBlurred" (mousedown)="$event.preventDefault()" matSuffix mat-icon-button aria-label="Clear" (click)="userModel.firstName=''">
            <mat-icon class="mat-icon-cancel">cancel</mat-icon>
          </a>
          <a *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" matSuffix>
            <mat-icon class="mat-icon-error">error</mat-icon>
          </a>
        </mat-form-field>
        <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
          <div *ngIf="firstName.errors?.required || firstName.errors?.minlength"
          class="input_error">
          {{ "ihub_shop_first_name_validation" | injectedText | async }}
        </div>
        </div>
      </div>

      <div class="input_inactive">
        <mat-form-field class="mat-form-field-display" appearance="outline">
          <mat-label>{{ "ihub_shop_last_name" | injectedText | async }}</mat-label>
          <input matInput placeholder="{{ 'ihub_shop_last_name'| injectedText | async }}" #name="ngModel" name="name"
          [(ngModel)]="userModel.name" required minlength="1" maxlength="100"
          (focus)="onblur(false,'name')" (blur)="onblur(true,'name')">
          <a *ngIf="userModel.name && !nameBlurred" (mousedown)="$event.preventDefault()" matSuffix mat-icon-button aria-label="Clear" (click)="userModel.name=''">
            <mat-icon class="mat-icon-cancel">cancel</mat-icon>
          </a>
          <a *ngIf="name.invalid && (name.dirty || name.touched)" matSuffix>
            <mat-icon class="mat-icon-error">error</mat-icon>
          </a>
        </mat-form-field>
        <div *ngIf="name.invalid && (name.dirty || name.touched)">
          <div *ngIf="name.errors?.required || name.errors?.minlength"
          class="input_error">
          {{ "ihub_shop_last_name_validation" | injectedText | async }}
          </div>
        </div>
      </div>
      <div class="input_inactive">

      <mat-form-field class="mat-form-field-display" appearance="outline">
        <mat-label>{{ "ihub_shop_date_of_birth" | injectedText | async }}</mat-label>
        <input
          matInput
          [ngxMatDatetimePicker]="picker"
          [formControl]="dateOfBirthControl"
          [max]="minBirthDate"
          [min]="maxBirthDate"
          placeholder="DD.MM.YYYY">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          [hideTime]="true"
          [startView]="'multi-year'"
          [touchUi]="true"
          #picker
        >
        </ngx-mat-datetime-picker>
      </mat-form-field>

      <div *ngIf="dateOfBirthControl.invalid && (dateOfBirthControl.dirty || dateOfBirthControl.touched)">
        <div *ngIf="!(dateOfBirthControl.errors?.ageIsNotValid) && (dateOfBirthControl.dirty || dateOfBirthControl.touched)"  class="input_error">
          {{'ihub_birthdate_eighteen' | injectedText | async}}</div>
        <div *ngIf="dateOfBirthControl.errors?.required || dateOfBirthControl.errors?.pattern"
             class="input_error">
          {{ "ihub_shop_birthday_validation" | injectedText | async }}
        </div>
      </div>
      </div>
    </div>

    <div class="Rectangle-Copy-2">
      <div class="personal-info">
        {{ "ihub_shop_address" | injectedText | async }}
      </div>
      <div class="fill-in-form">
        {{ "ihub_shop_address_remark" | injectedText | async }}
      </div>
      <div class="input_inactive">
          <mat-form-field class="mat-form-field-display" appearance="outline">
            <mat-label>{{ "ihub_shop_street" | injectedText | async }}</mat-label>
            <input matInput placeholder="{{ 'ihub_shop_street' | injectedText | async }}" #street="ngModel" name="street"
            [(ngModel)]="userModel.street" minlength="1" maxlength="100" required pattern="{{streetPattern}}"
            (focus)="onblur(false,'street')" (blur)="onblur(true,'street')">
            <a *ngIf="userModel.street && !streetBlurred && street.valid" (mousedown)="$event.preventDefault()" matSuffix mat-icon-button aria-label="Clear" (click)="userModel.street=''">
              <mat-icon class="mat-icon-cancel">cancel</mat-icon>
            </a>
            <a *ngIf="street.invalid && (street.dirty || street.touched)" matSuffix>
              <mat-icon class="mat-icon-error">error</mat-icon>
            </a>
          </mat-form-field>
          <div *ngIf="street.invalid && (street.dirty || street.touched)">
            <div *ngIf="street.errors?.required || street.errors?.minlength || street.errors?.pattern"
            class="input_error">
            {{ "ihub_shop_street_validation_err_msg" | injectedText | async }}
            </div>
          </div>
      </div>

      <div class="input_inactive">
        <mat-form-field class="mat-form-field-display" appearance="outline">
          <mat-label>{{ "ihub_shop_streetnumber" | injectedText | async }}</mat-label>
          <input matInput placeholder="{{ 'ihub_shop_streetnumber' | injectedText | async }}" #streetNumber="ngModel" name="streetNumber"
          [(ngModel)]="userModel.streetNumber" min="1" max="100" required pattern="{{streetNumberPattern}}"
          (focus)="onblur(false,'streetNumber')" (blur)="onblur(true,'streetNumber')">
          <a *ngIf="userModel.streetNumber && !streetNumberBlurred && streetNumber.valid" (mousedown)="$event.preventDefault()" matSuffix mat-icon-button aria-label="Clear" (click)="userModel.streetNumber=''">
            <mat-icon class="mat-icon-cancel">cancel</mat-icon>
          </a>
          <a *ngIf="streetNumber.invalid && (streetNumber.dirty || streetNumber.touched)" matSuffix>
            <mat-icon class="mat-icon-error">error</mat-icon>
          </a>
        </mat-form-field>
        <div *ngIf="streetNumber.invalid && (streetNumber.dirty || streetNumber.touched)">
          <div *ngIf="streetNumber.errors?.required || streetNumber.errors?.minlength"
          class="input_error">
          {{ "ihub_shop_street_validation" | injectedText | async }}
          </div>
        </div>
      </div>

      <div class="input_inactive">
          <mat-form-field class="mat-form-field-display" appearance="outline">
            <mat-label>{{ "ihub_shop_postal_code" | injectedText | async }}</mat-label>
            <input matInput placeholder="{{ 'ihub_shop_postal_code' | injectedText | async }}" #postalCode="ngModel" name="postalCode"
            [(ngModel)]="userModel.postalCode"
            required
            type="number"
            (focus)="onblur(false,'postalCode')" (blur)="onblur(true,'postalCode')">
            <a *ngIf="userModel.postalCode && !postalCodeBlurred && postalCode.valid" (mousedown)="$event.preventDefault()" matSuffix mat-icon-button aria-label="Clear" (click)="userModel.postalCode=''">
              <mat-icon class="mat-icon-cancel">cancel</mat-icon>
            </a>
            <a *ngIf="postalCode.invalid && (postalCode.dirty || postalCode.touched)" matSuffix>
              <mat-icon class="mat-icon-error">error</mat-icon>
            </a>
          </mat-form-field>
          <div *ngIf="postalCode.invalid && (postalCode.dirty || postalCode.touched)">
            <div *ngIf="postalCode.errors?.required || postalCode.errors?.invalidCode"
            class="input_error">
            {{ "ihub_shop_postal_code_validation" | injectedText | async }}
            </div>
          </div>
      </div>

      <div class="input_inactive">
          <mat-form-field class="mat-form-field-display" appearance="outline">
            <mat-label>{{ "ihub_shop_residance" | injectedText | async }}</mat-label>
            <input matInput placeholder="{{ 'ihub_shop_residance' | injectedText | async }}" #residance="ngModel" name="residance"
            [(ngModel)]="userModel.residance" minlength="1" maxlength="100" required
            (focus)="onblur(false,'residance')" (blur)="onblur(true,'residance')">
            <a *ngIf="!userModel.residance && !(residance.dirty || residance.touched)" matSuffix>
              <mat-icon class="mat-icon-cancel">
                <img style="vertical-align: top" src="../../../assets/swiss.svg" class="swiss-flag">
              </mat-icon>
            </a>
            <a *ngIf="userModel.residance && residanceBlurred" matSuffix>
              <mat-icon class="mat-icon-cancel">
                <img style="vertical-align: top"  src="../../../assets/swiss.svg" class="swiss-flag">
              </mat-icon>
            </a>
            <a *ngIf="userModel.residance && !residanceBlurred" (mousedown)="$event.preventDefault()" matSuffix mat-icon-button aria-label="Clear" (click)="userModel.residance=''">
              <mat-icon class="mat-icon-cancel">cancel</mat-icon>
            </a>
            <a *ngIf="residance.invalid && (residance.dirty || residance.touched)" matSuffix>
              <mat-icon class="mat-icon-error">error</mat-icon>
            </a>
          </mat-form-field>
          <div *ngIf="residance.invalid && (residance.dirty || residance.touched)">
            <div *ngIf="residance.errors?.required || residance.errors?.minlength"
            class="input_error">
            {{ "ihub_shop_residance_validation" | injectedText | async }}
            </div>
          </div>
      </div>
    </div>

    <div class="fixed-background">
      <div class="Rectangle-Copy-3">
        <div class="personal-info">
          {{ "ihub_shop_contact" | injectedText | async }}
        </div>
        <div class="input_inactive">
            <mat-form-field class="mat-form-field-display" appearance="outline">
              <mat-label>{{ "ihub_shop_phone" | injectedText | async }}</mat-label>
              <input  matInput placeholder="{{ 'ihub_shop_phone' | injectedText | async }}" #phone="ngModel" name="phone"
              [(ngModel)]="userModel.phone" type="tel"   required
              (focus)="onblur(false,'phone')" (blur)="onblur(true,'phone')" >
              <a *ngIf="userModel.phone  && phone.valid && !phoneBlurred" (mousedown)="$event.preventDefault()" matSuffix mat-icon-button aria-label="Clear" (click)="userModel.phone=''">
                <mat-icon class="mat-icon-cancel">cancel</mat-icon>
              </a>
              <a *ngIf="phone.invalid && (phone.dirty || phone.touched)" matSuffix>
                <mat-icon class="mat-icon-error">error</mat-icon>
              </a>
            </mat-form-field>
            <div *ngIf="phone.invalid && (phone.dirty || phone.touched)">
              <div *ngIf="phone.errors?.required || phone.errors?.invalidNumber"
              class="input_error">
              {{ "ihub_shop_phone_validation" | injectedText | async }}
              </div>
            </div>
        </div>

        <div class="input_inactive">
            <mat-form-field class="mat-form-field-display" appearance="outline">
              <mat-label>{{ "ihub_shop_email" | injectedText | async }}</mat-label>
              <input matInput placeholder="{{ 'ihub_shop_email' | injectedText | async }}" type="email" #email="ngModel" name="email"
              [(ngModel)]="userModel.email" pattern="{{emailPattern}}" required
              (focus)="onblur(false,'email')" (blur)="onblur(true,'email')" email>

              <a *ngIf="userModel.email  && email.valid && !emailBlurred" (mousedown)="$event.preventDefault()" matSuffix mat-icon-button aria-label="Clear" (click)="userModel.email=''">
                <mat-icon class="mat-icon-cancel">cancel</mat-icon>
              </a>
              <a *ngIf="email.invalid && (email.dirty || email.touched)" matSuffix>
                <mat-icon class="mat-icon-error">error</mat-icon>
              </a>
            </mat-form-field>
            <div *ngIf="email.invalid && (email.dirty || email.touched)">
              <div *ngIf="email.errors?.required || email.errors?.pattern"
              class="input_error">
              {{ "ihub_shop_email_validation" | injectedText | async }}
              </div>
            </div>
        </div>
      </div>

    </div>

</form>
</div>


