import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { IInsuranceProduct, IInsurancePartial } from '@modeso/types__ihub-lib-insurance-be';
import { Router, ActivatedRoute } from '@angular/router';
import { InsuranceProvider } from '@modeso/ihub-lib-insurance-fe';
import { ProductStoreProvider } from '@modeso/ihub-lib-products-fe';
import Debug from 'debug';
import { Observable } from 'rxjs';
const debug = Debug('ihub:project:InsuranceCheckoutBaseComponent');
@Component({
  selector: 'base-checkout',
  template: `<ng-content></ng-content>`
})
export class InsuranceCheckoutBaseComponent extends BasePageComponent implements OnInit {

  public draft$: Observable<IInsurancePartial>;
  public draft: IInsurancePartial;
  public merchant: string;

  constructor(
    public router: Router,
    protected route: ActivatedRoute,
    protected insuranceProvider: InsuranceProvider,
    protected productProvider: ProductStoreProvider,
    injector: Injector,
  ) {
    super(injector);
  }

  /** NgInit */
  ngOnInit() {
    const products = JSON.parse(localStorage.getItem('products'));
    this.draft$ = this.insuranceProvider.getCurrentProductTheUserWantsToBuy$();
    this.subscriptions.push(this.draft$.subscribe((draft: IInsurancePartial) => {
      this.draft = draft;
      if (this.draft) {
        this.merchant = products.filter((product) => draft.product === product.product)[0].merchant
      }
    }));
    super.ngOnInit();
  }

}