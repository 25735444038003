<div class="product-buy-container">

  <div class="Rectangle-Copy" >
    <div class="personal-info">
      {{ "ihub_shop_personal_info" | injectedText | async }}
    </div>
    <div class="fill-in-form">
      {{ "ihub_shop_fill_the_form" | injectedText | async }}
    </div>
  </div>

  <app-user-detail-page  #userDetails (insurancePerson)="getPersonId($event)"></app-user-detail-page>

   
  <div class="continue-btn">
    <dgoods-button [delegate]="this" class="button-style" identifier="next" [disabled]="!userDetails.formValidity || waitingForResponse">
      <div *ngIf="waitingForResponse"  >
        <mat-spinner class="mat-response-spinner" diameter="45"></mat-spinner>
      </div>
      {{ "ihub_shop_continue_button" | injectedText | async }}
    </dgoods-button>
  </div>
  <div class="back-btn">
    <dgoods-button [delegate]="this" class="button-style" state="notAction" identifier="back">
      {{ "dgoods_back_button" | injectedText | async }}
    </dgoods-button>
  </div>
    
</div>


