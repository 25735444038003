import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'

})

export class CheckboxService {

    subject: BehaviorSubject<any>;

    constructor() {
        this.subject = new BehaviorSubject<any>(false);
    }

    changeData(data) {
      this.subject.next(data);
    }

    unsubscribe() {
        this.subject.unsubscribe();
    }

}
