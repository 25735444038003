import { Component, Input, OnInit } from '@angular/core';
import { IInsuranceItem } from '../../models/insurance-item.model';
import { IIHubInsuranceItemDelegate } from '../incurance-item/incurance-item.component';


@Component({
  selector: 'ihub-incurance-list-item',
  templateUrl: './incurance-list-item.component.html',
  styleUrls: ['./incurance-list-item.component.scss']
})
export class IncuranceListItemComponent implements OnInit {

  @Input() dataSource: IInsuranceItem[];
  @Input() active: boolean;
  @Input() delegate?: IIHubInsuranceItemDelegate;
  @Input() showDates: boolean;
  constructor() { }

  ngOnInit() {
  }

}
