
import { Component, OnInit, Injector } from '@angular/core';
import Debug from 'debug';
import { InsuranceUserDetailPageControllerDirective } from './insurance-user-detail.page.controller';
import { ActivatedRoute, Router } from '@angular/router';
import { InsuranceProvider } from '@modeso/ihub-lib-insurance-fe';
import { ProductStoreProvider } from '@modeso/ihub-lib-products-fe';
const debug = Debug('dgoods:shared:InsuranceUserDetailPage');

@Component({
  selector: 'app-insurance-user-detail-page',
  templateUrl: './insurance-user-detail.page.view.html',
  styleUrls: ['./insurance-user-detail.page.view.scss']
})
export class InsuranceUserDetailPage extends InsuranceUserDetailPageControllerDirective implements OnInit {
  public product;
  public variations = [];
  public hasDenomination = false;
  public subscriptions = [];
  productLineName: string;
  // public appSwithOverlayDelegate = new AppSwithOverlayDelegate();

  constructor(
    router: Router,
    route: ActivatedRoute,
    insuranceProvider: InsuranceProvider,
    productProvider: ProductStoreProvider,
    injector: Injector,
  ) {
    super(router, route, insuranceProvider, productProvider, injector);

  }

  /** OnInit --- START */

  ngOnInit() {
    super.ngOnInit();
  }
  /** OnInit --- END */


}
