/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/router";
import * as i8 from "@modeso/modeso-lib-localization-fe";
import * as i9 from "@ngrx/store";
import * as i10 from "@angular/platform-browser";
import * as i11 from "./alert.component";
import * as i12 from "@modeso/ihub-lib-user-fe";
var styles_IHubAlertComponent = [i0.styles];
var RenderType_IHubAlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IHubAlertComponent, data: {} });
export { RenderType_IHubAlertComponent as RenderType_IHubAlertComponent };
function View_IHubAlertComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["diameter", "20"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = "20"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_IHubAlertComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "alertWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row1 alertrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "alertcol"], ["src", "assets/ihub/twint-icons-icon-denied-small.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [["class", "desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " "])), i1.ɵppd(5, 1), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "row2 alertrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "span", [["class", "link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.linkToTwint() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵppd(11, 1), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IHubAlertComponent_3)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.waitingForUofResponse; _ck(_v, 14, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 6).transform(i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), "ihub_inactive_uof_alert_text")))); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 12).transform(i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent.parent, 0), "ihub_shop_link_to_twint")))); _ck(_v, 10, 0, currVal_1); }); }
function View_IHubAlertComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "alertWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row1 alertrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "alertcol"], ["src", "assets/ihub/twint-icons-icon-denied-small.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [["class", "desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " "])), i1.ɵppd(5, 1), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "row2 alertrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "span", [["class", "link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵppd(12, 1), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.link; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 6).transform(i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), "ihub_shop_payment_alert_text")))); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 10).target; var currVal_2 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 13).transform(i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v.parent.parent, 0), "ihub_shop_payment_alert_details")))); _ck(_v, 11, 0, currVal_4); }); }
function View_IHubAlertComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IHubAlertComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IHubAlertComponent_4)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.alertType === 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.alertType === 2); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_IHubAlertComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.ɵa, [i9.Store, i10.DomSanitizer]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IHubAlertComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.alertType !== 0) && _co.link); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_IHubAlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ihub-alert", [], null, null, null, View_IHubAlertComponent_0, RenderType_IHubAlertComponent)), i1.ɵdid(1, 770048, null, 0, i11.IHubAlertComponent, [i1.Injector, i12.UserProvider], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IHubAlertComponentNgFactory = i1.ɵccf("ihub-alert", i11.IHubAlertComponent, View_IHubAlertComponent_Host_0, { failedSinglePaymentInsurances: "failedSinglePaymentInsurances", alertType: "alertType" }, {}, []);
export { IHubAlertComponentNgFactory as IHubAlertComponentNgFactory };
