import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DgoodsUserConfigService } from './../dgoods.user.config';
import {
  IUserStartUoFRegistrationRequest,
  IUserStartUoFRegistrationResponse,
  IUserUoFDeRegistrationResponse, ICreatePersonRequest
} from '@modeso/types__ihub-lib-user-be';
import { IUpdateUserRequest, IUpdateUserResponse, IUser } from '../models/user.model';
import { ICreatePersonResponse, IGetPersonResponse } from '../models/person.model';



@Injectable({
  providedIn: 'root',
})
export class DgoodsUserService {
  private server = '';
  private microserviceName = 'users';
  private readonly route = '/';
  private readonly startRegistrationRoute = '/startRegistration';
  private readonly createPersonRoute = '/create/person';
  private readonly getPersonRoute = '/person';
  private readonly getPersonByOfUser = '/personByOfUser';
  private readonly updatePersonRoute = '/update/person';
  private readonly uofDeregistrationRoute = '/uofDeregistration';

  /**
   *
   * @param http
   * @param config
   */
  constructor(
    private http: HttpClient, @Inject(DgoodsUserConfigService) private config) {
    this.server = config.apiGateway;
  }

  updateUserEmail(body: IUpdateUserRequest) {
    const url = `${this.server}${this.microserviceName}${this.route}`;
    return this.http.post<IUpdateUserResponse>(url, body);
  }

  startUofRegistration(body: IUserStartUoFRegistrationRequest) {
    const url = `${this.server}${this.microserviceName}${this.startRegistrationRoute}`;
    return this.http.post<IUserStartUoFRegistrationResponse>(url, body);
  }

  createPerson(body: ICreatePersonRequest) {
    const url = `${this.server}${this.microserviceName}${this.createPersonRoute}`;
    return this.http.post<ICreatePersonResponse>(url, body);
  }

  updatePerson(body: ICreatePersonRequest) {
    const url = `${this.server}${this.microserviceName}${this.updatePersonRoute}`;
    return this.http.post<ICreatePersonResponse>(url, body);
  }

  getPerson(id: string) {
    const url = `${this.server}${this.microserviceName}${this.getPersonRoute}${this.route}${id}`;
    return this.http.get<IGetPersonResponse>(url);
  }

  getPersonOfUser(id: string) {
    const url = `${this.server}${this.microserviceName}${this.getPersonByOfUser}`;
    return this.http.get<IGetPersonResponse>(url);
  }

  uofDeRegistration() {
    const url = `${this.server}${this.microserviceName}${this.uofDeregistrationRoute}`;
    return this.http.post<IUserUoFDeRegistrationResponse>(url, {});
  }

  getUser() {
    const url = `${this.server}${this.microserviceName}`;
    return this.http.get<IUser>(url);
  }

}
