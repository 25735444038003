/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cancelation-policy-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@modeso/ihub-lib-insurance-fe/modeso-ihub-lib-insurance-fe.ngfactory";
import * as i4 from "@modeso/ihub-lib-insurance-fe";
import * as i5 from "../../../../node_modules/@modeso/twint-lib-core-fe/modeso-twint-lib-core-fe.ngfactory";
import * as i6 from "@modeso/twint-lib-core-fe";
import * as i7 from "@modeso/modeso-lib-localization-fe";
import * as i8 from "@ngrx/store";
import * as i9 from "@angular/platform-browser";
import * as i10 from "@modeso/modeso-lib-core-fe";
import * as i11 from "./cancelation-policy-page.component";
import * as i12 from "@angular/router";
import * as i13 from "@modeso/twint-lib-analytics-fe";
import * as i14 from "@modeso/ihub-lib-user-fe";
var styles_CancelationPolicyPageComponent = [i0.styles];
var RenderType_CancelationPolicyPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CancelationPolicyPageComponent, data: {} });
export { RenderType_CancelationPolicyPageComponent as RenderType_CancelationPolicyPageComponent };
function View_CancelationPolicyPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "cancellation-policy-titles"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["style", "margin-bottom: 16px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [["class", "policy-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ": "])), i1.ɵppd(6, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "policy-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "ihub-insurance-cancelation-policy", [], null, null, null, i3.View_InsuranceCancelationPolicyComponent_0, i3.RenderType_InsuranceCancelationPolicyComponent)), i1.ɵdid(11, 114688, null, 0, i4.InsuranceCancelationPolicyComponent, [], { insurance: [0, "insurance"], firstPossibleCancellationDate: [1, "firstPossibleCancellationDate"], person: [2, "person"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "a", [["class", "confirm-cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵppd(15, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(17, 0, null, null, 4, "dgoods-button", [["identifier", "back"]], null, null, null, i5.View_DGoodsButtonComponent_0, i5.RenderType_DGoodsButtonComponent)), i1.ɵdid(18, 49152, null, 0, i6.DGoodsButtonComponent, [], { identifier: [0, "identifier"], delegate: [1, "delegate"] }, null), (_l()(), i1.ɵted(19, 0, [" ", " "])), i1.ɵppd(20, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.insuranceToBeCanceled; var currVal_4 = _co.firstPossibleCancellationDate; var currVal_5 = _co.person; _ck(_v, 11, 0, currVal_3, currVal_4, currVal_5); var currVal_7 = "back"; var currVal_8 = _co; _ck(_v, 18, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.insuranceToBeCanceled.productTitle; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 7).transform(i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), "ihub_shop_policy_number")))); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.insuranceToBeCanceled.policyNumber; _ck(_v, 9, 0, currVal_2); var currVal_6 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 16).transform(i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v.parent, 1), "ihub_shop_cancel_policy")))); _ck(_v, 14, 0, currVal_6); var currVal_9 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 21).transform(i1.ɵunv(_v, 19, 0, _ck(_v, 20, 0, i1.ɵnov(_v.parent, 1), "dgoods_back_button")))); _ck(_v, 19, 0, currVal_9); }); }
export function View_CancelationPolicyPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.ɵa, [i8.Store, i9.DomSanitizer]), i1.ɵpid(0, i10.InjectedTextPipe, [i8.Store]), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "main-title-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [["class", "main-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CancelationPolicyPageComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.insuranceToBeCanceled; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 6).transform(i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), "ihub_shop_cancellation_policy")))); _ck(_v, 4, 0, currVal_0); }); }
export function View_CancelationPolicyPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cancelation-policy-page", [], null, null, null, View_CancelationPolicyPageComponent_0, RenderType_CancelationPolicyPageComponent)), i1.ɵdid(1, 245760, null, 0, i11.CancelationPolicyPageComponent, [i1.Injector, i12.Router, i12.ActivatedRoute, i4.InsuranceProvider, i13.AnalyticsProvider, i14.UserProvider], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CancelationPolicyPageComponentNgFactory = i1.ɵccf("app-cancelation-policy-page", i11.CancelationPolicyPageComponent, View_CancelationPolicyPageComponent_Host_0, {}, {}, []);
export { CancelationPolicyPageComponentNgFactory as CancelationPolicyPageComponentNgFactory };
