<div class="product-buy-container">
  <form name="form" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
    <div class="rectangle-1">
      <div class="personal-info" >
        {{'ihub_shop_claim_form' | injectedText | async}}
        {{insurance?.productTitle}}
      </div>
      <div class="fill-in-form">
        {{'ihub_shop_claim_form_subtitle' | injectedText | async}}
      </div>
      <div class="fill-in-form" style="font-style: italic;">
        {{insuranceProduct?.productSpecificClaimsText}}
      </div>

      <div style="text-align: center;" *ngIf="insurancePhoneNumber">
        <button class="damage-report__button"><a href="{{'tel:'+ insurancePhoneNumber}}">
          {{'ihub_call_insurance' | injectedText | async}}
        </a></button>
      </div>
      <div style="text-align: center;" *ngIf="emergencyPhoneNumber">
        <button class="damage-report__button"><a href="{{'tel:'+ emergencyPhoneNumber}}">
          {{ "ihub_shop_emergency_call" | injectedText | async }}
        </a></button>
      </div>
    </div>

    <div class="rectangle-2">
      <div class="title">
        {{'ihub_shop_info_of_payment' | injectedText | async}}
      </div>
      <div class="input_inactive">
        <mat-form-field class="mat-form-field-display" appearance="outline">
          <mat-label>{{'ihub_shop_iban' | injectedText | async}}</mat-label>
          <input
            matInput
            placeholder="{{'ihub_shop_iban' | injectedText | async}}"
            #ibanNo="ngModel"
            name="ibanNo"
            [(ngModel)]="damageReportModel.ibanNo"
            minlength="1"
            maxlength="100"
            required
            (focus)="onblur(false,'ibanNo')"
            (blur)="onblur(true,'ibanNo')"
          />
          <a
            *ngIf="damageReportModel.ibanNo && !ibanNoBlurred"
            (mousedown)="$event.preventDefault()"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="damageReportModel.ibanNo=''"
          >
            <mat-icon class="mat-icon-cancel">cancel</mat-icon>
          </a>
          <a
            *ngIf="ibanNo.invalid && (ibanNo.dirty || ibanNo.touched)"
            matSuffix
          >
            <mat-icon class="mat-icon-error">error</mat-icon>
          </a>
        </mat-form-field>
        <div *ngIf="ibanNo.invalid && (ibanNo.dirty || ibanNo.touched)">
          <div
            *ngIf="ibanNo.errors?.required || ibanNo.errors?.minlength"
            class="input_error"
          >
            {{'ihub_shop_iban_error' | injectedText | async}}
          </div>
        </div>
      </div>

      <div class="input_inactive">
        <mat-form-field class="mat-form-field-display" appearance="outline">
          <mat-label
            >{{'ihub_shop_bank_name' | injectedText | async}}</mat-label
          >
          <input
            matInput
            placeholder="{{'ihub_shop_bank_name' | injectedText | async}}"
            #bankName="ngModel"
            name="bankName"
            [(ngModel)]="damageReportModel.bankName"
            required
            minlength="1"
            maxlength="100"
            (focus)="onblur(false,'bankName')"
            (blur)="onblur(true,'bankName')"
          />
          <a
            *ngIf="damageReportModel.bankName && !bankNameBlurred"
            (mousedown)="$event.preventDefault()"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="damageReportModel.bankName=''"
          >
            <mat-icon class="mat-icon-cancel">cancel</mat-icon>
          </a>
          <a
            *ngIf="bankName.invalid && (bankName.dirty || bankName.touched)"
            matSuffix
          >
            <mat-icon class="mat-icon-error">error</mat-icon>
          </a>
        </mat-form-field>
        <div *ngIf="bankName.invalid && (bankName.dirty || bankName.touched)">
          <div
            *ngIf="bankName.errors?.required || bankName.errors?.minlength"
            class="input_error"
          >
            {{'ihub_shop_bank_name_error' | injectedText | async}}
          </div>
        </div>
      </div>

      <div class="input_inactive">
        <mat-form-field class="mat-form-field-display" appearance="outline">
          <mat-label
            >{{'ihub_shop_account_name' | injectedText | async}}</mat-label
          >
          <textarea
            matInput
            placeholder="{{'ihub_shop_account_name' | injectedText | async}}"
            #accountName="ngModel"
            name="accountName"
            [(ngModel)]="damageReportModel.accountName"
            minlength="1"
            maxlength="100"
            (focus)="onblur(false,'accountName')"
            (blur)="onblur(true,'accountName')"
            style="height: 80px"
          ></textarea>

          <a
            *ngIf="damageReportModel.accountName && !accountNameBlurred"
            (mousedown)="$event.preventDefault()"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="damageReportModel.accountName=''"
          >
            <mat-icon class="mat-icon-cancel">cancel</mat-icon>
          </a>
        </mat-form-field>
      </div>
    </div>

    <div class="rectangle-3">
      <div class="title">
        {{'ihub_shop_recent_claims' | injectedText | async}}
      </div>

      <div class="input_inactive">
        <mat-form-field
          class="mat-form-field-display"
          appearance="outline"
          style="display: inline-flex"
        >
          <mat-label>{{'ihub_shop_date' | injectedText | async}}</mat-label>
          <app-date-custom-control
            #date="ngModel"
            name="date"
            [(ngModel)]="damageReportModel.date"
            required
            (focusin)="onblur(false,'date')"
            (focusout)="onblur(true,'date')"
          > 
        </app-date-custom-control>
          <a
            *ngIf="damageReportModel.date && date.valid &&!dateBlurred"
            (mousedown)="$event.preventDefault()"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="damageReportModel.date=''"
          >
            <mat-icon class="mat-icon-cancel">cancel</mat-icon>
          </a>
          <a *ngIf="date.invalid && (date.dirty || date.touched)" matSuffix>
            <mat-icon class="mat-icon-error">error</mat-icon>
          </a>
        </mat-form-field>
        <div *ngIf="date.invalid && (date.dirty || date.touched)">
          <div
            *ngIf="date.errors?.required"
            class="input_error"
          >
            {{'ihub_shop_date_error' | injectedText | async}}
          </div>
          <div
          *ngIf="date.errors?.notValid"
          class="input_error"
        >
          {{'ihub_damage_report_future_date_validation' | injectedText | async}}
        </div>
        </div>
      </div>
      <div class="input_inactive">
        <mat-form-field class="mat-form-field-display" appearance="outline">
          <mat-label
            >{{'ihub_shop_description' | injectedText | async}}</mat-label
          >
          <textarea
            matInput
            placeholder="{{'ihub_shop_description' | injectedText | async}}"
            #description="ngModel"
            name="description"
            [(ngModel)]="damageReportModel.description"
            required
            minlength="1"
            maxlength="1000"
            (focus)="onblur(false,'description')"
            (blur)="onblur(true,'description')"
            style="height: 80px"
          ></textarea>
          <a
            *ngIf="damageReportModel.description && !descriptionBlurred"
            (mousedown)="$event.preventDefault()"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="damageReportModel.description=''"
          >
            <mat-icon class="mat-icon-cancel text-area-mat-icon"
              >cancel</mat-icon
            >
          </a>
          <a
            *ngIf="description.invalid && (description.dirty || description.touched)"
            matSuffix
          >
            <mat-icon class="mat-icon-error text-area-mat-icon">error</mat-icon>
          </a>
        </mat-form-field>
        <div
          *ngIf="description.invalid && (description.dirty || description.touched)"
        >
          <div
            *ngIf="description.errors?.required || description.errors?.minlength"
            class="input_error"
          >
            {{'ihub_shop_description_error' | injectedText | async}}
          </div>
        </div>
      </div>

      <div class="input_inactive">
        <mat-form-field
          class="mat-form-field-display"
          appearance="outline"
          style="display: inline-flex"
        >
          <mat-label>{{'ihub_shop_amount' | injectedText | async}}</mat-label>
          <input
            type="number"
            matInput
            placeholder="{{'ihub_shop_amount' | injectedText | async}}"
            #amount="ngModel"
            name="amount"
            [(ngModel)]="damageReportModel.amount"
            min="0"
            minlength="1"
            maxlength="100"
            required
            (focus)="onblur(false,'amount')"
            (blur)="onblur(true,'amount')"
          />
          <a
            *ngIf="damageReportModel.amount && !amountBlurred"
            (mousedown)="$event.preventDefault()"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="damageReportModel.amount=''"
          >
            <mat-icon class="mat-icon-cancel">cancel</mat-icon>
          </a>
          <a
            *ngIf="amount.invalid && (amount.dirty || amount.touched)"
            matSuffix
          >
            <mat-icon class="mat-icon-error">error</mat-icon>
          </a>
        </mat-form-field>
        <div *ngIf="amount.invalid && (amount.dirty || amount.touched)">
          <div
            *ngIf="amount.errors?.required || amount.errors?.minlength"
            class="input_error"
          >
            {{'ihub_shop_amount_error' | injectedText | async}}
          </div>
        </div>
      </div>
    </div>

    <div class="rectangle-3" *ngIf="insuranceProduct">
      <div class="title">{{ "ihub_shop_extra" | injectedText | async }} </div>
      <div class="input_inactive" *ngFor="let attachment of insuranceProduct.productListAttachments"
       style="display: flex; padding-bottom: 20px">
        <span>
          <img src="../../../assets/twint-icons-icn-electronic-data.svg" />
        </span>
        <div style="margin-left: 15px">
          <div class="title-one">
            {{attachment.documentPurpose}}
          </div>
          <div class="par-one">
             {{attachment.documentExample}}
          </div>
        </div>
      </div>
      <br />
      <br />
      <div class="upload-container">
        <ngx-file-drop
          dropZoneLabel="Drop files here"
          (onFileDrop)="dropped($event)"
        >
          <ng-template
            ngx-file-drop-content-tmp
            let-openFileSelector="openFileSelector"
          >
            <div style="width: 70px">
              <button
                class="browse-button"
                type="button"
                (click)="openFileSelector()"
              >
                <mat-icon
                  style="
                    color: black;
                    font-size: 50px;
                    width: 50px;
                    height: 50px;
                  "
                  >add_circle</mat-icon
                >
              </button>
            </div>
            <span> {{ 'ihub_shop_upload_file' | injectedText |async }} </span>
          </ng-template>
        </ngx-file-drop>
        <div class="pt-1" *ngIf="imagesDropped">
          <div *ngFor="let file of uploadedFiles" class="upload-rectangle">
            <img
              class="img-document"
              src="../../../assets/twint-icons-document.svg"
            />
            <a (click)="removeFile(file)" matSuffix
              ><mat-icon class="cancel-mat-icon">cancel</mat-icon></a
            >
            <span *ngIf="file.fileEntry.name.length>10" class="file-name-span"
              >{{file.fileEntry.name.substring(0, 9)}}...</span
            >
            <span *ngIf="file.fileEntry.name.length<10" class="file-name-span"
              >{{file.fileEntry.name}}</span
            >
          </div>
        </div>
        <span class="input_error upload-error" *ngIf="errorFile"
          >{{'ihub_shop_file_errors'| injectedText | async }}</span
        >
      </div>
    </div>

    <div class="fixed-background" *ngIf="insuranceProduct">
      <div class="rectangle-4">
        <div class="div-checkbox" style="margin-bottom: 10px;">
          <mat-checkbox
            name="acceptTerm1"
            [(ngModel)]="acceptTerm1"
          ></mat-checkbox>
          <span
            class="span-text"
            style="margin-left: 10px"
            (click)="openPopup($event, insuranceProduct.damageReportCheckBoxDetail?.agreeToNoConfedentialityBox.text)"
            [innerHTML]="insuranceProduct.damageReportCheckBoxDetail?.agreeToNoConfedentialityBox.title"
          >
          </span>
        </div>
        <div class="div-checkbox" >
          <mat-checkbox
            name="acceptTerm2"
            [(ngModel)]="acceptTerm2"
          ></mat-checkbox>
          <span
            class="span-text"
            style="margin-left: 10px"
            (click)="openPopup($event,insuranceProduct.damageReportCheckBoxDetail?.agreeToRequiredAttachmentsBox.text)"
            [innerHTML]="insuranceProduct.damageReportCheckBoxDetail?.agreeToRequiredAttachmentsBox.title"
          >
          </span>
        </div>
      </div>

      <div class="continue-btn">
        <dgoods-button
          [delegate]="this"
          class="button-style"
          identifier="next"
          [disabled]="f.invalid || !acceptTerm1 || !acceptTerm2 || errorFile"
        >
       
          {{ "ihub_shop_submit_damage_report" | injectedText | async }}
        </dgoods-button>
      </div>
      <div class="back-btn">
        <dgoods-button
          [delegate]="this"
          class="button-style"
          state="notAction"
          identifier="back"
        >
          {{ "dgoods_back_button" | injectedText | async }}
        </dgoods-button>
      </div>
    </div>
  </form>
</div>

<div
  *ngIf="waitingForDamageReponse"
  style="
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
  "
>
  <mat-spinner style="margin: auto"></mat-spinner>
</div>

<ihub-popup #popup [content]="_popupContent"></ihub-popup>
