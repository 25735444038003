import { createAction, props, Action } from '@ngrx/store';
import { ActionWithPayload } from '@modeso/modeso-lib-core-fe';
import { ICheckInRequest, IStartRequest, ICheckInResponse, ICheckInRequestAuth, IValidateUrlRequest, IAccessTokenResponse } from '../services/dgoods.session.service';

export const onGetNewAccesToken = createAction(
    '[Dgoods session] get new access token'
);

export const onCheckin = createAction(
    '[Dgoods session] checkin',
    props<{payload: ICheckInRequest, auth: ICheckInRequestAuth}>()

);

export const onCheckinSuccess = createAction(
    '[Dgoods session] checkin successfully',
    props< { payload: ICheckInResponse }>()
);

export const onCheckinFail = createAction(
    '[Dgoods session] checkin failed'
);

export const onStart = createAction(
  '[Dgoods session] start',
  props<{payload: IStartRequest}>()

);

export const onStartSuccess = createAction(
  '[Dgoods session] start successfully',
  props<{payload: IAccessTokenResponse}>()
);

export const onStartFail = createAction(
  '[Dgoods session] start failed',
  props<{payload: any}>()
);

export const onGetNewTokenSuccess = createAction(
    '[Dgoods session] get new access token successfully'
);

export const onGetNewTokenFailed = createAction(
    '[Dgoods session] get new access token failed'
);

export const onLogout = createAction(
    '[Dgoods session] session logout'
);

export const onLogoutSuccess = createAction(
    '[Dgoods session] session logout sucess'
);

export const onLogoutFail = createAction(
    '[Dgoods session] session logout failed'
);

export const onValidateUrl = createAction(
    '[Dgoods session] validate',
    props<{payload: IValidateUrlRequest}>()
);

export const onValidateUrlSuccess = createAction(
    '[Dgoods session] validate url success',
);

export const onValidateUrlFail = createAction(
    '[Dgoods session] validate url fail',
);


export interface IActionWithPayload<T> extends Action {
    payload: T;
}
export interface IActionWithPayloadAndAuth<T, TT> extends Action {
    payload: T;
    auth: TT;
}
