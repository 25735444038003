import { Injectable } from '@angular/core';

import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';

import { Observable} from 'rxjs';

@Injectable()
export class LanguageHandler implements HttpInterceptor {
    constructor() {
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // getting the language of the browser
        const browserLanguage = navigator.language;
        let headerLanguage = '';
        if (browserLanguage.includes('de')) {
            headerLanguage = 'de-ch';
        } else if (browserLanguage.includes('fr')) {
            headerLanguage = 'fr-ch';
        } else if (browserLanguage.includes('it')) {
            headerLanguage = 'it-ch';
        } else {
            headerLanguage = 'en-us';
        }

        const languageReq = request.clone({
            headers: request.headers.set('content-language' , headerLanguage)
          });
        return next.handle(languageReq);
    }
}
