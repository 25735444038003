import { Component, OnInit, Input, ViewChild } from '@angular/core';
import Debug from 'debug';
import { IHubPopUpComponent, IIHubPopupContent } from '../ihub-popup/ihub-popup.component';
const debug = Debug('dgoods:shared:IHubSwitchComponent');

export interface IIHubSwitchSelection {
  text: string;
  description?: string;
  selected: boolean;
  reference?: any;
}

export interface IIHubSwitchDelegate {
  onSelect(selection: IIHubSwitchSelection, sender: IHubSwitchComponent, identifier?: string): void;
}

/**
 * Select one of componets. If more then one are selected from beginning, the first is selected, the rest get unselected
 */
@Component({
  selector: 'ihub-switch',
  templateUrl: './ihub-switch.component.html',
  styleUrls: ['./ihub-switch.component.scss']
})
export class IHubSwitchComponent {

  private _selection: IIHubSwitchSelection[];
  private _activeSelection: IIHubSwitchSelection;

  @ViewChild("popup", { static: true }) popup: IHubPopUpComponent;
  _popupContent: IIHubPopupContent;

  get selection(): IIHubSwitchSelection[] {
    return this._selection;
  }
  @Input() set selection(value: IIHubSwitchSelection[]) {
    debug("test");
    if (value === null) {
      this._selection = [];
    }
    let selected: IIHubSwitchSelection;
    try {
      selected = value.filter((item) => item.selected)[0];
    } catch (error) {
      selected = value[0];
    }
    value.forEach((item) => {
      if (item !== selected) {
        item.selected = false;
      }
    });
    this._activeSelection = selected;
    this._selection = value;
  }
  @Input() delegate?: IIHubSwitchDelegate;
  @Input() identifier?: string;
  @Input() translated?: boolean = false;
  @Input() openAsInfoIcon?: boolean = false;
  /**
   * onClick
   */
  public onClick(item: IIHubSwitchSelection) {
    this._activeSelection.selected = false;
    this._activeSelection = item;
    this._activeSelection.selected = true;
    debug("onClick", item);
    if (this.delegate) {
      this.delegate.onSelect(item, this, this.identifier);
    } else {
      debug('onSelect event not fired. There is no delegate attached to the component.');
    }
  }

  /**
   * onClick
   */
  public openPopup(event: any, item: IIHubSwitchSelection) {
    event.stopPropagation();
    this._popupContent = {
      title: item.text,
      text: item.description,
      needsTranslation: !this.translated
    };
    this.popup.visible = true;
    debug("openPopup:", this._popupContent, event);
  }
}
