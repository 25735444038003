import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DgoodsSessionStoreProvider } from '@modeso/twint-lib-session-fe';
import Debug from 'debug';
const debug = Debug('dgoods:project-shop:NavigationAuthGuard');
@Injectable()
export class NavigationAuthGuard implements CanActivate {
  protected subscriptions = [];

  constructor(private router: Router, private cookieService: CookieService, private sessionProvider: DgoodsSessionStoreProvider) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    return this.sessionProvider.isAllowedToCheckout$().pipe(tap(isAllowed => {
      debug("is allowed to checkout");
      debug(isAllowed);
      if (!isAllowed) {
        this.router.navigateByUrl('/de-ch/login');
      }
    }));
  }

}
