import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import Debug from 'debug';
import { IInsuranceProduct } from '@modeso/types__ihub-lib-insurance-be';
const debug = Debug('dgoods:project:SliderComponent');

export interface ISlideObject {
  product: string;
  teaserImage: string;
}

export interface ITwintSliderDelegate {
  onSlideClick(product: ISlideObject);
}
@Component({
  selector: 'twint-slider',
  templateUrl: './ihub-slider.component.html',
  styleUrls: ['./ihub-slider.component.scss'],
})
export class SliderComponent {

  _slides: ISlideObject[];
  @Input() set slides(value: ISlideObject[]) {
    this._slides = value;
    this.transformStyle = {
      transform: `translateX(${this.offset}px)`
    };
  }
  get slides() {
    return this._slides;
  }

  @Input() delegate?: ITwintSliderDelegate;
  @ViewChild('slidecontainer', { static: true }) slidecontainer: ElementRef;

  currentSlide: number = 0;
  transformStyle = {
    transform: "translateX(0)"
  };
  offset = 0;
  constructor(private renderer: Renderer2) {

  }

  dotClick(slideIndex: number) {
    debug("dotClick", slideIndex, this.currentSlide)
    if (this.currentSlide === slideIndex) {
      return;
    }
    this.currentSlide = slideIndex;
    this.offset = slideIndex * -290;
    this.transformStyle = {
      transform: `translateX(${this.offset}px)`
    };
  }

  onTap(event, product) {
    if (this.delegate) {
      this.delegate.onSlideClick(product);
    }
  }
  onTapNext(event) {
    debug('tap next', event);
    debug(this.slidecontainer.nativeElement);

  }
  onSwipeLeft(event) {
    if (this.currentSlide === this._slides.length - 1) {
      return;
    }
    this.currentSlide = this.currentSlide + 1;
    this.offset = this.offset - 290;
    this.transformStyle = {
      transform: `translateX(${this.offset}px)`
    };
    debug('left', this.offset);
  }
  onSwipeRight(event) {
    debug('rigth', event);
    if (this.currentSlide === 0) {
      return;
    }
    this.currentSlide = this.currentSlide - 1;
    this.offset = this.offset + 290;
    this.transformStyle = {
      transform: `translateX(${this.offset}px)`
    };
  }
}
