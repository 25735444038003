import { Injectable } from '@angular/core';
import * as fromActions from './../actions/ihub.damage.actions';
import * as fromFeature from './../reducers/ihub.damage.reducer';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICreateDamageRequest } from "@modeso/types__ihub-lib-damage-be";


@Injectable({
  providedIn: 'root'
})
export class DamageReportProvider {

  constructor(
    private store: Store<fromFeature.AppState>) { }


  public createDamageReport$(body:any): Observable<string> {
    this.store.dispatch(fromActions.onCreateDamageReport({ payload: body }));
    return this.store.pipe(select(fromFeature.featureDamageReportId),
    map((id: string) => {
        return id;
      })
    );
  }

  public getDamageReportByInsuranceId$(id: string): Observable<ICreateDamageRequest> {
    this.store.dispatch(fromActions.getDamageByInsuranceId({ payload:id }));
    return this.store.pipe(select(fromFeature.featureDamageReport),
      map((damage: ICreateDamageRequest) => {
        return damage;
      })
    );
  }
}
