import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { InjectedTextPipe } from '@modeso/modeso-lib-core-fe';
import { PaymentStatusEnum } from '@modeso/types__ihub-lib-insurance-be/dist/enums/paymentStatus.enum';
import { take } from 'rxjs/operators';
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[insuranceHighlight]'
})
export class InsuranceHighlightDirective implements OnInit, OnChanges {
  @Input() status;
  @Input() paymentStatus;
  constructor(private el: ElementRef, private injectTextPipe: InjectedTextPipe) {}
    ngOnChanges(): void {
        this.checkStatus();
    }
    ngOnInit(): void {
       this.checkStatus();
    }

    private checkStatus() {
        if ((this.status === 1 || this.status === 4) && (this.paymentStatus === PaymentStatusEnum.PAYED || this.paymentStatus === 12)) {
            this.renderElement('#a0d988', '#537a42', 'ihub_shop_insurance_active_status');
        }  else if ((this.status === 1 || this.status === 4) && this.paymentStatus !== PaymentStatusEnum.PAYED && this.paymentStatus !== 12) {
            this.renderElement('#fecf6d', '#537a42', 'ihub_insurance_status_pending_payment');
        }  else if (this.status === 2) {
            this.renderElement('#fecf6d', '#537a42', 'ihub_shop_insurance_quit_status');
        } else if (this.status === 3 || this.status === 6) {
            this.renderElement('#eb4367', '#891f36', 'ihub_shop_insurance_inactive_status');
        } else if (this.status === 5) {
            this.renderElement('#fecf6d', '#537a42', 'ihub_insurance_status_pending');
        }
    }

    private renderElement(backGroundColor: string, textColor: string, localizedString: string) {
        this.el.nativeElement.style.backgroundColor = backGroundColor;
        this.el.nativeElement.style.color = textColor;
        this.injectTextPipe.transform(localizedString).pipe(take(1)).subscribe((value) => {
            this.el.nativeElement.innerHTML = value;
        });
    }
}
