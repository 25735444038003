import { NgModule } from '@angular/core';
import { InjectedTextPipe } from './injectedText.pipe';
import { StoreModule } from '@ngrx/store';
import * as fromInjected from './namedString.reducer';
@NgModule({
  declarations: [InjectedTextPipe],
  imports: [
    StoreModule.forFeature(fromInjected.featureKey, fromInjected.reducer)
  ],
  exports: [InjectedTextPipe]
})
export class InjectedTextFeautureModule { }
