import { Component, Input, OnInit } from '@angular/core';
import { IInsuranceOperationResponse } from '@modeso/types__ihub-lib-insurance-be';
import { IProduct, IMerchantEnum } from '@modeso/types__ihub-lib-products-be';

@Component({
  selector: 'ihub-insurance-merchant-documents',
  templateUrl: './insurance-merchant-documents.component.html',
  styleUrls: ['./insurance-merchant-documents.component.scss']
})
export class InsuranceMerchantDocumentsComponent implements OnInit {
  @Input() insurance: IInsuranceOperationResponse;
  @Input() person: any;
  @Input() product: IProduct
  public merchantEnum: typeof IMerchantEnum
  constructor() {
    this.merchantEnum = IMerchantEnum;
  }

  ngOnInit() {
  }

}
