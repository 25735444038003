<div class="langing-buy-container ihub-styles" [ngClass]="hiddenAccountOverlay ? 'visible' : 'invisible'">
  <ihub-alert [alertType]="alertType" [failedSinglePaymentInsurances]="failedSinglePaymentInsurances"></ihub-alert>
  <dgoods-navigation [delegate]="this" [account]="true" [backBoolean]="false"></dgoods-navigation>

  <twint-slider *ngIf="slides.length" [slides]="slides" [delegate]="this"></twint-slider>

  <div class="ihub-view-container">

    <dgoods-title-component title="{{'ihub_shop_feature_product' | localize | async }}" *ngIf="featureProduct">
    </dgoods-title-component>

    <ihub-tile  *ngIf="featureProduct" [isTransparent]="true"
      [delegate]="this" [item]="featureProduct">
    </ihub-tile>


    <dgoods-title-component *ngIf="hasInsurances" title="{{'ihub_shop_personal_insurance' | localize | async }}">
    </dgoods-title-component>


    <ihub-tile *ngIf="hasInsurances" [isTransparent]="true" [delegate]="this" [item]="historyLink">
    </ihub-tile>
    <dgoods-title-component title="{{'ihub_shop_all_insurances' | localize | async }}"></dgoods-title-component>
    <ihub-filter *ngIf="productsExistInLocalStorage" [delegate]="this" [items]="productCategories"
      [selectedIndex]="selectedIndex"></ihub-filter>
  </div>

  <ihub-list [delegate]="this" *ngIf="productsExistInLocalStorage" [items]="insuranceProductTiles"></ihub-list>

  <ihub-wurth-logo></ihub-wurth-logo>
</div>
