

<div class="purchasing-history-container">
  <dgoods-navigation [delegate]="this" [account]="false"></dgoods-navigation>
  <div class="main-container" *ngIf="!loading && insuarances.length !== 0 ">
    <div class="ihub-list-items-container">
      <div class="page-text" >
        <span class="main-title">{{'ihub_my_insurances' | injectedText | async}}</span>
        <p class="main-description">{{'ihub_insurance_list_description' | injectedText | async}}</p>
      </div>
          <span class="list-title" *ngIf="activeInsurances && activeInsurances.length > 0"> {{'ihub_active' | localize | async}}</span>
          <ihub-incurance-list-item [delegate]="this" [active]="true" [dataSource]= "activeInsurances" [showDates]="true"></ihub-incurance-list-item>
          <span class="list-title" *ngIf="pendingInsurances && pendingInsurances.length > 0" style="text-transform: uppercase;"> {{'ihub_insurance_status_pending' | localize | async}}</span>
          <ihub-incurance-list-item [delegate]="this" [active]="true" [dataSource]= "pendingInsurances" [showDates]="false"></ihub-incurance-list-item>
          <span class="list-title" *ngIf="cancelledInsurances && cancelledInsurances.length > 0" style="text-transform: uppercase;"> {{'ihub_shop_insurance_quit_status'| localize | async}}</span>
          <ihub-incurance-list-item [delegate]="this" [active]="true" [dataSource]= "cancelledInsurances" [showDates]="true"></ihub-incurance-list-item>
          <span class="list-title" *ngIf="inActiveInsurances && inActiveInsurances.length > 0"> {{'ihub_inactive' | localize | async}}</span>
         <ihub-incurance-list-item [delegate]="this" [active]="false" [dataSource]= "inActiveInsurances" [showDates]="true"></ihub-incurance-list-item>
      </div>
    <div class="purchasing-history-container_divider"></div>
     <ihub-transaction-list-item  [dataSource]="transactions" (isRedirectToPayment)="confirmPayment($event)" *ngIf="!loading && transactions?.length !== 0"></ihub-transaction-list-item>
  </div>
  <div class="empty-table" *ngIf="insuarances.length === 0 && !loading">
    {{ "ihub_you_didnot_buy_yet" | localize | async}}
  </div>
  <div *ngIf="loading" class="loader"></div>
</div>
