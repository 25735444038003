import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { IInsuranceOperationResponse, IPdfData } from '@modeso/types__ihub-lib-insurance-be';
import { take } from 'rxjs/operators';
import { InsuranceProvider } from '../../provider/insurance.provider';
import { saveAs } from 'file-saver';
@Component({
  selector: 'ihub-insurance-documents',
  templateUrl: './insurance-documents.component.html',
  styleUrls: ['./insurance-documents.component.scss']
})
export class InsuranceDocumentsComponent implements OnInit {
  @Input() insurance: IInsuranceOperationResponse;
  @Input() person: any;
  public avbLink;
  public pdfLink;
  constructor(private insuranceProvider: InsuranceProvider,    private router: Router ) {
  }
  ngOnInit() {
    const products = JSON.parse(localStorage.getItem('products'));
    const insuranceProduct = products.find((product) => product.product === this.insurance.product);
    this.avbLink = insuranceProduct.disclaimerLink;
  }

  public getAgbLink() {
    const browserLanguage = navigator.language;
    let agbLanguage = '';
    if (browserLanguage.includes('de')) {
      agbLanguage = 'de';
    } else if (browserLanguage.includes('fr')) {
      agbLanguage = 'fr';
    } else if (browserLanguage.includes('it')) {
      agbLanguage = 'it';
    } else {
      agbLanguage = 'en';
    }
    return `assets/pdf/ihub-agb_${agbLanguage}.pdf`;
  }

  public generatePdf() {
    const newWindow = window.open();
    this.insuranceProvider.getPolicyPdf$(this.insurance.orderUuid).pipe(take(2)).subscribe((resultBlob) => {
      if (!resultBlob) {
        return;
      }
      const file = new Blob([resultBlob], { type: 'application/pdf' });
      const url = URL.createObjectURL(file);
      newWindow.location.href = url;
    });
  }

  public generateAVB() {
    const newWindow = window.open();
    this.insuranceProvider.getAVBPdf$(this.insurance.orderUuid).pipe(take(2)).subscribe((resultBlob) => {
      if (!resultBlob) {
        return;
      }
      const file = new Blob([resultBlob], { type: 'application/pdf' });
      const url = URL.createObjectURL(file);
      newWindow.location.href = url;
    });
  }
}
