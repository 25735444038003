import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IHubSwitchComponent, IIHubSwitchDelegate, IIHubSwitchSelection } from '@modeso/twint-lib-core-fe';
import { FormControl, Validators } from '@angular/forms';
import moment, { Moment } from 'moment';
import {  MatDatepicker} from '@angular/material';

@Component({
  selector: 'ihub-second-cancellation-component',
  templateUrl: './second-cancellation-component.component.html',
  styleUrls: ['./second-cancellation-component.component.scss'],
})

export class SecondCancellationComponentComponent implements
  OnInit,
  OnDestroy,
  IIHubSwitchDelegate {
  public datePattern =
    '(?:((?:0[1-9]|1[0-9]|2[0-9])[.](?:0[1-9]|1[0-2])|(?:30)[.](?!02)(?:0[1-9]|1[0-2])|31[.](?:0[13578]|1[02]))[.](?:19|20)[0-9]{2})';
  public selection =
    [
    { text: 'ihub_shop_reason_one', selected: true, reference: 0 },
    { text: 'ihub_shop_reason_two', selected: false, reference: 1 },
    { text: 'ihub_shop_reason_three', selected: false, reference: 2 },
    { text: 'ihub_shop_reason_four', selected: false, reference: 3 },
    { text: 'ihub_shop_other', selected: false, reference: 4 }
  ];
  public otherIsEnabled: boolean;
  public reasonMessage: string;
  public endDate: FormControl;
  public disabled: boolean;
  public cancellationData;
  public sixMonthsFromNow: Moment;
  @Input() orderUuid: string;
  sub: any;
  fullValue: void;
  constructor() {
    const date = this.setFirstCancelationPermittedDate();
    this.otherIsEnabled = false;
    this.reasonMessage = this.selection[0].text;
    this.disabled = false;
    this.cancellationData = {
      endDate: date.format('DD.MM.YYYY'),
      reasonMessage: this.reasonMessage,
      orderUuid: this.orderUuid,
    };
    this.sixMonthsFromNow = date;
    this.endDate = new FormControl(date, 
      [Validators.required]);
    this.sub = this.endDate.valueChanges.subscribe(() => this.checkButtonIsEnabled());
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  ngOnInit() {
  }

  onSelect(selection: IIHubSwitchSelection, sender: IHubSwitchComponent, identifier?: string): void {
    if (selection.reference === 4) {
      this.otherIsEnabled = true;
      this.reasonMessage = '';
    } else {
      this.otherIsEnabled = false;
      this.reasonMessage = selection.text;
    }
    this.checkButtonIsEnabled();
  }
  onKeyUp(reasonInputElement: any, value: string) {
    reasonInputElement.dirty = true;
    this.reasonMessage = value;
    this.checkButtonIsEnabled();
  }

  public checkButtonIsEnabled() {
    this.disabled = this.endDate.invalid || !this.reasonMessage;
    if (!this.disabled) {
      this.cancellationData = {
        endDate: this.endDate.value,
        reasonMessage:  this.reasonMessage,
        orderUuid: this.orderUuid,
      };
    }
  }

  private setFirstCancelationPermittedDate() {
    const validFromDate = moment(sessionStorage.getItem('validFrom')).startOf('day')
    const today = moment().endOf('day')
    const min6monthAhead = Math.max(today.diff(moment(validFromDate),'months') + 1, 6)
    var endDate = moment(validFromDate).add(min6monthAhead, 'month').add(-1, 'days')
    if (endDate.diff(today, 'month') < 1) {
      endDate = endDate.add(1, 'month')
    }
    return endDate
  }
  
  setMonthAndYear(normalizedMonthAndYear: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue: Moment = this.endDate.value;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    ctrlValue.set("date", +this.sixMonthsFromNow.format('DD'));
    this.endDate.setValue(ctrlValue);
    datepicker.close();
  }
}
