import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';
const CUSTOM_DATE_FORMATS = {
    parse: {
        dateInput: 'l, LTS'
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    }
};
const ɵ0 = CUSTOM_DATE_FORMATS;
export class CustomNgxDatetimePickerModule {
}
export { ɵ0 };
