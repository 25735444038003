import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './skeleton/header/header.component';
import { FooterComponent } from './skeleton/footer/footer.component';
import { ContentAreaComponent } from './skeleton/content-area/content-area.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';
import { BasePageComponent } from './pages/base.page';
import { SharedModule } from './shared/shared.module';
import * as fromAppState from './state/app.state.reducer';
import { AppEffects } from './state/app.state.effects';
import { FakeCheckinPage } from './pages/FakeCheckin/fakeCheckin.page';
import { DgoodsSessionModule, DgoodsSessionConfig, AuthHandler, AUTH_INTERCEPTOR } from '@modeso/twint-lib-session-fe';
import { LandingBuyPage } from './pages/Landing/landing-buy.page.view';
import { ProductsModule, ProductsConfig } from '@modeso/ihub-lib-products-fe';
import { ModesoLocalizationConfig, ModesoLocalizationModule } from '@modeso/modeso-lib-localization-fe';
import { InsuranceModule, InsuranceConfig, ConfirmationComponentComponent } from '@modeso/ihub-lib-insurance-fe';
import { CartModule, CartConfig } from '@modeso/twint-lib-cart-fe';
import { DgoodsSharedModule } from '@modeso/twint-lib-core-fe';
import { PurchasingHistoryPage } from './pages/PurchaseHistory/purchasing-history.page.view';
import { OneTimeUrlStartPage } from './pages/OneTimeUrlStart/onetimeurlStart.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductBuyPage } from './pages/ProductBuy/product-buy.page.view';
import { AuthGuard } from './shared/services/guards/AuthGuard.service';
import { LocalStorageService } from './shared/services/localSrorage.service';
import { LanguageHandler } from '@modeso/modeso-lib-core-fe';
import { SessionOrchestrator } from './orchestrator/session.orchestrator';
import { EmailPage } from './pages/Email/email.page.view';
import { AccountPage } from './pages/Account/account.page.view';
import { OrderConfirmationPage } from './pages/OrderConfirmation/order-confirmation.page.view';
import { ErrorPage } from './pages/ErrorPage/error.page.view';
import { NavigationAuthGuard } from './shared/services/guards/NavigationAuthGuard.service';
import { LoginPage } from './pages/login/login.page';
import { PortraitModePage } from './pages/PortraitMode/portrait-mode.page.view';
import { DgoodsUserConfig, DgoodsUserModule } from '@modeso/ihub-lib-user-fe';
import { DgoodsAnalyticsConfig, DgoodsAnalyticsModule } from '@modeso/twint-lib-analytics-fe';
import { CookieService } from 'ngx-cookie-service';
import { TermsOfProviderPageControllerDirective } from './pages/TermsOfProvider/terms-of-provider.controller';
import { TermsOfTwintPageControllerDirective } from './pages/TermsOfTwint/terms-of-twint.controller';
import { TermsOfProviderPage } from './pages/TermsOfProvider/terms-of-provider.page.view';
import { TermsOfTwintPage } from './pages/TermsOfTwint/terms-of-twint.page.view';
import { DataProtectionOfTwintPage } from './pages/DataProtectionOfTwint/data-protection-of-twint.page.view';
import { DataProtectionOfTwintPageControllerDirective } from './pages/DataProtectionOfTwint/data-protection-of-twint.controller';
import { PurchasingHistoryPageControllerDirective } from './pages/PurchaseHistory/purchasing-history.page.controller';
import { EmailPageControllerDirective } from './pages/Email/email.page.controller';
import { AccountPageControllerDirective } from './pages/Account/account.page.controller';
import { LandingBuyPageControllerDirective } from './pages/Landing/landing-buy.page.controller';
import { OrderConfirmationControllerDirective } from './pages/OrderConfirmation/order-confirmation.page.controller';
import { ProductBuyPageControllerDirective } from './pages/ProductBuy/product-buy.page.controller';
import { ClipboardModule, ClipboardService } from 'ngx-clipboard';
import { PaymentCanceldPage } from './pages/PaymentCanceldPage/paymentcanceld.page.view';
import { FAQPage } from './pages/FAQ/faq.page';
import { MaintenancePage } from './pages/Maintainance/maintenance.page';
import { MaintenenceGuard } from './shared/services/guards/MaintenenceGuard.service';
import { NoMaintenenceGuard } from './shared/services/guards/NoMaintenenceGuard.service';
import { InsuranceBuyPage } from './pages/InsuranceBuy/insurance-buy.page.view';
import { InsuranceConfirmationPage } from './pages/InsuranceConfirmation/insurance-confirmation.page.view';
import { InsuranceDetailPage } from './pages/InsuranceDetail/insurance-detail.page.view';
import { InsuranceSummaryPage } from './pages/InsuranceSummary/insurance-summary.page.view';
import { InsuranceUserDetailPage } from './pages/InsuranceUserDetails/insurance-user-detail.page.view';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InjectedTextFeautureModule, InjectedTextPipe } from '@modeso/modeso-lib-core-fe';
import { InsuranceBuyPageControllerDirective } from './pages/InsuranceBuy/insurance-buy.page.controller';
import { InsuranceCheckoutBaseComponent } from './pages/InsuranceCheckout/InsuranceCheckoutBase.component';
import { InsuranceConfirmationControllerDirective } from './pages/InsuranceConfirmation/insurance-confirmation.page.controller';
import { InsuranceDetailPageControllerDirective } from './pages/InsuranceDetail/insurance-detail.page.controller';
import { InsuranceSummaryPageControllerDirective } from './pages/InsuranceSummary/insurance-summary.page.controller';
import { InsuranceUserDetailPageControllerDirective } from './pages/InsuranceUserDetails/insurance-user-detail.page.controller';
import { UserDetailPageComponent } from './pages/UserDetails/user-detail.page.component';
import { MyInsuranceDetailsPageComponent } from './pages/MyInsuranceDetailsPage/my-insurance-details.page.component';
import { MyInsuranceDetailsPageControllerDirective } from './pages/MyInsuranceDetailsPage/my-insurance-details.page.controller';
import { DamageReportPageController } from './pages/DamageReport/damage-report.page.controller';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { IHubDamageConfig, IHubDamageModule } from '@modeso/ihub-lib-damage-fe';

import { CancelationPolicyPageComponent } from './pages/CancelationPolicyPage/cancelation-policy-page.component';
import { InsuranceCancelationPageComponent } from './pages/insurance-cancelation-page/insurance-cancelation-page.component';

import { MatButtonModule } from '@angular/material/button';
import { PaymentAlertPage } from './pages/PaymentAlert/paymentalert.page';
import { IHubAlertComponent } from './components/alert/alert.component';
import { CancellationPolicyController } from './pages/CancelationPolicyPage/cancellation-policy-page.controller';
import { SliderComponent } from './components/slider/ihub-slider.component';
import { ServiceIsOfflineHandler, SERVICE_IS_OFFLINE_INTERCEPTOR } from './interceptors/serviceIsDown.interceptor';
import { MajorErrorPage } from './pages/MajorError/error.page.view';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule, MatNativeDateModule, MAT_DATE_LOCALE} from '@angular/material';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';

export class MyHammerConfig extends HammerGestureConfig {
  overrides =  {
    swipe: { direction: Hammer.DIRECTION_ALL },
  } as any;
}


const config: DgoodsSessionConfig = {
  apiGateway: environment.modeso.apiGateway,
};

const productsConfig: ProductsConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'products'
};

const insuranceConfig: InsuranceConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'insurance'
};
const cartConfig: CartConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'cart'
};

const localizationServiceConfig: ModesoLocalizationConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'localization'
};

const userConfig: DgoodsUserConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'users'
};

const analyticsConfig: DgoodsAnalyticsConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'analytics'
};

const damageReportConfig: IHubDamageConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'damageReport'
};

export const ngMaskConfig: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    BasePageComponent,
    HeaderComponent,
    FooterComponent,
    ContentAreaComponent,
    PurchasingHistoryPage,
    LoginPage,
    EmailPage,
    AccountPage,
    OrderConfirmationPage,
    InsuranceConfirmationPage,
    InsuranceConfirmationControllerDirective,
    LandingBuyPage,
    ProductBuyPage,
    InsuranceBuyPage,
    InsuranceBuyPageControllerDirective,
    InsuranceDetailPage,
    InsuranceDetailPageControllerDirective,
    InsuranceUserDetailPage,
    InsuranceUserDetailPageControllerDirective,
    InsuranceSummaryPage,
    InsuranceSummaryPageControllerDirective,
    DamageReportPageController,
    InsuranceCheckoutBaseComponent,
    FakeCheckinPage,
    OneTimeUrlStartPage,
    ErrorPage,
    MajorErrorPage,
    PaymentCanceldPage,
    PortraitModePage,
    FAQPage,
    PurchasingHistoryPageControllerDirective,
    EmailPageControllerDirective,
    AccountPageControllerDirective,
    OrderConfirmationControllerDirective,
    TermsOfProviderPageControllerDirective,
    TermsOfProviderPage,
    TermsOfTwintPageControllerDirective,
    TermsOfTwintPage,
    LandingBuyPageControllerDirective,
    ProductBuyPageControllerDirective,
    DataProtectionOfTwintPage,
    DataProtectionOfTwintPageControllerDirective,
    MaintenancePage,
    UserDetailPageComponent,
    MyInsuranceDetailsPageComponent,
    MyInsuranceDetailsPageControllerDirective,
    CancelationPolicyPageComponent,
    CancellationPolicyController,
    InsuranceCancelationPageComponent,
    PaymentAlertPage,
    IHubAlertComponent,
    SliderComponent,
  ],
  imports: [
    NgxMaskModule.forRoot(ngMaskConfig),
    FormsModule,
    ClipboardModule,
    ModesoLocalizationModule.forRoot(localizationServiceConfig),
    ModesoLocalizationModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    StoreModule.forRoot([]),
    StoreModule.forFeature(fromAppState.appStateFeatureKey, fromAppState.reducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([AppEffects]),
    DgoodsSessionModule.forRoot(config),
    ProductsModule.forRoot(productsConfig),
    InsuranceModule.forRoot(insuranceConfig),
    CartModule.forRoot(cartConfig),
    DgoodsUserModule.forRoot(userConfig),
    DgoodsAnalyticsModule.forRoot(analyticsConfig),
    IHubDamageModule.forRoot(damageReportConfig),
    DgoodsSharedModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    InjectedTextFeautureModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    NgxFileDropModule,
    MatCheckboxModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    NgxMatNativeDateModule,
    NgxMatDatetimePickerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NgxMatMomentModule,

  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: navigator.language },
    { provide: AUTH_INTERCEPTOR, useClass: SessionOrchestrator },
    { provide: SERVICE_IS_OFFLINE_INTERCEPTOR, useClass: SessionOrchestrator },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHandler, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ServiceIsOfflineHandler, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageHandler, multi: true },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    MaintenenceGuard,
    NoMaintenenceGuard,
    AuthGuard,
    NavigationAuthGuard,
    LocalStorageService,
    CookieService,
    ClipboardService,
    InjectedTextPipe,
    MatDatepickerModule

  ],
  entryComponents: [ConfirmationComponentComponent],
  bootstrap: [AppComponent],
})
export class AppModule { }
