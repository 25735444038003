import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IHubPopUpComponent, IIHubPopupContent, IIHubSwitchSelection } from '@modeso/twint-lib-core-fe';
import { IConfiguredAttribute } from '@modeso/types__ihub-lib-products-be';
import { ControlsHandlerFactory } from '../../../../utils/FormHandlers/ControlsHandlerFactory';
import { IInsuranceAttributeControl } from '../../ComponentFactory/IInputControl.interface';
import { BaseControl } from '../Base';

@Component({
  selector: 'ihub-switch-control',
  templateUrl: './switch-control.component.html',
  styleUrls: ['./switch-control.component.scss']
})
export class SwitchControlComponent extends BaseControl implements OnInit, IInsuranceAttributeControl {
  @ViewChild('popup', { static: true }) popup: IHubPopUpComponent;
  private controlsHandlerFactory: ControlsHandlerFactory;
  popupContent: IIHubPopupContent;
  insuranceAttribute: IConfiguredAttribute;
  form: FormGroup;
  validationErrors: any;
  selectionMenu = [];
  constructor() {
    super();
    this.controlsHandlerFactory =  ControlsHandlerFactory.getInstance();
  }

  ngOnInit() {
    var i = 0;
    for (const [key, value] of Object.entries(this.insuranceAttribute.enum)) {
        this.selectionMenu.push({
          reference: value.value,
          text: value.language,
          value: value.value,
          selected: i++ === 0,
        } as IIHubSwitchSelection) 
    }
  }

  public openPopup(event: any) {
    event.stopPropagation();
    this.popupContent = {
      title: (this.insuranceAttribute.title as string),
      text: (this.insuranceAttribute.text as string),
      needsTranslation: false,
    };
    this.popup.visible = true;
  }
}
