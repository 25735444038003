import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IHubPopUpComponent, IIHubPopupContent } from '@modeso/twint-lib-core-fe';
import { IConfiguredAttribute } from '@modeso/types__ihub-lib-products-be';
import { ControlsHandlerFactory } from '../../../../utils/FormHandlers/ControlsHandlerFactory';
import { IInsuranceAttributeControl } from '../../ComponentFactory/IInputControl.interface';
import { BaseControl } from '../Base';
import { CheckboxService } from '../services/checkbox-data-handler';
import { takeWhile } from 'rxjs/operators';
import { ControlHandlerName } from '../../../../utils/enums/controlHandlerName.enum';

@Component({
  selector: 'ihub-currency-input-control',
  templateUrl: './currency-input-control.component.html',
  styleUrls: ['./currency-input-control.component.scss']
})
export class CurrencyInputControlComponent extends BaseControl implements OnInit, IInsuranceAttributeControl {
  @ViewChild('popup', { static: true }) popup: IHubPopUpComponent;
  popupContent: IIHubPopupContent;
  insuranceAttribute: IConfiguredAttribute;
  form: FormGroup;
  alive = true;
  handler;


  constructor(private data: CheckboxService) { super(); }
  validationErrors: any;
  ngOnInit() {
    
    if (this.insuranceAttribute.value !== undefined) {
      this.form.get(this.insuranceAttribute.id)
      .setValue(this.insuranceAttribute.value);
    } else {
      this.form.get(this.insuranceAttribute.id)
      .setValue(this.insuranceAttribute.preDefinedValue);
    }

    const eventHandlerFactory =  ControlsHandlerFactory.getInstance();
    this.handler = eventHandlerFactory.getObject(`${this.insuranceAttribute.id}Handler`);

    if (this.handler && `${this.insuranceAttribute.id}Handler` === ControlHandlerName.ANIVO_GADGET_PRICE_HANDLER) {
      this.data.subject
      .pipe(takeWhile(() => this.alive))
      .subscribe(res => {
        if (res) {
          this.onKeyUp();
        }
      });
    }
  }
  public openPopup(event: any) {
    event.stopPropagation();
    this.popupContent = {
      title: (this.insuranceAttribute.title as string),
      text: (this.insuranceAttribute.text as string),
      needsTranslation: false,
    };
    this.popup.visible = true;
  }

  public onKeyUp() {

    if (!this.handler) {
      return;
    }
    if (`${this.insuranceAttribute.id}Handler` === ControlHandlerName.ANIVO_GADGET_PRICE_HANDLER) {
      this.handler.onKeyUp({data: this.form.getRawValue() });
    } else {
      this.handler.onKeyUp({control: this.form.get(this.insuranceAttribute.id), name: this.insuranceAttribute.id});
    }

  }


  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    this.alive = false;
  }
}
