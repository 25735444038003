import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DgoodsSessionConfigService } from "../dgoods.session.config";

export interface ICheckInRequest {
  pairingUuid: string;
  customerRelationUuid: string;
}
export interface ICheckInRequestAuth {
  username: string;
  password: string;
}

export interface ICheckInResponse {
  checkInDate: Date;
  checkInUrl: string;
  user: IUserResponse;
  isP2P: boolean;
  productLineId?: string;
  terminalId: string;
}

export interface IStartRequest {
  checkInUrl: string;
  issuer: string;
}

export interface IUserResponse {
  email?: string; // option bec email could be saved before or not
  twintTC?: boolean;
}

export interface IAccessTokenResponse {
  checkInDate: Date;
  user: IUserResponse;
  isP2P: boolean;
  productLineId?: string;
  terminalId: string;
  delayPayment?: number;
}

export interface IValidateUrlRequest extends IStartRequest {}
@Injectable({
  providedIn: "root",
})
export class DgoodsSessionService {
  private server = "";

  private microserviceName = "sessions";
  private readonly newAccessTokenRoute = "/token";
  private readonly logoutRoute = "/logout";
  private readonly startRoute = "/start";
  private readonly validateRoute = "/validate";

  constructor(
    private http: HttpClient,
    @Inject(DgoodsSessionConfigService) private config,
  ) {
    this.server = config.apiGateway;
  }

  getNewAccesToken() {
    const url = `${this.server}${this.microserviceName}${this.newAccessTokenRoute}`;
    return this.http.get(url);
  }

  logout() {
    const url = `${this.server}${this.microserviceName}${this.logoutRoute}`;
    return this.http.get(url);
  }

  checkin(data: ICheckInRequest, auth: ICheckInRequestAuth) {
    const basic = btoa(auth.username + ":" + auth.password);
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Basic " + basic,
      }),
    };
    const url = `${this.server}v2/twint/terminals/1234567/checkin`;
    return this.http.post<ICheckInResponse>(url, data, httpOptions);
  }

  start(data: IStartRequest) {
    const url = `${this.server}${this.microserviceName}${this.startRoute}`;
    return this.http.post<ICheckInResponse>(url, data);
  }

  validateUrl(data: IValidateUrlRequest) {
    const url = `${this.server}${this.microserviceName}${this.validateRoute}`;
    return this.http.post(url, data);
  }
}
