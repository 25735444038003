/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./onetimeurlStart.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./onetimeurlStart.page";
import * as i3 from "@modeso/twint-lib-session-fe";
import * as i4 from "@angular/router";
import * as i5 from "@ngrx/store";
import * as i6 from "@modeso/ihub-lib-products-fe";
import * as i7 from "@modeso/ihub-lib-user-fe";
var styles_OneTimeUrlStartPage = [i0.styles];
var RenderType_OneTimeUrlStartPage = i1.ɵcrt({ encapsulation: 0, styles: styles_OneTimeUrlStartPage, data: {} });
export { RenderType_OneTimeUrlStartPage as RenderType_OneTimeUrlStartPage };
export function View_OneTimeUrlStartPage_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_OneTimeUrlStartPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-fakeStart", [], null, null, null, View_OneTimeUrlStartPage_0, RenderType_OneTimeUrlStartPage)), i1.ɵdid(1, 245760, null, 0, i2.OneTimeUrlStartPage, [i1.Injector, i3.DgoodsSessionStoreProvider, i4.ActivatedRoute, i4.Router, i5.Store, i6.ProductStoreProvider, i7.UserProvider], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OneTimeUrlStartPageNgFactory = i1.ɵccf("app-page-fakeStart", i2.OneTimeUrlStartPage, View_OneTimeUrlStartPage_Host_0, {}, {}, []);
export { OneTimeUrlStartPageNgFactory as OneTimeUrlStartPageNgFactory };
