import { createAction, props, Action } from '@ngrx/store';
import { IInsuranceOperationResponse, ICreateInsuranceRequest } from '@modeso/types__ihub-lib-insurance-be';

/**
 * The createAction function returns a function, that when called returns an object in the shape
 * of the Action interface. The props method is used to define any additional metadata needed
 * for the handling of the action.
 */

/**
 */
export const createInsurance = createAction('[Insurance] create',
  props<{ payload: ICreateInsuranceRequest }>()
);

/**
 * This is action of post cart when it return successfully.
 */
export const onInsuranceOperationSuccessfully = createAction(
  '[Insurance] insurance operation successfully',
  props<{ payload: IInsuranceOperationResponse }>()
);

/**
 * This is action of post cart when it failed.
 */
export const onInsuranceOperationFailed = createAction(
  '[Insurance]  insurance operation failed',
  props<{ payload: any }>()
);

/**
 */
export const activateInsurance = createAction('[Insurance] activate',
  props<{ payload: string }>()
);


export const updateInsuranceStatus = createAction('[Insurance] updateInsuranceStatus',
  props<{ payload: string }>()
);


export const onUpdateInsuranceSuccessfully = createAction(
  '[Insurance] update insurance operation successfully',
  props<{ payload: IInsuranceOperationResponse }>()
);

/**
 * This is action of post cart when it failed.
 */
export const onUpdateInsuranceFailed = createAction(
  '[Insurance] update insurance operation failed',
  props<{ payload: any }>()
);

export const getInsurancesByUserId = createAction('[Insurance] getInsurancebyUserId');

export const onGetInsurancesByUserIdSuccessfully = createAction(
  '[Insurance] onGetInsurancesByUserIdSuccessfully successfully',
  props<{ payload: Array<IInsuranceOperationResponse> }>()
);

export const onGetInsurancesByUserIdFailed = createAction(
  '[Insurance] onGetInsurancesByUserIdFailed failed',
  props<{ payload: any }>()
);

export const getInsuranceDetailsByOrderUuid = createAction('[Insurance] getInsuranceDetailsByOrderUuid', props<{ payload: string }>());

export const ongetInsuranceDetailsByOrderUuidSuccessfully = createAction(
  '[Insurance] ongetInsuranceDetailsByOrderUuidSuccessfully successfully',
  props<{ payload: IInsuranceOperationResponse }>()
);

export const ongetInsuranceDetailsByOrderUuidFailed = createAction(
  '[Insurance] ongetInsuranceDetailsByOrderUuidFailed failed',
  props<{ payload: any }>()
);


export const onUpdateInsuranceState = createAction('[Insurance] onUpdateInsuranceState', props<{ payload: any }>());

export const onUpdateInsuranceStateSuccessfully = createAction(
  '[Insurance] onUpdateInsuranceStateSuccessfully successfully',
  props<{ payload: IInsuranceOperationResponse }>()
);

export const onUpdateInsuranceStateFailed = createAction(
  '[Insurance] onUpdateInsuranceStateFailed failed',
  props<{ payload: any }>()
);

export const onGetPolicyPdf = createAction('[Insurance] onGetPolicyPdf', props<{ payload: string }>());

export const onGetPolicyPdfSuccessfully = createAction(
  '[Insurance] onGetPolicyPdfSuccessfully successfully',
  props<{ payload: any }>()
);

export const onGetPolicyPdfFailed = createAction(
  '[Insurance] onGetPolicyPdfFailed failed',
  props<{ payload: any }>()
);

export const onGetAVBPdf = createAction('[Insurance] onGetAVBPdf', props<{ payload: string }>());

export const onGetAVBPdfSuccessfully = createAction(
  '[Insurance] onGetAVBPdfSuccessfully successfully',
  props<{ payload: any }>()
);

export const onGetAVBPdfFailed = createAction(
  '[Insurance] onGetAVBPdfFailed failed',
  props<{ payload: any }>()
);

export const onGetDraftedInsurances = createAction('[Insurance] onGetDraftedInsurances');

export const onGetDraftedInsurancesSuccessfully = createAction(
  '[Insurance] onGetDraftedInsurancesSuccessfully successfully',
  props<{ payload: IInsuranceOperationResponse[] }>()
);

export const onGetDraftedInsurancesFailed = createAction(
  '[Insurance] onGetDraftedInsurancesFailed failed',
  props<{ payload: any }>()
);


export const updateInsuranceState = createAction(
  '[Insurance] updateInsuranceState',
  props<{ payload: any }>()
);


export const onSinglePaymentInsurance = createAction('[Insurance] Create Single Payment',
  props<{ payload: string }>()
);

/**
 * This is action of post cart when it return successfully.
 */
export const onSinglePaymentInsuranceSuccessfully = createAction(
  '[Insurance] Create Single Payment successfully',
  props<{ payload: any }>()
);

/**
 * This is action of post cart when it failed.
 */
export const onSinglePaymentInsuranceFailed = createAction(
  '[Insurance]  Create Single Payment failed',
  props<{ payload: any }>()
);

/**
 * This is action of post cart when it return successfully.
 */
export const flush = createAction(
  '[Insurance] flush created insurance'
);


/**
 * Dispatch to pass payload, you can create parametrizied Action in this way.
 */
export interface ActionWithPayload<T> extends Action {
  payload: T;
}
