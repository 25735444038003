import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IAuthHandlerDelegate } from '@modeso/twint-lib-session-fe';
import Debug from 'debug';
import { IServiceIsOfflineDelegate } from '../interceptors/serviceIsDown.interceptor';
const debug = Debug('dgoods:shop:SessionOrchestrator');

@Injectable({
  providedIn: 'root'
})
export class SessionOrchestrator implements IAuthHandlerDelegate, IServiceIsOfflineDelegate {

  constructor(private router: Router) { }

  onSessionExired() {
    debug(this.router.url);
    if (!this.router.isActive('/de-ch/login', false)) {
      debug('onSessionExired redirect to mocked');
      this.router.navigateByUrl('/de-ch/login');
    }

  }

  onServiceIsOffline(request: HttpRequest<any>) {
    debug("Service is offline:", request);
    if (request.url.split("localization").length > 1) {

      this.router.navigateByUrl('/de-ch/majorerror');
    } else {
      this.router.navigateByUrl('/de-ch/error');

    }
  }
}
