import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InsuranceConfigService } from './../insurance.config';
import { ICreateInsuranceRequest, IActivateInsuranceRequest, IInsuranceOperationResponse } from '@modeso/types__ihub-lib-insurance-be';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InsuranceService {
  private server = '';
  private microserviceName = 'insurances';

  /**
   * @param http
   * @param config
   */
  constructor(
    private http: HttpClient, @Inject(InsuranceConfigService) private config) {
    this.server = config.apiGateway;
  }

  createInsurance(request: ICreateInsuranceRequest): Observable<IInsuranceOperationResponse> {
    const url = `${this.server}${this.microserviceName}/create`;
    return this.http.post<IInsuranceOperationResponse>(url, request);
  }

  activateInsurance(orderUuid: string): Observable<IInsuranceOperationResponse> {
    const request: IActivateInsuranceRequest = {
      orderUuid,
    };
    const url = `${this.server}${this.microserviceName}/activate`;
    return this.http.post<IInsuranceOperationResponse>(url, request);
  }

  getInsurancesByUserId() {
    const url = `${this.server}${this.microserviceName}`;
    return this.http.get<IInsuranceOperationResponse[]>(url);
  }

  getInsuranceByOrderUuid(orderUuid: string) {
    const url = `${this.server}${this.microserviceName}/${orderUuid}`;
    return this.http.get<IInsuranceOperationResponse>(url);
  }

  updateInsuranceState(insurance: any) {
    if (insurance.insuranceStatus === 2) {
      return this.cancel(insurance);
    }
    if (insurance.insuranceStatus === 1) {
      return this.reactivate(insurance);
    }
  }

  reactivate(insurance: any) {
    const url = `${this.server}${this.microserviceName}/reactivate`;
    return this.http.put<IInsuranceOperationResponse>(url, insurance);
  }

  cancel(insurance: any) {
    const url = `${this.server}${this.microserviceName}/cancel`;
    return this.http.put<IInsuranceOperationResponse>(url, insurance);
  }

  public generatePdf(orderUuid: string) {
    let headers = new HttpHeaders();
    const url = `${this.server}${this.microserviceName}/generatePdf`;
    headers = headers.set('Accept', 'application/pdf');
    const request: IActivateInsuranceRequest = {
      orderUuid,
    };
    return this.http.post(url, request, { headers, responseType: 'blob' });
  }

  public getAllDraftedInsuranceByUserId() {
    const url = `${this.server}${this.microserviceName}/draft`;
    return this.http.get<any>(url);
  }

  public createSinglePaymentInsurance(orderUuid: string): Observable<IInsuranceOperationResponse> {
    const request: IActivateInsuranceRequest = {
      orderUuid,
    };
    const url = `${this.server}${this.microserviceName}/checkout`;
    return this.http.post<IInsuranceOperationResponse>(url, request);
  }

  public calculateExternalPrice(additionalAttributes: any, productName: string, merchant: string): Observable<any> {
    const request = {
      additionalAttributes,
      productName,
      merchant
    };
    const url = `${this.server}${this.microserviceName}/externalPrice`;
    return this.http.post<any>(url, request);
  }

  public generateAVB(orderUuid: string) {
    let headers = new HttpHeaders();
    const url = `${this.server}${this.microserviceName}/generateAVB`;
    headers = headers.set('Accept', 'application/pdf');
    const request: IActivateInsuranceRequest = {
      orderUuid,
    };
    return this.http.post(url, request, { headers, responseType: 'blob' });
  }

}
