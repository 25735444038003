/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error.page.view.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@modeso/modeso-lib-localization-fe";
import * as i3 from "@ngrx/store";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./error.page.view";
import * as i7 from "@angular/router";
var styles_ErrorPage = [i0.styles];
var RenderType_ErrorPage = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorPage, data: {} });
export { RenderType_ErrorPage as RenderType_ErrorPage };
export function View_ErrorPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.ɵa, [i3.Store, i4.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "error-page-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "error-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "error-msg-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "error-logo"], ["src", "assets/icon-denied.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "span", [["class", "error-msg"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵppd(7, 1), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "insur-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["class", "insur-img"], ["src", "assets/ihub/Fehlerscreen_InsurHub.png"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 8).transform(i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), _co.errorMessageKey)))); _ck(_v, 6, 0, currVal_0); }); }
export function View_ErrorPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-error", [], null, null, null, View_ErrorPage_0, RenderType_ErrorPage)), i1.ɵdid(1, 245760, null, 0, i6.ErrorPage, [i7.ActivatedRoute, i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorPageNgFactory = i1.ɵccf("app-page-error", i6.ErrorPage, View_ErrorPage_Host_0, {}, {}, []);
export { ErrorPageNgFactory as ErrorPageNgFactory };
