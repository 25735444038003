import {
  DGoodsButtonComponent, IDGoodsTableDelegate,
  IDGoodsButtonDelegate, DGoodsNavigationComponent
} from '@modeso/twint-lib-core-fe';
import { Location } from '@angular/common';
import { Injector, OnInit, Directive } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from './../../state/app.state.reducer';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
import { InsuranceProvider } from '@modeso/ihub-lib-insurance-fe';
import { ShopUtil } from '../../utils/shopUtil';
const debug = Debug('dgoods:shop:InsuranceConfirmationControllerDirective');

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'app-insurance-confirmation.page-controller'
})
export class InsuranceConfirmationControllerDirective extends BasePageComponent implements
  OnInit,
  IDGoodsButtonDelegate {

  public showNavigationBar = !this.route.snapshot.queryParams.navigation ? true : false;
  terminalId: string;
  orderUuid;
  insurance$;
  constructor(private analyticsProvider: AnalyticsProvider, private location: Location, private injector: Injector, private router: Router,
    private route: ActivatedRoute, private store: Store<fromApp.AppState>, private insuranceProvider: InsuranceProvider) {
    super(injector);
    this.orderUuid = this.route.snapshot.paramMap.get('orderUuid');
    this.terminalId = sessionStorage.getItem('terminalId');
  }

  ngOnInit() {
    this.insuranceProvider.flush();
    this.insuranceProvider.fetchAllMyPurchasedInsurances$();
    this.insurance$ = this.insuranceProvider.getMyInsuranceWithOrderUuid$(this.orderUuid);
    super.ngOnInit();
    this.savePageView();

    setTimeout(() => {
      this.switchToAppAndCloseWindow();
    }, 1000);

  }

  savePageView() {
    this.analyticsProvider.savePageView$({ productLine: null, pageName: 'Order Confirmation', terminalId: this.terminalId, pagePath: this.router.url, });
  }

  onButtonClicked(sender: DGoodsButtonComponent): void {

    const backToPurchasingHistory = this.route.snapshot.queryParams.navigation;
    if (!backToPurchasingHistory) {
      this.switchToAppAndCloseWindow();
    } else {
      this.router.navigate(['../../de-ch/history']);
    }

  }

  onFailedStatus() {
    this.router.navigate(['../../de-ch/paymentcanceld']);
  }

  /** IDGoodsNavigationDelegate --- START */

  onBackClicked(sender: DGoodsNavigationComponent) {
    this.router.navigate(['../../de-ch/history']);
  }

  switchToAppAndCloseWindow(){
  
    new ShopUtil().switchToAppAndCloseWindow();

  }

  /** IDGoodsNavigationDelegate --- END */
}
