<div class="error-page-container">
  <div class="error-content">
    <div class="error-msg-img">
      <img class="error-logo" src="assets/icon-denied.png" />
      <span class="error-msg">{{ errorMessageKey | localize | async}}</span>
    </div>
  </div>
  <div class="insur-logo">
    <img class="insur-img" src="assets/ihub/Fehlerscreen_InsurHub.png" />
  </div>
</div>