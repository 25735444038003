import { Component, Input } from '@angular/core';
import { IInsurancePolicy, IInsuranceProduct } from '@modeso/types__ihub-lib-insurance-be';

@Component({
  selector: 'ihub-virtualpolicy',
  templateUrl: './virtualpolicy.component.html',
  styleUrls: ['./virtualpolicy.component.scss']
})
export class VirtualPolicyComponent {

  @Input() policy: IInsurancePolicy;
  @Input() person;
  @Input() productIcon;
}
