import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DGoodsButtonComponent, IDGoodsButtonDelegate, InjectedTextPipe } from '@modeso/twint-lib-core-fe';
//import { IUpdateInsuranceStatusRequest } from '@modeso/types__ihub-lib-insurance-be';
import moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NavigationService } from '../../shared/navigation.service';
import { ConfirmationComponentComponent } from '../confirmation-component/confirmation-component.component';
@Component({
  selector: 'ihub-second-step-cancellation-actions',
  templateUrl: './second-step-cancellation-actions.component.html',
  styleUrls: ['./second-step-cancellation-actions.component.scss']
})
export class SecondStepCancellationActionsComponent implements OnInit, OnDestroy, IDGoodsButtonDelegate {

  private subscription: Subscription[];
  private cancellationDataSubject = new BehaviorSubject<any>(null);
  public cancellationDataValue;
  @Input() disabled: boolean;
  @Input() orderUuid: string;
  currentLanguage: string;
  @Input()
  set cancellationData(value: any) {
    if (value) {
      this.cancellationDataSubject.next(value);
    }
  }
  constructor(private matDialog: MatDialog, private navigationService: NavigationService) {
    this.subscription = [];
  }
  onButtonClicked(sender: DGoodsButtonComponent, identifier?: string): void {
    this.navigationService.navigateToPage(`${this.currentLanguage}/first-cancelation-step/${this.orderUuid}`);
  }
  ngOnDestroy(): void {
    this.subscription.forEach((subscribe) => subscribe.unsubscribe());
  }

  ngOnInit() {
    this.subscription.push(this.cancellationDataSubject.subscribe((data) => {
        this.cancellationDataValue = data;
      }));
    this.currentLanguage = localStorage.getItem('currentLanguage');
  }

  openConfirmationModal() {
    const endDate = moment(this.cancellationDataValue.endDate, 'DD.MM.YYYY').toDate();
    const insurance = {
      orderUuid: this.orderUuid,
      insuranceStatus: 1,
      endDate,
      insuranceCancelationReason: this.cancellationDataValue.reasonMessage,
    };
    const data =  {
      insurance,
      mainTitle: 'ihub_shop_cancel_policy',
      description: 'ihub_shop_confirm_insurance_cancellation',
      buttonTextConfirmation: 'ihub_shop_yes',
      buttonTextClose: 'ihub_shop_no'
    };
    this.matDialog.open(ConfirmationComponentComponent, {
      data,
    });
  }
}
