<div [hidden]="hidden" class="email-overlay">
  <div class="page-padding">
    <p class="header-style">{{"dgoods_shop_email_title" | localize | async}}</p>
     <dgoods-email-confirmation caption="email" captionConfirm="confirm_email_address" id="email" [delegate]="this">
    </dgoods-email-confirmation>
    <p class="paragraph-style">
      {{"dgoods_shop_email_description" | localize | async}}
    </p>
  </div>
  <div class="sticky">
    <div class="button-padding-style">
      <dgoods-button [delegate]="this" [state]="state" class="button-style" [disabled]="disabled">
        {{"buy_now" | localize | async}}
      </dgoods-button>
    </div>

    <div>
      <dgoods-button [delegate]="this" [state]="'notAction'" class="button-style">
        {{"dgoods_shop_emailview_abort" | localize | async}}
      </dgoods-button>
    </div>
  </div>
</div>
<dgoods-overlay #errorOverlay [hidden]="true" [message]="message" [delegate]="this" [error]="true">
</dgoods-overlay>
<dgoods-dialogbox #dialogBoxOverlay [hidden]="true" [dialogBoxData]="dialogBoxObj" [delegate]="this"
  [descriptionFromLocalization]="true">
</dgoods-dialogbox>