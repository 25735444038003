import Debug from 'debug';

const debug = Debug('ihub:project:ShopUtil');

export class ShopUtil {

    switchToApp(registrationToken: any, isSinglePayment:boolean = false) {
        // to show progress overlay

        const device = localStorage.getItem('device');
        const protocol = localStorage.getItem('protocol');

        if (device === 'ios') {

          // TODO: Replace TWINT_MORE_DIGITAL_GOODS with Real value
          const appSwitchIOS = {
            app_action_type: isSinglePayment ? 'TWINT_PAYMENT': 'UOF_REGISTRATION',
            extras: {
              code: registrationToken
            },
            referer_app_link: {
              //app_name: 'EXTERNAL_WEB_BROWSER',
              app_name: 'TWINT_MORE',
              target_url: '',
              url: ''
            },
            version: '6.0'
          };
          const jsonEncodedObj = JSON.stringify(appSwitchIOS);
          const redirectURL = `${protocol}://applinks/?al_applink_data=${jsonEncodedObj}`;
          debug(`ios browser-app-switch-redirect ${redirectURL}`);
          window.location.href = redirectURL;
        } else if (device === 'android') {
          // TODO: Replace TWINT_MORE_DIGITAL_GOODS with Real value
          // tslint:disable-next-line:max-line-length
          const redirectURL = isSinglePayment ?
          // tslint:disable-next-line: max-line-length
          `intent://payment#Intent;package=${protocol};action=ch.twint.action.TWINT_PAYMENT;scheme=twint;S.code=${registrationToken};S.startingOrigin=TWINT_MORE;end`
          // tslint:disable-next-line: max-line-length
          : `intent://payment#Intent;package=${protocol};action=ch.twint.action.TWINT_UOF_REGISTRATION;scheme=twint;S.code=${registrationToken};S.startingOrigin=TWINT_MORE;end`;

          debug(`android browser-app-switch-redirect ${redirectURL}`);
          //this.savePageView('Purchase confirm');
          debug(redirectURL);
          window.location.href = redirectURL;
        }
      }

      switchToAppAndCloseWindow() {
        const device = localStorage.getItem('device');
        const protocol = localStorage.getItem('protocol');
        if (!protocol || !device) {
          debug('state is not defined');
          return;
        }
        debug(device);
        debug(protocol);
        if (device === 'ios') {
          const redirectURL = `${protocol}://ch.twint.wallet`;
          debug(redirectURL);
          window.location.href = redirectURL;
        } else if (device === 'android') {
          // tslint:disable-next-line:max-line-length
          const redirectURL = `intent://ch.twint.wallet#Intent;package=${protocol};scheme=twint;end`;
          //intent://ch.twint.wallet#Intent;package=ch.twint.payment.configurable;scheme=twint;end
          debug(`android browser-app-switch-redirect ${redirectURL}`);
          debug(redirectURL);
          window.location.href = redirectURL;
        }
      }
}