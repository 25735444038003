
import { Directive, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DGoodsButtonComponent,  IDGoodsButtonDelegate } from '@modeso/twint-lib-core-fe';
import Debug from 'debug';
import { Observable  } from 'rxjs';
import { ICreateInsuranceRequest, IInsuranceProduct } from '@modeso/types__ihub-lib-insurance-be';
import { InsuranceProvider } from '@modeso/ihub-lib-insurance-fe';
import { ProductStoreProvider } from '@modeso/ihub-lib-products-fe';
import { InsuranceCheckoutBaseComponent } from '../InsuranceCheckout/InsuranceCheckoutBase.component';
import { UserDetailPageComponent } from '../UserDetails/user-detail.page.component';
import { Subscription } from 'rxjs';

const debug = Debug('dgoods:project:InsuranceUserDetailPageControllerDirective');

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'app-insurance-user-detail-page-controller'
})
export class InsuranceUserDetailPageControllerDirective extends InsuranceCheckoutBaseComponent implements IDGoodsButtonDelegate {

  protected observables = new Map<string, Observable<IInsuranceProduct>>();

  @ViewChild(NgForm, {static: false}) form;

  disabled = true;
  personId;
  @ViewChild('userDetails' , { static: false }) userDetails: UserDetailPageComponent;
  waitingForResponse = false;
  createInsurance$: Subscription;
  request: ICreateInsuranceRequest;
  constructor(
    router: Router,
    route: ActivatedRoute,
    insuranceProvider: InsuranceProvider,
    productProvider: ProductStoreProvider,
    injector: Injector,
  ) {
    super(router, route, insuranceProvider, productProvider, injector);

  }

  /** NgInit */
  ngOnInit() {
   super.ngOnInit();
  }

  /** IDGoodsButtonDelegate --- START */
  onButtonClicked(sender: DGoodsButtonComponent, id: string): void {
    debug('Button clicked', sender);
    if (id === 'next') {
      // Navigate to next page
      this.navigateNext();
    } else if (id === 'back') {
      // Navigate back
      this.navigateBack();
    }
  }

  private navigateBack() {
    this.router.navigate([`${this.languageFromURL}/insurance-buy/${this.draft.product}`]);

  }
  private navigateNext() {
    this.updateInsuranceWithUserData();
  }

  /** IDGoodsButtonDelegate --- END */

  private updateInsuranceWithUserData() {
    if (this.draft === undefined) {
      throw new Error('there is no draft to update...');
    }
    if (this.userDetails.personId) {
      this.userDetails.updatePerson();
      this.createPersonInInsurance(this.userDetails.personId);
    } else {
      this.userDetails.createNewPerson();
    }
  }

  getPersonId($event) {
    this.personId = $event;
    this.createPersonInInsurance(this.personId);
  }

  createPersonInInsurance(personId) {
    this.request = {
      product: this.draft.product,
      productTitle: this.draft.productTitle,
      family: this.draft.family,
      variation: this.draft.variation,
      variationTitle: this.draft.variationTitle,
      price: this.draft.price,
      terminalId: this.draft.terminalId,
      orderUuid: this.draft.orderUuid,
      person: personId,
      token: '',
      language: '',
      additionalAttributes: this.draft.additionalAttributes,
      merchant: this.merchant
    };
    debug('update request:', this.request);

    this.createInsurance$ = this.insuranceProvider.createInsurance(this.request) .subscribe((res) => {
      if (res.spinnerState) {
        this.waitingForResponse = true;
      } else {
        this.waitingForResponse = false;
        if (res.error) {
          this.router.navigateByUrl('/de-ch/error');
        } else {
          this.router.navigate(['../de-ch/summary']);
        }
      }
    });
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    if (this.createInsurance$) {
      this.createInsurance$.unsubscribe();
    }
  }

}
