import { Component, OnInit, Input, Injector, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { UserProvider } from '@modeso/ihub-lib-user-fe';
import { DgoodsSessionStoreProvider } from '@modeso/twint-lib-session-fe';
import Debug from 'debug';
import { BasePageComponent } from '../../pages/base.page';
import { ShopUtil } from '../../utils/shopUtil';
const debug = Debug('ihub:project:IHubAlertComponent');

export enum IIHubAlertType {
  NONE,
  UOF_INACTIVE,
  BUSINESS_ERROR,
}

@Component({
  selector: 'ihub-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class IHubAlertComponent  extends BasePageComponent implements OnChanges {

  // tslint:disable-next-line: variable-name
  link: any;
  private reregister: boolean;
  waitingForUofResponse = false;
  @Input() failedSinglePaymentInsurances;
  @Input() alertType: IIHubAlertType;
      
  constructor(private injector: Injector, private userProvider: UserProvider) {
    super(injector);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.userProvider.getUofRegistartionToken$().subscribe(token => {
        this.reregister = !token;
      }));
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.failedSinglePaymentInsurances)
      this.link = this.failedSinglePaymentInsurances.length ? ['../history'] : ['../paymentalert/' + this.alertType];
  }

  linkToTwint() {
    if (!this.reregister) {
      this.userProvider.uofDeRegistration();
    } else {
      this.userProvider.startUofRegistration({
        product: '',
        orderUuid: '',
        terminalId: sessionStorage.getItem('terminalId')
      });

      this.subscriptions.push(
        this.userProvider.getUofRegistartionToken$().subscribe(this.onUoFResponse.bind(this))
      );
    }
  }

  onUoFResponse(token: string) {
    this.waitingForUofResponse = true;
    debug('UofResponse', token);
    if (token) {
      this.switchToApp(token);
    }
    return true;
  }

  switchToApp(registrationToken: any) {
    new ShopUtil().switchToApp(registrationToken);
    this.waitingForUofResponse = false;

  }
}
