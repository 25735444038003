import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';

@Component({
  selector: 'app-insurance-cancelation-page',
  templateUrl: './insurance-cancelation-page.component.html',
  styleUrls: ['./insurance-cancelation-page.component.scss']
})
export class InsuranceCancelationPageComponent extends BasePageComponent implements OnInit {

  public orderUuid;
  constructor(injector: Injector, private route: Router, private activated: ActivatedRoute, private analyticsProvider: AnalyticsProvider) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscriptions.push(this.activated.params.subscribe((param) => {
      this.orderUuid = param.orderUuid;
    }));
    this.savePageView();
  }

  savePageView() {
    this.analyticsProvider.savePageView$({
      productLine: null, pageName: 'Second Cancelation Page',
      terminalId: sessionStorage.getItem('terminalId'),
      pagePath: this.route.url,
    });
  }

}
