/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error.page.view.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./error.page.view";
import * as i3 from "@ngrx/store";
var styles_MajorErrorPage = [i0.styles];
var RenderType_MajorErrorPage = i1.ɵcrt({ encapsulation: 0, styles: styles_MajorErrorPage, data: {} });
export { RenderType_MajorErrorPage as RenderType_MajorErrorPage };
export function View_MajorErrorPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "error-page-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "error-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "error-msg-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "error-logo"], ["src", "assets/icon-denied.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "error-msg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sorry, the service is currently unavailable. Please try again later."])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "insur-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["class", "insur-img"], ["src", "assets/ihub/Fehlerscreen_InsurHub.png"]], null, null, null, null, null))], null, null); }
export function View_MajorErrorPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-major-error-page", [], null, null, null, View_MajorErrorPage_0, RenderType_MajorErrorPage)), i1.ɵdid(1, 245760, null, 0, i2.MajorErrorPage, [i3.Store, i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MajorErrorPageNgFactory = i1.ɵccf("app-major-error-page", i2.MajorErrorPage, View_MajorErrorPage_Host_0, {}, {}, []);
export { MajorErrorPageNgFactory as MajorErrorPageNgFactory };
