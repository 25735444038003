import * as tslib_1 from "tslib";
import { Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { ShopUtil } from '../../utils/shopUtil';
const debug = Debug('dgoods:project:AccountPageController');
export class AccountPageControllerDirective extends BasePageComponent {
    constructor(injector, userProvider, route, router, sessionProvider, location) {
        super(injector);
        this.injector = injector;
        this.userProvider = userProvider;
        this.route = route;
        this.router = router;
        this.sessionProvider = sessionProvider;
        this.location = location;
        this.disabled = true;
        this.waitingForUofResponse = false;
    }
    ngOnInit() {
        this.subscriptions.push(this.userProvider.getUofRegistartionToken$().subscribe(token => {
            this.reregister = !token;
        }));
        super.ngOnInit();
    }
    // tslint:disable-next-line: use-lifecycle-interface
    ngOnDestroy() {
        super.ngOnDestroy();
    }
    onButtonClicked(sender, id) {
        debug('Button clicked', sender);
        if (id === 'next') {
            // Navigate to next page
            this.navigateNext();
        }
        if (id === 'back') {
            // Navigate back
            this.navigateBack();
        }
    }
    navigateBack() {
        this.location.back();
    }
    navigateNext() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.userDetails.personId) {
                this.userDetails.updatePerson();
            }
            else {
                this.userDetails.createNewPerson();
            }
            this.router.navigate([`${this.languageFromURL}/landing-buy`]);
        });
    }
    onUoFResponse(token) {
        this.waitingForUofResponse = true;
        debug('UofResponse', token);
        if (token) {
            new ShopUtil().switchToApp(token);
            this.waitingForUofResponse = false;
        }
        return true;
    }
    linkToTwint() {
        if (!this.reregister) {
            this.userProvider.uofDeRegistration();
        }
        else {
            this.userProvider.startUofRegistration({
                product: '',
                orderUuid: '',
                terminalId: sessionStorage.getItem('terminalId')
            });
            this.subscriptions.push(this.userProvider.getUofRegistartionToken$().subscribe(this.onUoFResponse.bind(this)));
        }
    }
    gotoFAQ() {
        this.router.navigate([`${this.languageFromURL}/account/faq`]);
    }
}
