
import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import Debug from 'debug';
import { Location } from '@angular/common';
import { InsuranceProvider } from '@modeso/ihub-lib-insurance-fe';
import { ActivatedRoute, Router } from '@angular/router';
import * as fromApp from './../../state/app.state.reducer';
import { Store } from '@ngrx/store';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
import { InsuranceConfirmationControllerDirective } from './insurance-confirmation.page.controller';

const debug = Debug('dgoods:shared:InsuranceConfirmationPage');

@Component({
  selector: 'app-insurance-confirmation.page',
  templateUrl: './insurance-confirmation.page.view.html',
  styleUrls: ['./insurance-confirmation.page.view.scss']
})
export class InsuranceConfirmationPage extends InsuranceConfirmationControllerDirective implements OnInit, OnDestroy {

  draft;

  constructor(analyticsProvider: AnalyticsProvider, location: Location,
    injector: Injector, insuranceProvider: InsuranceProvider,
    route: ActivatedRoute, router: Router, store: Store<fromApp.AppState>) {

    super(analyticsProvider, location, injector, router, route, store, insuranceProvider);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
