import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CommonModule } from '@angular/common';
import { InsuranceConfig, InsuranceConfigService } from './insurance.config';
import * as fromReducer from './reducers/insurance.reducer';
import { InsuranceEffects } from './effects/insurance.effects';
import { InsuranceService } from './services/insurance.service';
import { VirtualPolicyComponent } from './components/virtualpolicy/virtualpolicy.component';
import { IncuranceItemComponent } from './components/incurance-item/incurance-item.component';
import { IncuranceListItemComponent } from './components/incurance-list-item/incurance-list-item.component';
import { TransactionListItemComponent } from './components/transaction-list-item/transaction-list-item.component';
import { TransactionItemComponent } from './components/transaction-item/transaction-item.component';
import { InjectedTextFeautureModule, InjectedTextPipe } from '@modeso/modeso-lib-core-fe';
import { InsuranceInformationComponent } from './components/insurance-information/insurance-information.component';
import { InsuranceHighlightDirective } from './shared/highlightInsuranceStatusDirective';
import { InsuranceDocumentsComponent } from './components/insurance-documents/insurance-documents.component';
import { InsuranceCancelationActionsComponent } from './components/insurance-cancelation-actions/insurance-cancelation-actions.component';
import { InsuranceCancelationPolicyComponent } from './components/insurance-cancelation-policy/insurance-cancelation-policy.component';
import { PersonInformationComponent } from './components/person-information/person-information.component';
import { NavigationService } from './shared/navigation.service';
import { SecondCancellationComponentComponent } from './components/second-cancellation-component/second-cancellation-component.component';
// tslint:disable-next-line: max-line-length
import { SecondStepCancellationActionsComponent } from './components/second-step-cancellation-actions/second-step-cancellation-actions.component';
import { ConfirmationComponentComponent } from './components/confirmation-component/confirmation-component.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DgoodsSharedModule } from '@modeso/twint-lib-core-fe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DateAdapter, MatButtonModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatExpansionModule } from '@angular/material';
import { MatRadioModule } from '@angular/material/radio';
import { InsuranceDetailsComponent } from './components/insurance-details/insurance-details.component';
import { InsuranceMerchantDocumentsComponent } from './components/insurance-merchant-documents/insurance-merchant-documents.component';
import { InsuranceAnivoDocumentsComponent } from './components/insurance-anivo-documents/insurance-anivo-documents.component';
import { EnumFilterPipe } from './utils/enumFilterPipe';
import { ViewInsuranceHighlightsComponent } from './components/view-insurance-highlights/view-insurance-highlights.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM.YYYY',
  },
  display: {
    dateInput: 'MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@NgModule({
  declarations: [
    VirtualPolicyComponent,
    IncuranceItemComponent,
    IncuranceListItemComponent,
    TransactionListItemComponent,
    TransactionItemComponent,
    InsuranceInformationComponent,
    InsuranceHighlightDirective,
    InsuranceDocumentsComponent,
    InsuranceCancelationActionsComponent,
    InsuranceCancelationPolicyComponent,
    PersonInformationComponent,
    SecondCancellationComponentComponent,
    SecondStepCancellationActionsComponent,
    ConfirmationComponentComponent,
    InsuranceDetailsComponent,
    InsuranceMerchantDocumentsComponent,
    InsuranceAnivoDocumentsComponent,
    EnumFilterPipe,
    ViewInsuranceHighlightsComponent
  ],
  providers: [NavigationService],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromReducer.insuranceFeatureKey,
      fromReducer.reducer
    ),
    EffectsModule.forFeature([InsuranceEffects]),
    InjectedTextFeautureModule,
    MatDialogModule,
    DgoodsSharedModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatRadioModule
  ],
  exports: [
    VirtualPolicyComponent,
    IncuranceListItemComponent,
    TransactionListItemComponent,
    InsuranceInformationComponent,
    InjectedTextPipe,
    EnumFilterPipe,
    InsuranceDocumentsComponent,
    InsuranceCancelationActionsComponent,
    InsuranceCancelationPolicyComponent,
    SecondCancellationComponentComponent,
    ConfirmationComponentComponent,
    InsuranceDetailsComponent,
    InsuranceMerchantDocumentsComponent,
    InsuranceAnivoDocumentsComponent,
    ViewInsuranceHighlightsComponent
  ],
  entryComponents: [ConfirmationComponentComponent],
})
export class InsuranceModule {
  static forRoot(config: InsuranceConfig): ModuleWithProviders {
    return {
      ngModule: InsuranceModule,
      providers: [
        InsuranceService,
        {
          provide: InsuranceConfigService,
          useValue: config,
        },
        {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
      ],
    };
  }
}
