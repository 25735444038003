<div class="product-buy-container ihub-styles">
  <dgoods-view-container>
    <div *ngIf="insuranceProduct" class="fixed-background">
      <h1>{{ insuranceProduct.title }}</h1>
      <ihub-dynamic-form *ngIf="additionalAttributes"
      [additionalAttributes]="additionalAttributes"
      (emitToPersonDetailsButtonState)="toggleToPersonDetailsButtonState($event)"
      (emitAdditionalAttributes)="saveAdditionAttributes($event)"
      (emitControlSubject)="eventHandler($event)"
      [ngClass]="{'disable-mouse': waitingForPrice }"></ihub-dynamic-form>
      <div>
        <h2 style="margin-top: 32px">
          {{ "ihub_shop_protection_cost" | injectedText | async }}
        </h2>
        <p *ngIf="familyOrSingleSelection.length > 0" style="margin-bottom: 24px">
          {{ "ihub_shop_choose_insurance_coverage" | injectedText | async }}
        </p>
        <ihub-switch
          [delegate]="this"
          identifier="familyOrSingle"
          [selection]="familyOrSingleSelection"
          [translated]="false"
        ></ihub-switch>
      </div>

      <div style="position: relative;">
        <div *ngIf="this.insuranceProduct.variations.length > 1">
          <div class="div-alignment">
            <span style="margin-right: 3px">
              {{insuranceProduct.variationOptionsInfo.variationOptionTitle}}
            </span>
            <span class="popupOpener margin-fix" (click)="openPopup($event)">
              <img src="assets/ihub/icons-faq.svg">
            </span>
          </div>
          <ihub-switch
            [delegate]="this"
            identifier="variation"
            [selection]="variationSelections"
            [translated]="true"
          ></ihub-switch>
        </div>
  
        <div *ngIf="waitingForPrice">
          <mat-spinner style="margin: auto;" diameter="50"></mat-spinner>
        </div>

        <div *ngIf="this.variations.length && !waitingForPrice && !externalPriceError">
          <div class="div-alignment">
            <span style="margin-right: 3px">
              {{insuranceProduct.variationOptionsInfo.variationOptionTitle}}
            </span>
          </div>
          <ihub-switch
            [delegate]="this"
            identifier="variation"
            [selection]="variations"
            [translated]="true"
            [openAsInfoIcon]="true"
          ></ihub-switch> 
        </div>

        <div *ngIf="externalPriceError"> 
          <div>
            {{ "ihub_shop_couldnt_retrieve_price" | injectedText | async }}
          </div>
          <div>
            <a (click)="retryExternalPrice()">
              {{ "ihub_shop_retry_price_calculation" | injectedText | async }}
            </a>
          </div>
        </div>
  
      
      </div>

      <div class="pricetag" *ngIf="shouldShowPrice()">
        <div *ngIf="!insuranceProduct.isPromoProduct && insuranceProduct.delayPaymentByMonth > 1" class="discount-label">
          {{ insuranceProduct.delayPaymentByMonth }} {{ 'ihub_shop_delay_payment_for' | injectedText | async}}
        </div>
        <div *ngIf="!insuranceProduct.isPromoProduct && insuranceProduct.delayPaymentByMonth === 1" class="discount-label">
          {{ 'ihub_first_month_free' | injectedText | async}}
        </div>
        <div *ngIf="insuranceProduct.isPromoProduct" class="discount-label">
          {{ 'ihub_shop_promo_product' | injectedText | async}} 
        </div>
        <strong>{{ "ihub_shop_currency" | injectedText | async }} {{ activePrice}}</strong>
        <span *ngIf="!insuranceProduct.isSinglePayment"> / {{ insuranceProduct.paymentPer }}</span>
      </div>
    </div>
  </dgoods-view-container>

  <div style="position: relative;">
    <dgoods-button
      [delegate]="this"
      class="button-style"
      identifier="next"
      [disabled]="disabled || externalPriceError || waitingForPrice"
      
    >
    <div *ngIf="waitingForResponse"  >
      <mat-spinner class="mat-response-spinner" diameter="45"></mat-spinner>
    </div>
      {{ "ihub_shop_continue_personal_info" | injectedText | async }}
    </dgoods-button>
  </div>
  <div>
    <dgoods-button
      [delegate]="this"
      class="button-style"
      identifier="back"
      state="notAction"
    >
      {{ "dgoods_back_button" | injectedText | async }}
    </dgoods-button>
  </div>
</div>

<ihub-popup #popup [content]="_popupContent"></ihub-popup>