import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


export interface IIHubPopupContent {
  title: string;
  text: string;
  needsTranslation?: boolean;
  isUnsafe?: boolean;
}

@Component({
  selector: 'ihub-popup',
  templateUrl: './ihub-popup.component.html',
  styleUrls: ['./ihub-popup.component.scss']
})
export class IHubPopUpComponent {

  @Input() content?: IIHubPopupContent;
  @Input() visible?: boolean = false;
  unsafeContent: any;

  constructor(private sanitizer: DomSanitizer) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.content && this.content.isUnsafe)
      this.unsafeContent = this.sanitizer.bypassSecurityTrustHtml(this.content.text);
  }

  /**
   * onClick
   */
  public onClick() {
    this.visible = false;
  }
  public stopPorpagation(event) {
    event.stopPropagation();
  }
}
