import * as tslib_1 from "tslib";
import { Injector, OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { ShopUtil } from '../../utils/shopUtil';
const debug = Debug('dgoods:shop:LoginPage');
export class LoginPage extends BasePageComponent {
    constructor(injector, store) {
        super(injector);
        this.store = store;
    }
    ngOnInit() {
        super.ngOnInit();
        this.detectIncognitoModeOnChrome();
        this.detectPrivateModeOnSafari();
    }
    onButtonClicked() {
        debug('Open Twint app');
        new ShopUtil().switchToAppAndCloseWindow();
    }
    detectIncognitoModeOnChrome() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if ('storage' in navigator && 'estimate' in navigator.storage) {
                const { usage, quota } = yield navigator.storage.estimate();
                console.log(`Using ${usage} out of ${quota} bytes.`);
                if (quota < 120000000) {
                    this.incognitoOverlay.hidden = false;
                }
                else {
                    debug('Not Incognito');
                }
            }
            else {
                debug('Can not detect');
            }
        });
    }
    detectPrivateModeOnSafari() {
        try {
            window.localStorage.setItem('test', '1');
        }
        catch (e) {
            this.incognitoOverlay.hidden = false;
        }
    }
}
