import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CommonModule } from '@angular/common';
import { IHubDamageConfigService, IHubDamageConfig } from './ihub.damage.config';
import { IHubDamageService } from './services/ihub.damage.service';
import * as fromDamage from './reducers/ihub.damage.reducer';
import { DamageEffects } from './effects/ihub.damage.effects';


@NgModule({
  // declarations: [TestComponent],
  providers: [IHubDamageService],
  imports: [
    CommonModule ,
    StoreModule.forFeature(fromDamage.damageFeatureKey, fromDamage.reducer),
    EffectsModule.forFeature([DamageEffects])
  ],
  // exports: [TestComponent]
})
export class IHubDamageModule {
  static forRoot(config: IHubDamageConfig): ModuleWithProviders {
    return {
      ngModule: IHubDamageModule,
      providers: [
        IHubDamageService,
        {
          provide: IHubDamageConfigService,
          useValue: config
        }
      ]
    };
  }
}
