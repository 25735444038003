import {
  IDGoodsButtonDelegate, IDGoodsEmailConfirmationDelegate,
  DGoodsEmailConfirmationValue, DGoodsEmailConfirmationComponent, DGoodsOverlayComponent, IDGoodsDialogBoxDelegate, DGoodsDialogBoxComponent
} from '@modeso/twint-lib-core-fe';
import { take } from 'rxjs/operators';
import { UserProvider } from '@modeso/ihub-lib-user-fe';
import { Injector, OnInit, Input, ViewChild, Output, EventEmitter, Component, Directive } from '@angular/core';
import { BasePageComponent } from '../base.page';
import debug from 'debug';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'app-email-page-controller'
})
export class EmailPageControllerDirective extends BasePageComponent implements
  OnInit, IDGoodsDialogBoxDelegate,
  IDGoodsButtonDelegate, IDGoodsEmailConfirmationDelegate {
  protected subscriptions = [];
  private userEmail: string;
  private userConfirmEmail: string;
  private isValid: boolean;
  public state = 'normal';
  public disabled = true;
  public message: string;
  public dialogBoxObj = {
    title: 'dgoods_shop_email&accountview_emailconfirmation_dialogbox_title_duplicate_email',
    description: 'dgoods_shop_email&accountview_emailconfirmation_dialogbox_message_duplicate_email',
  };
  @Input() hidden: boolean;
  @Output() hideOverLay = new EventEmitter<any>();
  @ViewChild('errorOverlay', { static: false }) errorOverlay: DGoodsOverlayComponent;
  @ViewChild('dialogBoxOverlay', { static: false }) dialogBoxOverlay: DGoodsDialogBoxComponent;
  constructor(private location: Location, private injector: Injector, private userProvider: UserProvider, private route: ActivatedRoute,
    private router: Router) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onOverlayButtonClicked(): void {
    this.errorOverlay.hidden = true;
  }

  onDialogBoxButtonClicked() {
    this.dialogBoxOverlay.hidden = true;
  }

  onChangeEmail(value: DGoodsEmailConfirmationValue, id: string, element: DGoodsEmailConfirmationComponent): void {
    this.userEmail = element.input1['value'];
    debug('Email changed ' + value.value + ' _ ' + value.isValid);
    this.isValid = value.isValid;
    this.disabled = this.isValid ? false : true;
  }

  onButtonClicked(sender: import('@modeso/twint-lib-core-fe').DGoodsButtonComponent) {
    debug('Button clicked');
    if (sender.state === 'notAction') {
      this.hideOverLay.emit();
    } else {
      if (this.isValid) {
        const body = {
          email: this.userEmail,
        };
        // TODO remove to select afetr the status change
        let index = 0;
        //TODO this subscription must be destroyed onNgDestroy
        this.userProvider.updateUser$(body).pipe(take(2))
          .subscribe((userData) => {
            index++;
            if (index === 2) {
              if (userData.email) {
                this.message = 'dgoods_shared_overlaycomponent_messageemailsuccess';
                localStorage.setItem('email', userData.email);
                this.hideOverLay.emit();
              } else {
                if (userData.status.toString() === '405') {
                  this.dialogBoxOverlay.hidden = false;
                } else {
                  this.message = 'dgoods_shared_overlaycomponent_messageemailfailed';
                  this.errorOverlay.hidden = false;
                }
              }
            }
          });
      } else {
        this.message = 'dgoods_shared_overlaycomponent_messageemailnotmatch';
        this.errorOverlay.hidden = false;
      }
    }
  }

}
