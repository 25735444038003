<div class="main-title-container">
    <span class="main-title">{{'ihub_shop_cancellation_policy' | localize  | async }}</span>
</div>
<div class="main-container" *ngIf="insuranceToBeCanceled">
<div class="cancellation-policy-titles">
    <div style="margin-bottom: 16px;">{{insuranceToBeCanceled.productTitle}}</div>
    <span class="policy-number">{{'ihub_shop_policy_number' | localize | async}}: </span>
    <span class="policy-number">{{insuranceToBeCanceled.policyNumber}}</span>
</div>
<ihub-insurance-cancelation-policy [insurance]="insuranceToBeCanceled" [person]="person" [firstPossibleCancellationDate]="firstPossibleCancellationDate"></ihub-insurance-cancelation-policy>
<div>
    <a (click) ="onClick()" class="confirm-cancel">
       {{'ihub_shop_cancel_policy' | injectedText | async }}
      </a>
    <dgoods-button [delegate]="this" identifier="back">
        {{ "dgoods_back_button" | injectedText | async }}
    </dgoods-button>
</div>
</div>
