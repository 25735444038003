import { Injectable } from '@angular/core';
import { NamedString, InjectedTextProvider } from '@modeso/modeso-lib-core-fe';
import Debug from 'debug';
const debug = Debug('dgoods:shared:DGoodsSharedProvider');
//This are the default values
const strings = [
  { 'dgoods_shared_emailconfirmation_receiver': 'Empfänger' },
  { 'dgoods_shared_emailconfirmation_sender': 'Absender' },
  { 'dgoods_shared_emailconfirmation_message': 'Ihre Nachricht (freiwillig)' },
  { 'dgoods_shared_navigationcompontent_account': 'Konto' },
  { 'dgoods_shared_navigationcompontent_back': 'Zurück' },
  { 'dgoods_shared_orderconfirmationcompontent_instructions': 'Anleitung zur Einlösung des Codes & AGBs' },
  { 'dgoods_shared_orderconfirmationcompontent_email': 'Code zusätzlich verschickt an:' },
  { 'dgoods_shared_overlaycompontent_ok': 'ok' },
  { 'dgoods_shared_table_empty': 'Sie haben bisher noch keine Gutscheine oder Guthaben gekauft. Nach dem Kauf werden Ihnen diese hier angezeigt.' },
  { 'dgoods_shared_tablecellcompontent_reference': 'Referenz:' },
  { 'dgoods_shared_termsofservicecompontent_conclude': 'Schliessen' },
  { 'dgoods_shared_tutorialoverlaycompontent_roger': 'Verstanden' },
  { 'dgoods_shared_tutorialoverlaycompontent_title1': 'Guthaben und Gutscheine kaufen' },
  { 'dgoods_shared_tutorialoverlaycompontent_title2': 'Email-Adresse eingeben' },
  { 'dgoods_shared_tutorialoverlaycompontent_title3': 'Code erhalten' },
  { 'dgoods_shared_tutorialoverlaycompontent_description1': 'Sie finden Angebote unter den jeweiligen Anbietern. Wählen Sie dort das gewünschte Produkt.' },
  { 'dgoods_shared_tutorialoverlaycompontent_description2': 'Nach der Auswahl des Produktes können Sie Ihre E-Mail Adresse eingeben. Dorthin wird eine Kaufbestätigung, sowie der Code für die Einlösung gesendet.' },
  { 'dgoods_shared_tutorialoverlaycompontent_description3': 'Nach dem Kauf wird Ihnen der Code direkt angezeigt und zusätzlich an Ihre E-Mail Adresse gesendet.' },
  { 'dgoods_shared_textimageinfocomponent_information': 'Mehr Informationen' },
  { 'dgoods_shared_inputcomponent_emailrecipient': 'Email recipient' },
  { 'dgoods_shared_inputcomponent_yourname': 'Ihr Name' },
  { 'dgoods_shared_inputcomponent_yourmsg': 'Ihre Mitteilung (Optional)' },
  { 'dgoods_shared_orderconfirmationcomponent_code': 'Code' },
  { 'dgoods_shared_orderconfirmationcomponent_details': 'Details' },
  { 'dgoods_shared_orderconfirmationcomponent_scannablecode': 'Scanbaren Code anzeigen' },
  { 'dgoods_shared_orderconfirmationcomponent_inprogress': 'Der Code wird gerade erstellt' },
  { 'dgoods_shared_termsofservicecomponent_title': 'Terms & Conditions' },
  { 'dgoods_shared_overlaycomponent_messageemailsuccess': 'E-Mail wurde erfolgreich aktualisiert' },
  { 'dgoods_shared_overlaycomponent_messageemailfailed': 'Beim Aktualisieren der E-Mail ist ein Fehler aufgetreten' },
  { 'dgoods_shared_overlaycomponent_messageemailnotmatch': 'Bestätigen Sie, dass die E-Mail nicht mit der E-Mail übereinstimmt' },
  { 'dgoods_shared_priceoptiondenomination_ownAmount': 'Eigener Betrag' },
  { 'dgoods_shared_orderconfirmationcompontent_copyconfirmationmsg': 'Code wurde kopiert, fügen Sie Ihn einfach im Zielfenster ein.' }
];
@Injectable({
  providedIn: 'root'
})
export class DGoodsSharedProvider {

  constructor(private injectedTextProvider: InjectedTextProvider) {
    //Covert strings to NamedString
    debug("init");
    const defaultStrings = new Array<NamedString>();
    strings.forEach((element) => {
      const x = new NamedString(Object.keys(element)[0], element[Object.keys(element)[0]]);
      defaultStrings.push(x);
    });
    this.setInjectedText(defaultStrings);
  }

  private setInjectedText(injectedText: Array<NamedString>) {
    debug("set injected text" + injectedText);
    debug(injectedText);
    this.injectedTextProvider.setInjectedText(injectedText);
  }

}
