import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IHubPopUpComponent, IIHubPopupContent } from '@modeso/twint-lib-core-fe';
import { IConfiguredAttribute } from '@modeso/types__ihub-lib-products-be';
import { ControlsHandlerFactory } from '../../../../utils/FormHandlers/ControlsHandlerFactory';
import { IInsuranceAttributeControl } from '../../ComponentFactory/IInputControl.interface';
import { BaseControl } from '../Base';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { ValidatorType } from '../../../../utils/enums/validatorType.enum';


@Component({
  selector: 'ihub-select-control',
  templateUrl: './select-control.component.html',
  styleUrls: ['./select-control.component.scss']
})
export class SelectControlComponent extends BaseControl implements OnInit, IInsuranceAttributeControl {
  @ViewChild('popup', { static: true }) popup: IHubPopUpComponent;
  private controlsHandlerFactory: ControlsHandlerFactory;
  popupContent: IIHubPopupContent;
  insuranceAttribute: IConfiguredAttribute;
  form: FormGroup;
  validationErrors: any;
  autoComplete: boolean = false;

  selectionMenu: any[] = [];
  public filterCtrl: FormControl = new FormControl('');
  public filteredSelectionMenu: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();

  constructor() {
    super();
    this.controlsHandlerFactory =  ControlsHandlerFactory.getInstance();
  }

  ngOnInit() {
    this.autoComplete = this.insuranceAttribute && 
      this.insuranceAttribute.validation && this.insuranceAttribute.validation[ValidatorType.AutoComplete] &&
      this.insuranceAttribute.validation[ValidatorType.AutoComplete].value;

    for (const [key, value] of Object.entries(this.insuranceAttribute.enum)) {
      if (value.useValue) {
        this.selectionMenu.push({
          id: value.value,
          value: value.value,
        });
      } else {
        this.selectionMenu.push({
          id: value.language,
          value: value.value,
        });
      }
    }

    this.filteredSelectionMenu.next(this.selectionMenu.slice())

    this.filterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterItems();
      });

  }
  
  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredSelectionMenu
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        (this.getFormControl(this.form, this.insuranceAttribute.id) as unknown as MatSelect).compareWith = (a: any, b: any) => a && b && a === b;
      });
  }

  protected filterItems() {
    if (!this.selectionMenu) {
      return;
    }
    // get the search keyword
    let search = this.filterCtrl.value;
    if (!search) {
      this.filteredSelectionMenu.next(this.selectionMenu.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredSelectionMenu.next(
      this.selectionMenu.filter(item => item.value.toLowerCase().indexOf(search) > -1)
    );
  }

  public openPopup(event: any) {
    event.stopPropagation();
    this.popupContent = {
      title: (this.insuranceAttribute.title as string),
      text: (this.insuranceAttribute.text as string),
      needsTranslation: false,
    };
    this.popup.visible = true;
  }


}
