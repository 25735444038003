import { Component, Input, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { ProductConfiguration } from '@modeso/twint-lib-cart-fe';
import Debug from 'debug';
import { ClipboardService } from 'ngx-clipboard';
import { IDGoodsButtonDelegate, DGoodsButtonComponent } from '../dgoods-button/dgoods-button.component';
import { IDGoodsOverlayDelegate } from '../dgoods-overlay/dgoods-overlay.component';
const debug = Debug('dgoods:shared:DgoodsOrderConfirmationComponent');
@Component({
  selector: 'dgoods-order-confirmation',
  templateUrl: './dgoods-order-confirmation.component.html',
  styleUrls: ['./dgoods-order-confirmation.component.scss']
})
export class DgoodsOrderConfirmationComponent implements OnInit, IDGoodsButtonDelegate, IDGoodsOverlayDelegate {

  orderStatuses = ['ORDER_SUCCESS', 'PAYMENT_FAILED', 'CANCEL_FAILED', 'PAYMENT_CANCELED', 'PAYMENT_REVERSED',
    'REVERSE_CONFIRMED', 'REVERSE_FAILED', 'PAYMENT_SUCCESS'];
  failedStatuses = ['PAYMENT_FAILED', 'CANCEL_FAILED', 'PAYMENT_CANCELED', 'PAYMENT_REVERSED',
    'REVERSE_CONFIRMED', 'REVERSE_FAILED'];

  @Input() productImage: string;
  @Input() codeNumber?: string;
  @Input() productConfiguration?: ProductConfiguration;
  @Input() currency: string;
  @Input() price: number;
  @Input() productName: string;
  @Input() date: string;
  @Input() email: string;
  @Input() status: string;
  @Input() isInProductionMode: string;

  @ViewChild('barcode', { static: false }) barcode: ElementRef;

  showOverlay = false;
  copyOverlay = false;
  constructor(private clipboardService: ClipboardService, private renderer: Renderer2) { }

  public hideScannableCode = true;
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }

  showScannableCode() {
    debug('showScannableCode');
    this.hideScannableCode = !this.hideScannableCode;
  }

  followLinkAndCopy(value) {
    debug('followLinkAndCopy ' + value);
    this.clipboardService.copyFromContent(value);

    setTimeout(() => {
      window.open(this.getCopy(), '_blank');
    }, 100);
  }
  justFollowLink(value) {
    debug('followLink ' + value);
    this.clipboardService.copyFromContent(value);
    this.copyOverlay = true;
    setTimeout(() => {
      this.copyOverlay = false;
    }, 2000);
    setTimeout(() => {
      window.open(this.getLink(), '_blank');
    }, 600);

  }
  justCopy(value) {
    debug('Copy ' + value);
    this.clipboardService.copyFromContent(value);
    this.copyOverlay = true;
    setTimeout(() => {
      this.copyOverlay = false;
    }, 2000);

  }
  openInstructions() {
    this.showOverlay = true;
  }
  getTextSizeClass(text: string) {
    if (text.length <= 13) {
      return 'bigText';
    } else if (text.length <= 16) {
      return 'mediumText';
    } else if (text.length <= 19) {
      return 'smallText';
    } else {
      return 'tinyText';
    }
  }
  getInProgress() {
    return !this.status || !this.orderStatuses.includes(this.status);
  }

  getDebugStatus() {
    if (!this.isInProductionMode && this.failedStatuses.includes(this.status)) {
      return this.status;
    }
  }

  getLink() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config.get('LINK');
  }
  getDisplayLink() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config.get('DISPLAYLINK');
  }
  getLinkText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text.get('LINK');
  }
  getCopy() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config.get('COPY');
  }
  getCopyText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text.get('COPY');
  }
  getCode() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config.get('CODE');
  }
  getCodeText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text.get('CODE');
  }
  getSerial() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config.get('SERIAL');
  }
  getSerialText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text.get('SERIAL');
  }
  getCard() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config.get('CARD');
  }
  getCardText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text.get('CARD');
  }
  getBarcode() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config.get('EAN');
  }
  getBarcodeText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text.get('EAN');
  }
  getPin() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config.get('PIN');
  }
  getPinText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text.get('PIN');
  }
  getAGB() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config.get('AGB');
  }
  getAGBText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text.get('AGB');
  }
  getRedemtionInfo() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config.get('REDEMTION_INFORMATION');
  }
  getRedemtionInfoText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text.get('REDEMTION_INFORMATION');
  }
  getExpiresInfo() {
    debug("getExpiresInfo");
    debug(this.productConfiguration.config.get('EXPIRES'));
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.config.get('EXPIRES');
  }
  getExpiresInfoText() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text.get('EXPIRES');
  }
  getExpiresInfoDate() {
    if (!this.productConfiguration) { return undefined; }
    return this.productConfiguration.text.get('EXPIRES');
  }

  onButtonClicked(sender: DGoodsButtonComponent): void {
    this.showOverlay = false;
  }

  onOverlayButtonClicked(): void {
    this.hideScannableCode = true;
  }
}

