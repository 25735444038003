import { Component, Input, OnInit , Output , EventEmitter } from '@angular/core';
import { ITransactionItem } from '../../models/transaction-item.model';
import { InsuranceStatusEnum , PaymentStatusEnum } from '@modeso/types__ihub-lib-insurance-be';

@Component({
  selector: 'ihub-transaction-item',
  templateUrl: './transaction-item.component.html',
  styleUrls: ['./transaction-item.component.scss']
})
export class TransactionItemComponent implements OnInit {

  @Input() dataSource: ITransactionItem;
  @Output() isRedirectToPayment: EventEmitter<any> = new EventEmitter(null);
  isButtonClicked = false;

  constructor() { }

  ngOnInit() {
  }

  activateInsurance() {
    this.isButtonClicked = true;
    this.isRedirectToPayment.emit({
      event: true,
      orderUuid: this.dataSource.orderUuid,
      isSinglePayment: this.dataSource.isSinglePayment
    });
  }

  public get insuranceStatusEnum(): typeof InsuranceStatusEnum {
    return InsuranceStatusEnum;
  }

  public get paymentStatusEnum(): typeof PaymentStatusEnum {
    return PaymentStatusEnum;
  }
}
