import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CartConfigService } from './../cart.config';
import { CheckoutModel } from '../models/order.model';
import { PurchasedProducts } from '../models/purchasedProducts.model';
import { OrderConfirmationModel } from '../models/orderConfirmation.model';
import { share } from 'rxjs/operators';

export interface PurchasedOrders {
  purchasedOrders: Array<PurchasedProducts>;
}

export interface OrderConfirmationResponse {
  message: string;
  orderModel: OrderConfirmationModel;
}
@Injectable({
  providedIn: 'root',
})
export class CartService {
  private server = '';
  private microserviceName = 'cart';
  private checkout = 'checkout';
  private readonly route = '/';
  private readonly orders = 'orders';
  private readonly order = 'order';

  /**
   *
   * @param http
   * @param config
   */
  constructor(
    private http: HttpClient, @Inject(CartConfigService) private config) {
    this.server = config.apiGateway;
  }
  /**
   * service to post cart.
   * @param data
   */
  postCart(data) {
    const url = `${this.server}${this.microserviceName}${this.route}${this.checkout}`;
    return this.http.post<CheckoutModel>(url, data);
  }

  getOrderByUserId() {
    const url = `${this.server}${this.orders}${this.route}`;
    return this.http.get<PurchasedOrders>(url);
  }

  getOrderByReceiptUrl(receiptURL: string) {
    const url = `${this.server}${this.order}${this.route}${receiptURL}`;
    return this.http.get<OrderConfirmationResponse>(url).pipe(share());
  }

}
