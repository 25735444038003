import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {

    constructor(private router: Router) {
    }
    public navigateToErrorPage() {
        const currentLanguage = localStorage.getItem('currentLanguage');
        this.router.navigateByUrl(`${currentLanguage}/error`);
    }
    public navigateToPage(url: string) {
        this.router.navigateByUrl(url);
    }
}
