import { Component, Input, OnInit } from '@angular/core';
import { ValidatorType } from '../../../utils/enums/validatorType.enum';
@Component({
  selector: 'ihub-error-message-renderer',
  templateUrl: './error-message-renderer.component.html',
  styleUrls: ['./error-message-renderer.component.scss']
})
export class ErrorMessageRendererComponent implements OnInit {

  @Input() validationErrors: any;
  @Input() formErrors;
  @Input() formHints;
  constructor() { }

  ngOnInit() {
  }
  public get ValidatorType(): typeof ValidatorType {
    return ValidatorType;
  }
}
