import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductsConfigService } from './../products.config';
import Debug from 'debug';
import { ISpotlight } from '@modeso/types__ihub-lib-products-be';

const debug = Debug('ihub:products:SpotlightService');

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};


@Injectable({
  providedIn: 'root',
})
export class SpotlightService {

  private server = '';
  private microserviceName = 'spotlights';
  private readonly route = '/';

  constructor(private http: HttpClient, @Inject(ProductsConfigService) private config) {
    this.server = config.apiGateway;
    debug('ProductAPIGATEWAY: ' + config.apiGateway);
    debug('ProductServiceId: ' + config.serviceId);
  }

  getSpotlights(issuer: string) {
    debug('get all spotlights called for issuer', issuer);
    const url = `${this.server}${this.microserviceName}/${issuer}`;
    return this.http.get<ISpotlight[]>(url, httpOptions);
  }

}

