/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ihub-slider.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./ihub-slider.component";
var styles_SliderComponent = [i0.styles];
var RenderType_SliderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SliderComponent, data: {} });
export { RenderType_SliderComponent as RenderType_SliderComponent };
function View_SliderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "slide"]], null, [[null, "tap"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tap" === en)) {
        var pd_0 = (_co.onTap($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "imgTag"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.teaserImage; _ck(_v, 3, 0, currVal_0); }); }
function View_SliderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "dot"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dotClick(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentSlide === _v.context.index); _ck(_v, 0, 0, currVal_0); }); }
export function View_SliderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { slidecontainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "slider-wrapper"]], null, [[null, "swipeleft"], [null, "swiperight"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("swipeleft" === en)) {
        var pd_0 = (_co.onSwipeLeft($event) !== false);
        ad = (pd_0 && ad);
    } if (("swiperight" === en)) {
        var pd_1 = (_co.onSwipeRight($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["slidecontainer", 1]], null, 4, "div", [["class", "slider-container"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SliderComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["style", "text-align:center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SliderComponent_2)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.transformStyle; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.slides; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.slides; _ck(_v, 10, 0, currVal_2); }, null); }
export function View_SliderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "twint-slider", [], null, null, null, View_SliderComponent_0, RenderType_SliderComponent)), i1.ɵdid(1, 49152, null, 0, i3.SliderComponent, [i1.Renderer2], null, null)], null, null); }
var SliderComponentNgFactory = i1.ɵccf("twint-slider", i3.SliderComponent, View_SliderComponent_Host_0, { slides: "slides", delegate: "delegate" }, {}, []);
export { SliderComponentNgFactory as SliderComponentNgFactory };
