
import { Component, OnInit, Injector, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import Debug from 'debug';
import { InsuranceDetailPageControllerDirective } from './insurance-detail.page.controller';
import { ActivatedRoute, Router } from '@angular/router';
import { IHubPopUpComponent, IIHubExpandableContent, IIHubInsuranceServiceItem, IIHubPopupContent } from '@modeso/twint-lib-core-fe';
import { IInsuranceProduct, IInsuranceService } from '@modeso/types__ihub-lib-insurance-be';
import { map } from 'rxjs/operators';
import { InsuranceProvider } from '@modeso/ihub-lib-insurance-fe';
import { ProductStoreProvider } from '@modeso/ihub-lib-products-fe';
import { VariationConfiguration, VariationParams } from '@modeso/types__ihub-lib-products-be';
import { fromEvent } from 'rxjs';
import { DelayPaymemtUtil } from '../../utils/delayPaymentUtil';
const debug = Debug('dgoods:shared:InsuranceDetailPage');

@Component({
  selector: 'app-insurance-detail-page',
  templateUrl: './insurance-detail.page.view.html',
  styleUrls: ['./insurance-detail.page.view.scss']
})
export class InsuranceDetailPage extends InsuranceDetailPageControllerDirective implements
 OnInit, AfterViewInit {

  public subscriptions = [];
  services: IIHubInsuranceServiceItem[];
  content: IIHubExpandableContent;
  serviceHtml: string;
  public yourName = 'dgoods_shared_inputcomponent_yourname';
  public yourMessage = 'dgoods_shared_inputcomponent_yourmsg';
  public caption = 'dgoods_shared_inputcomponent_emailrecipient';
  // tslint:disable-next-line: variable-name
  _popupContent: IIHubPopupContent;
  @ViewChild('popup', { static: true }) popup: IHubPopUpComponent;
  constructor(
    router: Router,
    route: ActivatedRoute,
    insuranceProvider: InsuranceProvider,
    productProvider: ProductStoreProvider,
    private elementRef: ElementRef,
    injector: Injector,
  ) {
    super(router, route, insuranceProvider, productProvider, injector);

  }

  ngAfterViewInit() {
    this.checkProductDescriptionHasEmbeddedPopUpText();
  }

  /** OnInit --- START */

  ngOnInit() {
    super.ngOnInit();
    this.getProduct();
  }
  /** OnInit --- END */

  /**
   * getAgbLink
   */
  public getAgbLink() {
    const browserLanguage = navigator.language;
    let agbLanguage = '';
    if (browserLanguage.includes('de')) {
      agbLanguage = 'de';
    } else if (browserLanguage.includes('fr')) {
      agbLanguage = 'fr';
    } else if (browserLanguage.includes('it')) {
      agbLanguage = 'it';
    } else {
      agbLanguage = 'en';
    }
    return 'assets/pdf/' + agbLanguage + '/shopterms.pdf';
  }

  protected getProduct() {
    debug('subscribe to product observable');
    this.subscriptions.push(this.observables.get('getProductByProductCodeFromStore').pipe(
      map(this.mapProduct.bind(this))
    ).subscribe());
  }

  protected mapProduct(product: IInsuranceProduct) {
    debug(product);
    if (product) {
      this.mapExpandableDetails(product);
    }
    if (!product) {
      this.router.navigate(['../../de-ch/error']);
    }
    return product;
  }

  private mapExpandableDetails(product: IInsuranceProduct) {

    product = new DelayPaymemtUtil().getDelayPaymentForProduct(product);
    // Get price:
    let price = 99000;
    debug(product);
    if (product.priceStaticConfig) {
      price = Number(product.priceStaticConfig);
    } else {

      if (product.singlePrices) {
        const properties: string[] = Object.getOwnPropertyNames(product.singlePrices);
        properties.forEach(element => {
          if (typeof product.singlePrices[element] === 'number') {
            if (price > product.singlePrices[element]) {
              price = product.singlePrices[element] as number;
            }
          } else {
            const variation = product.singlePrices[element] as VariationConfiguration;

            if (variation.type === 'lookupTable_To') {
              const lowestPriceFromTable = this.getLowestPossiblePrice(variation.params);
              price = lowestPriceFromTable < price ? lowestPriceFromTable : price;
            }

            if (variation.type === 'multiply' ||
                variation.type === 'multiplyPriceByFactor') {
              price = variation.params.factor < price ? variation.params.factor : price;
            }
          }
        });
      }
      if (product.familyPrices && product.hasFamily) {
        const properties: string[] = Object.getOwnPropertyNames(product.familyPrices);
        properties.forEach(element => {
          if (typeof product.familyPrices[element] === 'number') {
            if (price > product.familyPrices[element]) {
              price = product.familyPrices[element] as number;
            }
          } else {
            const variation = product.familyPrices[element] as VariationConfiguration;
            if (variation.params.table) {
              const lowestPriceFromTable = this.getLowestPossiblePrice(variation.params);
              price = lowestPriceFromTable < price ? lowestPriceFromTable : price;
            }
          }
        });
      }
    }

    this.content = {
      delayPaymentByMonth: product.delayPaymentByMonth,
      price: price.toFixed(2), // ihub_shop_price_from
      timeframe: product.paymentPer,
      toggletext: 'ihub_shop_service_details',
      subtitletext: 'ihub_shop_service_title', // ihub_shop_service_title
      currencytext: 'ihub_shop_service_start_text', // ihub_shop_service_start_text
      avbtext: 'ihub_shop_avb_long', // ihub_shop_avb
      avblink: product.disclaimerLink,
      vitext: 'ihub_shop_vi_text', // ihub_shop_vi_text
      vilink: 'ihub_shop_vi_link', // ihub_shop_vi_link,
      isPromoProduct: product.isPromoProduct
    };
    this.serviceHtml = product.serviceHtml;
    this.services = [];
    product.services.forEach(
      (service: IInsuranceService) => {
        let sub = [];
        if (service.sub) {

          sub = service.sub.map((sub1) => {
            return {
              text: sub1.title,
              description: sub1.text,
              cover: sub1.cover,
            } as IIHubInsuranceServiceItem;
          });
        }

        const s = {
          text: service.title,
          description: service.text,
          cover: service.cover,
          sub
        };

        this.services.push(s);
      }
    );
  }

  private getLowestPossiblePrice(params: VariationParams): number {
    const sortedPrices = Object.keys(params.table).sort((a, b) => +a - +b);
    return params.table[sortedPrices[0]];
  }

  private checkProductDescriptionHasEmbeddedPopUpText() {
    const { description } = this.insuranceProduct;
    const descriptionParagraphTag = this.elementRef.nativeElement.querySelector('#insurance-description');
    descriptionParagraphTag.innerHTML = description;
    if (description.indexOf('<a') < 0) {
      debug('no embedded popuptext');
      return;
    }
    const aTags = descriptionParagraphTag.querySelectorAll('a[data-popuptext]');
    aTags.forEach((aTag: HTMLElement) => {
      this.subscriptions.push(fromEvent(aTag, 'click').subscribe(() => {
        this.openPopup(aTag.getAttribute('data-popuptext'), aTag.getAttribute('data-popuptitle'));
      }));
    })

  }
  public openPopup(text, title) {
    this._popupContent = {
      title,
      text,
      needsTranslation: false,
      isUnsafe: true,
    };
    this.popup.visible = true;
  }
}
