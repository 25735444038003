import { Injector, Directive, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import Debug from 'debug';
import { BasePageComponent } from '../base.page';
import { IDGoodsButtonDelegate, DGoodsButtonComponent } from '@modeso/twint-lib-core-fe';
const debug = Debug('dgoods:project:TermsOfTwintPageController');

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'app-terms-of-twint.page-controller'
})

export class TermsOfTwintPageControllerDirective extends BasePageComponent implements IDGoodsButtonDelegate {

  @Input() hiddenTermsOfTwint: boolean;
  @Output() hideTermsOfTwint = new EventEmitter<any>();

  constructor(injector: Injector, private router: Router, private route: ActivatedRoute) {
    super(injector);
  }
  /**
   * IDGoodsTextButtonDelegate
   */
  onButtonClicked(sender: DGoodsButtonComponent): void {
    debug('Button clicked');
    this.hideTermsOfTwint.emit();
  }

}
