import { Type } from '@angular/core';
import { AttributeType } from '../../../utils/enums/attributeType.enum';
import { CheckboxControlComponent } from '../FormControlComponents/checkbox-control/checkbox-control.component';
import { CurrencyInputControlComponent } from '../FormControlComponents/currency-input-control/currency-input-control.component';
import { DateInputControlComponent } from '../FormControlComponents/date-input-control/date-input-control.component';
import { DatePickerControlComponent } from '../FormControlComponents/date-picker-control/date-picker-control.component';
import { InputTextControlComponent } from '../FormControlComponents/input-text-control/input-text-control.component';
import { PeopleDetailsControlComponent } from '../FormControlComponents/people-details-control/people-details-control.component';
import { RadioButtonCheckControlComponent } from '../FormControlComponents/radio-button-check-control/radio-button-check-control.component';
import { RadioButtonControlComponent } from '../FormControlComponents/radio-button-control/radio-button-control.component';
import { SelectControlComponent } from '../FormControlComponents/select-control/select-control.component';
import { SwitchControlComponent } from '../FormControlComponents/switch-control/switch-control.component';
import { TitleControlComponent } from '../FormControlComponents/title-control/title-control.component';
import { IInsuranceAttributeControl } from './IInputControl.interface';



export class DynamicComponentFactory {
    public getComponent(property: string): Type<IInsuranceAttributeControl> {
        let type: Type<IInsuranceAttributeControl> ;
        switch (property) {
            case AttributeType.DATE:
                type = DateInputControlComponent;
                break;
            case AttributeType.TEXT:
                type = InputTextControlComponent;
                break;
            case AttributeType.CURRENCY:
                type = CurrencyInputControlComponent;
                break;
            case AttributeType.NUMBER:
                type = CurrencyInputControlComponent;
                break;
            case AttributeType.MAT_EXPANDABLE_FORM_ARRAY:
                type = PeopleDetailsControlComponent;
                break;
            case AttributeType.DATE_PICKER:
                type = DatePickerControlComponent;
                break;
            case AttributeType.SELECT:
                type = SelectControlComponent;
                break;
            case AttributeType.SWITCH:
                type = SwitchControlComponent;
                break;
            case AttributeType.CHECK_BOX:
                type = CheckboxControlComponent;
                break;
            case AttributeType.TITLE:
                type = TitleControlComponent;
                break;
            case AttributeType.RADIO_BUTTON:
                type = RadioButtonControlComponent;
                break;
            case AttributeType.RADIO_BUTTON_CHECK:
                type = RadioButtonCheckControlComponent;
                break;
            default:
                type = TitleControlComponent;
                break;
        }
        return type;
    }
}
