import { Injectable } from '@angular/core';
import * as fromActions from './../actions/cart.actions';
import * as fromFeature from './../reducers/cart.reducer';
import { Store, select } from '@ngrx/store';
import { CartModel } from './../models/cart.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderModel, CheckoutModel } from '../models/order.model';
import { PurchasedProducts } from '../models/purchasedProducts.model';
import { OrderConfirmationModel } from '../models/orderConfirmation.model';
import Debug from 'debug';
const debug = Debug('twint-lib-cart-fe');

@Injectable({
  providedIn: 'root'
})
export class CartProvider {

  constructor(
    private store: Store<fromFeature.AppState>) { }

  /**
   * function checkout to dispatch action of post cart.
   * @param cart
   */
  public checkout(cart: CartModel): Observable<CheckoutModel> {
    this.store.dispatch(fromActions.postCart(cart));
    return this.store.pipe(select(fromFeature.featureCart), map((cartResponse: CheckoutModel) => {
      if (cartResponse) {
        return cartResponse;
      } else {
        debug('cart store is empty');
      }
    }));
  }

  public getCartToken$(): Observable<string> {
    return this.store.pipe(select(fromFeature.featureCart), map((cartResponse: CheckoutModel) => {
      if (cartResponse) {
        return cartResponse.order.token;
      } else {
        debug('cart store is empty');
      }
    }));
  }

  public getPurchasedProducts$(): Observable<PurchasedProducts[]> {
    this.store.dispatch(fromActions.getOrdersByUserId());
    return this.store.pipe(select(fromFeature.featurePurchasedProducts),
      map((purchasedProductResponse: PurchasedProducts[]) => {
        if (purchasedProductResponse) {
          return purchasedProductResponse;
        } else {

        }

      })
    );
  }

  public getReceiptUrlOfORder$(): Observable<string> {
    return this.store.pipe(select(fromFeature.featureCart), map((cartResponse: CheckoutModel) => {
      if (cartResponse) {
        return cartResponse.order.receiptURL;
      } else {
        debug('cart store is empty');
      }
    }));
  }

  public getOrderByReceiptUrl$(receipturl: string): Observable<OrderConfirmationModel> {
    this.store.dispatch(fromActions.getOrderByReceiptUrl({ receiptURL: receipturl }));
    return this.store.pipe(select(fromFeature.featureOrder),
      map((order: OrderConfirmationModel) => {
        return order;
      })
    );
  }
  public getError$(): Observable<any> {
    return this.store.pipe(select(fromFeature.featureError),
      map((error: any) => {
        return error;
      })
    );
  }

}
