import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as fromActions from './app.state.actions';

export const appStateFeatureKey = 'modesoAppState';

export interface FeatureState {
    protocol: string
    device: 'ios' | 'android' | ""
}

export interface AppState {
    modesoAppState: FeatureState;
}

export const selectFeature = (state: AppState) => state.modesoAppState;
export const selectProtcol = createSelector(
    selectFeature,
    (state: FeatureState) => state
);

export const initialState: FeatureState = {
    protocol: "",
    device: "",
};

const appStateReducer = createReducer(
    initialState,
    on(fromActions.updateStoreProtocol, (state, action) => ({ ...state,  protocol: action.payload.protocol, device:action.payload.device   })),
    on(fromActions.updateStoreProtocolSuccess, (state) => ({ ...state })),
    on(fromActions.updateStoreProtocolFail, (state) => ({ ...state })),
    on(fromActions.getProtocol, (state) => ({ ...state })),
    on(fromActions.getProtocolSuccess, (state, action) => ({ ...state,  protocol: action.payload.protocol, device:action.payload.device    })),
    on(fromActions.getProtocolFail, (state) => ({ ...state })),

);

export function reducer(state: FeatureState | undefined, action: Action) {        
    return appStateReducer(state, action);
}
