import { DGoodsNavigationComponent, IDGoodsNavigationDelegate } from '@modeso/twint-lib-core-fe';
import { OnInit, Directive } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
import { IIHubInsuranceItemDelegate } from '@modeso/ihub-lib-insurance-fe';
import { IInsuranceItem, InsuranceProvider, ITransactionItem } from '@modeso/ihub-lib-insurance-fe';
// tslint:disable-next-line: max-line-length
import { IActivateInsuranceRequest, IInsuranceOperationResponse, IInsuranceProduct, InsuranceStatusEnum } from '@modeso/types__ihub-lib-insurance-be';
import { ShopUtil } from '../../utils/shopUtil';
import { UserProvider } from '@modeso/ihub-lib-user-fe';
import moment from 'moment';
const debug = Debug('dgoods:project:PurchasingHistoryPageController');

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'app-purchasing-history-page-controller'
})
export class PurchasingHistoryPageControllerDirective extends BasePageComponent implements
  OnInit,
  IIHubInsuranceItemDelegate,
  IDGoodsNavigationDelegate {
  terminalId: string;
  public insuarances: IInsuranceOperationResponse[];
  public activeInsurances: IInsuranceItem[];
  public inActiveInsurances: IInsuranceItem[];
  public cancelledInsurances: IInsuranceItem[];
  public transactions: ITransactionItem[];
  public loading = true;
  isSuperCashbackVisible: any;
  waitingForAppResponse = false;
  alreadySwitchedToApp = false;
  waitingForAppTimer: any;
  timeoutWait = 180;
  orderUuid;
  isSinglePaymentProduct;
  waitingForUofResponse = false;


  pendingInsurances: { productTitle: string; icon: string; description: string; orderUuid: string; }[];
  // tslint:disable-next-line: max-line-length
  constructor(private analyticsProvider: AnalyticsProvider, private insuranceProvider: InsuranceProvider, private userProvider: UserProvider , injector: Injector, protected router: Router) {
    super(injector);
    this.terminalId = sessionStorage.getItem('terminalId');
    this.insuarances = [];
  }
  ngOnInit() {
    this.getUserInsurances();
    super.ngOnInit();
    this.savePageView();

    this.subscriptions.push(
      this.insuranceProvider.getPaymentToken$().subscribe(this.onRequestPayment.bind(this)),
      this.userProvider.getUofRegistartionToken$().subscribe(this.onUoFResponse.bind(this))
    );

  }

  savePageView() {
    this.analyticsProvider.savePageView$({ productLine: null, pageName: 'Purchasing History', terminalId: this.terminalId, pagePath: this.router.url, });
  }
  onInsuranceItemClicked(orderUuid: string): void {
    this.router.navigateByUrl(`${this.languageFromURL}/my-insurance-detail/${orderUuid}`);
  }

  onBackClicked(sender: DGoodsNavigationComponent): void {
    this.router.navigate([`../../${this.languageFromURL}/landing-buy`]);
  }

  getUserInsurances() {
    this.loading = true;
    this.insuranceProvider.fetchAllMyPurchasedInsurances$();
    this.subscriptions.push(
      this.insuranceProvider.getPurchasedInsurances$()
        .subscribe((purchasedInsurance: IInsuranceOperationResponse[]) => {
          const singlePaymentProducts = [];
          if (purchasedInsurance) {
            this.insuarances = purchasedInsurance;
            const allProducts = JSON.parse(localStorage.getItem('products')) as IInsuranceProduct[];
            const productsData: { [product: string]: IInsuranceProduct } = {};
            allProducts.forEach(element => {
              productsData[element.product] = element;
              if (element.isSinglePayment) {
                singlePaymentProducts.push(element.product);
              }
            });
            this.loading = false;
            this.transactions = this.getAllPaymentsLogs(purchasedInsurance, singlePaymentProducts)
              .filter(insurance => insurance.amount > 0);
            this.activeInsurances = this.filterData(purchasedInsurance, (item) =>
              (item.insuranceStatus === InsuranceStatusEnum.ACTIVE || item.insuranceStatus === InsuranceStatusEnum.WAITFORUOF) &&
              !(item.endDate && new Date(item.endDate) < new Date()))
            .map((activeInsurance) => {
              return {
                ...this.prepareInsuranceViewModel(activeInsurance, productsData[activeInsurance.product])
              };
            });
            this.pendingInsurances = this.filterData(purchasedInsurance, (item) => item.insuranceStatus === InsuranceStatusEnum.PENDING)
            .map((pendingInsurances) => {
              return {
                ...this.prepareInsuranceViewModel(pendingInsurances, productsData[pendingInsurances.product])
              };
            });
            this.inActiveInsurances = this.filterData(purchasedInsurance, (item) => (
              // tslint:disable-next-line: max-line-length
              (item.endDate && new Date(item.endDate) < new Date()) || item.insuranceStatus === InsuranceStatusEnum.EXPIRED)).map((inActiveInsurance) => {
              return {
                ...this.prepareInsuranceViewModel(inActiveInsurance, productsData[inActiveInsurance.product])
              };
            });
            this.cancelledInsurances = this.filterData(purchasedInsurance,
              (item) => item.insuranceStatus === InsuranceStatusEnum.CANCELLED && new Date(item.endDate) > new Date())
              .map((cancelledInsurnace) => {
              return {
                ...this.prepareInsuranceViewModel(cancelledInsurnace, productsData[cancelledInsurnace.product])
              };
            });
          }
        })
    );
  }

  private getAllPaymentsLogs(insurances: IInsuranceOperationResponse[], singlePaymentProducts: any): ITransactionItem[] {
    let paymentLogs: ITransactionItem[] = [];

    insurances.forEach((insurance) => {
      if (insurance.payments && insurance.payments.length) {
        const isSinglePayment = singlePaymentProducts.includes(insurance.product);

        const latestReservationTimePayment =  [...insurance.payments].sort((a, b) => {
          return new Date(b.reservationTimestamp).valueOf() - new Date(a.reservationTimestamp).valueOf();
        })[0];

        const paymentsOfInsurance = insurance.payments.map((payment) => {
          return {
            amount: payment.amount,
            reservationTimestamp: payment.reservationTimestamp,
            productTitle: insurance.productTitle,
            response: payment.response,
            insuranceStatus: insurance.insuranceStatus,
            isSinglePayment,
            isLastReservationTime: payment.transcationReference === latestReservationTimePayment.transcationReference ? true : false,
            orderUuid: insurance.orderUuid,
            paymentStatus: insurance.paymentStatus
          } as ITransactionItem;
        });
        paymentLogs = [...paymentLogs, ...paymentsOfInsurance];
        paymentLogs = paymentLogs.filter((log) => log.response !== 'NOT_SENT');
      }
    });
    // tslint:disable-next-line: only-arrow-functions
    paymentLogs.sort(function(a, b) {
      return new Date(b.reservationTimestamp).valueOf() - new Date(a.reservationTimestamp).valueOf();
    });
    return paymentLogs;
  }

  private prepareInsuranceViewModel(insurance: IInsuranceOperationResponse, product: IInsuranceProduct): IInsuranceItem {
    return {
      productTitle: insurance.productTitle,
      icon: product && product.icon ? product.icon : 'assets/ihub/icn_my_insurances.svg',
      description: product && product.shortDescription ? product.shortDescription : '',
      orderUuid: insurance.orderUuid,
      validFrom: moment(insurance.validFrom).format('DD.MM.YYYY'),
      endDate: insurance.endDate ? moment(insurance.endDate).format('DD.MM.YYYY') : null
    } as IInsuranceItem;
  }

  private filterData(purchasedInsurance: IInsuranceOperationResponse[], func: any): IInsuranceOperationResponse[] {
    return  purchasedInsurance.filter(func)
    .sort((a, b) => {
      return new Date(b.validFrom).valueOf() - new Date(a.validFrom).valueOf();
    });
  }

  public async confirmPayment(event) {
    if (event.event) {
      if (event.isSinglePayment) {
        this.orderUuid = event.orderUuid;
        this.isSinglePaymentProduct = event.isSinglePayment;
        const request: IActivateInsuranceRequest = {
          orderUuid: this.orderUuid,
        };
        debug('single payment request:', request);
        this.insuranceProvider.dispatchSinglePaymentInsurance(request);
      } else {
        this.userProvider.startUofRegistration({
          product: '',
          orderUuid: '',
          terminalId: sessionStorage.getItem('terminalId')
        });
        this.subscriptions.push(
          this.userProvider.getUofRegistartionToken$().subscribe(this.onUoFResponse.bind(this))
        );
      }
    }
  }

  switchToApp(registrationToken: any) {
    this.alreadySwitchedToApp = true;
    this.waitingForAppTimer = setInterval(() => {
      debug('checking for update on current insurance');
      if (--this.timeoutWait >= 0) {
        this.insuranceProvider.updateCurrentProductTheUserWantsToBuy$(this.orderUuid);
      } else {
        debug('checking for update on current insurance - timeout');
        this.router.navigate(['../../de-ch/paymentcanceld/' + this.orderUuid]);
      }
    }, 1000);

    new ShopUtil().switchToApp(registrationToken, this.isSinglePaymentProduct);
  }

  onRequestPayment(token: string) {
    debug('onRequestPayment', token);
    if (token && this.isSinglePaymentProduct) {
      this.waitingForAppResponse = false;
      this.switchToApp(token);
    }
    return true;
  }

  onUoFResponse(token: string) {
    this.waitingForUofResponse = true;
    debug('UofResponse', token);
    if (token) {
      new ShopUtil().switchToApp(token);
      this.waitingForUofResponse = false;
    }
    return true;
  }

}
