import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { CustomInputDate, IHubPopUpComponent, IIHubPopupContent } from '@modeso/twint-lib-core-fe';
import { IConfiguredAttribute } from '@modeso/types__ihub-lib-products-be';
import { AbstractControlWithHints } from '../../../../utils/Controls/ControlWithHints.interface';
import { ControlHandlerName } from '../../../../utils/enums/controlHandlerName.enum';
import { ControlsHandlerFactory } from '../../../../utils/FormHandlers/ControlsHandlerFactory';
import { IInsuranceAttributeControl } from '../../ComponentFactory/IInputControl.interface';
import { BaseControl } from '../Base';

@Component({
  selector: 'ihub-date-input-control',
  templateUrl: './date-input-control.component.html',
  styleUrls: ['./date-input-control.component.scss']
})
export class DateInputControlComponent extends BaseControl implements OnInit, IInsuranceAttributeControl {
  @ViewChild('popup', { static: true }) popup: IHubPopUpComponent;
  private controlsHandlerFactory: ControlsHandlerFactory;
  popupContent: IIHubPopupContent;
  insuranceAttribute: IConfiguredAttribute;
  form: FormGroup;
  validationErrors: any;
  constructor() {
    super();
    this.controlsHandlerFactory =  ControlsHandlerFactory.getInstance();
  }

  ngOnInit() {
    if (this.insuranceAttribute.value) {
      this.form.get(this.insuranceAttribute.id)
      .setValue(this.getCustomInput(this.insuranceAttribute.value));
    }
  }

  public openPopup(event: any) {
    event.stopPropagation();
    this.popupContent = {
      title: (this.insuranceAttribute.title as string),
      text: (this.insuranceAttribute.text as string),
      needsTranslation: false,
    };
    this.popup.visible = true;
  }

  onChange($event: any) {
    const handler = this.controlsHandlerFactory.getObject(`${this.insuranceAttribute.id}Handler`);
    if (!handler) {
      return;
    } else if (`${this.insuranceAttribute.id}Handler` === ControlHandlerName.RENTAL_CAR_START_DATE_HANDLER) {
      handler.onKeyUp({startDate: this.form.getRawValue().startDate});
    } else if (`${this.insuranceAttribute.id}Handler` === ControlHandlerName.RENTAL_CAR_END_DATE_HANDLER) {
      handler.onKeyUp({endDate: this.form.getRawValue().endDate});
    } else {
      handler.onChange($event);

    }
  }
  private getCustomInput(date: string) {
    const [day, month, year] = date.split('.');
    return new CustomInputDate(day, month, year);
  }
}
