import { Component, OnInit } from '@angular/core';
import Debug from 'debug';
import { Location } from '@angular/common';


const debug = Debug('dgoods:project:FAQPage');

@Component({
  selector: 'app-faq',
  templateUrl: './faq.page.html',
  styleUrls: ['./faq.page.scss']
})
export class FAQPage implements OnInit {

  questionsLocalizationKeys: {index: string, titleKey: string, answerKey: string}[] = [];
  keyIndexSize = 3;
  numberOfQuestions = 30;

  constructor(private location: Location) { }

  addLeadingZeros(num: number, size: number): string {
    let str = num + "";
    while (str.length < size) str = "0" + str;
    return str;
  }

  ngOnInit() {
    for(let index = 1; index <= this.numberOfQuestions; index++){
      let keyIndex = this.addLeadingZeros(index, this.keyIndexSize);
      this.questionsLocalizationKeys.push({
        index: keyIndex,
        titleKey: 'dgoods_faq_q_' + keyIndex + '_title',
        answerKey: 'dgoods_faq_q_' + keyIndex+ '_answer'
      })
    }
  }

  onButtonClicked() {
    debug('Button clicked');
    this.location.back();
  }
}
