import { Pipe, PipeTransform } from '@angular/core';
import * as fromReducers from './../reducers/localization.reducer';
import { LocalizationModel } from './../models/localization.model';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import Debug from 'debug';
const debug = Debug('modeso:modeso-localization:LocalizePipe');

@Pipe({ name: 'localize' })
export class LocalizePipe implements PipeTransform {
  private debugModeIsActive = false;
  private domParser;
  constructor(private store: Store<fromReducers.AppState>, private sanitizer: DomSanitizer) {
    debug('LocalizePipe constructed');
    this.domParser = new DOMParser();
    this.store.pipe(select(fromReducers.selectIsDebugModeActivated)).subscribe((activ) => {
      this.debugModeIsActive = activ;
    });
  }

  transform(key: string): Observable<string | SafeHtml> {

    return this.store.pipe(
      select(fromReducers.selectEntitiesByID, { id: key }),
      map((localization: LocalizationModel) => {
        if (this.debugModeIsActive) {
          return key;
        }
        if (localization) {
          return this.domParser.parseFromString(localization.text, 'text/html').body.innerText;
        } else {
          // TODO this must return the undefined
          // key in case we are in development mode.
          // TODO In production it should always
          // return "" if the key is not defined.
          // or return a default value if possible
          // return 'UNDEFINED KEY: ' + key;
          return undefined;
        }
      }),
      filter((text: string | SafeHtml) => text !== undefined));
  }
}
