import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { IHubPopUpComponent, IIHubPopupContent } from '@modeso/twint-lib-core-fe';
import { IConfiguredAttribute } from '@modeso/types__ihub-lib-products-be';
import { IInsuranceAttributeControl } from '../../ComponentFactory/IInputControl.interface';
import { BaseControl } from '../Base';
import { FormControlWithHints } from '../../../../utils/Controls/FormControlWithHints';
import { ValidatorType } from '../../../../utils/enums/validatorType.enum';
import { DynamicFormUtils } from '../../../../utils/Controls/dynamicFormUtils';
import { CustomValidation } from '../../../../utils/Controls/customValidators.validator';
import { CheckboxService } from '../services/checkbox-data-handler';

@Component({
  selector: 'ihub-people-details-control',
  templateUrl: './people-details-control.component.html',
  styleUrls: ['./people-details-control.component.scss'],
})
export class PeopleDetailsControlComponent extends BaseControl implements OnInit, IInsuranceAttributeControl {
  @ViewChild('popup', { static: true }) popup: IHubPopUpComponent;
  popupContent: IIHubPopupContent;
  insuranceAttribute: IConfiguredAttribute;
  form: FormGroup;
  validationErrors: any;
  isPersonEntered = false;
  selectionMenu = [];
  customValidators: CustomValidation;
  isChecked = false;


  constructor(private data: CheckboxService) {
      super();
      this.customValidators = new CustomValidation();
  }

  ngOnInit() {
    for (const [key, value] of Object.entries(this.insuranceAttribute.enum)) {
      if (value.useValue) {
        this.selectionMenu.push({
          id: value.value,
          value: value.value,
        });
      } else {
        this.selectionMenu.push({
          id: value.language,
          value: value.value,
        });
      }
    }

    if (this.insuranceAttribute.value) {
      // add checkbox to form control with saved value
      this.form.addControl(this.insuranceAttribute.embeddedAdditionalAttributes[0].id,
      new FormControl(this.insuranceAttribute.embeddedAdditionalAttributes[0].value));
      this.isChecked = Boolean(this.insuranceAttribute.embeddedAdditionalAttributes[0].value);
      this.initiatePersonalDetailsForm();
    } else {
      // add checkbox to form control with default value
      this.form.addControl(this.insuranceAttribute.embeddedAdditionalAttributes[0].id,
      new FormControl(this.insuranceAttribute.embeddedAdditionalAttributes[0].preDefinedValue));
      this.isChecked = this.insuranceAttribute.embeddedAdditionalAttributes[0].preDefinedValue;
      this.initiatePersonalDetailsForm();
    }
  }

  public openPopup(event: any) {
    event.stopPropagation();
    this.popupContent = {
      title: this.insuranceAttribute.title as string,
      text: this.insuranceAttribute.text as string,
      needsTranslation: false,
    };
    this.popup.visible = true;
  }

  public onChange() {
    if (this.insuranceAttribute.formArrayAttributes) {
      this.insuranceAttribute.formArrayAttributes = [];
      if (this.insuranceAttribute.formArrayFields[0] && this.insuranceAttribute.formArrayFields[0].length > 0) {
        this.insuranceAttribute.formArrayFields = this.insuranceAttribute.formArrayFields[0].map((att) => {
          return delete att.value, {...att} ;
        });
      }
    }
    this.initiatePersonalDetailsForm();
  }

  get personDetails() {
    return this.form.controls[this.insuranceAttribute.formArrayId] as FormArray;
  }

  initForm(index?) {
    const group = {};
    let isCustomValidator = false;
    const customFields = [];
    let customValidationFnName: string;
    // tslint:disable-next-line: max-line-length
    const attributes = index || index === 0 ? this.insuranceAttribute.formArrayFields[index] : this.insuranceAttribute.formArrayFields;

    for (const attribute of attributes) {
      const formValue =  attribute.value ? attribute.value : '';

      if (attribute.hasFormArray) {
        group[attribute.formArrayId] = new FormArray([]);
      }
      if (attribute.validation) {
        const validators = new DynamicFormUtils().getValidators(attribute.validation);

        group[attribute.id] = new FormControlWithHints( formValue, validators.validationArray );
        if (attribute.validation[ValidatorType.CustomForm]) {
          isCustomValidator = true;
          customFields.push(attribute.id);
          customValidationFnName =
            attribute.validation[ValidatorType.CustomForm].value;
        }
      } else {
        group[attribute.id] = new FormControlWithHints(formValue);
      }
    }
    if (isCustomValidator) {
      return new FormGroup(group, {
        validators: this.customValidators[customValidationFnName](customFields),
      });
    } else {
      return new FormGroup(group);
    }
  }

  private initiatePersonalDetailsForm() {

    let dynamicForm;
    // tslint:disable-next-line: radix
    const noOfDrivers =  this.isChecked ? parseInt(this.form.get(this.insuranceAttribute.id).value) - 1 :
                         this.form.get(this.insuranceAttribute.id).value;

    this.isPersonEntered = true;

    for (let driver = 0; driver < noOfDrivers; driver++) {
      // in edit case
      if (this.insuranceAttribute.formArrayAttributes && this.insuranceAttribute.formArrayAttributes.length > 0) {
        dynamicForm = this.initForm(
          driver
        );
        this.personDetails.push(dynamicForm);
      }
    }
    // case if increase no of people
    if (this.personDetails.value.length < noOfDrivers) {
      for (let i = this.personDetails.value.length; i < noOfDrivers; i++ ) {
        dynamicForm = this.initForm();
        this.personDetails.push(dynamicForm);
      }
    }  // case if decrease no of people
    else {
      for (let i = this.personDetails.value.length - 1; i >= noOfDrivers; i--) {
        this.personDetails.removeAt(i);
      }
    }
  }

  pushToForm(data: any, index: string | number) {
    this.personDetails.controls[index].setValue(data);
  }

  onChangeCheckBox(event) {
    this.isChecked = event.checked;
    this.onChange();
  }

}
