import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProductsService } from '../services/products.service';
import * as productsActions from './../actions/products.actions';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import Debug from 'debug';
const debug = Debug('modeso:modeso-products:ProductsEffects');


@Injectable()
export class ProductsEffects {
  constructor(private actions$: Actions, private service: ProductsService) {}

  loadAllProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.getAllProducts.type),
      mergeMap(() => {
        return this.service.getAllProducts().pipe(
          retry(1),
          tap((response) => debug(response)),
          map((productsResponse) =>
            productsActions.onAllProductsLoadedSuccessfully({
              payload: productsResponse.products,
            })
          ),
          // tslint:disable-next-line:object-literal-shorthand
          catchError((error) =>
            of(productsActions.onAllProductsLoadingFailed({ payload: error }))
          )
        );
      })
    )
  );

  errorOnLoadAllProducts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productsActions.onAllProductsLoadingFailed.type),
        tap((action: productsActions.ActionWithPayload<any>) =>
          this.handleOnLoadAllProductsErrors(action.payload)
        )
      ),
    { dispatch: false }
  );

  getFeatureProductByIssuer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.onGetFeatureProductByIssuer.type),
      mergeMap((action: productsActions.ActionWithPayload<string>) => {
        debug("Effect is Dispatched");
        return this.service.getFeatureProductByIssuer(action.payload).pipe(
          map(
            (response) => {
              return productsActions.onGetFeatureProductByIssuerSuccessfully({
                payload: response,
              });
            },
            // tslint:disable-next-line:object-literal-shorthand
            catchError((error) =>
              of(
                productsActions.onGetFeatureProductByIssuerFailed({
                  payload: error,
                })
              )
            )
          )
        );
      })
    )
  );

  handleOnLoadAllProductsErrors(error) {
    debug(error);
    return error;
  }
}
