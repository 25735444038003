/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./portrait-mode.page.view.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@modeso/modeso-lib-localization-fe";
import * as i3 from "@ngrx/store";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./portrait-mode.page.view";
var styles_PortraitModePage = [i0.styles];
var RenderType_PortraitModePage = i1.ɵcrt({ encapsulation: 0, styles: styles_PortraitModePage, data: {} });
export { RenderType_PortraitModePage as RenderType_PortraitModePage };
export function View_PortraitModePage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.ɵa, [i3.Store, i4.DomSanitizer]), i1.ɵqud(402653184, 1, { rootEl: 0 }), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["root", 1]], null, 6, "div", [["class", "portrait-mode-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "portrait-mode-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["src", "assets/landscape-mode.svg"], ["style", "padding-bottom:23px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵppd(7, 1), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 8).transform(i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), "dgoods_shop_default_portrait_mode")))); _ck(_v, 6, 0, currVal_0); }); }
export function View_PortraitModePage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-portrait-mode", [], null, null, null, View_PortraitModePage_0, RenderType_PortraitModePage)), i1.ɵdid(1, 245760, null, 0, i6.PortraitModePage, [i1.Injector, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PortraitModePageNgFactory = i1.ɵccf("app-page-portrait-mode", i6.PortraitModePage, View_PortraitModePage_Host_0, {}, {}, []);
export { PortraitModePageNgFactory as PortraitModePageNgFactory };
