import { Component, OnInit, Injector, Renderer2 } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  DGoodsPriceOptionModel
} from '@modeso/twint-lib-core-fe';
import Debug from 'debug';
import { CartProvider } from '@modeso/twint-lib-cart-fe';
import { ProductStoreProvider } from '@modeso/ihub-lib-products-fe';
import { ProductBuyPageControllerDirective } from './product-buy.page.controller';
import { Router, ActivatedRoute } from '@angular/router';
import { DgoodsSessionStoreProvider } from '@modeso/twint-lib-session-fe';
import { Store } from '@ngrx/store';
import * as fromApp from './../../state/app.state.reducer';
import { UserProvider } from '@modeso/ihub-lib-user-fe';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
import { IInsuranceProduct } from '@modeso/types__ihub-lib-insurance-be';
const debug = Debug('dgoods:shared:ProductBuyPage');

@Component({
  selector: 'app-buy-page',
  templateUrl: './product-buy.page.view.html',
  styleUrls: ['./product-buy.page.view.scss']
})
export class ProductBuyPage extends ProductBuyPageControllerDirective implements OnInit {
  public provider;
  public prices = [];
  public hasDenomination = false;
  public subscriptions = [];
  productLineName: string;
  checkBoxSelected: boolean;
  // public appSwithOverlayDelegate = new AppSwithOverlayDelegate();

  public yourName = 'dgoods_shared_inputcomponent_yourname';
  public yourMessage = 'dgoods_shared_inputcomponent_yourmsg';
  public caption = 'dgoods_shared_inputcomponent_emailrecipient';
  constructor(
    analyticsProvider: AnalyticsProvider,
    router: Router,
    route: ActivatedRoute,
    sessionProvider: DgoodsSessionStoreProvider,
    productProvider: ProductStoreProvider,
    cartProvider: CartProvider,
    store: Store<fromApp.AppState>,
    injector: Injector,
    userProvider: UserProvider,
    renderer: Renderer2
  ) {
    super(analyticsProvider, router, route, cartProvider, productProvider, sessionProvider, userProvider, store, injector, renderer);
    this.checkBoxSelected = sessionStorage.getItem('isP2P') === 'true' ? true : false;
  }

  /** OnInit --- START */

  ngOnInit() {
    super.ngOnInit();
    this.getProduct();
  }
  /** OnInit --- END */

  /**
   * getAgbLink
   */
   public getAgbLink() {
    const browserLanguage = navigator.language;
    let agbLanguage = '';
    if (browserLanguage.includes('de')) {
      agbLanguage = 'de';
    } else if (browserLanguage.includes('fr')) {
      agbLanguage = 'fr';
    } else if (browserLanguage.includes('it')) {
      agbLanguage = 'it';
    } else {
      agbLanguage = 'en';
    }
    return `assets/pdf/ihub-agb_${agbLanguage}.pdf`;
  }

  protected getProduct() {
    debug('subscribe to product observable');
    this.subscriptions.push(this.observables.get('getProductByProductLineIdeFromStore').pipe(
      map((product) => {
        if (product) {
          (product.variations).forEach(element => {
            debug(product.singlePrices);
            this.prices.push(new DGoodsPriceOptionModel(element.name, product.singlePrices[element.name] as number, 'CHF', false));
          });
        }
        if (!product) {
          this.router.navigate(['../../de-ch/error']);
        }
        return product;
      })
    ).subscribe((product) => {
      this.productLineName = product ? product.title : null;
      this.provider = product;
    }));
  }

}
