import { Injectable } from '@angular/core';
import * as fromActions from './../actions/dgoods.user.actions';
import * as fromFeature from './../reducers/dgoods.user.reducer';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUpdateUserRequest, IUser } from '../models/user.model';
import { ICreatePersonRequest, IUserStartUoFRegistrationRequest } from '@modeso/types__ihub-lib-user-be';


@Injectable({
  providedIn: 'root'
})
export class UserProvider {

  constructor(
    private store: Store<fromFeature.AppState>) { }


  public updateUser$(body: IUpdateUserRequest): Observable<IUpdateUserRequest> {
    this.store.dispatch(fromActions.onUpdateUser({ payload: body }));
    return this.store.pipe(select(fromFeature.featureUser),
      map((user: fromFeature.FeatureState) => {
        return user;
      })
    );
  }

  public startUofRegistration(body: IUserStartUoFRegistrationRequest) {
    this.store.dispatch(fromActions.onStartUofRegistration({ payload: body }));
  }

  public uofDeRegistration() {
    this.store.dispatch(fromActions.onUofDeRegistration());
  }

  public getUofRegistartionToken$(): Observable<string> {
    return this.store.pipe(select(fromFeature.featureUofRegistrationToken));
  }

  public getEmail$(): Observable<IUpdateUserRequest> {
    return this.store.pipe(select(fromFeature.featureUser),
      map((body: IUpdateUserRequest) => {
        return body;
      })
    );
  }

  public createPerson$(body: ICreatePersonRequest): Observable<string> {
    this.store.dispatch(fromActions.onCreatePerson({ payload: body }));
    return this.store.pipe(select(fromFeature.featurePersonId),
      map((id: string) => {
        return id;
      })
    );
  }

  public updatePerson$(body: ICreatePersonRequest): Observable<string> {
    this.store.dispatch(fromActions.onUpdatePerson({ payload: body }));
    return this.store.pipe(select(fromFeature.featurePersonId),
      map((id: string) => {
        return id;
      })
    );
  }

  public getPersonById$(id: string): Observable<ICreatePersonRequest> {
    this.store.dispatch(fromActions.getPersonById({ payload: id }));
    return this.store.pipe(select(fromFeature.featurePerson),
      map((person: ICreatePersonRequest) => {
        return person;
      })
    );
  }

  public getPersonOfUser$(): Observable<ICreatePersonRequest> {
    this.store.dispatch(fromActions.getPersonOfUser());
    return this.store.pipe(select(fromFeature.featurePerson),
      map((person: ICreatePersonRequest) => {
        return person;
      })
    );
  }

  public getUser$(): Observable<IUser> {
    this.store.dispatch(fromActions.getUserDetails());
    return this.store.pipe(select(fromFeature.featureUofStatus),
      map((user: IUser) => {
        return user;
      })
    );
  }

}
