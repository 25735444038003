import { OnInit, Component, Injector, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { LocalizationStoreProvider } from '@modeso/modeso-lib-localization-fe';
import { InjectedTextProvider } from '@modeso/modeso-lib-core-fe';
import { DGoodsSharedProvider } from '@modeso/twint-lib-core-fe';
import Debug from 'debug';
const debug = Debug('dgoods:shop:BasePageComponent');

@Component({
  selector: 'base-add',
  template: `<ng-content></ng-content>`
})
export class BasePageComponent implements OnInit, OnDestroy {

  private activatedRoute: ActivatedRoute;
  protected subscriptions: any[] = [];
  private localizationStoreProvider: LocalizationStoreProvider;
  private sharedProvider: DGoodsSharedProvider;
  private injectedTextProvider: InjectedTextProvider;
  public languageFromURL: any;

  constructor(injector: Injector) {
    this.activatedRoute = injector.get(ActivatedRoute);
    this.localizationStoreProvider = injector.get(LocalizationStoreProvider);
    this.injectedTextProvider = injector.get(InjectedTextProvider);
    this.sharedProvider = injector.get(DGoodsSharedProvider);

    debug(this.sharedProvider);
    debug("load providers");
    debug(this.injectedTextProvider);
    this.localizationStoreProvider.addObserver("modeso_core", this.injectedTextProvider);
    this.languageFromURL = this.activatedRoute.snapshot.params.language;
    localStorage.setItem('currentLanguage', this.languageFromURL);
    // load link like that: textDebugMode=active
    // http://localhost:4200/de-ch/product-buy/6SQMM367TW1F3A2G4BJ7AGH8H9?textDebugMode=active
    const txtDebugMode = this.activatedRoute.snapshot.queryParamMap.get('textDebugMode');
    if (txtDebugMode == "active") {
      this.localizationStoreProvider.activateDebugMode(true);
      this.injectedTextProvider.activateDebugMode(true);
    }
  }

  ngOnInit(): void {
    this.setDebuggingMode();
    debug("load localization")
    this.localizationStoreProvider.loadLocalizations();
  }

  setDebuggingMode() {
    localStorage.debug = environment.enableDebugging;
  }

  isLanguageAvaliable(LanguageFromURL): boolean {
    return environment.availableLanguages.find(lang => lang === LanguageFromURL) ? true : false;
  }

  getIssuer() {
    return localStorage.getItem('protocol');
  }

  ngOnDestroy(): void {
    debug("ngOnDestroy", this);

    this.subscriptions.forEach(subscribe => {
      subscribe.unsubscribe();
    });
  }
}
