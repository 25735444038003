import {
  DGoodsButtonComponent, IDGoodsTableDelegate,
  IDGoodsButtonDelegate, DGoodsNavigationComponent
} from '@modeso/twint-lib-core-fe';
import { Location } from '@angular/common';
import { Injector, OnInit, Directive } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from './../../state/app.state.reducer';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
import { ShopUtil } from '../../utils/shopUtil';
const debug = Debug('dgoods:shop:OrderConfirmationController');

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'app-order-confirmation.page-controller'
})
export class OrderConfirmationControllerDirective extends BasePageComponent implements
  OnInit,
  IDGoodsButtonDelegate {

  public showNavigationBar = !this.route.snapshot.queryParams['navigation'] ? true : false;
  terminalId: string;

  constructor(private analyticsProvider: AnalyticsProvider, private location: Location, private injector: Injector, private router: Router,
    private route: ActivatedRoute, private store: Store<fromApp.AppState>) {
    super(injector);
    this.terminalId = sessionStorage.getItem('terminalId');
  }

  ngOnInit() {
    super.ngOnInit();
    this.savePageView();
  }

  savePageView() {
    this.analyticsProvider.savePageView$({ productLine: null, pageName: 'Order Confirmation', terminalId: this.terminalId, pagePath: this.router.url, });
  }

  onButtonClicked(sender: DGoodsButtonComponent): void {

    const backToPurchasingHistory = this.route.snapshot.queryParams['navigation'];
    if (!backToPurchasingHistory) {
      new ShopUtil().switchToAppAndCloseWindow();
    } else {
      this.router.navigate(['../../de-ch/history']);
    }

  }

  onFailedStatus() {
    this.router.navigate(['../../de-ch/paymentcanceld']);
  }


  /** IDGoodsNavigationDelegate --- START */

  onBackClicked(sender: DGoodsNavigationComponent) {
    this.router.navigate(['../../de-ch/history']);
  }

  /** IDGoodsNavigationDelegate --- END */
}
