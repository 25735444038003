import { createAction, Action, props } from '@ngrx/store';
import { ICreateDamageRequest } from "@modeso/types__ihub-lib-damage-be";


export const onCreateDamageReport = createAction(
  '[IHub create damage] create damage report',
  props<{ payload: any }>()
);

export const onCreateDamageReportSuccess = createAction(
  '[IHub create damage successfully] create damage report successfully',
  props<{ payload:string }>()
);

export const onCreateDamageReportFailed = createAction(
  '[IHub create damage failed] create damage report failed',
  props<{ payload: any }>()
);

export const getDamageByInsuranceId = createAction(
  '[IHub Get Damage] get damage reports by insurance id',
  props<{ payload: string }>()
);

export const onGetDamageByInsuranceIdSuccessfully = createAction(
  '[IHub Get Damage] get damage reports by insurance id loaded successfully',
  props<{ payload: ICreateDamageRequest }>()
);

export const onGetDamageByInsuranceIdFailed = createAction(
  '[IHub Get Damage] get damage reports by insurance id loading failed',
  props<{ payload: any }>()
);

export interface IActionWithPayload<T> extends Action {
  payload: T;
}
