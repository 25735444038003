<div class="payment-alert">
    <div class="page-padding">
      <h3>{{"ihub_paymentalert_title" | localize | async}}</h3>
      <div>{{"ihub_paymentalert_intro" | localize | async}}</div>
      <h4>{{"ihub_paymentalert_reason1_title" | localize | async}}</h4>
      <div>{{"ihub_paymentalert_reason1" | localize | async}}</div>
      <!-- <h4>{{"ihub_paymentalert_reason2_title" | localize | async}}</h4>
      <div>{{"ihub_paymentalert_reason2" | localize | async}}</div>
      <div style="text-align: center;">
        <button [routerLink]="['../../account/edit']">
          {{"ihub_paymentalert_connect" | localize | async}}
        </button>
      </div> -->
      <h4>{{"ihub_paymentalert_reason3_title" | localize | async}}</h4>
      <div>{{"ihub_paymentalert_reason3" | localize | async}}</div>
      </div>
    <div class="sticky">
        <dgoods-button [delegate]="this">
            {{"dgoods_shared_navigationcompontent_back" | localize | async}}
        </dgoods-button>
    </div>
</div>
