import { Prices, VariationConfiguration } from "@modeso/types__ihub-lib-products-be";

export class PriceUtil {
    public static getMinimumVariationPrice(singlePrice: Prices, variationName: string) {

        const firstVariation = singlePrice[variationName];
        if (typeof firstVariation === 'number') {
          return firstVariation;
        }
        const config = singlePrice[variationName] as VariationConfiguration;

        if (config.type === 'lookupTable_To') {
          const [firstValue] = Object.values(config.params.table);
          return firstValue;
        }

        if (config.type === 'multiply') {
          return config.params.factor;
        }

        if (config.type === 'multiplyPriceByFactor') {
          return config.params.factor;
        }

        if (config.type === 'externalPriceCalculation') {
          return config.params.factor;
        }
    }
}
