/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./insurance-confirmation.page.view.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@modeso/modeso-lib-localization-fe";
import * as i3 from "@ngrx/store";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./insurance-confirmation.page.view";
import * as i7 from "@modeso/twint-lib-analytics-fe";
import * as i8 from "@modeso/ihub-lib-insurance-fe";
import * as i9 from "@angular/router";
var styles_InsuranceConfirmationPage = [i0.styles];
var RenderType_InsuranceConfirmationPage = i1.ɵcrt({ encapsulation: 0, styles: styles_InsuranceConfirmationPage, data: {} });
export { RenderType_InsuranceConfirmationPage as RenderType_InsuranceConfirmationPage };
export function View_InsuranceConfirmationPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.ɵa, [i3.Store, i4.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "msg-page-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "twint-logo"], ["src", "assets/twint_logo_q_pos_bg.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchToAppAndCloseWindow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "msg-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "msg-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "msg-logo"], ["src", "assets/ihub/icn_my_insurances.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "span", [["class", "msg-msg"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 1), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 9).transform(i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), "confirmation_msg")))); _ck(_v, 7, 0, currVal_0); }); }
export function View_InsuranceConfirmationPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-insurance-confirmation", [["class", "page"]], null, null, null, View_InsuranceConfirmationPage_0, RenderType_InsuranceConfirmationPage)), i1.ɵdid(1, 245760, null, 0, i6.InsuranceConfirmationPage, [i7.AnalyticsProvider, i5.Location, i1.Injector, i8.InsuranceProvider, i9.ActivatedRoute, i9.Router, i3.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InsuranceConfirmationPageNgFactory = i1.ɵccf("app-insurance-confirmation.page", i6.InsuranceConfirmationPage, View_InsuranceConfirmationPage_Host_0, {}, {}, []);
export { InsuranceConfirmationPageNgFactory as InsuranceConfirmationPageNgFactory };
