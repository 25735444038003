
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import Debug from 'debug';
import {
  IDGoodsTextImageInfoDelegate,
  IDGoodsPriceOptionGroupDelegate,
  IDGoodsCheckboxDelegate,
  IDGoodsButtonDelegate,
  DGoodsEmailConfirmationComponent,
  DGoodsButtonComponent,
  DGoodsPriceOptionModel,
  DGoodsOverlayComponent,
  IDGoodsNavigationDelegate,
  DGoodsNavigationComponent,
  DGoodsCheckboxComponent,
  IDGoodsEmailConfirmationDelegate,
  DGoodsEmailConfirmationValue,
  IDGoodsDialogBoxDelegate,
  DGoodsDialogBoxComponent
} from '@modeso/twint-lib-core-fe';
import { CartProvider, CartModel, CheckoutModel } from '@modeso/twint-lib-cart-fe';
import { ViewChild, Injector, Directive, Renderer2 } from '@angular/core';
import { ProductStoreProvider } from '@modeso/ihub-lib-products-fe';
import { DgoodsSessionStoreProvider } from '@modeso/twint-lib-session-fe';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromApp from './../../state/app.state.reducer';
import { IAppStateProtocolModel } from '../../shared/models/appStateProtocol.model';
import { BasePageComponent } from '../base.page';
import { UserProvider } from '@modeso/ihub-lib-user-fe';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
import { ProductDTO } from '@modeso/types__ihub-lib-products-be';

const debug = Debug('dgoods:project:ProductBuyPageController');

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'app-buy-page-controller'
})
export class ProductBuyPageControllerDirective extends BasePageComponent implements
  IDGoodsTextImageInfoDelegate,
  IDGoodsPriceOptionGroupDelegate,
  IDGoodsCheckboxDelegate,
  IDGoodsButtonDelegate,
  IDGoodsEmailConfirmationDelegate,
  IDGoodsNavigationDelegate,
  IDGoodsDialogBoxDelegate {

  protected productLineId;
  protected unviersalProductCode;
  protected oneTimeUrl;
  protected email;
  message;
  receiptUrl: string;
  protected valid = false;
  protected amount = 0;
  protected observables = new Map<string, Observable<ProductDTO>>();
  protected appSwitchProtocol$: Observable<IAppStateProtocolModel>;
  toDataProtectionOfTwintOverlay = true;
  toTermsOfProvider = true;
  toTermsOfTwint = true;
  userEmail;
  hiddenEmailOverlay = true;
  disabled = true;
  twintTC;
  curerntDialogBox = { title: '', description: '' };
  productLineDialogBox = {
    title: 'dgoods_shop_productbuyview_dialogbox_title_productLine_exceed_limit',
    description: 'dgoods_shop_productbuyview_dialogbox_message_productLine_exceed_limit',
  };
  shopDialogBox = {
    title: 'dgoods_shop_productbuyview_dialogbox_title_shop_exceed_limit',
    description: 'dgoods_shop_productbuyview_dialogbox_message_shop_exceed_limit',
  };
  userDialogBox = {
    title: 'dgoods_shop_productbuyview_dialogbox_title_user_exceed_limit',
    description: 'dgoods_shop_productbuyview_dialogbox_message_user_exceed_limit',
  };
  userLimitExceeded = false;
  fromLocalization = true;
  isSuperCashbackVisible: any;
  @ViewChild('emailConfirmation', { static: false }) emailConfirmation: DGoodsEmailConfirmationComponent;
  @ViewChild('errorOverlay', { static: false }) errorOverlay: DGoodsOverlayComponent;
  @ViewChild('progressOverlay', { static: false }) progressOverlay: DGoodsOverlayComponent;
  @ViewChild('appSwitch', { static: false }) appSwitch: DGoodsOverlayComponent;
  @ViewChild('checkbox1', { static: false }) checkbox1: DGoodsCheckboxComponent;
  @ViewChild('checkbox2', { static: false }) checkbox2: DGoodsCheckboxComponent;
  @ViewChild('switchToSendAsGift', { static: false }) switchToSendAsGift: DGoodsCheckboxComponent;
  @ViewChild('productDescriptionInfo', { static: false }) productDescriptionInfo: DGoodsOverlayComponent;
  @ViewChild('dialogBoxOverlay', { static: false }) dialogBoxOverlay: DGoodsDialogBoxComponent;
  constructor(
    private analyticsProvider: AnalyticsProvider,
    public router: Router,
    private route: ActivatedRoute,
    private cartProvider: CartProvider,
    private productProvider: ProductStoreProvider,
    private sessionProvider: DgoodsSessionStoreProvider,
    private userProvider: UserProvider,
    private store: Store<fromApp.AppState>,
    injector: Injector,
    private renderer: Renderer2
  ) {
    super(injector);
    this.productLineId = this.route.snapshot.paramMap.get('productLineId');
    this.getProductByProductLineIdeFromStore();
    this.savePageView('Product Buy');
    this.getUserEmail();
    this.getUserTwintTC();
  }
  onDialogBoxButtonClicked(): void {
    this.dialogBoxOverlay.hidden = true;
  }


  savePageView(pageName) {
    const productLine = JSON.parse(localStorage.getItem('products')).find(x =>
      x.product === this.productLineId);
    this.analyticsProvider.savePageView$({
      terminalId: sessionStorage.getItem('terminalId'),
      productLine: productLine.product, pageName,
      pagePath: this.router.url,
    });
  }


  onChangeEmail(value: DGoodsEmailConfirmationValue, id: string, element: DGoodsEmailConfirmationComponent): void {
    debug('Email changed ' + value.value + ' _ ' + value.isValid);
    this.valid = value.isValid;
    this.email = value.value;
    this.checkValidCheckout();
  }

  /** NgInit */
  ngOnInit() {
    super.ngOnInit();
    const location = this.router.url;
    const locationHref = window.location.href;
    debug(location);
    this.subscriptions.push(this.router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          if (!this.hiddenEmailOverlay) {
            this.hiddenEmailOverlay = true;
            this.router.navigate([location]);
            history.pushState(null, document.title, locationHref);
            debug('no navigation');
          }
          if (!this.toDataProtectionOfTwintOverlay) {
            this.toDataProtectionOfTwintOverlay = true;
            this.router.navigate([location]);
            history.pushState(null, document.title, locationHref);
            debug('no navigation');
          }
          if (!this.toTermsOfProvider) {
            this.toTermsOfProvider = true;
            this.router.navigate([location]);
            history.pushState(null, document.title, locationHref);
            debug('no navigation');
          }
          if (!this.toTermsOfTwint) {
            this.toTermsOfTwint = true;
            this.router.navigate([location]);
            history.pushState(null, document.title, locationHref);
            debug('no navigation');
          }
          if (this.appSwitch.hidden === false) {
            this.router.navigate([location]);
            history.pushState(null, document.title, locationHref);
            debug('no navigation');
          }

          // Perform actions
        }
      })
    );
  }

  /** IDGoodsOverlayDelegate --- START */

  onOverlayButtonClicked(): void {
    if (this.appSwitch.hidden === false) {
      this.appSwitch.hidden = true;
      this.router.navigate(['../../de-ch/order-confirmation/', this.receiptUrl], {});
    } else {
      this.errorOverlay.hidden = true;
    }
  }

  /** IDGoodsOverlayDelegate --- END */

  /** IDGoodsButtonDelegate --- START */

  onButtonClicked(sender: DGoodsButtonComponent): void {
    debug('Button clicked');
    if (this.productDescriptionInfo.hidden) {
      this.checkout();
    } else {
      this.productDescriptionInfo.hidden = true;
    }
  }

  /** IDGoodsButtonDelegate --- END */

  /** IDGoodsCheckboxDelegate --- START */

  // TODO: Refactor Delegate to be able to distinguish between multiple checkboxes
  onSelected(): void {
    debug('onSelected');
    if (this.switchToSendAsGift.selected) {
      this.emailConfirmation.hidden = false;
    }
    this.checkValidCheckout();
  }
  onUnselected(): void {
    debug('onUnselected');
    if (!this.switchToSendAsGift.selected) {
      this.emailConfirmation.hidden = true;
    }
    this.checkValidCheckout();
  }

  /** IDGoodsCheckboxDelegate --- END */

  /** IDGoodsPriceOptionGroupDelegate --- START */

  onPriceSelected(priceOption: DGoodsPriceOptionModel): void {
    debug('onPriceselected ');
    debug(priceOption);
    this.amount = priceOption.value;
    this.unviersalProductCode = priceOption.reference;
    this.checkValidCheckout();
  }

  onPriceUnselected(priceOption: DGoodsPriceOptionModel): void {
    debug('onPriceUnselected ');
    debug(priceOption);
    this.amount -= priceOption.value;
    this.checkValidCheckout();
  }

  checkValidCheckout() {
    // check all conditions valid for activating the checkout button after each change
    const mainValidations = this.checkbox1.selected && this.amount &&
      ((!this.checkbox2 && this.twintTC) || this.checkbox2.selected);
    if (this.switchToSendAsGift.selected) {
      const sender = this.emailConfirmation.getSender();
      this.disabled = (mainValidations && this.valid && sender && sender.length > 1) ? false : true;
    } else {
      this.disabled = mainValidations ? false : true;
    }
  }

  /** IDGoodsPriceOptionGroupDelegate --- END */
  /** IDGoodsTextImageInfoDelegate --- START */

  onInfoClicked(): void {
    debug('Info clicked');
    this.productDescriptionInfo.hidden = false;
  }

  /** IDGoodsTextImageInfoDelegate --- END */

  /**
   * terms and conditions of provider
   */
  onTermsOfProviderClick() {
    window.scrollTo(0, 0);
    this.toTermsOfProvider = false;
  }

  /**
   * terms and conditions of twint
   */
  onTermsOfTwintClick() {
    window.scrollTo(0, 0);
    this.toTermsOfTwint = false;
  }

  /**
   * data protection of twint
   */
  onDataProtectionOfTwintClick() {
    this.toDataProtectionOfTwintOverlay = false;
  }

  /** IDGoodsNavigationDelegate --- START */

  onBackClicked(sender: DGoodsNavigationComponent) {
    this.router.navigate(['../../de-ch/landing-buy']);
  }

  /** IDGoodsNavigationDelegate --- END */

  switchToApp(orderToken: string) {
    // to show progress overlay

    const device = localStorage.getItem('device');
    const protocol = localStorage.getItem('protocol');
    this.progressOverlay.hidden = true;
    if (device === 'ios') {
      const appSwitchIOS = {
        app_action_type: 'TWINT_PAYMENT',
        extras: {
          code: orderToken
        },
        referer_app_link: {
          app_name: 'TWINT_MORE_DIGITAL_GOODS',
          target_url: '',
          url: ''
        },
        version: '6.0'
      };
      const jsonEncodedObj = JSON.stringify(appSwitchIOS);
      const redirectURL = `${protocol}://applinks/?al_applink_data=${jsonEncodedObj}`;
      debug(`ios browser-app-switch-redirect ${redirectURL}`);
      debug(redirectURL);
      window.location.href = redirectURL;
      this.appSwitch.hidden = false;
      this.savePageView('Purchase confirm');
    } else if (device === 'android') {
      // tslint:disable-next-line:max-line-length
      const redirectURL = `intent://payment#Intent;package=${protocol};action=ch.twint.action.TWINT_PAYMENT;scheme=twint;S.code=${orderToken};S.startingOrigin=TWINT_MORE_DIGITAL_GOODS;end`;
      debug(`android browser-app-switch-redirect ${redirectURL}`);
      this.appSwitch.hidden = false;
      this.savePageView('Purchase confirm');
      debug(redirectURL);
      window.location.href = redirectURL;
    }

  }

  protected getProductByProductLineIdeFromStore() {
    debug('register get product observable');
    this.observables.set('getProductByProductLineIdeFromStore', this.productProvider.getProduct$(this.productLineId));
  }

  private checkout() {
    debug('checkingout');
    let checkoutRequest: CartModel;
    this.getUserEmail();
    if (this.switchToSendAsGift.selected) {
      checkoutRequest = {
        productId: this.unviersalProductCode,
        amount: this.amount,
        senderName: this.emailConfirmation.getSender(),
        receiverMessage: this.emailConfirmation.getMessage(), // gift message
        receiverEmail: this.userEmail,  // my user email
        receiverSender: this.email,     // gift email
        twintTC: this.twintTC ? 'true' : null,
        terminalId: sessionStorage.getItem('terminalId'),
      };
    } else {
      checkoutRequest = {
        productId: this.unviersalProductCode,
        amount: this.amount,
        receiverEmail: this.userEmail,
        twintTC: this.twintTC ? 'true' : null,
        terminalId: sessionStorage.getItem('terminalId'),
      };
    }
    if (this.userEmail) {
      localStorage.setItem('twintTC', true.toString());
      this.progressOverlay.hidden = false;
      let checkoutSubscribtionCount = 0;
      this.subscriptions.push(this.cartProvider.checkout(checkoutRequest).pipe(take(2)).subscribe((response: CheckoutModel) => {
        checkoutSubscribtionCount++;

        if (response && response.order) {
          this.subscriptions.push(this.cartProvider.getCartToken$().pipe(take(2)).subscribe((orderToken: string) => {
            this.switchToApp(orderToken);
          }));
          this.subscriptions.push(this.cartProvider.getReceiptUrlOfORder$().pipe(take(2)).subscribe((receiptURL: string) => {
            if (receiptURL) {
              const arr = receiptURL.split('/');
              this.receiptUrl = arr[arr.length - 1];
            }
          }));
          // } else if (checkoutSubscribtionCount === 2) {
          // console.log('trace0');

          // // this.router.navigate(['../../de-ch/error']);
          // // }
        } else {
          this.subscriptions.push(this.cartProvider.getError$().pipe(take(2)).subscribe((error) => {
            if (error) {
              this.progressOverlay.hidden = true;
              if (error.status.toString() === '405') {
                this.userLimitExceeded = true;
                this.fromLocalization = false;
                this.curerntDialogBox.title = this.userDialogBox.title;
                this.curerntDialogBox.description = error.error.message;
                this.dialogBoxOverlay.hidden = false;
              } else if (error.status.toString() === '406') {
                this.fromLocalization = true;
                this.userLimitExceeded = true;
                this.curerntDialogBox = this.shopDialogBox;
                this.dialogBoxOverlay.hidden = false;
              } else if (error.status.toString() === '410') {
                this.fromLocalization = true;
                this.userLimitExceeded = true;
                this.curerntDialogBox = this.productLineDialogBox;
                this.dialogBoxOverlay.hidden = false;
              } else {
                this.router.navigate(['../../de-ch/error']);
              }
            }
          }));

        }
      }));
    } else {
      this.hiddenEmailOverlay = !this.hiddenEmailOverlay;
    }
  }

  private getUserEmail() {
    const email = localStorage.getItem('email');
    this.userEmail = email ? email : null;
  }

  private getUserTwintTC() {
    const twintTC = localStorage.getItem('twintTC');
    this.twintTC = twintTC ? twintTC : null;
  }

  getVisibility() {
    return (this.hiddenEmailOverlay && this.toDataProtectionOfTwintOverlay && this.toTermsOfProvider && this.toTermsOfTwint);
  }

  ResetOverlaysFlags() {

    // if email is saved then checkout
    if (!this.hiddenEmailOverlay) {

      this.getUserEmail();
      if (this.userEmail && !this.userLimitExceeded) {

        this.checkout();
      } else {

        // navigate back from email overlay without saving the email enetered
        this.savePageView('Product buy');
        this.hiddenEmailOverlay = true;
      }
    } else {

      this.toDataProtectionOfTwintOverlay = true;
      this.toTermsOfProvider = true;
      this.toTermsOfTwint = true;
      this.hiddenEmailOverlay = true;
      setTimeout(() => {
        window.scrollTo(0, window.innerHeight + 1000);
      }, 2);
    }
  }

  onHideTermsOfProvider(termsAccepted?: boolean) {
    if (termsAccepted !== undefined && termsAccepted === true) {
      this.checkbox1.selected = true;
      this.renderer.addClass(this.checkbox1.rootelement.nativeElement, 'selected');
      this.checkValidCheckout();
    } else if (termsAccepted !== undefined && termsAccepted === false) {
      this.checkbox1.selected = false;
      this.renderer.removeClass(this.checkbox1.rootelement.nativeElement, 'selected');
      this.checkValidCheckout();
    }
    this.ResetOverlaysFlags();
  }
}
