
import { Component, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {FormControl, NgForm, NgModel} from '@angular/forms';
import { UserProvider } from '@modeso/ihub-lib-user-fe';
import { ICreatePersonRequest } from '@modeso/types__ihub-lib-user-be';
import Debug from 'debug';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular-material-components/moment-adapter';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { CUSTOM_DATE_FORMATS, CUSTOM_MOMENT_FORMATS } from '../../utils/dateUtils';
import moment from 'moment';
import { BasePageComponent } from '../base.page';
const debug = Debug('dgoods:project:UserDetailPageComponent');

@Component({
  selector: 'app-user-detail-page',
  templateUrl: './user-detail.page.view.html',
  styleUrls: ['./user-detail.page.view.scss'],
  providers: [
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS},
    {provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
  ]
})

export class UserDetailPageComponent extends BasePageComponent implements OnInit, OnDestroy {


  @ViewChild(NgForm, { static: true }) form: NgForm;
  public dateOfBirthControl = new FormControl(moment().subtract(18, 'years').subtract(1, 'days').toDate());
  @ViewChild('phone', { static: true }) phone: NgModel;
  @ViewChild('postalCode', { static: true }) postalCode: NgModel;
  @Output() insurancePerson = new EventEmitter<string>();
  nameBlurred = true;
  firstNameBlurred = true;
  emailBlurred = true;
  dateOfBirthBlurred = true;
  residanceBlurred = true;
  postalCodeBlurred = true;
  phoneBlurred = true;
  streetBlurred = true;
  componentName = true;
  streetNumberBlurred = true;
  userModel: any = {};
  emailPattern = '[A-Za-z0-9._%+-]+@[a-z0-9-.]+[.][a-z]{2,4}$';
  formValidity = false;
  disabled = true;
  minBirthDate;
  maxBirthDate;
  personId;
  hasAccount = false;
  streetPattern = '^([^0-9]*)$';
  streetNumberPattern = '^[a-zA-Z0-9#, ]*$';

  subscription: Subscription;
  constructor(
    private userProvider: UserProvider,
    private injector: Injector,
  ) {
    super(injector);
    this.personId = localStorage.getItem('personId') ? localStorage.getItem('personId') : undefined;
    if (this.personId) {
      this.getPerson();
    }
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.minBirthDate = moment().subtract(18, 'years').subtract(1, 'days').toDate();
    this.maxBirthDate = moment().subtract(100, 'years').toDate();
    this.dateOfBirthControl.setValidators((control) => {
      if (!control.value) {
        return { ageIsNotValid: false };
      }
      const date2 = moment(control.value);
      const diffDuration = moment.duration(moment().diff(date2));
      return diffDuration.years() >= 18 ? null : { ageIsNotValid: false };
    });
    this.phone.control.setValidators((control) => {
      if (control.value) {
        const regex = new RegExp(/^(\+41|0){1}(\(0\))?[0-9]{9,14}$/);
        const test = regex.test(control.value.toString());
        return test ? null : { invalidNumber: true };
      }
    });

    this.postalCode.control.setValidators((control) => {
      if (control.value && (control.value).toString().length !== 4) {
        return { invalidCode: true };
      }
      return null;
    });
    this.subscriptions.push(
      this.form.statusChanges.subscribe((x) => {
      if (x === 'VALID') {
        this.formValidity = true;
      } else {
        this.formValidity = false;
      }
    }),
    this.dateOfBirthControl.statusChanges.subscribe((status) => {
      if (status === 'VALID') {
        this.formValidity = true;
      } else {
        this.formValidity = false;
      }
    })
    );
  }
  onblur(value: boolean, inputName: string) {
    switch (inputName) {
      case 'name':
        this.nameBlurred = value;
        break;
      case 'firstName':
        this.firstNameBlurred = value;
        break;
      case 'email':
        this.emailBlurred = value;
        break;
      case 'phone':
        this.phoneBlurred = value;
        break;
      case 'residance':
        this.residanceBlurred = value;
        break;
      case 'postalCode':
        this.postalCodeBlurred = value;
        break;
      case 'street':
        this.streetBlurred = value;
        break;
      case 'streetNumber':
        this.streetNumberBlurred = value;

      default:
    }
  }

  createPersonRequestBody() {
    moment.locale('en')
    const birthday = moment(this.dateOfBirthControl.value).format('DD.MM.YYYY');
    const person: ICreatePersonRequest = {
      lastname: this.userModel.name,
      forename: this.userModel.firstName,
      street: this.userModel.street,
      zipcode: this.userModel.postalCode.toString(),
      city: this.userModel.residance,
      birthday,
      phone: this.userModel.phone.toString(),
      email: this.userModel.email,
      receiveEmails: this.userModel.receiveEmails,
      streetNumber: this.userModel.streetNumber
    };
    return person;
  }


  createNewPerson() {

    const personBody = this.createPersonRequestBody();
    let index = 0;
    this.personId = undefined;

    this.userProvider.createPerson$(personBody).pipe(take(2))
      .subscribe((personIdRes) => {
        index++;
        if (index === 2) {
          if (personIdRes) {
            this.personId = personIdRes;
            localStorage.setItem('personId', this.personId);
            this.insurancePerson.emit(personIdRes);
          }
        }
      });

  }

  updatePerson() {
    const personBody = this.createPersonRequestBody();
    personBody.id = this.personId;
    this.userProvider.updatePerson$(personBody).pipe(take(2));
  }

  getPerson() {
    let index = 0;
    this.userProvider.getPersonOfUser$().pipe(take(2))
      .subscribe((person) => {
        index++;
        this.personId = undefined;
        if (index === 2) {
          if (person) {
            this.dateOfBirthControl.setValue(moment(person.birthday, 'DD.MM.YYYY'));
            this.personId = person._id;
            this.hasAccount = true;
            this.formValidity = true;
            this.userModel = {
              streetNumberBlurred: true,
              streetBlurred: true,
              nameBlurred: true,
              firstNameBlurred: true,
              emailBlurred: true,
              dateOfBirthBlurred: true,
              residanceBlurred: true,
              postalCodeBlurred: true,
              phoneBlurred: true,
              firstName: person.forename,
              name: person.lastname,
              email: person.email,
              street: person.street,
              postalCode: person.zipcode,
              phone: person.phone,
              residance: person.city,
              receiveEmails: person.receiveEmails,
              streetNumber: person.streetNumber
            };

          }
        }
      });
  }

}
