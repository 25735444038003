<div class="login-page-container">
  <img class="twint-logo" src="assets/twint_logo_q_pos_bg.png" />
  <div class="login-content">
    <div class="login-msg-img">
      <img class="login-logo" src="assets/clock.svg" />
      <span class="login-msg">{{"dgoods_shop_default_navigation_time_out_msg" | localize | async}}</span>
      <span class="login-msg">{{"dgoods_shop_orderconfirmationview_privatemode_warning2" | localize | async}}</span>
    </div>
  </div>
</div>

<dgoods-overlay #incognitoOverlay [hidden]="true" [delegate]="this" [html]="true">
  <div class="private-mode-container">
    <img class="twint-logo" src="assets/twint_logo_q_pos_bg.png" />
    <div class="private-mode-content">
      <div class="warning-img-msg">
        <img class="warning-img" src="assets/icn-warning.png" />
        <span class="warning-msg">{{"dgoods_shop_orderconfirmationview_privatemode_warning1" | localize | async}}</span>
        <span class="warning-msg">{{"dgoods_shop_orderconfirmationview_privatemode_warning2" | localize | async}}</span>

      </div>
    </div>
  </div>
</dgoods-overlay>
