import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IHubDamageConfigService } from '../ihub.damage.config';
import { ICreateDamageResponse, IGetDamageResponse } from '../models/damage.mode';

@Injectable({
  providedIn: 'root',
})
export class IHubDamageService {
  private server = '';
  private microserviceName = 'damageReport'; 
  private route = '/';

  /**
   *
   * @param http
   * @param config
   */
  constructor(
    private http: HttpClient, @Inject(IHubDamageConfigService) private config) {
    this.server = config.apiGateway;
  }

  createDamageReport(body:any) {

    const options = {
      reportProgress: true
    };

    let formData = this.appendBodyToFormData(body);

    const url = `${this.server}${this.microserviceName}`;
    return this.http.post<ICreateDamageResponse>(url, formData,options);
  }

  appendBodyToFormData(body:any){

    let formData = new FormData();
    formData.append('iban', body.iban);
    formData.append('description', body.description);
    formData.append('personId', body.personId);
    formData.append('insuranceId',body.insuranceId);
    formData.append('amount', body.amount);
    formData.append('accountName', body.accountName);
    formData.append('bankName', body.bankName);
    formData.append('dateOfIncident', body.dateOfIncident);
    if(body.policyNo){
      formData.append('policyNo', body.policyNo);
    }
    if(body.files){
      for (const droppedFile of body.files) {

        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry;
          fileEntry.file((file: File) => {
           formData.append('files', file);
          });
        }
      }
    }

    return formData;

  }

  getDamageReportByInsuranceId(id:string) {
    const url = `${this.server}${this.microserviceName}${this.route}${id}`;
    return this.http.get<IGetDamageResponse>(url);
  }
}
