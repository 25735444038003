
import { Directive, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DGoodsButtonComponent, IDGoodsButtonDelegate } from '@modeso/twint-lib-core-fe';
import Debug from 'debug';
import { Observable, of } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { BasePageComponent } from '../base.page';
import { InsuranceProvider } from '@modeso/ihub-lib-insurance-fe';
import { ProductStoreProvider } from '@modeso/ihub-lib-products-fe';
import { ProductDTO } from '@modeso/types__ihub-lib-products-be';
import { DelayPaymemtUtil } from '../../utils/delayPaymentUtil';
import { PriceUtil } from '../../utils/priceUtil';
import { IInsuranceOperationResponse } from '@modeso/types__ihub-lib-insurance-be';

const debug = Debug('dgoods:project:InsuranceDetailPageControllerDirective');

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'app-buy-page-controller'
})
export class InsuranceDetailPageControllerDirective extends BasePageComponent implements
 IDGoodsButtonDelegate {
  protected productcode;
  products: string[] = [];
  protected observables = new Map<string, Observable<ProductDTO>>();
  public insuranceProduct: ProductDTO;
  public disabled = true;
  subCategoryProducts: ProductDTO[] = [];
  purchasedInsurance: any = [];
  isCategory = false;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private insuranceProvider: InsuranceProvider,
    private productProvider: ProductStoreProvider,
    injector: Injector,
  ) {
    super(injector);
    this.productcode = this.route.snapshot.paramMap.get('product');
    this.insuranceProvider.fetchAllMyPurchasedInsurances$();
    this.subscriptions.push(
      this.productProvider.getAllProducts$().subscribe((products) => {
        this.products = [];
        if (products.length > 0) {
          products.map(prod => {
              this.products.push(prod.product);
          });
        }
      }),
      this.insuranceProvider.getPurchasedInsurances$()
      .subscribe((purchasedInsurance: IInsuranceOperationResponse[]) => {
        if (purchasedInsurance && purchasedInsurance.length > 0) {
        this.purchasedInsurance = purchasedInsurance;
        this.getProductByProductCodeFromStore();
        debug(purchasedInsurance);
      }}));
    this.getProductByProductCodeFromStore();
  }

  /** NgInit */
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() {
    super.ngOnInit();
    this.insuranceProvider.flush();

    this.checkForDraftedInsurances(this.route.snapshot.paramMap.get('product'));

  }

  /** IDGoodsButtonDelegate --- START */

  onButtonClicked(sender: DGoodsButtonComponent, id: string): void {
    debug('Button clicked', sender);
    if (id === 'next') {
      // Navigate to next page
      this.navigateNext();
    } else if (id === 'back') {
      // Navigate back
      this.navigateBack();
    }
  }

  private navigateBack() {
    this.router.navigate([`../../${this.languageFromURL}/landing-buy`]);
  }
  private navigateNext() {
    this.subCategoryProducts = [];
    // Set insurance product in store
    this.router.navigate([`../../${this.languageFromURL}/insurance-buy/` + this.productcode]);
  }

  /** IDGoodsButtonDelegate --- END */


  protected getProductByProductCodeFromStore() {
    debug('register get product observable');
    this.observables.set('getProductByProductCodeFromStore', this.productProvider.getProduct$(this.productcode));

    this.subscriptions.push(this.productProvider.getProduct$(this.productcode).pipe(concatMap(
      (product) => {
        if (!product) {
          return this.observables.get('getAllProducts');
        } else {
          return of(product);
        }
      })
      ).subscribe((value: ProductDTO | ProductDTO[]) => {
        if (Array.isArray(value)) {
          this.insuranceProduct = (value as ProductDTO[]).find((product) => product.product ===
          this.productcode) as ProductDTO;
        } else {
          this.insuranceProduct = value as ProductDTO;
        }
        if (this.insuranceProduct.subCategory.length > 0) {
          this.subCategoryProducts = [];
          this.isCategory = true;

          this.insuranceProduct.subCategory.sort((a, b) => {
            return this.products.indexOf(a) - this.products.indexOf(b);
          });

          for (const category of this.insuranceProduct.subCategory) {
            this.productProvider.getProduct$(category).pipe(take(1)).subscribe((product) => {
              if (product) {
                // tslint:disable-next-line: max-line-length
                const price = product.priceStaticConfig ?
                              product.priceStaticConfig :
                              !product.isSinglePayment ?
                              PriceUtil.getMinimumVariationPrice(product.singlePrices, product.variations[0].name).toFixed(2) :
                              PriceUtil.getMinimumVariationPrice(product.singlePrices, product.variations[0].name);
                const subCategoryProduct = {...product , price } ;
                // tslint:disable-next-line: max-line-length
                subCategoryProduct.blocked = this.purchasedInsurance.filter( boughtProduct =>  boughtProduct.product === subCategoryProduct.product && !subCategoryProduct.canBuyMultipleTimes && !subCategoryProduct.isSinglePayment
                 && (boughtProduct.endDate ? new Date(boughtProduct.endDate).getTime() >  new Date().getTime() : true)).length !== 0
                // tslint:disable-next-line: no-unused-expression
                ? true
                : false;

                this.subCategoryProducts.push(subCategoryProduct);

              }
            });
          }
        }
        this.insuranceProduct = new DelayPaymemtUtil().getDelayPaymentForProduct(this.insuranceProduct);
        this.disabled =  this.insuranceProduct.subCategory.length > 0 ? true : false;
      })
    );
  }

  onProductCheck(event) {
    if (event.checked) {
      this.disabled = false;
      this.productcode = event.product;
      this.checkForDraftedInsurances(this.productcode);
    }
  }

  checkForDraftedInsurances(productId) {
    this.insuranceProvider.getDraftedInsurancesByUserId$().pipe(take(2)).subscribe((draftedInsurance: any) => {

      const draft = draftedInsurance.filter((insurance) => insurance.product === productId )[0];

      if (draft) {
        this.insuranceProvider.updateInsuranceState(draft);
      }
    });
  }

   // tslint:disable-next-line: use-lifecycle-interface
   ngOnDestroy() {
    super.ngOnDestroy();
  }

}
