import { createAction, props, Action } from '@ngrx/store';
import { ISortingOrder, ProductDTO } from '@modeso/types__ihub-lib-products-be';

export const getAllProducts = createAction('[Product List] getAllProducts');



export const onAllProductsLoadedSuccessfully = createAction(
  '[Product List] all products loaded successfully',
  props<{ payload: Array<ProductDTO> }>()
);

/**
 * create an Action of get product by id
 * check https://ngrx.io/guide/store/actions
 * @param1 handle of the action with ngrx convention
 * @param2 props method is used to define any additional metadata
 *         needed for the handling of the action
 */
export const getProductById = createAction(
  '[Product List] getProductById',
  props<{ productId: string }>()
);

export const onAllProductsLoadingFailed = createAction(
  '[Product List] all products loading failed',
  props<{ payload: any }>()
);


export const onGetFeatureProductByIssuer = createAction(
  '[Product List] onGetFeatureProductByIssuer',
  props<{ payload: string }>()
);

export const onGetFeatureProductByIssuerSuccessfully = createAction(
  '[Product List] onGetFeatureProductByIssuerSuccessfully Successfully',
  props<{ payload: ISortingOrder }>()
);

export const onGetFeatureProductByIssuerFailed = createAction(
  '[Product List] onGetFeatureProductByIssuerFailed Failed',
  props<{ payload: any }>()
);

export interface ActionWithPayload<T> extends Action {
  payload: T;
}
