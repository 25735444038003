/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./insurance-cancelation-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@modeso/modeso-lib-localization-fe";
import * as i3 from "@ngrx/store";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "../../../../node_modules/@modeso/ihub-lib-insurance-fe/modeso-ihub-lib-insurance-fe.ngfactory";
import * as i7 from "@modeso/ihub-lib-insurance-fe";
import * as i8 from "./insurance-cancelation-page.component";
import * as i9 from "@angular/router";
import * as i10 from "@modeso/twint-lib-analytics-fe";
var styles_InsuranceCancelationPageComponent = [i0.styles];
var RenderType_InsuranceCancelationPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InsuranceCancelationPageComponent, data: {} });
export { RenderType_InsuranceCancelationPageComponent as RenderType_InsuranceCancelationPageComponent };
export function View_InsuranceCancelationPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.ɵa, [i3.Store, i4.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "main-title-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["class", "main-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 1), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "ihub-second-cancellation-component", [], null, null, null, i6.View_SecondCancellationComponentComponent_0, i6.RenderType_SecondCancellationComponentComponent)), i1.ɵdid(8, 245760, null, 0, i7.SecondCancellationComponentComponent, [], { orderUuid: [0, "orderUuid"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.orderUuid; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), "ihub_shop_cancellation_policy")))); _ck(_v, 3, 0, currVal_0); }); }
export function View_InsuranceCancelationPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-insurance-cancelation-page", [], null, null, null, View_InsuranceCancelationPageComponent_0, RenderType_InsuranceCancelationPageComponent)), i1.ɵdid(1, 245760, null, 0, i8.InsuranceCancelationPageComponent, [i1.Injector, i9.Router, i9.ActivatedRoute, i10.AnalyticsProvider], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InsuranceCancelationPageComponentNgFactory = i1.ɵccf("app-insurance-cancelation-page", i8.InsuranceCancelationPageComponent, View_InsuranceCancelationPageComponent_Host_0, {}, {}, []);
export { InsuranceCancelationPageComponentNgFactory as InsuranceCancelationPageComponentNgFactory };
