import { BehaviorSubject } from 'rxjs';
import { BaseControlHandlerClass } from '../BaseControlHandlerClass';

export class PriceBasedOnInsuranceAttributeHandler extends BaseControlHandlerClass {
    subject: BehaviorSubject<{[key: string]: any}>;
    constructor() {
        super();
        this.subject = new BehaviorSubject<{[key: string]: any}>(undefined);
    }
    onKeyUp($event: any) {
        const key = $event.name;
        const additionalAttributes = { [key]: $event.value, test: "dummy" };
        this.subject.next(additionalAttributes);
    }
}
