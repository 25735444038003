
import { Component, OnInit, Injector } from '@angular/core';
import Debug from 'debug';
import { InsuranceBuyPageControllerDirective } from './insurance-buy.page.controller';
import { ActivatedRoute, Router } from '@angular/router';
import { DGoodsPriceOptionModel, IIHubSwitchSelection } from '@modeso/twint-lib-core-fe';
import { IInsuranceProduct } from '@modeso/types__ihub-lib-insurance-be';
import { map } from 'rxjs/operators';
import { InsuranceProvider } from '@modeso/ihub-lib-insurance-fe';
import { ProductStoreProvider } from '@modeso/ihub-lib-products-fe';
const debug = Debug('dgoods:shared:InsuranceBuyPage');

@Component({
  selector: 'app-insurance-buy-page',
  templateUrl: './insurance-buy.page.view.html',
  styleUrls: ['./insurance-buy.page.view.scss']
})
export class InsuranceBuyPage extends InsuranceBuyPageControllerDirective implements OnInit {


  public variations = [];
  public hasDenomination = false;
  public subscriptions = [];

  public yourName = 'dgoods_shared_inputcomponent_yourname';
  public yourMessage = 'dgoods_shared_inputcomponent_yourmsg';
  public caption = 'dgoods_shared_inputcomponent_emailrecipient';
  constructor(
    router: Router,
    route: ActivatedRoute,
    insuranceProvider: InsuranceProvider,
    productProvider: ProductStoreProvider,
    injector: Injector,

  ) {
    super(router, route, insuranceProvider, productProvider, injector) ;
  }

  /** OnInit --- START */

  ngOnInit() {
    super.ngOnInit();
    this.getProduct();

  }
  /** OnInit --- END */

  /**
   * getAgbLink
   */
  public getAgbLink() {
    const browserLanguage = navigator.language;
    let agbLanguage = '';
    if (browserLanguage.includes('de')) {
      agbLanguage = 'de';
    } else if (browserLanguage.includes('fr')) {
      agbLanguage = 'fr';
    } else if (browserLanguage.includes('it')) {
      agbLanguage = 'it';
    } else {
      agbLanguage = 'en';
    }
    return 'assets/pdf/' + agbLanguage + '/shopterms.pdf';
  }

  protected getProduct() {
    debug('subscribe to product observable');
    this.subscriptions.push(this.observables.get('getProductByProductCodeFromStore').pipe(
      map(this.mapProduct.bind(this))
    ).subscribe());
  }

  protected mapProduct(product: IInsuranceProduct) {
    debug(product);
    if (product) {
      this.setFamilyOrSingleSelection(product.hasFamily);
      this.setVariationSelections(product);
      this.isSelectionToggled = true;
      this.calculatePrice();
    }
    if (!product) {
      this.router.navigate(['../../de-ch/error']);
    }
    return product;
  }

  private setFamilyOrSingleSelection(canSelect: boolean) {
    if (!canSelect) {
      this.familyOrSingleSelection = [];
    } else {

      const selectSingle: IIHubSwitchSelection = {
        text: 'ihub_shop_singlehousehold',
        description: 'ihub_shop_singlehousehold_popup_text',
        selected: true,
        reference: 'single',
      };
      const selectFamily: IIHubSwitchSelection = {
        text: 'ihub_shop_familyhousehold',
        description: 'ihub_shop_familyhousehold_popup_text',
        selected: false,
        reference: 'family',
      };


      // Preselect
      if (this.draft && this.draft.family) {
        selectSingle.selected = false;
        selectFamily.selected = true;
      } else {
        selectSingle.selected = true;
        selectFamily.selected = false;
      }

      this.familyOrSingleSelection = [selectSingle, selectFamily];
    }
  }

  private setVariationSelections(product: IInsuranceProduct) {
    this.variationSelections = [];
    (product.variations).forEach(element => {
      const selection: IIHubSwitchSelection = {
        text: element.title,
        selected: false,
        reference: element.name,
      };
      this.variationSelections.push(selection);

    });

    // Preselect
    if (this.draft) {
      if (this.variationSelections.length > 0) {
        const selection = this.variationSelections.filter((v) => v.reference === this.draft.variation);
        if (selection.length > 0) {
          selection[0].selected = true;
        } else {
          this.variationSelections[0].selected = true;
        }
      }
    } else {
      if (this.variationSelections.length > 0) {
        this.variationSelections[0].selected = true;
      }
    }

  }

}
