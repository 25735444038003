<div class="faq-overlay">
    <div class="page-padding">
        <div class="header-overlay">
            <p class="header-style">{{"dgoods_faq_title" | localize | async}}</p>
        </div>
        <div *ngFor="let key of questionsLocalizationKeys">
            <div *ngIf="(key.titleKey| localize | async ) && (key.answerKey| localize | async )">
                <input type="checkbox" id="{{ 'question' + key.index }}" class="toggle">
                <label for="{{ 'question' + key.index }}" class="question">
                    {{ key.titleKey | localize | async}}
                </label>
                <div class="answer">
                    <p>{{ key.answerKey| localize | async}}</p>
                </div>
                <hr>
            </div>
        </div>
    </div>
    <div class="sticky">
        <dgoods-button [delegate]="this">
            {{"dgoods_shared_navigationcompontent_back" | localize | async}}
        </dgoods-button>
    </div>
</div>