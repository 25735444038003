import { LandingBuyPageControllerDirective } from './landing-buy.page.controller';
import { Component, OnInit, Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IIHubTile, ProductStoreProvider } from '@modeso/ihub-lib-products-fe';
// tslint:disable-next-line: max-line-length
import { IInsuranceOperationResponse, IInsuranceProduct, InsuranceStatusEnum } from '@modeso/types__ihub-lib-insurance-be';
import Debug from 'debug';
import { Store } from '@ngrx/store';
import * as fromApp from './../../state/app.state.reducer';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
import { InsuranceProvider } from '@modeso/ihub-lib-insurance-fe';
import { InjectedTextPipe } from '@modeso/modeso-lib-core-fe';
import { ISpotlight } from '@modeso/types__ihub-lib-products-be';
import { PriceUtil } from '../../utils/priceUtil';
import { UserProvider } from '@modeso/ihub-lib-user-fe';
import { DelayPaymemtUtil } from '../../utils/delayPaymentUtil';
import { combineLatest } from 'rxjs';

const debug = Debug('ihub:project:LandingBuyPage');

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-buy.page.view.html',
  styleUrls: ['./landing-buy.page.view.scss']
})
export class LandingBuyPage extends LandingBuyPageControllerDirective implements OnInit {

  blockedProductLines: boolean[] = [];
  selectedIndex = 0;

  slides = [];
  showDisclaimer: boolean;

  constructor(
    analyticsProvider: AnalyticsProvider,
    insuranceProvider: InsuranceProvider,
    router: Router,
    route: ActivatedRoute,
    productProvider: ProductStoreProvider,
    store: Store<fromApp.AppState>,
    injector: Injector,
    private injectTextPipe: InjectedTextPipe,
    userProvider: UserProvider
  ) {
    super(analyticsProvider, insuranceProvider, router, route, productProvider, store, injector, userProvider);

    this.historyLink = {
      reference: 'history',
      icon: 'assets/ihub/icn_my_insurances.svg',
      blocked: false,
      title: 'ihub_shop_my_insurance',
      description: 'ihub_shop_my_insurance_text',
      needsTranslation: true,
      delayPaymentByMonth: 0,
    };
    this.showDisclaimer =
      sessionStorage.getItem('isP2P') === 'true' ? true : false;
  }

  // get all products and filter out the bought insurances
  getProductsAlongWithUserInsurances() {

    localStorage.removeItem('products');
    const getProducts$ = this.observables.get('getAllProducts');
    this.insuranceProvider.fetchAllMyPurchasedInsurances$();
    const getInsurances$ = this.insuranceProvider.getPurchasedInsurances$();
    const getFeatureProduct$ = this.productProvider.getFeatureProductByIssuer(this.getIssuer());
    const getSpotLights$ = this.productProvider.getSpotlights$(this.getIssuer());

    this.subscriptions.push(combineLatest([getProducts$, getInsurances$, getFeatureProduct$, getSpotLights$])

    .subscribe(([products, purchasedInsurance, featureProductArray, spotlights]) => {
      if (products.length > 0 ) {
        localStorage.setItem('products', JSON.stringify(products));
        this.products  = [...products];

        // Creating tiles from products
        const tiles: IIHubTile[] = products.filter((product) => {
          // tslint:disable-next-line: no-shadowed-variable
          let isSubCategoryProduct = false;

          if (products.find(prod => prod.subCategory.includes(product.product) )) {
             isSubCategoryProduct = true;
          }
          return !product.hidden && ! isSubCategoryProduct;
        }).map((product) => {
          product = new DelayPaymemtUtil().getDelayPaymentForProduct(product);
          return {
            ...product,
            icon: product.icon,
            reference: product.product,
            description: product.shortDescription,
            // tslint:disable-next-line: max-line-length
            price:  product.priceStaticConfig ?  product.priceStaticConfig :
                    product.singlePrices && product.variations[0] ?
                    PriceUtil.getMinimumVariationPrice(product.singlePrices, product.variations[0].name).toFixed(2) : '' ,
            paymentPer: product.paymentPer,
            blocked: product.blocked,
            title: product.subCategory.length > 0 ? product.productCategoryTitle : product.title,
            isCategory : product.subCategory.length > 0 ? true : false

          } as IIHubTile;
        });

        this.insuranceProductTiles = [...tiles];

        this.getCategories(products);

        if (purchasedInsurance && purchasedInsurance.length > 0) {
          debug(purchasedInsurance);
          this.loadingInsurances = false;
          this.hasInsurances = true;
          const productsData = {};
          this.insurances = purchasedInsurance;
          products.forEach(element => {
            productsData[element.product] = element;
          });

          // in order to alert payment failure for the checkin user
          this.getFailedPayment(purchasedInsurance);

          this.blockedProducts = purchasedInsurance
              .filter((insurance: IInsuranceOperationResponse) =>
                productsData[insurance.product] &&
                !productsData[insurance.product].isSinglePayment &&
                !productsData[insurance.product].canBuyMultipleTimes &&
                (
                  insurance.insuranceStatus === InsuranceStatusEnum.ACTIVE ||
                  insurance.insuranceStatus === InsuranceStatusEnum.WAITFORUOF ||
                  insurance.insuranceStatus === InsuranceStatusEnum.PENDING ||
                  (insurance.insuranceStatus === InsuranceStatusEnum.CANCELLED && new Date(insurance.endDate) > new Date()))
              ).map((insurance) => insurance.product);


          this.insuranceProductTiles.forEach((tile: IIHubTile) => {
              if (this.blockedProducts.filter(item => item === tile.reference).length > 0) {
                tile.blocked = true;
              }
          });

          // sort tiles according to bought insurances
          this.insuranceProductTiles.sort((a, b) => a.blocked - b.blocked);
        }

        if (featureProductArray && featureProductArray.products && featureProductArray.products.length) {
            this.featureProductId = featureProductArray.products[0];
            const featureProduct = products && products.find(p => p.product === this.featureProductId);
            if (featureProduct) {
              const subCategoryProduct = products.find(prod => prod.subCategory.includes(featureProduct.product));
              if (subCategoryProduct) {
                featureProduct.shortDescription = subCategoryProduct.shortDescription;
                featureProduct.icon = subCategoryProduct.icon;
              }
              this.featureProduct = {
                ...featureProduct,
                reference: 'feature',
                description: featureProduct.shortDescription,
                price:  featureProduct.priceStaticConfig ?  featureProduct.priceStaticConfig :
                        featureProduct.singlePrices && featureProduct.variations[0] ?
                        PriceUtil.getMinimumVariationPrice(featureProduct.singlePrices, featureProduct.variations[0].name).toFixed(2) : '' ,
                paymentPer: featureProduct.paymentPer,
                blocked: featureProduct.blocked,
                title: featureProduct.subCategory.length > 0 ? featureProduct.productCategoryTitle : featureProduct.title,
                isCategory : featureProduct.subCategory.length > 0 ? true : false
              };
              this.insuranceProductTiles = this.insuranceProductTiles.filter(insurance => insurance.product !== this.featureProductId);
            }
        }

        let browserLanguage = navigator.language ? navigator.language.toLocaleLowerCase().substring(0, 2) : null;
        if (browserLanguage === 'de' || browserLanguage === 'fr' || browserLanguage === 'it') {
          browserLanguage = `${browserLanguage}-ch`;
        } else { browserLanguage = 'en-us' ; }

        debug(browserLanguage);
        this.slides = spotlights
        .filter((spotlight: ISpotlight) => {
          let product = this.insuranceProductTiles.find(p => p.product === spotlight.product);
          if (!product && this.featureProductId && this.featureProductId === spotlight.product) product = this.featureProduct
          return (product && !product.blocked && !product.hidden);
        })
        .map((spotlight: ISpotlight) => {
          return {
              product: spotlight.product,
              teaserImage: spotlight.image[browserLanguage]
            };
        });

        this.getUser();
      }
    }));
  }



  getCategories(products) {
    debug('Filter  selected');

    this.productCategories = products.map((product) => {
      if (product.category.length !== 0 ) {
        return product.category;
      }
    });

    this.productCategories = this.productCategories.filter((value, index, self) => {
      return self.indexOf(value) === index && value !== undefined;
    });
    this.productCategories.unshift('All');
  }


  /** OnInit --- START */

  ngOnInit() {
    super.ngOnInit();
    this.getProductsAlongWithUserInsurances();
  }

  /** OnInit --- END */
}
