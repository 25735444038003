/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./paymentcanceld.page.view.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@modeso/modeso-lib-localization-fe";
import * as i3 from "@ngrx/store";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./paymentcanceld.page.view";
var styles_PaymentCanceldPage = [i0.styles];
var RenderType_PaymentCanceldPage = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentCanceldPage, data: {} });
export { RenderType_PaymentCanceldPage as RenderType_PaymentCanceldPage };
export function View_PaymentCanceldPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.ɵa, [i3.Store, i4.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "error-page-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "twint-logo"], ["src", "assets/twint_logo_q_pos_bg.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "error-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "error-msg-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "error-logo"], ["src", "assets/icon-denied.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "span", [["class", "error-msg"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 1), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 9).transform(i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), "dgoods_shop_paymentcanceld_error_msg")))); _ck(_v, 7, 0, currVal_0); }); }
export function View_PaymentCanceldPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-paymentcanceld", [], null, null, null, View_PaymentCanceldPage_0, RenderType_PaymentCanceldPage)), i1.ɵdid(1, 245760, null, 0, i6.PaymentCanceldPage, [i3.Store, i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentCanceldPageNgFactory = i1.ɵccf("app-page-paymentcanceld", i6.PaymentCanceldPage, View_PaymentCanceldPage_Host_0, {}, {}, []);
export { PaymentCanceldPageNgFactory as PaymentCanceldPageNgFactory };
