export const environment = {
  production: true,
  maintenance: false,
  modeso: {
    apiGateway: 'https://api-ihub-testing.modesoventures.com/api/'
  },
  defaultLanguage: 'de-ch',
  availableLanguages: ['de-ch', 'fr-ch', 'it-ch'],
  enableDebugging: 'modeso:*, dgoods:*, ihub:*',
  insurancePhoneNumber: '0447234400',
  dataDomain: 'ihub-testing.modesoventures.com'

};
