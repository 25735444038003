<div *ngIf="alertType !== 0 && link">

  <div  class="alertWrapper"  *ngIf="alertType === 1">
    <div class="row1 alertrow">
      <img class="alertcol" src="assets/ihub/twint-icons-icon-denied-small.svg">
      <span class="alertcol" class="desc">{{ "ihub_inactive_uof_alert_text" | localize | async}} </span>
    </div>
    <div class="row2 alertrow">
      <span class="link">
        <a  (click)="linkToTwint()" >{{"ihub_shop_link_to_twint" | localize | async}}</a>
      </span>
      <mat-spinner
        *ngIf="waitingForUofResponse"
        diameter="20"
      ></mat-spinner>
    </div>
  </div>

  <div class="alertWrapper"  *ngIf="alertType === 2">
    <div class="row1 alertrow">
      <img class="alertcol" src="assets/ihub/twint-icons-icon-denied-small.svg">
      <span class="alertcol" class="desc">{{ "ihub_shop_payment_alert_text" | localize | async}} </span>
    </div>
    <div class="row2 alertrow">
      <span class="link">
        <a [routerLink]="link">{{"ihub_shop_payment_alert_details" | localize | async}}</a>
      </span>
    </div>
  </div>
 

</div>

