import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { InsuranceService } from '../services/insurance.service';
import * as fromActions from '../actions/insurance.actions';
import { mergeMap, map, catchError, retry, tap, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ICreateInsuranceRequest } from '@modeso/types__ihub-lib-insurance-be';
// IUpdateInsuranceStatusRequest
import Debug from 'debug';
import { NavigationService } from '../shared/navigation.service';
import { IPdfData } from '@modeso/types__ihub-lib-insurance-be';
const debug = Debug('modeso:ihub-lib-insurance-fe:InsuranceEffects');


@Injectable()
export class InsuranceEffects {
  constructor(private actions$: Actions, private service: InsuranceService, private navigationService: NavigationService) { }

  /**
   * Effect of create insurances.
   */
  createInsurance$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.createInsurance.type),
      mergeMap(
        (action: fromActions.ActionWithPayload<ICreateInsuranceRequest>) => {
          debug(action);
          return this.service.createInsurance(action.payload)
            .pipe(
              // retry(1),
              map(
                response => (fromActions.onInsuranceOperationSuccessfully({ payload: response })),
              ),
              catchError((error) => of(fromActions.onInsuranceOperationFailed({ payload: error })))
            );
        }
      )
    )
  );

  /**
   * Effect of activate insurance .
   */
  activateInsurance$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.activateInsurance.type),
      mergeMap(
        (action: fromActions.ActionWithPayload<string>) => {
          debug(action);
          return this.service.activateInsurance(action.payload)
            .pipe(
              // retry(1),
              map(
                response => (fromActions.onInsuranceOperationSuccessfully({ payload: response })),
              ),
              catchError((error) => of(fromActions.onInsuranceOperationFailed({ payload: error })))
            );
        }
      )
    )
  );


  updateInsuranceStatus$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.updateInsuranceStatus.type),
      mergeMap(
        (action: fromActions.ActionWithPayload<string>) => {
          debug(action);
          return this.service.getInsuranceByOrderUuid(action.payload)
            .pipe(
              // retry(1),
              map(
                response => (fromActions.onUpdateInsuranceSuccessfully({ payload: response })),
              ),
              catchError((error) => of(fromActions.onUpdateInsuranceFailed({ payload: error })))
            );
        }
      )
    )
  );
  /**
   * Effect of post cart error.
   */
  errorOnInsuranceOperation$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onInsuranceOperationFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => this.handleOnLoadAllInsuranceErrors(action.payload)
      )
    )
    , { dispatch: false });


  getInsurancesByUserId$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.getInsurancesByUserId.type),
      mergeMap(
        () => {
          return this.service.getInsurancesByUserId()
            .pipe(
              retry(1),
              map(
                response => {

                  return fromActions.onGetInsurancesByUserIdSuccessfully({ payload: response });
                }
              ),
              catchError((error) => {
                return of(fromActions.onGetInsurancesByUserIdFailed({ payload: error }));
              })
            );
        }
      )
    )
  );

  errorOnGetInsurancesByUserId$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onGetInsurancesByUserIdFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => {
          this.handleOnLoadAllInsuranceErrors(action.payload);
          this.handleInsuranceError();
        }
      )
    ), { dispatch: false }
  );

  getInsuranceDetailsByOrderUuid$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.getInsuranceDetailsByOrderUuid.type),
      concatMap(
        (action: fromActions.ActionWithPayload<string>) => {
          return this.service.getInsuranceByOrderUuid(action.payload)
            .pipe(
              retry(1),
              map(
                response => {
                  return fromActions.ongetInsuranceDetailsByOrderUuidSuccessfully({ payload: response });
                }
              ),
              catchError((error) => {
                return of(fromActions.ongetInsuranceDetailsByOrderUuidFailed({ payload: error }));
              })
            );
        }
      )
    )
  );

  errorOnGetInsurancesByOrderUuid$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.ongetInsuranceDetailsByOrderUuidFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => {
          this.handleOnLoadAllInsuranceErrors(action.payload);
          this.handleInsuranceError();
        }
      )
    ), { dispatch: false }
  );
  getPolicyPdf$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onGetPolicyPdf.type),
      concatMap(
        (action: fromActions.ActionWithPayload<string>) => {
          return this.service.generatePdf(action.payload)
            .pipe(
              retry(1),
              map(
                response => {
                  return fromActions.onGetPolicyPdfSuccessfully({ payload: response });
                }
              ),
              catchError((error) => {
                return of(fromActions.onGetPolicyPdfFailed({ payload: error }));
              })
            );
        }
      )
    )
  );
  errorOnGetPolicyPdf$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onGetPolicyPdfFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => {
          this.handleOnLoadAllInsuranceErrors(action.payload);
          this.handleInsuranceError();
        }
      )
    ), { dispatch: false }
  );

  getAVBPdf$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onGetAVBPdf.type),
      concatMap(
        (action: fromActions.ActionWithPayload<string>) => {
          return this.service.generateAVB(action.payload)
            .pipe(
              retry(1),
              map(
                response => {
                  return fromActions.onGetAVBPdfSuccessfully({ payload: response });
                }
              ),
              catchError((error) => {
                return of(fromActions.onGetAVBPdfFailed({ payload: error }));
              })
            );
        }
      )
    )
  );
  errorOnGetAVBPdf$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onGetAVBPdfFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => {
          this.handleOnLoadAllInsuranceErrors(action.payload);
          this.handleInsuranceError();
        }
      )
    ), { dispatch: false }
  );

  updateInsuranceState$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onUpdateInsuranceState.type),
      concatMap(
        (action: fromActions.ActionWithPayload<any>) => {
          return this.service.updateInsuranceState(action.payload)
            .pipe(
              retry(1),
              map(
                response => {
                  return fromActions.onUpdateInsuranceStateSuccessfully({ payload: response });
                }
              ),
              catchError((error) => {
                return of(fromActions.onUpdateInsuranceStateFailed({ payload: error }));
              })
            );
        }
      )
    )
  );
  errorOnUpdateInsuranceState$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onUpdateInsuranceStateFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => {
          this.handleOnLoadAllInsuranceErrors(action.payload);
          this.handleInsuranceError();
        }
      )
    ), { dispatch: false }
  );

  getDraftedInsurancesByUserId$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onGetDraftedInsurances.type),
      mergeMap(
        () => {
          return this.service.getAllDraftedInsuranceByUserId()
            .pipe(
              retry(1),
              map(
                response => {
                  return fromActions.onGetDraftedInsurancesSuccessfully({ payload: response });
                }
              ),
              catchError((error) => {
                return of(fromActions.onGetDraftedInsurancesFailed({ payload: error }));
              })
            )})
        )
    );

    createSinglePaymentInsurance$ = createEffect(
      () => this.actions$.pipe(
        ofType(fromActions.onSinglePaymentInsurance.type),
        mergeMap(
          (action: fromActions.ActionWithPayload<string>) => {
            debug(action);
            return this.service.createSinglePaymentInsurance(action.payload)
              .pipe(
                // retry(1),
                map(
                  response => (fromActions.onSinglePaymentInsuranceSuccessfully({ payload: response })),
                ),
                catchError((error) => of(fromActions.onSinglePaymentInsuranceFailed({ payload: error })))
              );
          }
        )
      )
    );

  errorOnGetDraftedInsurancesByUserId$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onGetDraftedInsurancesFailed.type),
      tap(
        (action: fromActions.ActionWithPayload<any>) => {
          this.handleOnLoadAllInsuranceErrors(action.payload);
          this.handleInsuranceError();
        }
      )
    ), { dispatch: false }
  );


  handleOnLoadAllInsuranceErrors(error) {
    debug(error);
    return error;
  }

  handleInsuranceError(): void {
    this.navigationService.navigateToErrorPage();
  }
}

