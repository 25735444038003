import { createAction, props, Action } from '@ngrx/store';
import { CartModel } from '../models/cart.model';
import { PurchasedProducts } from '../models/purchasedProducts.model';
import { OrderConfirmationModel } from '../models/orderConfirmation.model';
/**
 * The createAction function returns a function, that when called returns an object in the shape
 * of the Action interface. The props method is used to define any additional metadata needed
 * for the handling of the action.
 */

/**
 * We can change the cart state using the actions.
 * This is action of post a cart.
 */
export const postCart = createAction('[Cart] postCart',
  props<CartModel>());

/**
 * This is action of post cart when it return successfully.
 */
export const onCartPostedSuccessfully = createAction(
  '[Cart] cart posted successfully',
  props<{ payload: any }>()
);

/**
 * This is action of post cart when it failed.
 */
export const onCartPostingFailed = createAction(
  '[Cart]  cart loading failed',
  props<{ payload: any }>()
);

export const getOrdersByUserId = createAction('[Cart] getOrdersByUserId');

export const onGetOrdersByUserIdSuccessfully = createAction(
  '[Cart] getOrderByUserId successfully',
  props<{ payload: Array<PurchasedProducts> }>()
);

export const onGetOrdersByUserIdFailed = createAction(
  '[Cart] getOrdersByUserId failed',
  props<{ payload: any }>()
);

export const getOrderByReceiptUrl = createAction(
  '[Cart] getOrderByReceiptUrl',
  props<{ receiptURL: string }>()
);

export const onGetOrderByReceiptUrlSuccessfully = createAction(
  '[Cart] getOrderByReceiptUrl successfully',
  props<{ payload: OrderConfirmationModel }>()
);

export const onGetOrderByReceiptUrlFailed = createAction(
  '[Cart] getOrderByReceiptUrl failed',
  props<{ payload: any }>()
);

/**
 * Dispatch to pass payload, you can create parametrizied Action in this way.
 */
export interface ActionWithPayload<T> extends Action {
  payload: T;
}

export interface ActionWithReceiptUrl extends Action {
  receiptURL: string;
}
