import { Component, Input, OnInit } from '@angular/core';
import { IInsuranceOperationResponse } from '@modeso/types__ihub-lib-insurance-be';
import { ICreatePersonRequest } from '@modeso/types__ihub-lib-user-be';

@Component({
  selector: 'ihub-insurance-cancelation-policy',
  templateUrl: './insurance-cancelation-policy.component.html',
  styleUrls: ['./insurance-cancelation-policy.component.scss']
})
export class InsuranceCancelationPolicyComponent implements OnInit {

  @Input() insurance: IInsuranceOperationResponse;
  @Input() firstPossibleCancellationDate: Date;
  @Input() person: ICreatePersonRequest;
  constructor() { }

  ngOnInit() {
  }

}
