import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { ConfirmationComponentComponent } from '@modeso/ihub-lib-insurance-fe';
// tslint:disable-next-line: max-line-length
import { InsuranceStatusEnum } from '@modeso/types__ihub-lib-insurance-be';
import { MyInsuranceDetailsPageControllerDirective } from './my-insurance-details.page.controller';
import { take } from 'rxjs/operators';
import Debug from 'debug';
import { ShopUtil } from '../../utils/shopUtil';
import moment from 'moment';
const debug = Debug('ihub:project:MyInsuranceDetailsPageComponent');
export var InsuranceProducts;
(function (InsuranceProducts) {
    InsuranceProducts["TRAVEL_INSURANCE"] = "travel-care-hansemerkur";
    InsuranceProducts["SKI_INSURANCE"] = "ski-insurance";
    InsuranceProducts["RENTAL_CAR"] = "rentalcar-hansemerkur";
})(InsuranceProducts || (InsuranceProducts = {}));
export class MyInsuranceDetailsPageComponent extends MyInsuranceDetailsPageControllerDirective {
    constructor(route, insuranceProvider, analyticsProvider, injector, router, damageProvider, injectTextPipe, sanitizer, dialog, userProvider) {
        super(route, insuranceProvider, analyticsProvider, injector, router);
        this.damageProvider = damageProvider;
        this.injectTextPipe = injectTextPipe;
        this.sanitizer = sanitizer;
        this.dialog = dialog;
        this.userProvider = userProvider;
        this.popupTitle = '';
        this.isDamageReport = false;
        this.hasShowSummary = false;
        this.additionalAttributes = [];
        this.displayAttributes = [];
        this.waitingForAppResponse = false;
        this.alreadySwitchedToApp = false;
        this.timeoutWait = 180;
        this.personId = localStorage.getItem('personId') ? localStorage.getItem('personId') : undefined;
        this.allProducts = JSON.parse(localStorage.getItem('products'));
        if (this.personId) {
            this.getPerson();
        }
    }
    // tslint:disable-next-line: use-lifecycle-interface
    ngOnInit() {
        super.ngOnInit();
        this.getInsuranceByOrderUuid();
        this.subscriptions.push(this.insuranceProvider.getPaymentToken$().subscribe(this.onRequestPayment.bind(this)));
    }
    getInsuranceByOrderUuid() {
        this.subscriptions.push(this.insuranceProvider.getMyInsuranceWithOrderUuid$(this.orderUuid).subscribe((data) => {
            if (data) {
                this.insuranceDetails = data;
                this.setInsuranceData();
                const hasOneVariation = this.product.variations.length > 1 || !this.product.displayVariationsAccordingToNumber ? false : true;
                const hasFamily = this.product.hasFamily;
                const shopTotal = this.product.shopTotal;
                this.insuranceDetails = Object.assign({}, this.insuranceDetails, { shopTotal, hasFamily, hasOneVariation });
            }
            else {
                this.subscriptions.push(this.insuranceProvider.getInsuranceByOrderUuid$(this.orderUuid).subscribe({
                    // tslint:disable-next-line: no-shadowed-variable
                    next: (data) => {
                        if (data) {
                            this.insuranceDetails = data;
                            this.setInsuranceData();
                            const hasOneVariation = this.product.variations.length > 1 || !this.product.displayVariationsAccordingToNumber ? false : true;
                            const hasFamily = this.product.hasFamily;
                            const shopTotal = this.product.shopTotal;
                            this.insuranceDetails = Object.assign({}, this.insuranceDetails, { shopTotal, hasFamily, hasOneVariation });
                        }
                    }
                }));
            }
        }));
    }
    getInsuranceStatus(status, endDate) {
        if (endDate && new Date() > new Date(endDate)) {
            return InsuranceStatusEnum.EXPIRED;
        }
        else if (status === InsuranceStatusEnum.ACTIVE || status === InsuranceStatusEnum.WAITFORUOF) {
            return InsuranceStatusEnum.ACTIVE;
        }
        return status;
    }
    redirectToDamage() {
        this.router.navigateByUrl(`/de-ch/damage-report/${this.orderUuid}`);
    }
    openPopup(event, title) {
        event.stopPropagation();
        this.renderElement(title);
        this._popupContent = {
            title: this.popupTitle,
            text: this.damageReportHistory(this.damageReports)
        };
        this.popup.visible = true;
    }
    getDamageReportHistory() {
        // tslint:disable-next-line: no-string-literal
        const insuranceId = this.insuranceDetails['_id'];
        let index = 0;
        this.damageProvider.getDamageReportByInsuranceId$(insuranceId).pipe(take(2))
            .subscribe(damage => {
            index++;
            if (index === 2) {
                this.damageReports = damage;
                Object.keys(this.damageReports).length !== 0 ? this.isDamageReport = true : this.isDamageReport = false;
            }
        });
    }
    renderElement(localizedString) {
        this.injectTextPipe.transform(localizedString).pipe(take(1)).subscribe((value) => {
            this.popupTitle = value;
        });
    }
    damageReportHistory(damages) {
        this.text = '';
        for (const damage of damages) {
            // tslint:disable-next-line: max-line-length
            this.text += `<div><div style='color:grey;margin-bottom: 7px;'>${damage.dateOfIncident}</div><div>${damage.description}</div><br></div>`;
        }
        return this.text;
    }
    safeHTML(unsafe) {
        return this.sanitizer.bypassSecurityTrustHtml(unsafe);
    }
    openConfirmationModal(insuranceStatus) {
        const insurance = {
            orderUuid: this.orderUuid,
            insuranceStatus,
            endDate: this.insuranceDetails.endDate,
        };
        const data = {
            insurance,
            mainTitle: 'ihub_shop_cancel_termination',
            description: 'ihub_shop_really_withdraw',
            buttonTextConfirmation: 'ihub_shop_cancel_termination',
            buttonTextClose: 'dgoods_back_button'
        };
        if (insuranceStatus === 2) {
            this.dialog.open(ConfirmationComponentComponent, {
                data,
            });
        }
        else {
            // tslint:disable-next-line: max-line-length
            this.router.navigate([`../../${this.languageFromURL}/first-cancelation-step/${this.orderUuid}`], { state: { insurance: this.insuranceDetails } });
        }
    }
    getPerson() {
        let index = 0;
        this.userProvider.getPersonOfUser$().pipe(take(2))
            .subscribe((person) => {
            index++;
            if (index === 2) {
                if (person) {
                    this.person = person;
                }
            }
        });
    }
    gotoMyAccount() {
        this.router.navigate([`${this.languageFromURL}/account/edit`]);
    }
    showClaimButton() {
        return ((!this.insuranceDetails.endDate || new Date(this.insuranceDetails.endDate) > new Date()) &&
            this.insuranceStatus !== InsuranceStatusEnum.PENDING &&
            this.insuranceStatus !== InsuranceStatusEnum.EXPIRED) ||
            (Object.values(InsuranceProducts).includes(this.insuranceDetails.product) &&
                (moment(this.insuranceDetails.endDate).add(2, 'years').isAfter(moment())));
    }
    showCancellationButton() {
        return !this.product.isSinglePayment &&
            this.insuranceStatus !== InsuranceStatusEnum.PENDING &&
            this.insuranceStatus !== InsuranceStatusEnum.EXPIRED;
    }
    isEndDateReached() {
        if (this.insuranceStatus === InsuranceStatusEnum.CANCELLED) {
            return true;
        }
        else {
            return (this.insuranceDetails.endDate && new Date(this.insuranceDetails.endDate) < new Date()) ? true : false;
        }
    }
    getProductIcon() {
        this.productIcon = this.product.icon;
    }
    setInsuranceData() {
        this.setProduct();
        this.getDamageReportHistory();
        this.getProductIcon();
        this.insuranceStatus =
            this.getInsuranceStatus(this.insuranceDetails.insuranceStatus, this.insuranceDetails.endDate);
    }
    setProduct() {
        this.additionalAttributes = [];
        this.displayAttributes = [];
        this.product = this.allProducts.filter((product) => {
            return product.product === this.insuranceDetails.product;
        })[0];
        this.getHighLights();
        const subCategoryProduct = this.allProducts.find(prod => prod.subCategory.includes(this.product.product));
        this.product.icon = subCategoryProduct ? subCategoryProduct.icon : this.product.icon;
        if (this.product.additionalAttributes) {
            for (const attribute of this.product.additionalAttributes) {
                if (attribute.showOnSummary) {
                    this.additionalAttributes.push(attribute);
                    this.hasShowSummary = attribute.showOnSummary;
                }
            }
        }
        if (this.product.displayAttributes && this.insuranceDetails.displayAttributes.length > 0) {
            for (const attribute of this.product.displayAttributes) {
                this.displayAttributes.push(attribute);
            }
        }
    }
    get InsuranceStatusEnum() {
        return InsuranceStatusEnum;
    }
    confirmPayment(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (event) {
                const request = {
                    orderUuid: this.orderUuid,
                };
                debug('single payment request:', request);
                this.insuranceProvider.dispatchSinglePaymentInsurance(request);
            }
        });
    }
    switchToApp(registrationToken) {
        this.alreadySwitchedToApp = true;
        this.waitingForAppTimer = setInterval(() => {
            debug('checking for update on current insurance');
            if (--this.timeoutWait >= 0) {
                this.insuranceProvider.updateCurrentProductTheUserWantsToBuy$(this.orderUuid);
            }
            else {
                debug('checking for update on current insurance - timeout');
                this.router.navigate(['../../de-ch/paymentcanceld/' + this.orderUuid]);
            }
        }, 1000);
        new ShopUtil().switchToApp(registrationToken, this.product.isSinglePayment);
    }
    onRequestPayment(token) {
        debug('onRequestPayment', token);
        if (token && this.product.isSinglePayment) {
            this.waitingForAppResponse = false;
            this.switchToApp(token);
        }
        return true;
    }
    getHighLights() {
        if (this.product.subCategory.length === 0 && this.product.highlights.length === 0) {
            this.allProducts.forEach(product => {
                if (product.subCategory.includes(this.product.product)) {
                    this.highlights = product.highlights;
                }
            });
        }
        else {
            this.highlights = this.product.highlights;
        }
    }
}
