import { createAction, props, Action } from '@ngrx/store';
import { ISpotlight } from '@modeso/types__ihub-lib-products-be';
import { HttpErrorResponse } from '@angular/common/http';

export const onGetSpotlights = createAction(
  '[Ihub Spotlights] onGetAllSpotlight',
  props<{ payload: string }>()
);
export const onGetSpotlightsSuccessfully = createAction(
  '[Ihub Spotlights] onGetSpotlightsSuccessfully',
  props<{ payload: ISpotlight[] }>()
);

export const onGetSpotlightsFailed = createAction(
  '[Ihub Spotlights] onGetSpotlightsFailed',
  props<{ payload: HttpErrorResponse }>()
);

export interface ActionWithPayload<T> extends Action {
  payload: T;
}
