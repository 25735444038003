import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InjectedTextPipe } from '@modeso/modeso-lib-core-fe';
import {InsuranceProvider} from '../../provider/insurance.provider';
import { NavigationService } from '../../shared/navigation.service';
@Component({
  selector: 'ihub-confirmation-component',
  templateUrl: './confirmation-component.component.html',
  styleUrls: ['./confirmation-component.component.scss']
})
export class ConfirmationComponentComponent implements OnInit {

  public data;
  realDescriptions: string[];
  subscription = [];
  currentLanguage: string;
  constructor(@Inject(MAT_DIALOG_DATA) data: any, private insuranceProvider: InsuranceProvider,
              private injectedTextPipe: InjectedTextPipe, private navigationService: NavigationService) {
    this.data = data;
    this.subscription.push(this.injectedTextPipe.transform(data.description).subscribe((value) => {
      if (!value) {
        return;
      }
      this.realDescriptions = value.split('DD.MM.YYYY');
    }));
    this.currentLanguage =  localStorage.getItem('currentLanguage');

  }
  ngOnDestroy(): void {
    this.subscription.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit() {
  }

  confirm() {
    if (this.data.insurance.insuranceStatus === 2) {
      this.withDrawCancellation();
    } else {
      this.deactivateInsurance();
    }
  }
  deactivateInsurance() {
    const modifiedData = {...this.data.insurance, insuranceStatus: 2};
    this.insuranceProvider.dispatchUpateInsuranceStatus(modifiedData);
    this.navigationService.navigateToPage(`${this.currentLanguage}/my-insurance-detail/${this.data.insurance.orderUuid}`);
  }

  withDrawCancellation() {
    const modifiedData = {...this.data.insurance, insuranceStatus: 1, endDate: null, insuranceCancelationReason: null};
    this.insuranceProvider.dispatchUpateInsuranceStatus(modifiedData);
  }

}
