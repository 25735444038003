import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProductsService } from './services/products.service';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CommonModule } from '@angular/common';
import * as fromProducts from './reducers/products.reducer';
import { ProductsEffects } from './effects/products.effects';
import { ProductsConfig, ProductsConfigService } from './products.config';
import { IHubTileComponent } from './components/ihub-tile/ihub-tile.component';
import { IHubListComponent } from './components/ihub-list/ihub-list.component';
import { InjectedTextFeautureModule, InjectedTextPipe } from '@modeso/modeso-lib-core-fe';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicControlDirective } from './components/dynamic-form/dynamic-control.directive';
import { DateInputControlComponent } from './components/dynamic-form/FormControlComponents/date-input-control/date-input-control.component';
import { InputTextControlComponent } from './components/dynamic-form/FormControlComponents/input-text-control/input-text-control.component';
// tslint:disable-next-line: max-line-length
import { CurrencyInputControlComponent } from './components/dynamic-form/FormControlComponents/currency-input-control/currency-input-control.component';
import { ErrorMessageRendererComponent } from './components/dynamic-form/error-message-renderer/error-message-renderer.component';
import { DgoodsSharedModule } from '@modeso/twint-lib-core-fe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { SpotlightsEffects } from './effects/spotlights.effects';
import { MatDatepickerModule } from '@angular/material/datepicker';
// tslint:disable-next-line: max-line-length
import { PeopleDetailsControlComponent } from './components/dynamic-form/FormControlComponents/people-details-control/people-details-control.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatNativeDateModule, MatRadioModule, MatSelectModule } from '@angular/material';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// tslint:disable-next-line: max-line-length
import { DatePickerControlComponent } from './components/dynamic-form/FormControlComponents/date-picker-control/date-picker-control.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { SelectControlComponent } from './components/dynamic-form/FormControlComponents/select-control/select-control.component';
import { SwitchControlComponent } from './components/dynamic-form/FormControlComponents/switch-control/switch-control.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CheckboxControlComponent } from './components/dynamic-form/FormControlComponents/checkbox-control/checkbox-control.component';
import { TitleControlComponent } from './components/dynamic-form/FormControlComponents/title-control/title-control.component';
// tslint:disable-next-line: max-line-length
import { RadioButtonControlComponent } from './components/dynamic-form/FormControlComponents/radio-button-control/radio-button-control.component';
import { RadioButtonCheckControlComponent } from './components/dynamic-form/FormControlComponents/radio-button-check-control/radio-button-check-control.component';


@NgModule({
  declarations: [
    IHubTileComponent,
    IHubListComponent,
    DynamicFormComponent,
    DynamicControlDirective,
    DateInputControlComponent,
    InputTextControlComponent,
    CurrencyInputControlComponent,
    ErrorMessageRendererComponent,
    PeopleDetailsControlComponent,
    DatePickerControlComponent,
    SelectControlComponent,
    SwitchControlComponent,
    CheckboxControlComponent,
    TitleControlComponent,
    RadioButtonControlComponent,
    RadioButtonCheckControlComponent
  ],
  providers: [ProductsService, MatDatepickerModule],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromProducts.productsFeatureKey, fromProducts.reducer),
    EffectsModule.forFeature([ProductsEffects, SpotlightsEffects]),
    InjectedTextFeautureModule,
    ReactiveFormsModule,
    DgoodsSharedModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatExpansionModule,
    NgxMatNativeDateModule,
    NgxMatDatetimePickerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NgxMatMomentModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatCheckboxModule,
    MatRadioModule
  ],
  exports: [
    IHubTileComponent,
    IHubListComponent,
    InjectedTextPipe,
    DynamicFormComponent,
  ],
  entryComponents: [
    DateInputControlComponent,
    InputTextControlComponent,
    CurrencyInputControlComponent,
    PeopleDetailsControlComponent,
    DatePickerControlComponent,
    SelectControlComponent,
    SwitchControlComponent,
    CheckboxControlComponent,
    TitleControlComponent,
    RadioButtonControlComponent,
    RadioButtonCheckControlComponent],
})
export class ProductsModule {
  static forRoot(config: ProductsConfig): ModuleWithProviders {
    return {
      ngModule: ProductsModule,
      providers: [
        ProductsService,
        {
          provide: ProductsConfigService,
          useValue: config
        },

      ]
    };
  }
}
