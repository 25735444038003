import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BasePageComponent } from '../base.page';
import Debug from 'debug';
// tslint:disable-next-line: max-line-length
import { InsuranceStatusEnum } from '@modeso/types__ihub-lib-insurance-be';
import { ShopUtil } from '../../utils/shopUtil';
import moment from 'moment';
const debug = Debug('dgoods:project:PurchasingHistoryPageController');
export class PurchasingHistoryPageControllerDirective extends BasePageComponent {
    // tslint:disable-next-line: max-line-length
    constructor(analyticsProvider, insuranceProvider, userProvider, injector, router) {
        super(injector);
        this.analyticsProvider = analyticsProvider;
        this.insuranceProvider = insuranceProvider;
        this.userProvider = userProvider;
        this.router = router;
        this.loading = true;
        this.waitingForAppResponse = false;
        this.alreadySwitchedToApp = false;
        this.timeoutWait = 180;
        this.waitingForUofResponse = false;
        this.terminalId = sessionStorage.getItem('terminalId');
        this.insuarances = [];
    }
    ngOnInit() {
        this.getUserInsurances();
        super.ngOnInit();
        this.savePageView();
        this.subscriptions.push(this.insuranceProvider.getPaymentToken$().subscribe(this.onRequestPayment.bind(this)), this.userProvider.getUofRegistartionToken$().subscribe(this.onUoFResponse.bind(this)));
    }
    savePageView() {
        this.analyticsProvider.savePageView$({ productLine: null, pageName: 'Purchasing History', terminalId: this.terminalId, pagePath: this.router.url, });
    }
    onInsuranceItemClicked(orderUuid) {
        this.router.navigateByUrl(`${this.languageFromURL}/my-insurance-detail/${orderUuid}`);
    }
    onBackClicked(sender) {
        this.router.navigate([`../../${this.languageFromURL}/landing-buy`]);
    }
    getUserInsurances() {
        this.loading = true;
        this.insuranceProvider.fetchAllMyPurchasedInsurances$();
        this.subscriptions.push(this.insuranceProvider.getPurchasedInsurances$()
            .subscribe((purchasedInsurance) => {
            const singlePaymentProducts = [];
            if (purchasedInsurance) {
                this.insuarances = purchasedInsurance;
                const allProducts = JSON.parse(localStorage.getItem('products'));
                const productsData = {};
                allProducts.forEach(element => {
                    productsData[element.product] = element;
                    if (element.isSinglePayment) {
                        singlePaymentProducts.push(element.product);
                    }
                });
                this.loading = false;
                this.transactions = this.getAllPaymentsLogs(purchasedInsurance, singlePaymentProducts)
                    .filter(insurance => insurance.amount > 0);
                this.activeInsurances = this.filterData(purchasedInsurance, (item) => (item.insuranceStatus === InsuranceStatusEnum.ACTIVE || item.insuranceStatus === InsuranceStatusEnum.WAITFORUOF) &&
                    !(item.endDate && new Date(item.endDate) < new Date()))
                    .map((activeInsurance) => {
                    return Object.assign({}, this.prepareInsuranceViewModel(activeInsurance, productsData[activeInsurance.product]));
                });
                this.pendingInsurances = this.filterData(purchasedInsurance, (item) => item.insuranceStatus === InsuranceStatusEnum.PENDING)
                    .map((pendingInsurances) => {
                    return Object.assign({}, this.prepareInsuranceViewModel(pendingInsurances, productsData[pendingInsurances.product]));
                });
                this.inActiveInsurances = this.filterData(purchasedInsurance, (item) => (
                // tslint:disable-next-line: max-line-length
                (item.endDate && new Date(item.endDate) < new Date()) || item.insuranceStatus === InsuranceStatusEnum.EXPIRED)).map((inActiveInsurance) => {
                    return Object.assign({}, this.prepareInsuranceViewModel(inActiveInsurance, productsData[inActiveInsurance.product]));
                });
                this.cancelledInsurances = this.filterData(purchasedInsurance, (item) => item.insuranceStatus === InsuranceStatusEnum.CANCELLED && new Date(item.endDate) > new Date())
                    .map((cancelledInsurnace) => {
                    return Object.assign({}, this.prepareInsuranceViewModel(cancelledInsurnace, productsData[cancelledInsurnace.product]));
                });
            }
        }));
    }
    getAllPaymentsLogs(insurances, singlePaymentProducts) {
        let paymentLogs = [];
        insurances.forEach((insurance) => {
            if (insurance.payments && insurance.payments.length) {
                const isSinglePayment = singlePaymentProducts.includes(insurance.product);
                const latestReservationTimePayment = [...insurance.payments].sort((a, b) => {
                    return new Date(b.reservationTimestamp).valueOf() - new Date(a.reservationTimestamp).valueOf();
                })[0];
                const paymentsOfInsurance = insurance.payments.map((payment) => {
                    return {
                        amount: payment.amount,
                        reservationTimestamp: payment.reservationTimestamp,
                        productTitle: insurance.productTitle,
                        response: payment.response,
                        insuranceStatus: insurance.insuranceStatus,
                        isSinglePayment,
                        isLastReservationTime: payment.transcationReference === latestReservationTimePayment.transcationReference ? true : false,
                        orderUuid: insurance.orderUuid,
                        paymentStatus: insurance.paymentStatus
                    };
                });
                paymentLogs = [...paymentLogs, ...paymentsOfInsurance];
                paymentLogs = paymentLogs.filter((log) => log.response !== 'NOT_SENT');
            }
        });
        // tslint:disable-next-line: only-arrow-functions
        paymentLogs.sort(function (a, b) {
            return new Date(b.reservationTimestamp).valueOf() - new Date(a.reservationTimestamp).valueOf();
        });
        return paymentLogs;
    }
    prepareInsuranceViewModel(insurance, product) {
        return {
            productTitle: insurance.productTitle,
            icon: product && product.icon ? product.icon : 'assets/ihub/icn_my_insurances.svg',
            description: product && product.shortDescription ? product.shortDescription : '',
            orderUuid: insurance.orderUuid,
            validFrom: moment(insurance.validFrom).format('DD.MM.YYYY'),
            endDate: insurance.endDate ? moment(insurance.endDate).format('DD.MM.YYYY') : null
        };
    }
    filterData(purchasedInsurance, func) {
        return purchasedInsurance.filter(func)
            .sort((a, b) => {
            return new Date(b.validFrom).valueOf() - new Date(a.validFrom).valueOf();
        });
    }
    confirmPayment(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (event.event) {
                if (event.isSinglePayment) {
                    this.orderUuid = event.orderUuid;
                    this.isSinglePaymentProduct = event.isSinglePayment;
                    const request = {
                        orderUuid: this.orderUuid,
                    };
                    debug('single payment request:', request);
                    this.insuranceProvider.dispatchSinglePaymentInsurance(request);
                }
                else {
                    this.userProvider.startUofRegistration({
                        product: '',
                        orderUuid: '',
                        terminalId: sessionStorage.getItem('terminalId')
                    });
                    this.subscriptions.push(this.userProvider.getUofRegistartionToken$().subscribe(this.onUoFResponse.bind(this)));
                }
            }
        });
    }
    switchToApp(registrationToken) {
        this.alreadySwitchedToApp = true;
        this.waitingForAppTimer = setInterval(() => {
            debug('checking for update on current insurance');
            if (--this.timeoutWait >= 0) {
                this.insuranceProvider.updateCurrentProductTheUserWantsToBuy$(this.orderUuid);
            }
            else {
                debug('checking for update on current insurance - timeout');
                this.router.navigate(['../../de-ch/paymentcanceld/' + this.orderUuid]);
            }
        }, 1000);
        new ShopUtil().switchToApp(registrationToken, this.isSinglePaymentProduct);
    }
    onRequestPayment(token) {
        debug('onRequestPayment', token);
        if (token && this.isSinglePaymentProduct) {
            this.waitingForAppResponse = false;
            this.switchToApp(token);
        }
        return true;
    }
    onUoFResponse(token) {
        this.waitingForUofResponse = true;
        debug('UofResponse', token);
        if (token) {
            new ShopUtil().switchToApp(token);
            this.waitingForUofResponse = false;
        }
        return true;
    }
}
