/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./data-protection-of-twint.page.view.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@modeso/modeso-lib-localization-fe";
import * as i3 from "@ngrx/store";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../../node_modules/@modeso/twint-lib-core-fe/modeso-twint-lib-core-fe.ngfactory";
import * as i6 from "@modeso/twint-lib-core-fe";
import * as i7 from "@angular/common";
import * as i8 from "./data-protection-of-twint.page.view";
import * as i9 from "@angular/router";
var styles_DataProtectionOfTwintPage = [i0.styles];
var RenderType_DataProtectionOfTwintPage = i1.ɵcrt({ encapsulation: 0, styles: styles_DataProtectionOfTwintPage, data: {} });
export { RenderType_DataProtectionOfTwintPage as RenderType_DataProtectionOfTwintPage };
export function View_DataProtectionOfTwintPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.ɵa, [i3.Store, i4.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "overlay"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "dgoods-terms-of-service", [], null, null, null, i5.View_DgoodsTermsOfServiceComponent_0, i5.RenderType_DgoodsTermsOfServiceComponent)), i1.ɵdid(3, 114688, null, 0, i6.DgoodsTermsOfServiceComponent, [], { delegate: [0, "delegate"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵppd(7, 1), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hiddenDataProtection; _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 8).transform(i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), "data_protection_of_twint")))); _ck(_v, 6, 0, currVal_2); }); }
export function View_DataProtectionOfTwintPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-data-protection-of-twint", [], null, null, null, View_DataProtectionOfTwintPage_0, RenderType_DataProtectionOfTwintPage)), i1.ɵdid(1, 245760, null, 0, i8.DataProtectionOfTwintPage, [i1.Injector, i9.Router, i9.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DataProtectionOfTwintPageNgFactory = i1.ɵccf("app-page-data-protection-of-twint", i8.DataProtectionOfTwintPage, View_DataProtectionOfTwintPage_Host_0, { hiddenDataProtection: "hiddenDataProtection" }, { hideDataProtection: "hideDataProtection" }, []);
export { DataProtectionOfTwintPageNgFactory as DataProtectionOfTwintPageNgFactory };
