import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromFeature from './../reducers/products.reducer';
import { Observable } from 'rxjs';
import * as fromActions from '../actions/products.actions';
import * as fromSpotlightActions from '../actions/spotlights.actions';
import { map } from 'rxjs/operators';
import { ISpotlight, ProductDTO } from '@modeso/types__ihub-lib-products-be';


@Injectable({
  providedIn: 'root'
})
export class ProductStoreProvider {

  constructor(public store: Store<fromFeature.AppState>) { }

  public getAllProducts$(): Observable<ProductDTO[]> {
    this.store.dispatch(fromActions.getAllProducts());
    return this.store.pipe(
      select(fromFeature.selectFeatureProducts),
      map((products: ProductDTO[]) => {
        return products;
      }));
  }

  public getProduct$(productId: string): Observable<ProductDTO> {
    return this.store.pipe(select(fromFeature.selectProductById, { id: productId }),
      map((product: ProductDTO) => {
        // retreive saved products array from storage if the store is empty
        const savedProducts = JSON.parse(localStorage.getItem('products'));
        const savedProduct = product ? product :
          (savedProducts && savedProducts.length ? savedProducts.find(x => x.product === productId)
            : null);
        return savedProduct;
      })
    );
  }

  public getTermsAndConditions$(productId: string): Observable<string> {
    return this.store.pipe(select(fromFeature.selectProductById, { id: productId }),
      map((product: ProductDTO) => {
        // retreive saved products array from storage if the store is empty
        const savedProducts = JSON.parse(localStorage.getItem('products'));
        const savedProduct = product ? product :
          (savedProducts && savedProducts.length ? savedProducts.find(x => x.product === productId)
            : null);
        if (savedProduct) {
          return savedProduct.disclaimerLink;
        }
      })
    );
  }

  public getSpotlights$(issuer: string): Observable<ISpotlight[]> {
    return this.store.pipe(select(fromFeature.selectFeatureSpotlights));
  }

  public fetchSpotlights(issuer: string) {
    this.store.dispatch(fromSpotlightActions.onGetSpotlights({payload: issuer}));
  }

  public getFeatureProductByIssuer(issuer: string) {
    this.store.dispatch(fromActions.onGetFeatureProductByIssuer({payload: issuer}));
    return this.store.pipe(select(fromFeature.getFeatureProducts));
  }
}
