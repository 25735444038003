import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CommonModule } from '@angular/common';
import * as fromUser from './reducers/dgoods.user.reducer';
import { DgoodsUserConfigService, DgoodsUserConfig } from './dgoods.user.config';
import { DgoodsUserService } from './services/dgoods.user.service';
import { UserEffects } from './effects/dgoods.user.effects';

@NgModule({
  // declarations: [TestComponent],
  providers: [DgoodsUserService],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromUser.userFeatureKey, fromUser.reducer),
    EffectsModule.forFeature([UserEffects])
  ],
  // exports: [TestComponent]
})
export class DgoodsUserModule {
  static forRoot(config: DgoodsUserConfig): ModuleWithProviders {
    return {
      ngModule: DgoodsUserModule,
      providers: [
        DgoodsUserService,
        {
          provide: DgoodsUserConfigService,
          useValue: config
        }
      ]
    };
  }
}
