import { Component, Input } from '@angular/core';
import Debug from 'debug';
const debug = Debug('ihub:shared:IhHubFilterComponent');

export interface IhubFilterDelegate {
  onFilterItems(item: string): void;
}


@Component({
  selector: 'ihub-filter',
  templateUrl: './ihub-filter.component.html',
  styleUrls: ['./ihub-filter.component.scss']
})
export class IhubFilterComponent {

  @Input() items;
  @Input() selectedIndex: number;
  @Input() delegate?: IhubFilterDelegate;

  constructor() { }

  filterItems(index,item) {
    this.selectedIndex = index;
    if (this.delegate) {
      this.delegate.onFilterItems(item);
    } else {
      debug('filterItems event not fired. No delegate defined for the component.');
    }
  }
}
