import { Component, Input, OnInit, ViewEncapsulation , Output, EventEmitter} from '@angular/core';
import debug from 'debug';


export interface  MenuItem {

  title: string;
  innerarray: {
    action: () => void,
    iconLink: string,
    textLink: string,
    fontColor: string,
    arrowIconLink: string;
  }[];

}
@Component({
  selector: 'tsd-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class MenuComponent implements OnInit {

  @Input() linked: any;
  @Input() showSuperCashback: boolean;
  @Input() showUpcomingDeal: boolean;
  iconIsPressed = false;
  menuArray: MenuItem[];
  @Output() changedBoolean = new EventEmitter<boolean>();

  constructor(  ) { }

  ngOnInit() {
    this.handleDataDynamic();
  }

  public menuOpened() {
    this.iconIsPressed = true;
    this.changedBoolean.emit(this.iconIsPressed);

  }
  public menuClosed() {
    this.iconIsPressed = false;
    this.changedBoolean.emit(this.iconIsPressed);
  }

  public gotoMyAccount() {
    if (this.linked.delegate) {
      this.linked.delegate.gotoMyAccount(this);
    } else {
      debug('onClick event not fired. No delegate defined for the component.');
    }
  }

  public gotoFAQ() {
    if (this.linked.delegate) {
      this.linked.delegate.gotoFAQ(this);
    } else {
      debug('onClick event not fired. No delegate defined for the component.');
    }
  }

  gotoMyOrders() {
    if (this.linked.delegate) {
      this.linked.delegate.gotoMyOrders(this);
    } else {
      debug('onClick event not fired. No delegate defined for the component.');
    }
  }

  public contactUs() {
    if (this.linked.delegate) {
      this.linked.delegate.contactUs(this);
    } else {
      debug('onClick event not fired. No delegate defined for the component.');
    }
  }

  public gotoSuperCashback() {
    if (this.linked.delegate) {
      this.linked.delegate.gotoSuperCashback(this);
    } else {
      debug('onClick event not fired. No delegate defined for the component.');
    }
  }

  public goToNewsLetter() {
    if (this.linked.delegate) {
      this.linked.delegate.goToNewsLetter();
    } else {
      debug('onClick event not fired. No delegate defined for the component.');
    }
  }

  public gotoUpcomingDeal() {
    if (this.linked.delegate) {
      this.linked.delegate.gotoUpcomingDeal(this);
    } else {
      debug('onClick event not fired. No delegate defined for the component.');
    }
  }

  handleDataDynamic() {
    this.menuArray = [{
      title : 'tsd_my_account_title',
      innerarray : [{
        action : () => {
          this.gotoMyAccount();
        },
        textLink : 'tsd_my_account_profile',
        iconLink : 'assets/sd/icon-profile.svg',
        fontColor : '',
        arrowIconLink: 'assets/sd/chevron_right_black.svg'

      },
      {
        action : () => {
          this.gotoMyOrders();
        },
        textLink : 'tsd_my_account_orders',
        iconLink : 'assets/sd/icon-orders.svg',
        fontColor : '',
        arrowIconLink: 'assets/sd/chevron_right_black.svg'
      },
    ] },
    {
      title : 'tsd_helpcenter_title',
      innerarray : [
        {
          action : () => {
            this.goToNewsLetter();
          },
          textLink : 'tsd_subscribe_newsletter',
          iconLink : 'assets/sd/newsletter@2x.svg',
          fontColor : '',
          arrowIconLink: 'assets/sd/chevron_right_black.svg'
        },
        {
          action : () => {
            this.contactUs();
          },
          textLink : 'tsd_my_account_contact',
          iconLink : 'assets/sd/Contact@2x.svg',
          fontColor : '',
          arrowIconLink: 'assets/sd/chevron_right_black.svg'
        },
        {
        action : () => {
          this.gotoFAQ();
        },
        textLink : 'tsd_my_account_faq',
        iconLink : 'assets/sd/icon-faq.svg',
        fontColor : '',
        arrowIconLink: 'assets/sd/chevron_right_black.svg'
      },
    ] }
    ];


    if (this.showUpcomingDeal) {
        const helpIndex = this.menuArray.findIndex( (item) => item.title === 'tsd_helpcenter_title');
        this.menuArray[helpIndex].innerarray.push({
          action : () => {
            this.gotoUpcomingDeal();
          },
          textLink : 'tsd_upcoming_deal',
          iconLink : 'assets/sd/Upcoming_Deal_Icon.svg',
          fontColor : '',
          arrowIconLink: 'assets/sd/chevron_right_black.svg'
        });
      }

    if (this.showSuperCashback) {
      const helpIndex = this.menuArray.findIndex( (item) => item.title === 'tsd_helpcenter_title');
      this.menuArray[helpIndex].innerarray.push({
        action : () => {
          this.gotoSuperCashback();
        },
        textLink : 'tsd_super_cashback',
        iconLink : 'assets/sd/Super_Cashback_Gradient_icon.svg',
        fontColor : '#e95069',
        arrowIconLink: 'assets/sd/chevron_right_cashback.svg'
      });
    }
    return this.menuArray;
  }

}
