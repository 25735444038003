export enum ValidatorType {
  Error = 'error',
  Hint = 'hint',
  CustomForm = 'customForm',
  Min = 'min',
  Today = 'today',
  HideTime = 'hideTime',
  Max = 'max',
  Yesterday = 'yesterday',
  Adult = 'adult',
  BasedOn = 'BASED_ON',
  StartView = 'startView',
  Range = 'RANGE',
  SelectMonth = "selectMonth",
  AutoComplete = "autoComplete",
  DateFormat = "dateFormat"
}
