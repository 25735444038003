import { NgModule } from '@angular/core';
import { NgxBarcodeModule } from 'ngx-barcode';
import { ClipboardModule } from 'ngx-clipboard';
import { CommonModule } from '@angular/common';
import { DGoodsTitleComponent } from './components/title-component/title.component';
import { DGoodsViewContainerComponent } from './components/view-container-component/view.component';
import { DGoodsTextImageInfoComponent } from './components/dgoods-text-image-info/dgoods-text-image-info.component';
import { DGoodsButtonComponent } from './components/dgoods-button/dgoods-button.component';
import { DGoodsInputComponent } from './components/dgoods-input/dgoods-input.component';
import { DGoodsTextAreaComponent } from './components/dgoods-textarea/dgoods-textarea.component';
import { DGoodsCheckboxComponent } from './components/dgoods-checkbox/dgoods-checkbox.component';
import { DGoodsPriceOptionComponent } from './components/dgoods-price-option/dgoods-price-option.component';
import { DGoodsPriceOptionGroupComponent } from './components/dgoods-price-option-group/dgoods-price-option-group.component';
import { DGoodsPriceOptionDenominationComponent } from './components/dgoods-price-option/dgoods-price-option-denomination.component';
import { DGoodsEmailConfirmationComponent } from './components/dgoods-email-confirmation/dgoods-email-confirmation.component';
import { DGoodsTableCellComponent } from './components/dgoods-table/dgoods-table-cell.component';
import { DGoodsTableComponent } from './components/dgoods-table/dgoods-table.component';
import { DGoodsBackButtonComponent } from './components/dgoods-button/dgoods-back-button.component';
import { DGoodsOverlayComponent } from './components/dgoods-overlay/dgoods-overlay.component';
import { DGoodsNavigationComponent } from './components/dgoods-navigation/dgoods-navigation.component';
import { DGoodsTutorialElementComponent } from './components/dgoods-tutorial-element/dgoods-tutorial-element.component';
import { DGoodsTutorialOverlayComponent } from './components/dgoods-tutorial-overlay/dgoods-tutorial-overlay.component';
import { DgoodsOrderConfirmationComponent } from './components/dgoods-order-confirmation/dgoods-order-confirmation.component';
// tslint:disable-next-line: max-line-length
import { DgoodsOrderConfirmationDetailsComponent } from './components/dgoods-order-confirmation/dgoods-order-confirmation-details/dgoods-order-confirmation-details.component';
import { InjectedTextFeautureModule, InjectedTextPipe } from '@modeso/modeso-lib-core-fe';
import { DgoodsTitleWithContentComponent } from './components/dgoods-title-with-content/dgoods-title-with-content.component';
import { DgoodsTermsOfServiceComponent } from './components/dgoods-terms-of-service/dgoods-terms-of-service.component';
import { DGoodsOrderConfirmationOverlayComponent } from './components/dgoods-order-confirmation/dgoods-order-confirmation-overlay/dgoods-order-confirmation-overlay.component';
import { DgoodsDotsLoadingIndicatorComponent } from './components/dgoods-dots-loading-indicator/dgoods-dots-loading-indicator.component';
import { DGoodsDialogBoxComponent } from './components/dgoods-dialogbox/dgoods-dialogbox.component';
import { IhubFilterComponent } from './components/ihub-filter/ihub-filter.component';
import { IHubHighlightsComponent } from './components/ihub-highlights/ihub-highlights.component';
import { IHubSwitchComponent } from './components/ihub-switch/ihub-switch.component';
import { IHubPopUpComponent } from './components/ihub-popup/ihub-popup.component';
import { IHubInsuranceDetailsExpandable } from './components/ihub-detail-expandable/ihub-detail-expandable.component';
import { MatButtonModule, MatExpansionModule, MatInputModule } from '@angular/material';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { IHubWurthLogoComponent } from './components/ihub-wurth-logo/ihub-wurth-logo.component';
import { IHubSliderComponent } from './components/ihub-slider/ihub-slider.component';
import { TsdYoutTubePlayerComponent } from './components/tsd-youtube-player/youtube-player.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { CurrencyNumberPipe } from './pipes/currency.pipe';
import { GetEnumKeyFromValuePipe } from './pipes/get-enum-key-from-value.pipe';
import { PascalCasePipe } from './pipes/pascal-case.pipe';
import { ModesoMarkdownEditorComponent } from './components/markdown/markdown.component';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { MarkdownPipe } from './pipes/markdown.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModesoMarkdownInputComponent } from './components/markdown/markdown-input.component';
import { TsdNewstickerComponent } from './components/tsd-newsticker/tsd-newsticker.component';
import { MenuComponent } from './components/menu/menu.component';
import {MatMenuModule} from '@angular/material/menu';
import { DateCustomControlComponent } from './components/date-custom-control/date-custom-control.component';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    CurrencyNumberPipe,
    GetEnumKeyFromValuePipe,
    PascalCasePipe,
    IHubHighlightsComponent,
    IHubSwitchComponent,
    IHubPopUpComponent,
    IHubInsuranceDetailsExpandable,
    DGoodsTitleComponent,
    DGoodsViewContainerComponent,
    DGoodsTextImageInfoComponent,
    DGoodsButtonComponent,
    DGoodsBackButtonComponent,
    DGoodsInputComponent,
    DGoodsTextAreaComponent,
    DGoodsEmailConfirmationComponent,
    DGoodsCheckboxComponent,
    DGoodsPriceOptionComponent,
    DGoodsPriceOptionDenominationComponent,
    DGoodsPriceOptionGroupComponent,
    DGoodsTableCellComponent,
    DGoodsTableComponent,
    DGoodsOverlayComponent,
    DGoodsNavigationComponent,
    DGoodsTutorialElementComponent,
    DGoodsTutorialOverlayComponent,
    DgoodsOrderConfirmationComponent,
    DgoodsOrderConfirmationDetailsComponent,
    DgoodsTitleWithContentComponent,
    DgoodsTermsOfServiceComponent,
    DGoodsDialogBoxComponent,
    DGoodsOrderConfirmationOverlayComponent,
    DgoodsDotsLoadingIndicatorComponent,
    IhubFilterComponent,
    IHubWurthLogoComponent,
    IHubSliderComponent,
    TsdYoutTubePlayerComponent,
    ModesoMarkdownEditorComponent,
    ModesoMarkdownInputComponent,
    SafeHtmlPipe, MarkdownPipe,
    TsdNewstickerComponent,
    MenuComponent,
    DateCustomControlComponent
  ],
  providers: [InjectedTextPipe, SafeHtmlPipe,
    MarkdownPipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule, MatButtonModule, MatInputModule,
    NgxBarcodeModule,
    InjectedTextFeautureModule,
    ClipboardModule,
    MatExpansionModule,
    CdkAccordionModule,
    YouTubePlayerModule,
    MatMenuModule,
    MatIconModule

  ],
  exports: [
    CurrencyNumberPipe,
    GetEnumKeyFromValuePipe,
    PascalCasePipe,
    IHubHighlightsComponent,
    IHubSwitchComponent,
    IHubPopUpComponent,
    IHubInsuranceDetailsExpandable,
    DGoodsTitleComponent,
    DGoodsViewContainerComponent,
    DGoodsTextImageInfoComponent,
    DGoodsButtonComponent,
    DGoodsBackButtonComponent,
    DGoodsInputComponent,
    DGoodsTextAreaComponent,
    DGoodsEmailConfirmationComponent,
    DGoodsCheckboxComponent,
    DGoodsPriceOptionComponent,
    DGoodsPriceOptionDenominationComponent,
    DGoodsPriceOptionGroupComponent,
    DGoodsTableCellComponent,
    DGoodsTableComponent,
    DGoodsOverlayComponent,
    DGoodsNavigationComponent,
    DGoodsDialogBoxComponent,
    DGoodsTutorialElementComponent,
    DGoodsTutorialOverlayComponent,
    DgoodsOrderConfirmationComponent,
    DgoodsOrderConfirmationDetailsComponent,
    DgoodsTitleWithContentComponent,
    DgoodsTermsOfServiceComponent,
    DgoodsDotsLoadingIndicatorComponent,
    IhubFilterComponent,
    IHubWurthLogoComponent,
    TsdYoutTubePlayerComponent,
    IHubSliderComponent,
    ModesoMarkdownEditorComponent,
    ModesoMarkdownInputComponent,
    SafeHtmlPipe,
    MarkdownPipe,
    TsdNewstickerComponent,
    MenuComponent,
    DateCustomControlComponent
  ]
})
export class DgoodsSharedModule {
}




