import { createAction, Action, props } from '@ngrx/store';
import { IUpdateUserRequest, IUser } from '../models/user.model';
import {
  IUserStartUoFRegistrationRequest, IUserStartUoFRegistrationResponse,
  IUserUoFDeRegistrationResponse,
  ICreatePersonRequest
} from '@modeso/types__ihub-lib-user-be';

export const onUpdateUser = createAction(
  '[Dgoods update user] update user',
  props<{ payload: IUpdateUserRequest }>()
);

export const onUpdateUserSuccess = createAction(
  '[Dgoods update user successfully] update user successfully',
  props<{ payload: IUpdateUserRequest }>()
);

export const onUpdateUserFailed = createAction(
  '[Dgoods update user failed] update user failed',
  props<{ payload: IUpdateUserRequest }>()
);

export const onStartUofRegistration = createAction(
  '[Dgoods user] onStartUofRegistration',
  props<{ payload: IUserStartUoFRegistrationRequest }>()
);

export const onStartUofRegistrationSuccess = createAction(
  '[Dgoods user] onStartUofRegistrationSuccess',
  props<{ payload: IUserStartUoFRegistrationResponse }>()
);

export const onStartUofRegistrationFailed = createAction(
  '[Dgoods user] onStartUofRegistrationFailed',
  props<{ payload: any }>()
);

export const onCreatePerson = createAction(
  '[IHub create person] create person',
  props<{ payload: ICreatePersonRequest }>()
);

export const onCreatePersonSuccess = createAction(
  '[IHub create person successfully] create person successfully',
  props<{ payload: string }>()
);

export const onCreatePersonFailed = createAction(
  '[IHub create person failed] create person failed',
  props<{ payload: any }>()
);

export const getPersonById = createAction(
  '[IHub Get Person] getPersonById',
  props<{ payload: string }>()
);

export const getPersonOfUser = createAction(
  '[IHub Get Person] getPersonOfUser'
);

export const onGetPersonLoadedSuccessfully = createAction(
  '[IHub Get Person] get person by id loaded successfully',
  props<{ payload: ICreatePersonRequest }>()
);

export const onGetPersonLoadingFailed = createAction(
  '[IHub Get Person] get person by id loading failed',
  props<{ payload: any }>()
);


export const onUpdatePerson = createAction(
  '[IHub update person] update person',
  props<{ payload: ICreatePersonRequest }>()
);

export const onUpdatePersonSuccess = createAction(
  '[IHub update person successfully] update person successfully',
  props<{ payload: string }>()
);

export const onUpdatePersonFailed = createAction(
  '[IHub update person failed] update person failed',
  props<{ payload: any }>()
);

export const onUofDeRegistration = createAction(
  '[Dgoods user] onUofDeRegistration'
);

export const onUofDeRegistrationSuccess = createAction(
  '[Dgoods user] onUofDeRegistrationSuccess',
  props<{ payload: IUserUoFDeRegistrationResponse }>()
);

export const onUofDeRegistrationFailed = createAction(
  '[Dgoods user] onUofDeRegistrationFailed',
  props<{ payload: any }>()
);


export const getUserDetails = createAction(
  '[Dgoods user] getUserDetails'
);

export const getUserDetailsSuccess = createAction(
  '[Dgoods user] getUserDetailsSuccess',
  props<{ payload: IUser }>()
);

export const getUserDetailsFailed = createAction(
  '[Dgoods user] getUserDetailsFailed',
  props<{ payload: any }>()
);
export interface IActionWithPayload<T> extends Action {
  payload: T;
}
