import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CustomNgxDatetimePickerModule} from './adapters/CustomNgxDatetimePickerModule';

@NgModule({
  declarations: [
  ],
  providers: [
  ],
  imports: [
    CommonModule,
    CustomNgxDatetimePickerModule,
  ],
  exports: [
  ]
})
export class SharedModule { }
