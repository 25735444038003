import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'modeso-frontend-gateway';

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {
    let browserLang = navigator.language.substr(0, 2);
    if (!['de', 'fr', 'it', 'en'].includes(browserLang)) {
      browserLang = 'en';
    }
    this.document.documentElement.lang = browserLang;
    this.updateIndexHtmlFile();
  }

  updateIndexHtmlFile() {
    try {
      const script = document.createElement('script');
      script.defer = true;
      script.src = 'https://plausible.io/js/script.js';
      script.setAttribute('data-domain', environment.dataDomain);
      this.document.head.appendChild(script);

    } catch (ex) {
      console.error('Error appending plausible' + ex);
    }
  }
}
