import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FakeCheckinPage } from './pages/FakeCheckin/fakeCheckin.page';
import { LandingBuyPage } from './pages/Landing/landing-buy.page.view';
import { PurchasingHistoryPage } from './pages/PurchaseHistory/purchasing-history.page.view';
import { OneTimeUrlStartPage } from './pages/OneTimeUrlStart/onetimeurlStart.page';
import { ProductBuyPage } from './pages/ProductBuy/product-buy.page.view';
import { OrderConfirmationPage } from './pages/OrderConfirmation/order-confirmation.page.view';
import { ErrorPage } from './pages/ErrorPage/error.page.view';
import { PaymentCanceldPage } from './pages/PaymentCanceldPage/paymentcanceld.page.view';
import { NavigationAuthGuard } from './shared/services/guards/NavigationAuthGuard.service';
import { LoginPage } from './pages/login/login.page';
import { TermsOfTwintPage } from './pages/TermsOfTwint/terms-of-twint.page.view';
import { TermsOfProviderPage } from './pages/TermsOfProvider/terms-of-provider.page.view';
import { AccountPage } from './pages/Account/account.page.view';
import { DataProtectionOfTwintPage } from './pages/DataProtectionOfTwint/data-protection-of-twint.page.view';
import { FAQPage } from './pages/FAQ/faq.page';
import { MaintenancePage } from './pages/Maintainance/maintenance.page';
import { MaintenenceGuard } from './shared/services/guards/MaintenenceGuard.service';
import { NoMaintenenceGuard } from './shared/services/guards/NoMaintenenceGuard.service';
import { InsuranceBuyPage } from './pages/InsuranceBuy/insurance-buy.page.view';
import { InsuranceConfirmationPage } from './pages/InsuranceConfirmation/insurance-confirmation.page.view';
import { InsuranceDetailPage } from './pages/InsuranceDetail/insurance-detail.page.view';
import { InsuranceUserDetailPage } from './pages/InsuranceUserDetails/insurance-user-detail.page.view';
import { InsuranceSummaryPage } from './pages/InsuranceSummary/insurance-summary.page.view';
import { MyInsuranceDetailsPageComponent } from './pages/MyInsuranceDetailsPage/my-insurance-details.page.component';
import { DamageReportPageController } from './pages/DamageReport/damage-report.page.controller';
import { CancelationPolicyPageComponent } from './pages/CancelationPolicyPage/cancelation-policy-page.component';
import { InsuranceCancelationPageComponent } from './pages/insurance-cancelation-page/insurance-cancelation-page.component';
import { PaymentAlertPage } from './pages/PaymentAlert/paymentalert.page';
import { MajorErrorPage } from './pages/MajorError/error.page.view';


const routes: Routes = [
  // {
  //   path: ':language/mockedchecking',
  //   component: FakeCheckinPage,
  // },
  {
    path: ':language/login',
    component: LoginPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/landing-buy',
    component: LandingBuyPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/maintenance',
    component: MaintenancePage,
    canActivate: [NoMaintenenceGuard]
  },
  {
    path: ':language/history',
    component: PurchasingHistoryPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/terms-and-conditions/:productLineId',
    component: TermsOfProviderPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/terms-of-twint/:productLineId',
    component: TermsOfTwintPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/data-protection-of-twint/:productLineId',
    component: DataProtectionOfTwintPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/error',
    component: ErrorPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/majorerror',
    component: MajorErrorPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/paymentcanceld',
    component: PaymentCanceldPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/product-buy/:productLineId',
    component: ProductBuyPage,
    canActivate: [NavigationAuthGuard, MaintenenceGuard]
  },
  {
    path: ':language/insurance-detail/:product',
    component: InsuranceDetailPage,
    canActivate: [NavigationAuthGuard, MaintenenceGuard]
  },
  {
    path: ':language/insurance-buy/:product',
    component: InsuranceBuyPage,
    canActivate: [NavigationAuthGuard, MaintenenceGuard]
  },
  {
    path: ':language/my-insurance-detail/:orderUuid',
    component: MyInsuranceDetailsPageComponent,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/paymentalert/:type',
    component: PaymentAlertPage,
    canActivate: [MaintenenceGuard]
  },

  {
    path: ':language/user-details',
    component: InsuranceUserDetailPage,
    canActivate: [NavigationAuthGuard, MaintenenceGuard]
  },
  {
    path: ':language/summary',
    component: InsuranceSummaryPage,
    canActivate: [NavigationAuthGuard, MaintenenceGuard]
  },
  {
    path: ':language/damage-report/:orderUuid',
    component: DamageReportPageController,
    canActivate: [MaintenenceGuard]
  }, {
    path: ':language/first-cancelation-step/:orderUuid',
    component: CancelationPolicyPageComponent,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/second-cancelation-step/:orderUuid',
    component: InsuranceCancelationPageComponent,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/insurance-confirmation/:orderUuid',
    component: InsuranceConfirmationPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/order-confirmation/:receiptUrl',
    component: OrderConfirmationPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/account/edit',
    component: AccountPage,
    canActivate: [MaintenenceGuard, NavigationAuthGuard]
  },
  {
    path: ':language/account/faq',
    component: FAQPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: ':language/:urltoken',
    component: OneTimeUrlStartPage,
    canActivate: [MaintenenceGuard]
  },
  {
    path: '',
    redirectTo: '/de-ch',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: LoginPage,
    canActivate: [MaintenenceGuard]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
