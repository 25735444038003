import * as tslib_1 from "tslib";
import { Injector, OnInit } from '@angular/core';
import { DGoodsButtonComponent, IDGoodsButtonDelegate, } from '@modeso/twint-lib-core-fe';
import Debug from 'debug';
import { InsuranceCheckoutBaseComponent } from '../InsuranceCheckout/InsuranceCheckoutBase.component';
import { IActivateInsuranceRequest, IInsuranceProduct, InsuranceStatusEnum } from '@modeso/types__ihub-lib-insurance-be';
import { UserProvider } from '@modeso/ihub-lib-user-fe';
import { take } from 'rxjs/operators';
import { ShopUtil } from '../../utils/shopUtil';
import moment from 'moment';
const debug = Debug('dgoods:project:InsuranceSummaryPageControllerDirective');
export class InsuranceSummaryPageControllerDirective extends InsuranceCheckoutBaseComponent {
    constructor(router, route, insuranceProvider, productProvider, injector) {
        super(router, route, insuranceProvider, productProvider, injector);
        this.router = router;
        this.observables = new Map();
        this.acceptTerm1 = false;
        this.disabled = true;
        this.receiveEmails = false;
        this.hasAccount = false;
        this.alreadySwitchedToApp = false;
        this.waitingForAppResponse = false;
        this.timeoutWait = 180;
        this.enrichedAdditionalAttributes = [];
        this.userProvider = injector.get(UserProvider);
        this.personId = localStorage.getItem('personId') ? localStorage.getItem('personId') : undefined;
        if (this.personId) {
            this.getPerson();
        }
    }
    /** NgInit */
    ngOnInit() {
        super.ngOnInit();
        this.subscriptions.push(this.insuranceProvider.getPaymentToken$().subscribe(this.onRequestPayment.bind(this)));
        this.subscriptions.push(this.userProvider.getUofRegistartionToken$().subscribe(this.onUoFResponse.bind(this)));
        this.userProvider.getUser$().subscribe((user) => {
            if (user) {
                this.uofStatus = user.user.uofStatus;
            }
        });
        this.subscriptions.push(this.draft$.subscribe(draft => {
            if (!draft || this.alreadySwitchedToApp || !this.uofStatus) {
                return;
            }
            this.draft = draft;
            this.product = JSON.parse(localStorage.getItem('products')).filter(product => product.product === this.draft.product)[0];
            // tslint:disable-next-line: no-string-literal
            this.draft['hasFamily'] = this.product.hasFamily;
            // tslint:disable-next-line: no-string-literal
            // tslint:disable-next-line: max-line-length
            this.draft.hasOneVariation = this.product.variations.length > 1 || !this.product.displayVariationsAccordingToNumber ? false : true;
            if (this.draft.additionalAttributes) {
                const attributes = this.checkOnAdditionalAttributes(this.draft.additionalAttributes);
                this.getAttributes(attributes);
            }
            if (this.draft.insuranceStatus === InsuranceStatusEnum.PENDING || this.draft.insuranceStatus === InsuranceStatusEnum.ACTIVE) {
                if (this.waitingForAppTimer) {
                    clearInterval(this.waitingForAppTimer);
                }
                if (!this.product.isSinglePayment) {
                    if (this.uofStatus !== 'ACTIVE') {
                        this.userProvider.startUofRegistration({
                            product: draft.product,
                            orderUuid: draft.orderUuid,
                            terminalId: draft.terminalId
                        });
                    }
                    else {
                        this.router.navigate([`${this.languageFromURL}/insurance-confirmation/` + this.draft.orderUuid]);
                    }
                }
            }
        }));
    }
    getAttributes(additionalAttributes) {
        this.enrichedAdditionalAttributes = [
            ...(this.product.additionalAttributes || [])
        ]
            .filter(attr => attr.showOnSummary)
            .map(attr => {
            const attrValue = additionalAttributes[attr.id];
            let enrichedValue = attrValue;
            if (attr.enum) {
                const enumValue = Object.values(attr.enum).find((e) => e.value === attrValue);
                enrichedValue = enumValue.useValue ? enumValue.value : enumValue.language;
            }
            if (attr.validation['dateFormat']) {
                enrichedValue = moment(attrValue, "DD.MM.YYYY").format(attr.validation['dateFormat'].value);
            }
            return Object.assign({}, attr, { value: enrichedValue });
        });
    }
    // tslint:disable-next-line: use-lifecycle-interface
    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.waitingForAppTimer) {
            clearInterval(this.waitingForAppTimer);
        }
    }
    onRequestPayment(token) {
        debug('onRequestPayment', token);
        if (token && this.product.isSinglePayment) {
            this.switchToApp(token);
            this.router.navigate([`${this.languageFromURL}/insurance-confirmation/` + this.draft.orderUuid]);
        }
        return true;
    }
    /** UOF --- START */
    onUoFResponse(token) {
        debug('UofResponse', token);
        if (token) {
            this.switchToApp(token);
            this.router.navigate([`${this.languageFromURL}/insurance-confirmation/` + this.draft.orderUuid]);
        }
        return true;
    }
    switchToApp(registrationToken) {
        this.alreadySwitchedToApp = true;
        new ShopUtil().switchToApp(registrationToken, this.product.isSinglePayment);
    }
    /** UOF --- END */
    /** IDGoodsButtonDelegate --- START */
    onButtonClicked(sender, id) {
        debug('Button clicked', sender);
        if (id === 'next') {
            this.navigateNext();
        }
        else if (id === 'back') {
            // Navigate back
            this.navigateBack();
        }
    }
    navigateBack() {
        this.router.navigate(['../../de-ch/user-details']);
    }
    navigateNext() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.waitingForAppResponse = true;
            this.waitingForAppTimer = setInterval(() => {
                debug('checking for update on current insurance');
                if (--this.timeoutWait >= 0) {
                    this.insuranceProvider.updateCurrentProductTheUserWantsToBuy$(this.draft.orderUuid);
                }
                else {
                    debug('checking for update on current insurance - timeout');
                    this.router.navigate(['../../de-ch/paymentcanceld/' + this.draft.orderUuid]);
                }
            }, 1000);
            if (this.product.isSinglePayment) {
                yield this.confirmPayment();
            }
            else {
                // Set insurance product in store
                yield this.activateInsurance();
            }
        });
    }
    /** IDGoodsButtonDelegate --- END */
    activateInsurance() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.draft === undefined) {
                throw new Error('there is no draft to update...');
            }
            const request = {
                orderUuid: this.draft.orderUuid,
            };
            debug('activateInsurance request:', request);
            this.updatePerson();
            this.insuranceProvider.activateInsurance(request);
        });
    }
    emitAcceptedTerms() {
        if (this.acceptTerm1 && this.hasAccount) {
            this.disabled = false;
        }
        else {
            this.disabled = true;
        }
    }
    getPerson() {
        let index = 0;
        this.userProvider.getPersonOfUser$().pipe(take(2))
            .subscribe((person) => {
            index++;
            this.personId = undefined;
            if (index === 2) {
                if (person) {
                    this.personId = person._id;
                    this.person = person;
                    this.receiveEmails = person.receiveEmails;
                }
            }
        });
    }
    updatePerson() {
        this.person.receiveEmails = this.receiveEmails;
        this.person.id = this.personId;
        this.userProvider.updatePerson$(this.person);
    }
    confirmPayment() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.draft === undefined) {
                throw new Error('there is no draft to update...');
            }
            const request = {
                orderUuid: this.draft.orderUuid,
            };
            debug('single payment request:', request);
            this.updatePerson();
            this.insuranceProvider.dispatchSinglePaymentInsurance(request);
        });
    }
    checkOnAdditionalAttributes(additionalAttributes) {
        if (Array.isArray(additionalAttributes)) {
            return additionalAttributes[0];
        }
        else {
            return additionalAttributes;
        }
    }
}
