import { Injectable, InjectionToken, Inject } from '@angular/core';
import Debug from 'debug';

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
const debug = Debug('modeso:project:ServiceIsOfflineHandler');

export interface IServiceIsOfflineDelegate {
  onServiceIsOffline(request: HttpRequest<any>);
}

export const SERVICE_IS_OFFLINE_INTERCEPTOR = new InjectionToken<IServiceIsOfflineDelegate>('SERVICE_IS_OFFLINE_INTERCEPTOR');
@Injectable()
export class ServiceIsOfflineHandler implements HttpInterceptor {

  constructor(@Inject(SERVICE_IS_OFFLINE_INTERCEPTOR) private serviceIsOfflineDelegate: IServiceIsOfflineDelegate) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    return next.handle(request).pipe(
      catchError(err => {
        debug(err);
        if (err.status === 0) {
          // redirect to checkin again
          // localStorage.clear();

          if (this.serviceIsOfflineDelegate) {
            debug('onServiceIsOffline, doesnt respond');
            debug(request.url);
            this.serviceIsOfflineDelegate.onServiceIsOffline(request);
          } else {
            debug('onServiceIsOffline event not fired. No delegate defined for the IServiceIsOfflineDelegate.');
          }
        } else if (err.status === 504) {
          // redirect to checkin again
          // localStorage.clear();

          if (this.serviceIsOfflineDelegate) {
            debug('onServiceIsOffline');
            debug(request.url);
            this.serviceIsOfflineDelegate.onServiceIsOffline(request);
          } else {
            debug('onServiceIsOffline event not fired. No delegate defined for the IServiceIsOfflineDelegate.');
          }
        }

        return throwError(err);
      })
    );

  }
}
