import { Component, Input, OnInit , Output , EventEmitter } from '@angular/core';
import { ITransactionItem } from '../../models/transaction-item.model';

@Component({
  selector: 'ihub-transaction-list-item',
  templateUrl: './transaction-list-item.component.html',
  styleUrls: ['./transaction-list-item.component.scss']
})
export class TransactionListItemComponent implements OnInit {

  @Input() dataSource: ITransactionItem[];
  @Output() isRedirectToPayment: EventEmitter<any> = new EventEmitter(null);

  constructor() { }

  ngOnInit() {
  }

  activateInsurance($event) {
    this.isRedirectToPayment.emit($event);
  }
}
