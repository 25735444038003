import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductsConfigService } from './../products.config';
import Debug from 'debug';
import { ISortingOrder, ProductDTO } from '@modeso/types__ihub-lib-products-be';

const debug = Debug('modeso:modeso-products:ProductsService');

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

export interface ProductsResponse {
  products: Array<ProductDTO>;
}

@Injectable({
  providedIn: 'root',
})
export class ProductsService {

  private server = '';
  private microserviceName = 'products';
  private readonly productsRoute = '/';
  private readonly featureRoute = 'feature';

  constructor(private http: HttpClient, @Inject(ProductsConfigService) private config) {
    this.server = config.apiGateway;
    debug('ProductAPIGATEWAY: ' + config.apiGateway);
    debug('ProductServiceId: ' + config.serviceId);
  }

  getAllProducts() {
    debug('get all products called');
    const url = `${this.server}${this.microserviceName}${this.productsRoute}`;
    return this.http.get<ProductsResponse>(url, httpOptions);
  }

  getSpotlights() {
    debug('get all spotlights called');
    const url = `${this.server}${this.microserviceName}${this.productsRoute}`;
    return this.http.get<ProductsResponse>(url, httpOptions);
  }

  getFeatureProductByIssuer(issuerId: string) {
    debug('get feature product by issuer');
    const url = `${this.server}${this.microserviceName}${this.productsRoute}${this.featureRoute}${this.productsRoute}${issuerId}`;
    return this.http.get<ISortingOrder>(url, httpOptions);
  }

}

