<div class="slider-wrapper" (swipeleft)="onSwipeLeft($event)"
(swiperight)="onSwipeRight($event)">
  <div class="slider-container" #slidecontainer [ngStyle]=transformStyle>
    <div  *ngFor="let slide of slides; let i = index">
      <div class="row">
          <div class="slide"
            (tap)="onTap($event, slide)"
            >
            <img  [src]="slide.teaserImage" class="imgTag" >
          </div>
      </div>
    </div>
  </div>
  <br>
  <div style="text-align:center">
    <span *ngFor="let slide of slides; let i=index;"
        [class.active]="currentSlide === i"
        (click)="dotClick(i)"
        class="dot">
    </span>
  </div>

</div>



