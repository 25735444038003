import { Component, Input, OnInit } from '@angular/core';
import { IInsuranceItem } from '../../models/insurance-item.model';
import Debug from 'debug';
const debug = Debug('ihub:insurance:IncuranceItemComponent');
export interface IIHubInsuranceItemDelegate {
  onInsuranceItemClicked(data: string): void;
}

@Component({
  selector: 'ihub-incurance-item',
  templateUrl: './incurance-item.component.html',
  styleUrls: ['./incurance-item.component.scss']
})
export class IncuranceItemComponent implements OnInit  {
  @Input() dataSource: IInsuranceItem;
  @Input() active: boolean;
  @Input() delegate: IIHubInsuranceItemDelegate;
  @Input() showDates: boolean;
  constructor() { }
  onClick(data: string): void {
    if (this.delegate) {
      this.delegate.onInsuranceItemClicked(data);
    } else {
      debug('onInsuranceItemClicked event not fired. There is no delegate attached to the component.');
    }
  }

  ngOnInit() {
  }

}
