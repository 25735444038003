import { Component, OnInit, Injector } from '@angular/core';
import { DGoodsTableDataSource } from '@modeso/twint-lib-core-fe';
import Debug from 'debug';
import { PurchasingHistoryPageControllerDirective } from './purchasing-history.page.controller';
import { Router } from '@angular/router';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
import {InsuranceProvider } from '@modeso/ihub-lib-insurance-fe';
import { UserProvider } from '@modeso/ihub-lib-user-fe';
const debug = Debug('dgoods:shared:PurchasingHistoryPageUiTest');

@Component({
  selector: 'app-purchasing-history-page',
  templateUrl: './purchasing-history.page.view.html',
  styleUrls: ['./purchasing-history.page.view.scss']
})
export class PurchasingHistoryPage extends PurchasingHistoryPageControllerDirective implements OnInit {

  // tslint:disable-next-line: max-line-length
  constructor(analyticsProvider: AnalyticsProvider, insuranceProvider: InsuranceProvider, userProvider: UserProvider , injector: Injector, router: Router) {
    super(analyticsProvider, insuranceProvider, userProvider, injector, router);
  }



  ngOnInit() {
    super.ngOnInit();
  }
  // onRowClick(data: IDGoodsTableDataSource);

  onClickedDataTable(data: DGoodsTableDataSource) {
    debug(data);
    const linkParts = data.link.split('/');

    debug(linkParts[linkParts.length - 1]);

    this.router.navigate(['../../de-ch/order-confirmation/', linkParts[linkParts.length - 1]], { queryParams: { navigation: 'back' } });
  }

}
