import { Action, createReducer, on, createSelector } from '@ngrx/store';
import * as fromActions from './../actions/insurance.actions';
import {
  IInsuranceOperationResponse, IInsurancePartial
} from '@modeso/types__ihub-lib-insurance-be';


export const insuranceFeatureKey = 'modesoInsuranceMicroservice';

// State Declarations - START

export interface FeatureState {
  insurance: IInsurancePartial; // Current selected insurance which the user wants to buy
  activeInsurances: Array<IInsuranceOperationResponse>;
  insuranceDetails: IInsuranceOperationResponse;
  draftedInsurances: Array<IInsuranceOperationResponse>;
  policyPdf: any;
  avbPdf:any;
  error: any;
  paymentToken: string;
  spinnerState: boolean;
}

export interface AppState {
  modesoInsuranceMicroservice: FeatureState;
}

// State Declarations - END

// Selectors Declarations - START

export const selectFeature = (state: AppState) => state.modesoInsuranceMicroservice;
export const featureInsurance = createSelector(
  selectFeature,
  (state: FeatureState) => state.insurance
);

export const featureError = createSelector(
  selectFeature,
  (state: FeatureState) => state.error
);

export const featureActiveInsurances = createSelector(
  selectFeature,
  (state: FeatureState) => state.activeInsurances
);
export const featurePolicyPdf = createSelector(
  selectFeature,
  (state: FeatureState) => state.policyPdf
);
export const featureAVBPdf = createSelector(
  selectFeature,
  (state: FeatureState) => state.avbPdf
);
export const featureInsuranceDetails = createSelector(
  selectFeature,
  (state: FeatureState) => state.insuranceDetails
);

export const featureDraftedInsurances = createSelector(
  selectFeature,
  (state: FeatureState) => state.draftedInsurances
);

export const featurePaymentToken = createSelector(
  selectFeature,
  (state: FeatureState) => state.paymentToken
);

export const featureCreateInsuranceResponse = createSelector(
  selectFeature,
  (state: FeatureState) =>  {
    return {response: state.insurance , error: state.error , spinnerState: state.spinnerState };
  }
);
// Selectors Declarations - END

// Reducer Declarations - START

export const initialState: FeatureState = {
  insurance: undefined,
  insuranceDetails: undefined,
  policyPdf: undefined,
  activeInsurances: new Array<IInsuranceOperationResponse>(),
  error: undefined,
  draftedInsurances: new Array<IInsuranceOperationResponse>(),
  paymentToken: null,
  spinnerState: false,
  avbPdf:undefined
};

const insuranceReducer = createReducer(
  initialState,
  on(fromActions.createInsurance, (state) => ({ ...state, error: undefined , spinnerState: true })),
  // tslint:disable-next-line: max-line-length
  on(fromActions.onInsuranceOperationSuccessfully, (state, action) => ({ ...state, insurance: action.payload as IInsurancePartial, error: undefined  , spinnerState: false})),
  on(fromActions.onInsuranceOperationFailed, (state, action) => ({ ...state, error: action.payload , spinnerState: false})),

  on(fromActions.activateInsurance, (state) => ({ ...state, error: undefined  })),
  // tslint:disable-next-line: max-line-length
  on(fromActions.onUpdateInsuranceSuccessfully, (state, action) => ({ ...state, insurance: action.payload as IInsurancePartial, error: undefined})),
  on(fromActions.onUpdateInsuranceFailed, (state, action) => ({ ...state, error: action.payload })),


  on(fromActions.flush, (state) => ({ ...state, insurance: undefined, error: undefined , paymentToken: null})),

  on(fromActions.getInsurancesByUserId, (state) => ({ ...state, error: undefined })),
  on(fromActions.onGetInsurancesByUserIdSuccessfully, (state, action) => ({ ...state, activeInsurances: action.payload })),
  on(fromActions.onGetInsurancesByUserIdFailed, (state, action) =>
    ({ ...state, activeInsurances: new Array<IInsuranceOperationResponse>(), error: action.payload })),

  on(fromActions.getInsuranceDetailsByOrderUuid, (state) => ({ ...state, error: undefined })),
  on(fromActions.ongetInsuranceDetailsByOrderUuidSuccessfully, (state, action) => ({ ...state, insuranceDetails: action.payload })),
  on(fromActions.ongetInsuranceDetailsByOrderUuidFailed, (state, action) =>
    ({ ...state, error: action.payload, insuranceDetails: undefined })),
  on(fromActions.onUpdateInsuranceState, (state) => ({ ...state, error: undefined })),
  on(fromActions.onUpdateInsuranceStateSuccessfully, (state, action) => {
    const modifiedObjectIndex = state.activeInsurances.findIndex((insurance) => insurance.orderUuid === action.payload.orderUuid);
    state.activeInsurances[modifiedObjectIndex] = action.payload;
    const updatedArray = [...state.activeInsurances];
    return ({ ...state, activeInsurances: updatedArray, insuranceDetails: action.payload });
  }),
  on(fromActions.onUpdateInsuranceStateFailed, (state, action) =>
    ({ ...state, error: action.payload, insuranceDetails: undefined })),

  on(fromActions.onGetPolicyPdf, (state) => ({ ...state, error: undefined, policyPdf: undefined })),
  on(fromActions.onGetPolicyPdfSuccessfully, (state, action) => {
    return ({ ...state, policyPdf: action.payload });
  }),
  on(fromActions.onUpdateInsuranceStateFailed, (state, action) =>
    ({ ...state, error: action.payload, policyPdf: undefined })),


  on(fromActions.onGetAVBPdf, (state) => ({ ...state, error: undefined, avbPdf: undefined })),
  on(fromActions.onGetAVBPdfSuccessfully, (state, action) => {
    return ({ ...state, avbPdf: action.payload });
  }),
  on(fromActions.onGetAVBPdfFailed, (state, action) =>
  ({ ...state, error: action.payload, avbPdf: undefined })),

  on(fromActions.onGetDraftedInsurances, (state) => ({ ...state, error: undefined })),
  on(fromActions.onGetDraftedInsurancesSuccessfully, (state, action) => ({ ...state, draftedInsurances: action.payload })),
  on(fromActions.onGetDraftedInsurancesFailed, (state, action) =>
    ({ ...state, error: action.payload, draftedInsurances: undefined })),
  on(fromActions.updateInsuranceState, (state, action) => ({ ...state, insurance: action.payload })),

  on(fromActions.onSinglePaymentInsurance, (state) => ({ ...state, error: undefined })),
  // tslint:disable-next-line: max-line-length
  on(fromActions.onSinglePaymentInsuranceSuccessfully, (state, action) => ({ ...state, insurance: action.payload.insuranceResponse as IInsurancePartial, paymentToken: action.payload.registrationToken, error: undefined })),
  on(fromActions.onSinglePaymentInsuranceFailed, (state, action) => ({ ...state, error: action.payload })),

);

export function reducer(state: FeatureState | undefined, action: Action) {
  return insuranceReducer(state, action);
}
