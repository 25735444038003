<div class="product-buy-container" [ngClass]="getVisibility() ? 'visible' : 'invisible'">
  <dgoods-navigation [delegate]="this" [account]="false"></dgoods-navigation>
  <div *ngIf="provider" class="fixed-background">
    <div>
      <dgoods-text-image-info src={{provider.displayImage}} class="add-shadow"
        infobutton="dgoods_shared_textimageinfocomponent_information" [delegate]="this">
        {{provider.title}}
      </dgoods-text-image-info>
    </div>

    <div class="fixed-background">
      <dgoods-price-option-group [delegate]="this" [prices]="prices">
      </dgoods-price-option-group>
    </div>
    <div class="email-container">

      <dgoods-checkbox #switchToSendAsGift name="test-checkbox" [isProductPage]="true"
        [delegate]="this"
        [selected]="checkBoxSelected"
        [checked]="checkBoxSelected">
        {{"dgoods_shop_productbuyview_sendToOthers1" | localize | async}}
        {{"dgoods_shop_productbuyview_sendToOthers2" | localize | async}}
        {{"dgoods_shop_productbuyview_sendToOthers3" | localize | async}}
      </dgoods-checkbox>

      <dgoods-view-container>
        <dgoods-email-confirmation class="email"  #emailConfirmation [hidden]="!checkBoxSelected" [isProductPage]="true"
          yourName="dgoods_shared_inputcomponent_yourname" yourMessage="dgoods_shared_inputcomponent_yourmsg"
          caption="dgoods_shared_inputcomponent_emailrecipient" [delegate]="this">
        </dgoods-email-confirmation>
      </dgoods-view-container>
    </div>
    <div class="terms-conditions">
      <dgoods-checkbox *ngIf="!provider.enforceTC" name="test-checkbox1" [selected]="false" #checkbox1 [delegate]="this">
        {{"dgoods_shop_productbuyview_acceptTerms1" | localize | async}}
        <span (click)="onTermsOfProviderClick()" class="link">
          {{"dgoods_shop_productbuyview_acceptTerms2" | localize | async}}
        </span> {{"dgoods_shop_productbuyview_acceptTerms3" | localize | async}} <span
          class="bold">{{productLineName}}</span>.
      </dgoods-checkbox>
      <dgoods-checkbox *ngIf="provider.enforceTC" (click)="onTermsOfProviderClick()" name="test-checkbox1" [selected]="false"
        #checkbox1 [delegate]="this">
        {{"dgoods_shop_productbuyview_acceptTerms1" | localize | async}}
        <span class="link">
          {{"dgoods_shop_productbuyview_acceptTerms2" | localize | async}}
        </span> {{"dgoods_shop_productbuyview_acceptTerms3" | localize | async}} <span
          class="bold">{{productLineName}}</span>.
      </dgoods-checkbox>
      <dgoods-checkbox name="test-checkbox2" [selected]="false" #checkbox2 [delegate]="this" *ngIf="!twintTC">
        {{"dgoods_shop_productbuyview_acceptTerms1" | localize | async}}
        <a href="{{getAgbLink()}}" target="_blank">
          {{"dgoods_shop_productbuyview_acceptTerms2" | localize | async}}
          {{"dgoods_shop_productbuyview_andThe" | localize | async}}
          {{"dgoods_shop_productbuyview_acceptDataProtection" | localize | async}}
        </a>
        {{"dgoods_shop_productbuyview_acceptTerms3" | localize | async}}
        <span class="bold">{{"twint" | localize | async}}</span>.
      </dgoods-checkbox>
    </div>
    <div class="bottom-container">
      <div class="sticky">
        <dgoods-button [delegate]="this" class="button-style" [disabled]="disabled">
          {{"dgoods_shop_productbuyview_buybutton" | localize | async }}
        </dgoods-button>
      </div>
    </div>
  </div>


</div>

<dgoods-overlay #errorOverlay [hidden]="true" [message]="message" [delegate]="this" [error]="true">
</dgoods-overlay>

<dgoods-overlay #progressOverlay [hidden]="true" [delegate]="this" [progress]="true">
</dgoods-overlay>

<dgoods-overlay #appSwitch [hidden]="true" [delegate]="this" [html]="true">
  <div class="checkout-confirmation-container">
    <img class="twint-logo" src="assets/twint_logo_q_pos_bg.png" />
    <div class="checkout-confirmation-content">
      <div class="confirmation-img-msg">
        <img class="confirmation-img" src="assets/icon-shopping-sart.png" />
        <span class="confirmation-msg">{{"confirmation_msg" | localize | async}}</span>
      </div>
    </div>
  </div>
</dgoods-overlay>

<dgoods-overlay #productDescriptionInfo [hidden]="true" [delegate]="this" [html]="true" [htmlScroll]="true"
  *ngIf="provider">
  <div class="product-description">
    <p class="product-description-text" [innerHTML]="provider.productLineDescription"></p>
    <div class="sticky">
      <dgoods-button [delegate]="this" class="button-style">
        {{"dgoods_shop_productbuyview_closebutton" | localize | async }}
      </dgoods-button>
    </div>
  </div>
</dgoods-overlay>


<app-email-page [hidden]="hiddenEmailOverlay" (hideOverLay)="ResetOverlaysFlags()"></app-email-page>



<app-page-data-protection-of-twint [hiddenDataProtection]="toDataProtectionOfTwintOverlay"
  (hideDataProtection)="ResetOverlaysFlags()"></app-page-data-protection-of-twint>

<app-page-terms-of-provider [enforceTC]="provider.enforceTC" [hiddenTermsOfProvider]="toTermsOfProvider" (hideTermsOfProvider)="onHideTermsOfProvider($event)">
</app-page-terms-of-provider>

<app-page-terms-of-twint [hiddenTermsOfTwint]="toTermsOfTwint" (hideTermsOfTwint)="ResetOverlaysFlags()">
</app-page-terms-of-twint>

<dgoods-dialogbox #dialogBoxOverlay [hidden]="true" [dialogBoxData]="curerntDialogBox" [delegate]="this"
  [descriptionFromLocalization]="fromLocalization">
</dgoods-dialogbox>
