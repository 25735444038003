import { Component, OnInit, Injector, ChangeDetectorRef, Renderer2 } from '@angular/core';
import Debug from 'debug';
import { TermsOfProviderPageControllerDirective } from './terms-of-provider.controller';
import { ProductStoreProvider } from '@modeso/ihub-lib-products-fe';
import { Router, ActivatedRoute } from '@angular/router';


const debug = Debug('dgoods:shop:TermsOfProviderPage');
@Component({
  selector: 'app-page-terms-of-provider',
  templateUrl: './terms-of-provider.page.view.html',
  styleUrls: ['./terms-of-provider.page.view.scss']
})
export class TermsOfProviderPage extends TermsOfProviderPageControllerDirective implements OnInit {
  termAndCondition: string;
  constructor(
    router: Router,
    route: ActivatedRoute,
    private productProvider: ProductStoreProvider,
    injector: Injector,
    changeDetector: ChangeDetectorRef,
    renderer2: Renderer2) {
    super(injector, router, route, changeDetector, renderer2);
  }

  getTermsOfProvider() {
    this.subscriptions.push(
      this.productProvider.getTermsAndConditions$(super.getProductId()).subscribe(termAndConditionProvider => {
        this.termAndCondition = termAndConditionProvider;
      })
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getTermsOfProvider();
  }
}

