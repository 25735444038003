import { Injectable  } from '@angular/core';
import { Actions, createEffect, ofType, act } from '@ngrx/effects';
import { DgoodsSessionService, ICheckInRequest, IStartRequest, ICheckInRequestAuth, IValidateUrlRequest } from '../services/dgoods.session.service';
import * as fromActions from '../actions/dgoods.session.actions';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of} from 'rxjs';
import { Router } from '@angular/router';
import Debug from 'debug';

const debug = Debug('modeso:dgoods-session:DgoodsSessionEffects');

@Injectable()
export class DgoodsSessionEffects {

  constructor(private actions$: Actions, private service: DgoodsSessionService, private router:Router) { }

  onCheckin$ = createEffect (
    () => this.actions$.pipe(
      ofType(fromActions.onCheckin.type),
      mergeMap((action: fromActions.IActionWithPayloadAndAuth<ICheckInRequest, ICheckInRequestAuth>) => {
         return this.service.checkin(action.payload, action.auth)
         .pipe(
           tap(
             (response) => this.logResponse(response)
           ),
           map(
             (response) => {
               return fromActions.onCheckinSuccess({ payload: response });
             }
           ),
           catchError((error) => {
             return of(fromActions.onCheckinFail());
           })
         );
      })
    )
  );

  onCheckinSuccess$ = createEffect (
    () => this.actions$.pipe(
      ofType(fromActions.onCheckinSuccess.type),
      tap(
        (action: fromActions.IActionWithPayload<any>) => {
          this.logResponse('checkin successfuly');
        }
      )
    )
    , {dispatch: false}
  );

  onChekinFailed$ =  createEffect (
    () => this.actions$.pipe(
      ofType(fromActions.onCheckinFail.type),
      tap(
        (action: fromActions.IActionWithPayload<any>) => {
          this.logError('checkin failed');
        }
      )
    )
    , {dispatch: false}
  );


  onStart$ = createEffect (
    () => this.actions$.pipe(
      ofType(fromActions.onStart.type),
      mergeMap((action: fromActions.IActionWithPayload<IStartRequest>) => {
         return this.service.start(action.payload)
         .pipe(
           tap(
             (response) => this.logResponse(response)
           ),
           map(
             (response) => {
               debug("onStartSuccess");
               return fromActions.onStartSuccess({ payload: response });
             }
           ),
           catchError((error) => {
             return of(fromActions.onStartFail({ payload: error }));
           })
         );
      })
    )
  );

  onStartSuccess$ = createEffect (
    () => this.actions$.pipe(
      ofType(fromActions.onStartSuccess.type),
      tap(
        (action: fromActions.IActionWithPayload<any>) => {
          this.logResponse('start successfully');
        }
      )
    )
    , {dispatch: false}
  );

  onStartFailed$ =  createEffect (
    () => this.actions$.pipe(
      ofType(fromActions.onStartFail.type),
      tap(
        (action: fromActions.IActionWithPayload<any>) => {
          this.logError('start failed');
          if(action.payload.status === 403){
            this.router.navigate(['/de-ch/error'], { queryParams: { errorStatus: 'forbidden' } });
          }
        }
      )
    )
    , {dispatch: false}
  );

  onGetNewAccessToken$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onGetNewAccesToken.type),
      mergeMap(
        () => {
          return this.service.getNewAccesToken()
          .pipe(
            tap(
              (response) => this.logResponse(response)
            ),
            map(
               (reponse) => {
                  return fromActions.onGetNewTokenSuccess();
               }
              ),
            catchError((err) => {
              return of(fromActions.onGetNewTokenFailed());
            }
          )
          );
        }
      )
    )
  );
  onNewAccessTokenSuccess$ = createEffect(
    () => this.actions$.pipe(
        ofType(fromActions.onGetNewTokenSuccess.type),
        tap(
            (action: fromActions.IActionWithPayload<any>) => {
              this.logResponse('token generated successfuly');
            }
        )
    )
    , {dispatch: false}
  );

  onNewAccessTokenFailed$ = createEffect(
    () => this.actions$.pipe(
        ofType(fromActions.onGetNewTokenFailed.type),
        tap(
            (action: fromActions.IActionWithPayload<any>) => {
              this.logError('token generation failed');
            }
        )
    )
    , {dispatch: false}
  );

  onLogout$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onLogout.type),
      mergeMap(() => {
        return this.service.logout()
        .pipe(
          tap(
            (response) => this.logResponse(response)
          ),
          map(response => {
            return fromActions.onLogoutSuccess();
          }),
          catchError((error) => {
            return of(fromActions.onLogoutFail());
          })
        );
      })
    )
  );

  onLogoutSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onLogoutSuccess.type),
      tap(
        (action: fromActions.IActionWithPayload<any>) => {
          this.logResponse('logout is done successfully');
        }
      )
    )
    , {dispatch: false}
  );

  onLogoutFail$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onLogoutFail.type),
      tap(
        (action: fromActions.IActionWithPayload<any>) => {
          this.logError('logout failed');
        }
      )
    )
    , {dispatch: false}
  );

  onValidateUrl$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onValidateUrl.type),
      mergeMap((action: fromActions.IActionWithPayload<IValidateUrlRequest>) => {
        return this.service.validateUrl(action.payload)
        .pipe(
          tap(
            (response) => this.logResponse(response)
          ),
          map(response => {
            return fromActions.onValidateUrlSuccess();
          }),
          catchError((error) => {
            return of(fromActions.onValidateUrlFail());
          })
        );
      })
    )
  );

  onValidateUrlSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onValidateUrlSuccess.type),
      tap(
        (action: fromActions.IActionWithPayload<any>) => {
          this.logResponse('url is valid till now');
        }
      )
    )
    , {dispatch: false}
  );

  onValidateUrlFail$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onValidateUrlFail.type),
      tap(
        (action: fromActions.IActionWithPayload<any>) => {
          this.logError('url is not valid anymore');
        }
      )
    )
    , {dispatch: false}
  );



  logResponse(data) {
    debug(data);
  }

  logError(data) {
    debug(data);
  }
}
