import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CommonModule } from '@angular/common';
import { CartConfig, CartConfigService } from './cart.config';
import * as fromReducer from './reducers/cart.reducer';
import { CartEffects } from './effects/cart.effects';
import { CartService } from './services/cart.service';



@NgModule({
  declarations: [],
  providers: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromReducer.cartFeatureKey, fromReducer.reducer),
    EffectsModule.forFeature([CartEffects]),
  ],
  exports: []
})
export class CartModule {
  static forRoot(config: CartConfig): ModuleWithProviders {
    return {
      ngModule: CartModule,
      providers: [
        CartService,
        {
          provide: CartConfigService,
          useValue: config
        }
      ]
    };
  }
}
