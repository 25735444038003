<div class="insurance-details-container ihub-styles" *ngIf="insuranceDetails">
  <div class="insurance-details-container__titles">
    <div class="main_title">{{ insuranceDetails.productTitle }}</div>
    <p class="sub-title">
      {{ "ihub_shop_everything_you_know" | injectedText | async }}
    </p>
  </div>
  <div *ngIf="insuranceDetails" class="policy-container">
    <ihub-virtualpolicy
      [policy]="insuranceDetails"
      [person]="person"
      [productIcon]="productIcon"
    ></ihub-virtualpolicy>
    <ng-container *ngIf="product">
        <button class="contact-actions__btn"><a class="contact-link" href="{{'tel:'+ product.productContactNumber}}">
          {{ "ihub_shop_contact_call" | injectedText | async }}
        </a></button>
    </ng-container>
  
  
    <a (click)="gotoMyAccount()">{{
      "ihub_shop_personal_information" | injectedText | async
    }}</a>
    <div class="line-breaker up-line-break"></div>
    <ihub-view-insurance-highlights *ngIf="highlights.length > 0" [highlights]='highlights'>
    </ihub-view-insurance-highlights>
    <div class="line-breaker down-line-break"></div>
  </div>
  <ihub-insurance-information
    [insuranceDetails]="insuranceDetails"
    [insuranceStatus]="insuranceStatus"
    [additionalAttributes]="additionalAttributes"
    [displayAttributes]="displayAttributes"
    [hasShowSummary]="hasShowSummary"
    (isRedirectToPayment)="confirmPayment($event)"
    [isSinglePayment]="product.isSinglePayment"
    [isEndDateReached]="isEndDateReached()"
  ></ihub-insurance-information>
  <div class="damage-report">
    <button
      *ngIf="showClaimButton()"
      class="damage-report__button"
      (click)="redirectToDamage()"
    >
     {{ "ihub_shop_report_damage" | injectedText | async }}
    </button>
    <a
      *ngIf="isDamageReport"
      class="damage-report__history-link"
      (click)="openPopup($event, 'ihub_shop_damage_report')"
      >{{ "ihub_shop_report_damage_history" | injectedText | async }}</a
    >
  </div>
  <div class="line-breaker"></div>
  <ihub-insurance-merchant-documents
    [insurance]="insuranceDetails"
    [person]="person"
    [product]="product"
  >
  </ihub-insurance-merchant-documents>
  <ihub-insurance-cancelation-actions *ngIf="showCancellationButton()"
    [delegate]="this"
    [insuranceStatus]="insuranceStatus"
  ></ihub-insurance-cancelation-actions>
</div>
<div style="margin-bottom: 16px">
  <dgoods-button [delegate]="this" identifier="back">
    {{ "dgoods_back_button" | injectedText | async }}
  </dgoods-button>
</div>

<ihub-popup #popup [content]="_popupContent"></ihub-popup>
