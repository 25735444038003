import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions/ihub.damage.actions';
import { mergeMap, map, catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import Debug from 'debug';
import { IHubDamageService } from '../services/ihub.damage.service';
const debug = Debug('modeso:ihub-lib-damage-fe:DamageEffects');


@Injectable()
export class DamageEffects {
  languageFromURL;
  constructor(private actions$: Actions, private service: IHubDamageService,private router: Router,
    private activatedRoute: ActivatedRoute
    ) {
    }

  onCreateDamageReport$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.onCreateDamageReport.type),
      mergeMap(
        (action: any) => {
          this.languageFromURL=localStorage.getItem('currentLanguage');

          return this.service.createDamageReport(action.payload)
            .pipe(
              retry(1),
              tap(response => debug(response.id)
              ),
              map(
                response => (fromActions.onCreateDamageReportSuccess({ payload: response.id })),
              ),
              catchError((error) => of(fromActions.onCreateDamageReportFailed({ payload: error })))
            );
        }
      )
    )
  );

  onCreateDamageReportFailed$ = createEffect(
    () => this.actions$.pipe(
        ofType(fromActions.onCreateDamageReportFailed.type),
        tap(
            (action: fromActions.IActionWithPayload<any>) => {
              console.log(`${this.languageFromURL}/error`)
              this.router.navigate([`${this.languageFromURL}/error`]);
            },
        ),
    )
    , { dispatch: false },
  );

  getDamage$ = createEffect(
    () => this.actions$.pipe(
      ofType(fromActions.getDamageByInsuranceId.type),
      mergeMap(
        (payload: any) => {
          return this.service.getDamageReportByInsuranceId(payload.payload)
            .pipe(
              retry(1),
              tap(
                response => debug(response)
              ),
              map(
                response => (fromActions.onGetDamageByInsuranceIdSuccessfully({ payload: response.damageReport }))
              ),
              catchError((error) => of(fromActions.onGetDamageByInsuranceIdFailed({ payload: error })))
            );
        }
      )
    )
  );

}
