import { BehaviorSubject } from "rxjs"

export abstract class BaseControlHandlerClass {
    subject: BehaviorSubject<any>;
    onKeyUp($event) {
        console.log('Method not implemented');
    }
    onChange($event) {
        console.log('Method not implemented');
    }
}
