import {
  IIHubListDelegate, IIHubTileDelegate, IIHubTile
  , ProductStoreProvider
} from '@modeso/ihub-lib-products-fe';
import { Prices, ProductDTO, VariationConfiguration } from '@modeso/types__ihub-lib-products-be';
import { IDGoodsNavigationDelegate, IDGoodsTutorialOverlayDelegate } from '@modeso/twint-lib-core-fe';
import { Router, ActivatedRoute } from '@angular/router';
import Debug from 'debug';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromApp from './../../state/app.state.reducer';
import { BasePageComponent } from '../base.page';
import { Injector, OnInit, Directive } from '@angular/core';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
import { IhubFilterDelegate } from '@modeso/twint-lib-core-fe';
import { InsuranceProvider } from '@modeso/ihub-lib-insurance-fe';
import { IInsuranceOperationResponse, PaymentStatusEnum } from '@modeso/types__ihub-lib-insurance-be';
import { IIHubAlertType } from '../../components/alert/alert.component';
import { ISlideObject, ITwintSliderDelegate } from '../../components/slider/ihub-slider.component';
import { PriceUtil } from '../../utils/priceUtil';
import { UserProvider } from '@modeso/ihub-lib-user-fe';
import { take , delay} from 'rxjs/operators';
import { DelayPaymemtUtil } from '../../utils/delayPaymentUtil';
import { ShopUtil } from '../../utils/shopUtil';

const debug = Debug('dgoods:project:LandingBuyPageController');

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'app-landing-page-controller'
})
export class LandingBuyPageControllerDirective extends BasePageComponent implements
  OnInit,
  IIHubListDelegate,
  IIHubTileDelegate,
  IDGoodsNavigationDelegate,
  IDGoodsTutorialOverlayDelegate,
  ITwintSliderDelegate,
  IhubFilterDelegate {

  protected observables = new Map<string, Observable<ProductDTO[]>>();
  hiddenTutorialOverlay: boolean;
  hiddenAccountOverlay: boolean;
  historyLink: IIHubTile;
  insuranceProductTiles = [];
  curerntDialogBox = { title: '', description: '' };
  terminalId: string;
  filteredProducts = [];
  productCategories = [];
  productsExistInLocalStorage = true;
  alertType: IIHubAlertType = IIHubAlertType.NONE;
  blockedProducts: string[] = [];
  products: ProductDTO[];
  isSuperCashbackVisible: any;
  uofStatus: any;
  alertText: string;
  alertDetailslink: any;
  failedSinglePaymentInsurances: IInsuranceOperationResponse[];

  protected productLineDialogBox = {
    title: 'dgoods_shop_productbuyview_dialogbox_title_productLine_exceed_limit',
    description: 'dgoods_shop_productbuyview_dialogbox_message_productLine_exceed_limit',
  };
  protected shopDialogBox = {
    title: 'dgoods_shop_productbuyview_dialogbox_title_shop_exceed_limit',
    description: 'dgoods_shop_productbuyview_dialogbox_message_shop_exceed_limit',
  };

  public insurances: IInsuranceOperationResponse[];
  public loadingInsurances = true;
  public hasInsurances = false;
  public featureProduct: IIHubTile;
  public featureProductId: string;
  constructor(
    private analyticsProvider: AnalyticsProvider,
    protected insuranceProvider: InsuranceProvider,
    private router: Router,
    private route: ActivatedRoute,
    protected productProvider: ProductStoreProvider,
    private store: Store<fromApp.AppState>,
    private injector: Injector,
    private userProvider: UserProvider

  ) {
    super(injector);
    this.getAllProducts();
    this.productProvider.fetchSpotlights(this.getIssuer());
    this.terminalId = sessionStorage.getItem('terminalId');
  }

  getAllProducts() {
    this.observables.set('getAllProducts', this.productProvider.getAllProducts$());
  }

  /** OnInit --- START */

  ngOnInit() {
    super.ngOnInit();
    this.savePageView();
    this.hiddenTutorialOverlay = true;
    this.hiddenAccountOverlay = true;
  }

  savePageView() {
    this.analyticsProvider.savePageView$({
      productLine: null, pageName: 'Landing Buy',
      terminalId: this.terminalId,
      pagePath: this.router.url,
    });
  }

  /** OnInit --- END */
  /** IIHubTileDelegate --- START */

  onTileClick(tile: any): void {
    debug('On Tile Click: Product');
    debug(tile);

    if (tile.reference === 'history') {
      this.router.navigate(['../../de-ch/history']);
    }
    if (tile.reference === 'feature' && !tile.blocked && !this.products.filter(p => p.subCategory.includes(tile.product)).length) {
      this.router.navigate(['../../de-ch/insurance-detail/' + tile.product]);
    }
  }

  onSlideClick(slide: ISlideObject) {

    if (slide.product === 'UNDEFINED') {
      return;
    }
    // Save click to PageView
    this.analyticsProvider.savePageView$({
      productLine: slide.product, pageName: 'SpotlightClick',
      terminalId: this.terminalId,
      pagePath: this.router.url,
    });

    this.router.navigate(['../../de-ch/insurance-detail/' + slide.product]);
  }


  /** IIHubTileDelegate --- END */
  /** IIHubListDelegate --- START */
  onListItemClicked(item: IIHubTile): void {
    debug('On Tile Click: Product');
    debug(item);
    if (item.blocked) {
      this.curerntDialogBox = this.productLineDialogBox;
    } else {
      // Hotfix because after filtering, there is not a IIHubTile in the list, instead it's just a product.
      if (item.reference === undefined) {
        // tslint:disable-next-line: no-string-literal
        item.reference = item['product'];
      }
      this.router.navigate(['../../de-ch/insurance-detail/' + item.reference]);
    }
  }

  /** IIHubListDelegate --- END */
  /** IDGoodsNavigationDelegate --- START */

  onBackClicked() {
    // TODO add the router to navigate to it.
    new ShopUtil().switchToAppAndCloseWindow();
  }

  onAccountClicked() {
    this.router.navigate(['../../de-ch/account/edit']);
  }

  /** IDGoodsNavigationDelegate --- END */
  /** IDGoodsTutorialOverlayDelegate --- START */

  /*
  * click on button ,tutorial overlay disappear
  */
  onOverlayButtonClicked() {
    this.hiddenTutorialOverlay = !this.hiddenTutorialOverlay;
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 2);
  }

  /** IDGoodsTutorialOverlayDelegate --- END */

  onFilterItems(category) {
    debug('Filter  selected');
    const allProducts: ProductDTO[] = JSON.parse(localStorage.getItem('products'));
    let products = allProducts.filter((product) => {
      let isSubCategoryProduct = false;
      if (allProducts.find(prod => prod.subCategory.includes(product.product) )) {
          isSubCategoryProduct = true;
      }
      return !product.hidden && !isSubCategoryProduct && ( product.product !== this.featureProductId); });

    if (products) {
      products = new DelayPaymemtUtil().getDelayPaymentForProducts(products);
      this.insuranceProductTiles = this.productCategories.includes(category) && category !== 'All' ?
        products.filter((product) => {
          return product.category === category;
        })
        : products;
      this.insuranceProductTiles = this.insuranceProductTiles.map((element) => {
        return {
          ...element,
          icon: element.icon,
          reference: element.product,
          blocked: element.blocked,
          description: element.shortDescription,
          // tslint:disable-next-line: max-line-length
          price:  element.priceStaticConfig ?  element.priceStaticConfig :
                  element.singlePrices && element.variations[0] ?
                  PriceUtil.getMinimumVariationPrice(element.singlePrices, element.variations[0].name).toFixed(2) : '' ,
          delayPaymentByMonth: element.delayPaymentByMonth,
          isPromoProduct: element.isPromoProduct,
          paymentPer: element.paymentPer,
          title: element.subCategory.length > 0 ? element.productCategoryTitle : element.title,
          isCategory : element.subCategory.length > 0 ? true : false
        } as IIHubTile;
      });
      this.insuranceProductTiles.forEach((tile: IIHubTile) => {
        if (this.blockedProducts.filter(item => item === tile.reference).length > 0) {
          tile.blocked = true;
        }
      });
      this.insuranceProductTiles.sort((a, b) => a.blocked - b.blocked);

      debug('tiles blocked 3');
      this.productsExistInLocalStorage = true;
    } else {
      this.insuranceProductTiles = [];
      this.productsExistInLocalStorage = false;
    }
  }

  getUser() {
    this.userProvider.getUser$().pipe(take(2))
      .subscribe((res: any) => {
        if (res) {
          this.uofStatus = res.user.uofStatus;
          const uofInsurances = this.insurances && this.products ?
            this.insurances.filter(i => !!this.products.find(p => p.product === i.product && !p.isSinglePayment)) : [];

          if (this.uofStatus !== 'ACTIVE' && uofInsurances.length > 0) {
            this.alertType = IIHubAlertType.UOF_INACTIVE;
          }
          // else {
          //   // invoked again to update this function with the uofStatus
          //   this.getFailedPayment(this.insurances);
          // }
        }
      });
  }

  getFailedPayment(purchasedInsurance) {
    const paymentFailedInsurances = purchasedInsurance
    .filter((insurance: IInsuranceOperationResponse) =>
      (insurance.paymentStatus !== PaymentStatusEnum.PAYED && insurance.paymentStatus !== PaymentStatusEnum.ORDER_SUCCESS))
    .filter(insurance => !!insurance.paymentStatus); // filter discount

    this.failedSinglePaymentInsurances = paymentFailedInsurances
      .filter(insurance => this.products && !!this.products.find(p => p.product === insurance.product && p.isSinglePayment));
    if ( paymentFailedInsurances.length > 0 ) {
      // There is an alert...
      this.alertType = IIHubAlertType.BUSINESS_ERROR;
    }
  }
}

