import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IHubPopUpComponent, IIHubPopupContent } from '@modeso/twint-lib-core-fe';
import { IConfiguredAttribute } from '@modeso/types__ihub-lib-products-be';
import { IInsuranceAttributeControl } from '../../ComponentFactory/IInputControl.interface';
import { BaseControl } from '../Base';
import { NGX_MAT_DATE_FORMATS, NgxMatDatetimePicker } from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular-material-components/moment-adapter';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { CUSTOM_DATE_FORMATS, CUSTOM_MOMENT_FORMATS } from '../../../../utils/date-formats';
import { ControlHandlerName } from '../../../../utils/enums/controlHandlerName.enum';
import { ControlsHandlerFactory } from '../../../../utils/FormHandlers/ControlsHandlerFactory';
import { ValidatorType } from '../../../../utils/enums/validatorType.enum';
import moment, { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';


@Component({
  selector: 'ihub-date-picker-control',
  templateUrl: './date-picker-control.component.html',
  styleUrls: ['./date-picker-control.component.scss'],
  providers: [
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS},
    {provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
  ],
})
export class DatePickerControlComponent extends BaseControl implements OnInit, IInsuranceAttributeControl  {
  @ViewChild('popup', { static: true }) popup: IHubPopUpComponent;
  popupContent: IIHubPopupContent;
  insuranceAttribute: IConfiguredAttribute;
  form: FormGroup;
  // tslint:disable-next-line: no-inferrable-types
  hideTime: boolean = true;
  startView = 'month';
  min;
  max;
  dateFormat = "DD.MM.YYYY"
  private controlsHandlerFactory: ControlsHandlerFactory;
  selectMonth: boolean = false;

  constructor() {
    super();
    this.controlsHandlerFactory =  ControlsHandlerFactory.getInstance();
  }
  validationErrors: any;

  ngOnInit() {
    if (this.insuranceAttribute.value) {
      this.form.get(this.insuranceAttribute.id)
         .setValue(this.getMomentDateFormat(this.insuranceAttribute.value));
    }
  }

  ngAfterViewInit(){
    this.setDateValidation();
  }
  extractedErrors() {
    let flattenedErrorsObject = {};
    const errors = this.getFormControl(this.form, this.insuranceAttribute.id).errors;
    for (const key in errors) {
      if (typeof errors[key] === 'object') {
        flattenedErrorsObject = {
          ...flattenedErrorsObject,
          ...errors[key]
        };
      } else {
        flattenedErrorsObject[key] = errors[key];
      }
    }
    return flattenedErrorsObject;
  }
  onDateChange($event: any) {

    const handler = this.controlsHandlerFactory.getObject(`${this.insuranceAttribute.id}Handler`);
    if (!handler) {
      return;
    } else {
      handler.onKeyUp($event);
    }
  }


  public openPopup(event: any) {
    event.stopPropagation();
    this.popupContent = {
      title: (this.insuranceAttribute.title as string),
      text: (this.insuranceAttribute.text as string),
      needsTranslation: false,
    };
    this.popup.visible = true;
  }

  getFormattedDate() {
    const formControl = this.getFormControl(this.form, this.insuranceAttribute.id);
    if (formControl.valid)
      return moment(formControl.value, "DD.MM.YYYY").format(this.dateFormat)
      
    return formControl.value
  }

  private getMomentDateFormat(date: string) {
    return moment(date, "DD.MM.YYYY");
  }

  setMonthAndYear(normalizedMonthAndYear: moment.Moment, datepicker: NgxMatDatetimePicker<moment.Moment>) {
    if (!this.selectMonth) return;
    if (!this.form) return;
    if (!this.insuranceAttribute) return;

    const ctrlValue: Moment = moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    ctrlValue.set("date", 1);
    this.form.get(this.insuranceAttribute.id)
      .setValue(ctrlValue);
    datepicker.close();
  }

  

  private setDateValidation() {

    const validation = this.insuranceAttribute.validation;
    const isMonthSelector = !!(validation[ValidatorType.SelectMonth]||{}).value;
    Object.keys(validation).forEach((key) => {
      switch (key) {
        case ValidatorType.Min:
          if (validation[key].value.includes(ValidatorType.BasedOn)) {
             // Breaks the key, should have a format of "BASED_ON:<fieldKey>:<difference in days ( optional )>"
            const validationKeyParts = validation[key].value.split(':');
            const baseFieldKey = validationKeyParts[1];
            const differenceInDays = validationKeyParts.length > 2 && validationKeyParts[2] !== '' ?
              parseInt(validationKeyParts[2], 10)
              : 0; // Gets the difference in days if exists, otherwise sets it to 0
            this.form.controls[baseFieldKey].valueChanges.subscribe(value => { // Subscribe to a base control change
              const thisControl =  this.form.get(this.insuranceAttribute.id); // Access the current control
              const basedControlValue = moment(value).add(differenceInDays, 'days'); // Based control value after change
              if (thisControl && moment(thisControl.value).diff(basedControlValue, 'days') < 0) {
                // Resets the date to the base date if it's changed to a later date
                thisControl.setValue(basedControlValue);
              }
              this.min = basedControlValue; // Apply a new minimum date validation
            });
          } else if (validation[key].value.includes(ValidatorType.Range)) {

            const validationKeyParts = validation[key].value.split(':');
            const dateRange = validationKeyParts.length > 1 && validationKeyParts[1] !== '' ?
            validationKeyParts[1].split('+') : 0;
            this.min = moment().startOf('day').subtract(dateRange[0], dateRange[1]);

          }else {
            if (validation[key].value === ValidatorType.Today) {
              this.min = moment().startOf('day');
            } else if (validation[key].value.startsWith &&
                validation[key].value.startsWith(`${ValidatorType.Today}-`)) {
              const value = validation[key].value as string;
              const split = value.substring(value.indexOf('-') + 1).split(":");
              this.min = moment().startOf('day').subtract(parseInt(split[0]), split[1] as any);
              if (isMonthSelector) {
                this.min.set("date", 1);
              }
            } 
            else {
              // the date in json file is date format
              this.min = new Date(validation[key].value);
            }
          }
          break;
        case ValidatorType.Max:
          if (validation[key].value.includes(ValidatorType.BasedOn)) {
            // Breaks the key, should have a format of "BASED_ON:<fieldKey>:<difference in days ( optional )>"
            const validationKeyParts = validation[key].value.split(':');
            const baseFieldKey = validationKeyParts[1];
            const differenceInDays = validationKeyParts.length > 2 && validationKeyParts[2] !== '' ?
              parseInt(validationKeyParts[2], 10)
              : 0; // Gets the difference in days if exists, otherwise sets it to 0

            this.form.controls[baseFieldKey].valueChanges.subscribe(value => { // Subscribe to a base control change
              const basedControlValue = moment(value); // Based control value after change
              this.max = basedControlValue.add(differenceInDays, 'days'); // Apply a new minimum date validation
            });
          } else {
            if (validation[key].value === ValidatorType.Today) {
              this.max = moment().endOf('day');
            } else if (validation[key].value.startsWith &&
                validation[key].value.startsWith(`${ValidatorType.Today}-`)) {
              const value = validation[key].value as string;
              const split = value.substring(value.indexOf('-') + 1).split(":");
              this.max = moment().endOf('day').subtract(parseInt(split[0]), split[1] as any);
            }
            if (validation[key].value === ValidatorType.Yesterday) {
              this.max = moment().endOf('day').subtract(1, 'days');
            }
            if (validation[key].value === ValidatorType.Adult) {
              this.max = moment().startOf('day').subtract(18, 'years');
            }

          }
          break;
          case ValidatorType.HideTime:
              this.hideTime = validation[key].value;
              break;
          case ValidatorType.StartView:
              this.startView = validation[key].value;
              break;
          case ValidatorType.SelectMonth:
            this.selectMonth = validation[key].value;
            break;
          case ValidatorType.DateFormat:
            this.dateFormat = validation[key].value;
            break;
        default:
            break;
      }
    });
  }
}
