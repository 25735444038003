<div id="shop-wrapper" class="container-fluid" >
  <div>
    <!-- <app-header></app-header> -->
    <app-content-area>
    </app-content-area>
    <!-- <app-footer></app-footer> -->
  </div>

</div>
<app-page-portrait-mode></app-page-portrait-mode>
