import Debug from 'debug';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
const debug = Debug('dgoods:shop:SessionOrchestrator');
export class SessionOrchestrator {
    constructor(router) {
        this.router = router;
    }
    onSessionExired() {
        debug(this.router.url);
        if (!this.router.isActive('/de-ch/login', false)) {
            debug('onSessionExired redirect to mocked');
            this.router.navigateByUrl('/de-ch/login');
        }
    }
    onServiceIsOffline(request) {
        debug("Service is offline:", request);
        if (request.url.split("localization").length > 1) {
            this.router.navigateByUrl('/de-ch/majorerror');
        }
        else {
            this.router.navigateByUrl('/de-ch/error');
        }
    }
}
SessionOrchestrator.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionOrchestrator_Factory() { return new SessionOrchestrator(i0.ɵɵinject(i1.Router)); }, token: SessionOrchestrator, providedIn: "root" });
