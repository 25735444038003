import { Injector, Directive, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import Debug from 'debug';
import { BasePageComponent } from '../base.page';
import { DGoodsButtonComponent, IDGoodsButtonDelegate } from '@modeso/twint-lib-core-fe';


const debug = Debug('dgoods:project:TermsOfProviderPageController');
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'app-terms-of-provider.page-controller'
})
export class TermsOfProviderPageControllerDirective extends BasePageComponent implements IDGoodsButtonDelegate {
  @Input() enforceTC: boolean;
  @Input() hiddenTermsOfProvider: boolean;
  @Output() hideTermsOfProvider = new EventEmitter<any>();

  isTermsScrollable: boolean;
  termsScrollStart = false;
  termsScrollEnd = false;
  scrollListener;

  @ViewChild('termsContent', { static: false }) termsContent: ElementRef;
  @ViewChild('overlay', { static: false }) overlay: ElementRef;
  constructor(injector: Injector, private router: Router, private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef, private renderer2: Renderer2) {
    super(injector);
  }

  ngOnInit() {
    if (this.enforceTC) {
      this.scrollListener = this.renderer2.listen('window', 'scroll', (event) => {

        if (!this.hiddenTermsOfProvider) {
          debug("Scroll");
          debug(window.innerHeight); //Window height  2
          debug(this.overlay.nativeElement.scrollHeight); //Overlay height // 3
          debug(window.scrollY); // 4
          // 2 + 4 = 3
          if (window.scrollY > 0) {
            this.termsScrollStart = true;
          }
          if (this.overlay.nativeElement.scrollHeight === window.innerHeight + window.scrollY) {
            debug("reached end");
            this.termsScrollEnd = true;
          }
        }

      });
    }
    super.ngOnInit();
  }

  ngOnDestroy() {
    if (this.scrollListener) {
      this.scrollListener();
    }
    super.ngOnDestroy();
  }

  ngAfterViewChecked() {
    this.isTermsScrollable = window.innerHeight < this.overlay.nativeElement.scrollHeight;
    this.changeDetector.detectChanges();
  }

  /**
   * IDGoodsTextButtonDelegate
   */
  onButtonClicked(sender: DGoodsButtonComponent): void {
    if (this.enforceTC) {
      if (sender.state === 'notAction') {
        this.hideTermsOfProvider.emit(false);
      } else {
        this.hideTermsOfProvider.emit(true);
      }
    } else {
      this.hideTermsOfProvider.emit();
    }
  }

  getProductId(): string {
    const productLineId = this.route.snapshot.paramMap.get('productLineId');
    return productLineId;
  }


}
