import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InsuranceProvider } from '@modeso/ihub-lib-insurance-fe';
import { UserProvider } from '@modeso/ihub-lib-user-fe';
import { AnalyticsProvider } from '@modeso/twint-lib-analytics-fe';
import { DGoodsButtonComponent, IDGoodsButtonDelegate } from '@modeso/twint-lib-core-fe';
import { IInsuranceOperationResponse } from '@modeso/types__ihub-lib-insurance-be';
import { CancellationPolicyController } from './cancellation-policy-page.controller';
import { take } from 'rxjs/operators';
import moment from 'moment';


@Component({
  selector: 'app-cancelation-policy-page',
  templateUrl: './cancelation-policy-page.component.html',
  styleUrls: ['./cancelation-policy-page.component.scss']
})
export class CancelationPolicyPageComponent extends CancellationPolicyController implements OnInit, IDGoodsButtonDelegate {

  public insuranceToBeCanceled: IInsuranceOperationResponse;
  public firstPossibleCancellationDate: Date;
  private personId: string;
  person;

  constructor(injector: Injector, router: Router, activated: ActivatedRoute,
    private insuranceProvider: InsuranceProvider, analyticsProvider: AnalyticsProvider,
    private userProvider: UserProvider
  ) {
    super(injector, router, activated, analyticsProvider);
    this.personId = localStorage.getItem('personId') ? localStorage.getItem('personId') : undefined;
    if (this.personId) {
      this.getPerson();
    }

    if (!this.router.getCurrentNavigation().extras.state) {
      this.subscriptions.push(this.route.params.subscribe((param) => {
        const orderUuid = param.orderUuid;
        this.subscriptions.push(this.insuranceProvider.getInsuranceByOrderUuid$(orderUuid).subscribe((value) => {
          this.insuranceToBeCanceled = value;
          if (this.insuranceToBeCanceled) {
            sessionStorage.setItem("validFrom", this.insuranceToBeCanceled.validFrom!.toString());
            this.setFirstCancelationPermittedDate();
          }

        }));
      }));
    } else {
      this.insuranceToBeCanceled = this.router.getCurrentNavigation().extras.state.insurance;
      this.setFirstCancelationPermittedDate();
      sessionStorage.setItem("validFrom", this.insuranceToBeCanceled.validFrom!.toString());
    }
  }
  ngOnInit() {
    super.ngOnInit();
  }

  onButtonClicked(sender: DGoodsButtonComponent, identifier?: string): void {
    this.router.navigateByUrl(`${this.languageFromURL}/my-insurance-detail/${this.insuranceToBeCanceled.orderUuid}`);
  }

  onClick() {
    this.router.navigateByUrl(`${this.languageFromURL}/second-cancelation-step/${this.insuranceToBeCanceled.orderUuid}`);
  }

  private setFirstCancelationPermittedDate() {
      const validFromDate = moment(this.insuranceToBeCanceled.validFrom).startOf('day')
      const today = moment().endOf('day')
      const min6monthAhead = Math.max(today.diff(moment(validFromDate),'months') + 1, 6)
      var endDate = moment(validFromDate).add(min6monthAhead, 'month').add(-1, 'days')
      if (endDate.diff(today, 'month') < 1) {
        endDate = endDate.add(1, 'month')
      }
      this.firstPossibleCancellationDate = endDate.toDate()
  }

  getPerson() {
    let index = 0;
    this.userProvider.getPersonOfUser$().pipe(take(2))
      .subscribe((person) => {
        index++;
        if (index === 2) {
          if (person) {
            this.person = person;
          }
        }
      });
  }

}
