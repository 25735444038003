import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IHubPopUpComponent, IIHubPopupContent } from '@modeso/twint-lib-core-fe';
import { IConfiguredAttribute } from '@modeso/types__ihub-lib-products-be';
import { IInsuranceAttributeControl } from '../../ComponentFactory/IInputControl.interface';
import { BaseControl } from '../Base';

@Component({
  selector: 'ihub-input-text-control',
  templateUrl: './input-text-control.component.html',
  styleUrls: ['./input-text-control.component.scss']
})
export class InputTextControlComponent extends BaseControl implements OnInit, IInsuranceAttributeControl  {
  @ViewChild('popup', { static: true }) popup: IHubPopUpComponent;
  popupContent: IIHubPopupContent;
  insuranceAttribute: IConfiguredAttribute;
  form: FormGroup;
  constructor() { super(); }
  validationErrors: any;

  ngOnInit() {}

  public openPopup(event: any) {
    event.stopPropagation();
    this.popupContent = {
      title: (this.insuranceAttribute.title as string),
      text: (this.insuranceAttribute.text as string),
      needsTranslation: false,
    };
    this.popup.visible = true;
  }

}
