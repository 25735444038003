<div class="product-buy-container">
  <div class="Rectangle-Copy">
    <div class="personal-info">
      {{'ihub_shop_my_account' | injectedText | async}}
    </div>
    <div class="fill-in-form">
      {{'ihub_shop_account_personal_details' | injectedText | async}}
    </div>
    <div class="faq-div">
      <a (click)="gotoFAQ()">{{'ihub_shop_faq' | injectedText | async}}</a>
    </div>
  </div>

  <app-user-detail-page #userDetails></app-user-detail-page>

  <div style="background-color: white; margin-bottom: 16px; margin-top: 8px">
    <div class="Rectangle-Copy">
      <div class="personal-info">
        {{'ihub_shop_payment' | injectedText | async}}
      </div>
      <div class="payment-content">
        {{'ihub_shop_activate_auto_payment' | injectedText | async}}
      </div>

      <div class="payment-div">
        <button
          class="active_auto_payment_btn"
          (click)="linkToTwint()"
          [disabled]="waitingForUofResponse"
        >
          <mat-spinner
            *ngIf="waitingForUofResponse"
            diameter="20"
          ></mat-spinner>
          {{'ihub_shop_link_to_twint' | injectedText | async}}
        </button>
      </div>
    </div>

    <div class="div-1">
      <input
        type="checkbox"
        id="hasAccount"
        name="hasAccount"
        [checked]="userDetails.hasAccount"
        (change)="userDetails.hasAccount = !userDetails.hasAccount;"
        [disabled]="userDetails.hasAccount"
        readonly="readonly"
      />
      <label for="hasAccount" class="label-1"></label>
      <span
        class="span-text"
        [innerHTML]="'ihub_shop_accept_insurance' | injectedText | async"
      ></span>
    </div>

    <div class="div-1">
      <input
        type="checkbox"
        id="receiveEmails"
        name="receiveEmails"
        [checked]="userDetails.userModel.receiveEmails"
        (change)="userDetails.userModel.receiveEmails = !userDetails.userModel.receiveEmails;"
      />
      <label for="receiveEmails" class="label-1"></label>
      <span
        class="span-text"
        [innerHTML]="'ihub_shop_accept_to_recieve_mails' | injectedText | async"
      ></span>
    </div>

    <div style="margin-bottom: 10px">
      <dgoods-button
        [delegate]="this"
        identifier="next"
        [disabled]="!userDetails.hasAccount || !userDetails.formValidity"
      >
        {{ "ihub_shop_save_btn" | injectedText | async }}
      </dgoods-button>
    </div>
    <div>
      <dgoods-button
        [delegate]="this"
        class="button-style"
        state="notAction"
        identifier="back"
      >
        {{ "dgoods_back_button" | injectedText | async }}
      </dgoods-button>
    </div>
  </div>
</div>
